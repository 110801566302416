import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-feedly', 'IconBrandFeedly', [
  ['path', { d: 'M7.833 12.278l4.445 -4.445', key: 'svg-0' }],
  ['path', { d: 'M10.055 14.5l2.223 -2.222', key: 'svg-1' }],
  ['path', { d: 'M12.278 16.722l.555 -.555', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M19.828 14.828a4 4 0 0 0 0 -5.656l-5 -5a4 4 0 0 0 -5.656 0l-5 5a4 4 0 0 0 0 5.656l6.171 6.172h3.314l6.171 -6.172z',
      key: 'svg-3',
    },
  ],
]);
