import createVueComponent from '../createVueComponent';
export default createVueComponent('binary-off', 'IconBinaryOff', [
  ['path', { d: 'M11 7v-2h-1', key: 'svg-0' }],
  ['path', { d: 'M18 19v-1', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M15.5 5h2a.5 .5 0 0 1 .5 .5v4a.5 .5 0 0 1 -.5 .5h-2a.5 .5 0 0 1 -.5 -.5v-4a.5 .5 0 0 1 .5 -.5z',
      key: 'svg-2',
    },
  ],
  [
    'path',
    {
      d: 'M10.5 14h2a.5 .5 0 0 1 .5 .5v4a.5 .5 0 0 1 -.5 .5h-2a.5 .5 0 0 1 -.5 -.5v-4a.5 .5 0 0 1 .5 -.5z',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M6 10v.01', key: 'svg-4' }],
  ['path', { d: 'M6 19v.01', key: 'svg-5' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
]);
