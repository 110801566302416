import createVueComponent from '../createVueComponent';
export default createVueComponent('ticket-off', 'IconTicketOff', [
  ['path', { d: 'M15 5v2', key: 'svg-0' }],
  ['path', { d: 'M15 17v2', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M9 5h10a2 2 0 0 1 2 2v3a2 2 0 1 0 0 4v3m-2 2h-14a2 2 0 0 1 -2 -2v-3a2 2 0 1 0 0 -4v-3a2 2 0 0 1 2 -2',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
