import createVueComponent from '../createVueComponent';
export default createVueComponent('container', 'IconContainer', [
  ['path', { d: 'M20 4v.01', key: 'svg-0' }],
  ['path', { d: 'M20 20v.01', key: 'svg-1' }],
  ['path', { d: 'M20 16v.01', key: 'svg-2' }],
  ['path', { d: 'M20 12v.01', key: 'svg-3' }],
  ['path', { d: 'M20 8v.01', key: 'svg-4' }],
  [
    'path',
    {
      d: 'M8 4m0 1a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1z',
      key: 'svg-5',
    },
  ],
  ['path', { d: 'M4 4v.01', key: 'svg-6' }],
  ['path', { d: 'M4 20v.01', key: 'svg-7' }],
  ['path', { d: 'M4 16v.01', key: 'svg-8' }],
  ['path', { d: 'M4 12v.01', key: 'svg-9' }],
  ['path', { d: 'M4 8v.01', key: 'svg-10' }],
]);
