import createVueComponent from '../createVueComponent';
export default createVueComponent('file-zip', 'IconFileZip', [
  [
    'path',
    {
      d: 'M6 20.735a2 2 0 0 1 -1 -1.735v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-1',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M11 17a2 2 0 0 1 2 2v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-2a2 2 0 0 1 2 -2z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M11 5l-1 0', key: 'svg-2' }],
  ['path', { d: 'M13 7l-1 0', key: 'svg-3' }],
  ['path', { d: 'M11 9l-1 0', key: 'svg-4' }],
  ['path', { d: 'M13 11l-1 0', key: 'svg-5' }],
  ['path', { d: 'M11 13l-1 0', key: 'svg-6' }],
  ['path', { d: 'M13 15l-1 0', key: 'svg-7' }],
]);
