import createVueComponent from '../createVueComponent';
export default createVueComponent('layout-align-left', 'IconLayoutAlignLeft', [
  ['path', { d: 'M4 4l0 16', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M8 9m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v2a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z',
      key: 'svg-1',
    },
  ],
]);
