import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'arrow-autofit-height',
  'IconArrowAutofitHeight',
  [
    [
      'path',
      { d: 'M12 20h-6a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h6', key: 'svg-0' },
    ],
    ['path', { d: 'M18 14v7', key: 'svg-1' }],
    ['path', { d: 'M18 3v7', key: 'svg-2' }],
    ['path', { d: 'M15 18l3 3l3 -3', key: 'svg-3' }],
    ['path', { d: 'M15 6l3 -3l3 3', key: 'svg-4' }],
  ],
);
