import createVueComponent from '../createVueComponent';
export default createVueComponent('oval-vertical', 'IconOvalVertical', [
  [
    'path',
    {
      d: 'M3 12c0 -3.314 4.03 -6 9 -6s9 2.686 9 6s-4.03 6 -9 6s-9 -2.686 -9 -6z',
      key: 'svg-0',
    },
  ],
]);
