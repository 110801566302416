import createVueComponent from '../createVueComponent';
export default createVueComponent('play-card', 'IconPlayCard', [
  [
    'path',
    {
      d: 'M19 5v14a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8 6h.01', key: 'svg-1' }],
  ['path', { d: 'M16 18h.01', key: 'svg-2' }],
  ['path', { d: 'M12 16l-3 -4l3 -4l3 4z', key: 'svg-3' }],
]);
