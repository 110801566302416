import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-tidal', 'IconBrandTidal', [
  [
    'path',
    {
      d: 'M5.333 6l3.334 3.25l3.333 -3.25l3.333 3.25l3.334 -3.25l3.333 3.25l-3.333 3.25l-3.334 -3.25l-3.333 3.25l3.333 3.25l-3.333 3.25l-3.333 -3.25l3.333 -3.25l-3.333 -3.25l-3.334 3.25l-3.333 -3.25z',
      key: 'svg-0',
    },
  ],
]);
