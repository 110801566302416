import createVueComponent from '../createVueComponent';
export default createVueComponent('abacus', 'IconAbacus', [
  ['path', { d: 'M5 3v18', key: 'svg-0' }],
  ['path', { d: 'M19 21v-18', key: 'svg-1' }],
  ['path', { d: 'M5 7h14', key: 'svg-2' }],
  ['path', { d: 'M5 15h14', key: 'svg-3' }],
  ['path', { d: 'M8 13v4', key: 'svg-4' }],
  ['path', { d: 'M11 13v4', key: 'svg-5' }],
  ['path', { d: 'M16 13v4', key: 'svg-6' }],
  ['path', { d: 'M14 5v4', key: 'svg-7' }],
  ['path', { d: 'M11 5v4', key: 'svg-8' }],
  ['path', { d: 'M8 5v4', key: 'svg-9' }],
  ['path', { d: 'M3 21h18', key: 'svg-10' }],
]);
