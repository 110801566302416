import createVueComponent from '../createVueComponent';
export default createVueComponent('box-align-left', 'IconBoxAlignLeft', [
  [
    'path',
    {
      d: 'M10.002 20.003v-16h-5a1 1 0 0 0 -1 1v14a1 1 0 0 0 1 1h5z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M15.002 20.003h-.01', key: 'svg-1' }],
  ['path', { d: 'M20.003 20.003h-.011', key: 'svg-2' }],
  ['path', { d: 'M20.003 15.002h-.011', key: 'svg-3' }],
  ['path', { d: 'M20.003 9.002h-.011', key: 'svg-4' }],
  ['path', { d: 'M20.003 4.002h-.011', key: 'svg-5' }],
  ['path', { d: 'M15.002 4.002h-.01', key: 'svg-6' }],
]);
