import createVueComponent from '../createVueComponent';
export default createVueComponent('paperclip', 'IconPaperclip', [
  [
    'path',
    {
      d: 'M15 7l-6.5 6.5a1.5 1.5 0 0 0 3 3l6.5 -6.5a3 3 0 0 0 -6 -6l-6.5 6.5a4.5 4.5 0 0 0 9 9l6.5 -6.5',
      key: 'svg-0',
    },
  ],
]);
