import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-dingtalk', 'IconBrandDingtalk', [
  ['path', { d: 'M21 12a9 9 0 1 1 -18 0a9 9 0 0 1 18 0z', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M8 7.5l7.02 2.632a1 1 0 0 1 .567 1.33l-1.087 2.538h1.5l-5 4l1 -4c-3.1 .03 -3.114 -3.139 -4 -6.5z',
      key: 'svg-1',
    },
  ],
]);
