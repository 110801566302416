import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'box-align-top-right',
  'IconBoxAlignTopRight',
  [
    [
      'path',
      {
        d: 'M19 11.01h-5a1 1 0 0 1 -1 -1v-5a1 1 0 0 1 1 -1h5a1 1 0 0 1 1 1v5a1 1 0 0 1 -1 1z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M20 15.01v-.01', key: 'svg-1' }],
    ['path', { d: 'M20 20.01v-.01', key: 'svg-2' }],
    ['path', { d: 'M15 20.01v-.01', key: 'svg-3' }],
    ['path', { d: 'M9 20.01v-.01', key: 'svg-4' }],
    ['path', { d: 'M9 4.01v-.01', key: 'svg-5' }],
    ['path', { d: 'M4 20.01v-.01', key: 'svg-6' }],
    ['path', { d: 'M4 15.01v-.01', key: 'svg-7' }],
    ['path', { d: 'M4 9.01v-.01', key: 'svg-8' }],
    ['path', { d: 'M4 4.01v-.01', key: 'svg-9' }],
  ],
);
