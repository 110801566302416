import createVueComponent from '../createVueComponent';
export default createVueComponent('lock-star', 'IconLockStar', [
  [
    'path',
    { d: 'M11 21h-4a2 2 0 0 1 -2 -2v-6a2 2 0 0 1 2 -2h9', key: 'svg-0' },
  ],
  ['path', { d: 'M8 11v-4a4 4 0 1 1 8 0v4', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M17.8 20.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z',
      key: 'svg-2',
    },
  ],
]);
