import createVueComponent from '../createVueComponent';
export default createVueComponent('crystal-ball', 'IconCrystalBall', [
  ['path', { d: 'M6.73 17.018a8 8 0 1 1 10.54 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M5 19a2 2 0 0 0 2 2h10a2 2 0 1 0 0 -4h-10a2 2 0 0 0 -2 2z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M11 7a3 3 0 0 0 -3 3', key: 'svg-2' }],
]);
