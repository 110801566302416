import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'database-exclamation',
  'IconDatabaseExclamation',
  [
    [
      'path',
      {
        d: 'M4 6c0 1.657 3.582 3 8 3s8 -1.343 8 -3s-3.582 -3 -8 -3s-8 1.343 -8 3',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M4 6v6c0 1.657 3.582 3 8 3c1.118 0 2.182 -.086 3.148 -.241m4.852 -2.759v-6',
        key: 'svg-1',
      },
    ],
    [
      'path',
      {
        d: 'M4 12v6c0 1.657 3.582 3 8 3c1.064 0 2.079 -.078 3.007 -.22',
        key: 'svg-2',
      },
    ],
    ['path', { d: 'M19 16v3', key: 'svg-3' }],
    ['path', { d: 'M19 22v.01', key: 'svg-4' }],
  ],
);
