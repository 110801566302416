import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-finder', 'IconBrandFinder', [
  [
    'path',
    {
      d: 'M3 4m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7 8v1', key: 'svg-1' }],
  ['path', { d: 'M17 8v1', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M12.5 4c-.654 1.486 -1.26 3.443 -1.5 9h2.5c-.19 2.867 .094 5.024 .5 7',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M7 15.5c3.667 2 6.333 2 10 0', key: 'svg-4' }],
]);
