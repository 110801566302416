import createVueComponent from '../createVueComponent';
export default createVueComponent('cube', 'IconCube', [
  [
    'path',
    {
      d: 'M21 16.008v-8.018a1.98 1.98 0 0 0 -1 -1.717l-7 -4.008a2.016 2.016 0 0 0 -2 0l-7 4.008c-.619 .355 -1 1.01 -1 1.718v8.018c0 .709 .381 1.363 1 1.717l7 4.008a2.016 2.016 0 0 0 2 0l7 -4.008c.619 -.355 1 -1.01 1 -1.718z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 22v-10', key: 'svg-1' }],
  ['path', { d: 'M12 12l8.73 -5.04', key: 'svg-2' }],
  ['path', { d: 'M3.27 6.96l8.73 5.04', key: 'svg-3' }],
]);
