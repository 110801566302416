import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'creative-commons-by',
  'IconCreativeCommonsBy',
  [
    ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
    ['path', { d: 'M12 7m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M9 13v-1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-.5l-.5 4h-2l-.5 -4h-.5a1 1 0 0 1 -1 -1z',
        key: 'svg-2',
      },
    ],
  ],
);
