import createVueComponent from '../createVueComponent';
export default createVueComponent('mailbox', 'IconMailbox', [
  [
    'path',
    {
      d: 'M10 21v-6.5a3.5 3.5 0 0 0 -7 0v6.5h18v-6a4 4 0 0 0 -4 -4h-10.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 11v-8h4l2 2l-2 2h-4', key: 'svg-1' }],
  ['path', { d: 'M6 15h1', key: 'svg-2' }],
]);
