import createVueComponent from '../createVueComponent';
export default createVueComponent('mail-opened', 'IconMailOpened', [
  ['path', { d: 'M3 9l9 6l9 -6l-9 -6l-9 6', key: 'svg-0' }],
  [
    'path',
    { d: 'M21 9v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10', key: 'svg-1' },
  ],
  ['path', { d: 'M3 19l6 -6', key: 'svg-2' }],
  ['path', { d: 'M15 13l6 6', key: 'svg-3' }],
]);
