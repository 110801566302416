import createVueComponent from '../createVueComponent';
export default createVueComponent('message-2-pause', 'IconMessage2Pause', [
  ['path', { d: 'M8 9h8', key: 'svg-0' }],
  ['path', { d: 'M8 13h6', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M13 20l-1 1l-3 -3h-3a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v6',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M17 17v5', key: 'svg-3' }],
  ['path', { d: 'M21 17v5', key: 'svg-4' }],
]);
