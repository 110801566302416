import createVueComponent from '../createVueComponent';
export default createVueComponent('comet', 'IconComet', [
  [
    'path',
    {
      d: 'M15.5 18.5l-3 1.5l.5 -3.5l-2 -2l3 -.5l1.5 -3l1.5 3l3 .5l-2 2l.5 3.5z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 4l7 7', key: 'svg-1' }],
  ['path', { d: 'M9 4l3.5 3.5', key: 'svg-2' }],
  ['path', { d: 'M4 9l3.5 3.5', key: 'svg-3' }],
]);
