import createVueComponent from '../createVueComponent';
export default createVueComponent('armchair-2', 'IconArmchair2', [
  ['path', { d: 'M5 10v-4a3 3 0 0 1 3 -3h8a3 3 0 0 1 3 3v4', key: 'svg-0' }],
  [
    'path',
    { d: 'M16 15v-2a3 3 0 1 1 3 3v3h-14v-3a3 3 0 1 1 3 -3v2', key: 'svg-1' },
  ],
  ['path', { d: 'M8 12h8', key: 'svg-2' }],
  ['path', { d: 'M7 19v2', key: 'svg-3' }],
  ['path', { d: 'M17 19v2', key: 'svg-4' }],
]);
