import createVueComponent from '../createVueComponent';
export default createVueComponent('clover-2', 'IconClover2', [
  [
    'path',
    {
      d: 'M11 11l-3.397 -3.44a2.104 2.104 0 0 1 0 -2.95a2.04 2.04 0 0 1 2.912 0l.485 .39l.485 -.39a2.04 2.04 0 0 1 2.912 0a2.104 2.104 0 0 1 0 2.95l-3.397 3.44z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M11 11l-3.397 3.44a2.104 2.104 0 0 0 0 2.95a2.04 2.04 0 0 0 2.912 0l.485 -.39l.485 .39a2.04 2.04 0 0 0 2.912 0a2.104 2.104 0 0 0 0 -2.95l-3.397 -3.44z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M14.44 7.603a2.104 2.104 0 0 1 2.95 0a2.04 2.04 0 0 1 0 2.912l-.39 .485l.39 .485a2.04 2.04 0 0 1 0 2.912a2.104 2.104 0 0 1 -2.95 0',
      key: 'svg-2',
    },
  ],
  [
    'path',
    {
      d: 'M7.56 7.603a2.104 2.104 0 0 0 -2.95 0a2.04 2.04 0 0 0 0 2.912l.39 .485l-.39 .485a2.04 2.04 0 0 0 0 2.912a2.104 2.104 0 0 0 2.95 0',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M15 15l6 6', key: 'svg-4' }],
]);
