import createVueComponent from '../createVueComponent';
export default createVueComponent('photo-search', 'IconPhotoSearch', [
  ['path', { d: 'M15 8h.01', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M11.5 21h-5.5a3 3 0 0 1 -3 -3v-12a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v5.5',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-2' }],
  ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-3' }],
  ['path', { d: 'M3 16l5 -5c.928 -.893 2.072 -.893 3 0l2 2', key: 'svg-4' }],
]);
