import createVueComponent from '../createVueComponent';
export default createVueComponent('world-plus', 'IconWorldPlus', [
  ['path', { d: 'M20.985 12.518a9 9 0 1 0 -8.45 8.466', key: 'svg-0' }],
  ['path', { d: 'M3.6 9h16.8', key: 'svg-1' }],
  ['path', { d: 'M3.6 15h11.4', key: 'svg-2' }],
  ['path', { d: 'M11.5 3a17 17 0 0 0 0 18', key: 'svg-3' }],
  ['path', { d: 'M12.5 3a16.998 16.998 0 0 1 2.283 12.157', key: 'svg-4' }],
  ['path', { d: 'M16 19h6', key: 'svg-5' }],
  ['path', { d: 'M19 16v6', key: 'svg-6' }],
]);
