import createVueComponent from '../createVueComponent';
export default createVueComponent('triangle-inverted', 'IconTriangleInverted', [
  [
    'path',
    {
      d: 'M10.24 20.043l-8.422 -14.06a1.989 1.989 0 0 1 1.7 -2.983h16.845a1.989 1.989 0 0 1 1.7 2.983l-8.422 14.06a1.989 1.989 0 0 1 -3.4 0z',
      key: 'svg-0',
    },
  ],
]);
