import createVueComponent from '../createVueComponent';
export default createVueComponent('book-off', 'IconBookOff', [
  ['path', { d: 'M3 19a9 9 0 0 1 9 0a9 9 0 0 1 5.899 -1.096', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M3 6a9 9 0 0 1 2.114 -.884m3.8 -.21c1.07 .17 2.116 .534 3.086 1.094a9 9 0 0 1 9 0',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 6v13', key: 'svg-2' }],
  ['path', { d: 'M12 6v2m0 4v7', key: 'svg-3' }],
  ['path', { d: 'M21 6v11', key: 'svg-4' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
