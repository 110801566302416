import createVueComponent from '../createVueComponent';
export default createVueComponent('database-export', 'IconDatabaseExport', [
  [
    'path',
    {
      d: 'M4 6c0 1.657 3.582 3 8 3s8 -1.343 8 -3s-3.582 -3 -8 -3s-8 1.343 -8 3',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M4 6v6c0 1.657 3.582 3 8 3c1.118 0 2.183 -.086 3.15 -.241',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M20 12v-6', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M4 12v6c0 1.657 3.582 3 8 3c.157 0 .312 -.002 .466 -.005',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M16 19h6', key: 'svg-4' }],
  ['path', { d: 'M19 16l3 3l-3 3', key: 'svg-5' }],
]);
