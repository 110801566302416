import createVueComponent from '../createVueComponent';
export default createVueComponent('math-1-divide-3', 'IconMath1Divide3', [
  [
    'path',
    {
      d: 'M10 15.5a.5 .5 0 0 1 .5 -.5h2a1.5 1.5 0 0 1 0 3h-1.167h1.167a1.5 1.5 0 0 1 0 3h-2a.5 .5 0 0 1 -.5 -.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M5 12h14', key: 'svg-1' }],
  ['path', { d: 'M10 5l2 -2v6', key: 'svg-2' }],
]);
