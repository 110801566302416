import createVueComponent from '../createVueComponent';
export default createVueComponent('cloud-upload', 'IconCloudUpload', [
  [
    'path',
    {
      d: 'M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 15l3 -3l3 3', key: 'svg-1' }],
  ['path', { d: 'M12 12l0 9', key: 'svg-2' }],
]);
