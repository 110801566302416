import createVueComponent from '../createVueComponent';
export default createVueComponent('battery-eco', 'IconBatteryEco', [
  [
    'path',
    {
      d: 'M4 9a2 2 0 0 1 2 -2h11a2 2 0 0 1 2 2v.5a.5 .5 0 0 0 .5 .5a.5 .5 0 0 1 .5 .5v3a.5 .5 0 0 1 -.5 .5a.5 .5 0 0 0 -.5 .5v.5a2 2 0 0 1 -2 2h-5.5',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M3 16.143c0 -2.84 2.09 -5.143 4.667 -5.143h2.333v.857c0 2.84 -2.09 5.143 -4.667 5.143h-2.333v-.857z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 20v-3', key: 'svg-2' }],
]);
