import createVueComponent from '../createVueComponent';
export default createVueComponent('wallpaper-off', 'IconWallpaperOff', [
  [
    'path',
    {
      d: 'M10 6h8a2 2 0 0 1 2 2v8m-.58 3.409a2 2 0 0 1 -1.42 .591h-12',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M6 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M8 18v-10m-3.427 -3.402c-.353 .362 -.573 .856 -.573 1.402v12',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
