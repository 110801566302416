import createVueComponent from '../createVueComponent';
export default createVueComponent('message-2-check', 'IconMessage2Check', [
  ['path', { d: 'M8 9h8', key: 'svg-0' }],
  ['path', { d: 'M8 13h6', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M12 21l-1 -1l-2 -2h-3a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v6',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M15 19l2 2l4 -4', key: 'svg-3' }],
]);
