import createVueComponent from '../createVueComponent';
export default createVueComponent('medicine-syrup', 'IconMedicineSyrup', [
  [
    'path',
    {
      d: 'M8 21h8a1 1 0 0 0 1 -1v-10a3 3 0 0 0 -3 -3h-4a3 3 0 0 0 -3 3v10a1 1 0 0 0 1 1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 14h4', key: 'svg-1' }],
  ['path', { d: 'M12 12v4', key: 'svg-2' }],
  ['path', { d: 'M10 7v-3a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v3', key: 'svg-3' }],
]);
