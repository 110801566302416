import createVueComponent from '../createVueComponent';
export default createVueComponent('ad', 'IconAd', [
  [
    'path',
    {
      d: 'M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7 15v-4a2 2 0 0 1 4 0v4', key: 'svg-1' }],
  ['path', { d: 'M7 13l4 0', key: 'svg-2' }],
  ['path', { d: 'M17 9v6h-1.5a1.5 1.5 0 1 1 1.5 -1.5', key: 'svg-3' }],
]);
