import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-xamarin', 'IconBrandXamarin', [
  [
    'path',
    {
      d: 'M15.958 21h-7.917a2 2 0 0 1 -1.732 -1l-4.041 -7a2 2 0 0 1 0 -2l4.041 -7a2 2 0 0 1 1.732 -1h7.917a2 2 0 0 1 1.732 1l4.042 7a2 2 0 0 1 0 2l-4.041 7a2 2 0 0 1 -1.733 1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M15 16l-6 -8', key: 'svg-1' }],
  ['path', { d: 'M9 16l6 -8', key: 'svg-2' }],
]);
