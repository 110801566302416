import createVueComponent from '../createVueComponent';
export default createVueComponent('grill-spatula', 'IconGrillSpatula', [
  ['path', { d: 'M10.2 10.2l6.3 6.3', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M19.347 16.575l1.08 1.079a1.96 1.96 0 0 1 -2.773 2.772l-1.08 -1.079a1.96 1.96 0 0 1 2.773 -2.772z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M3 7l3.05 3.15a2.9 2.9 0 0 0 4.1 -4.1l-3.15 -3.05l-4 4z',
      key: 'svg-2',
    },
  ],
]);
