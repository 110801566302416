import createVueComponent from '../createVueComponent';
export default createVueComponent('pool', 'IconPool', [
  [
    'path',
    {
      d: 'M2 20a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M2 16a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M15 12v-7.5a1.5 1.5 0 0 1 3 0', key: 'svg-2' }],
  ['path', { d: 'M9 12v-7.5a1.5 1.5 0 0 0 -3 0', key: 'svg-3' }],
  ['path', { d: 'M15 5l-6 0', key: 'svg-4' }],
  ['path', { d: 'M9 10l6 0', key: 'svg-5' }],
]);
