import createVueComponent from '../createVueComponent';
export default createVueComponent('cards', 'IconCards', [
  [
    'path',
    {
      d: 'M3.604 7.197l7.138 -3.109a.96 .96 0 0 1 1.27 .527l4.924 11.902a1 1 0 0 1 -.514 1.304l-7.137 3.109a.96 .96 0 0 1 -1.271 -.527l-4.924 -11.903a1 1 0 0 1 .514 -1.304z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M15 4h1a1 1 0 0 1 1 1v3.5', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M20 6c.264 .112 .52 .217 .768 .315a1 1 0 0 1 .53 1.311l-2.298 5.374',
      key: 'svg-2',
    },
  ],
]);
