import createVueComponent from '../createVueComponent';
export default createVueComponent('device-sd-card', 'IconDeviceSdCard', [
  [
    'path',
    {
      d: 'M7 21h10a2 2 0 0 0 2 -2v-14a2 2 0 0 0 -2 -2h-6.172a2 2 0 0 0 -1.414 .586l-3.828 3.828a2 2 0 0 0 -.586 1.414v10.172a2 2 0 0 0 2 2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M13 6v2', key: 'svg-1' }],
  ['path', { d: 'M16 6v2', key: 'svg-2' }],
  ['path', { d: 'M10 7v1', key: 'svg-3' }],
]);
