import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-nextcloud', 'IconBrandNextcloud', [
  ['path', { d: 'M12 12m-5 0a5 5 0 1 0 10 0a5 5 0 1 0 -10 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M4.5 12.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M19.5 12.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0',
      key: 'svg-2',
    },
  ],
]);
