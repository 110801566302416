import createVueComponent from '../createVueComponent';
export default createVueComponent('vaccine', 'IconVaccine', [
  ['path', { d: 'M17 3l4 4', key: 'svg-0' }],
  ['path', { d: 'M19 5l-4.5 4.5', key: 'svg-1' }],
  ['path', { d: 'M11.5 6.5l6 6', key: 'svg-2' }],
  ['path', { d: 'M16.5 11.5l-6.5 6.5h-4v-4l6.5 -6.5', key: 'svg-3' }],
  ['path', { d: 'M7.5 12.5l1.5 1.5', key: 'svg-4' }],
  ['path', { d: 'M10.5 9.5l1.5 1.5', key: 'svg-5' }],
  ['path', { d: 'M3 21l3 -3', key: 'svg-6' }],
]);
