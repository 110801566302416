import createVueComponent from '../createVueComponent';
export default createVueComponent('device-mobile-x', 'IconDeviceMobileX', [
  [
    'path',
    {
      d: 'M13 21h-5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M22 22l-5 -5', key: 'svg-1' }],
  ['path', { d: 'M17 22l5 -5', key: 'svg-2' }],
  ['path', { d: 'M11 4h2', key: 'svg-3' }],
  ['path', { d: 'M12 17v.01', key: 'svg-4' }],
]);
