import createVueComponent from '../createVueComponent';
export default createVueComponent('page-break', 'IconPageBreak', [
  ['path', { d: 'M14 3v4a1 1 0 0 0 1 1h4', key: 'svg-0' }],
  [
    'path',
    { d: 'M19 18v1a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-1', key: 'svg-1' },
  ],
  ['path', { d: 'M3 14h3m4.5 0h3m4.5 0h3', key: 'svg-2' }],
  ['path', { d: 'M5 10v-5a2 2 0 0 1 2 -2h7l5 5v2', key: 'svg-3' }],
]);
