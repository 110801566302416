import createVueComponent from '../createVueComponent';
export default createVueComponent('report-search', 'IconReportSearch', [
  [
    'path',
    { d: 'M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697', key: 'svg-0' },
  ],
  ['path', { d: 'M18 12v-5a2 2 0 0 0 -2 -2h-2', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M8 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M8 11h4', key: 'svg-3' }],
  ['path', { d: 'M8 15h3', key: 'svg-4' }],
  [
    'path',
    {
      d: 'M16.5 17.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0',
      key: 'svg-5',
    },
  ],
  ['path', { d: 'M18.5 19.5l2.5 2.5', key: 'svg-6' }],
]);
