import createVueComponent from '../createVueComponent';
export default createVueComponent('h-4', 'IconH4', [
  ['path', { d: 'M20 18v-8l-4 6h5', key: 'svg-0' }],
  ['path', { d: 'M4 6v12', key: 'svg-1' }],
  ['path', { d: 'M12 6v12', key: 'svg-2' }],
  ['path', { d: 'M11 18h2', key: 'svg-3' }],
  ['path', { d: 'M3 18h2', key: 'svg-4' }],
  ['path', { d: 'M4 12h8', key: 'svg-5' }],
  ['path', { d: 'M3 6h2', key: 'svg-6' }],
  ['path', { d: 'M11 6h2', key: 'svg-7' }],
]);
