import createVueComponent from '../createVueComponent';
export default createVueComponent('share-2', 'IconShare2', [
  [
    'path',
    {
      d: 'M8 9h-1a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-8a2 2 0 0 0 -2 -2h-1',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 14v-11', key: 'svg-1' }],
  ['path', { d: 'M9 6l3 -3l3 3', key: 'svg-2' }],
]);
