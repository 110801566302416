import createVueComponent from '../createVueComponent';
export default createVueComponent('heart', 'IconHeart', [
  [
    'path',
    {
      d: 'M19.5 12.572l-7.5 7.428l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572',
      key: 'svg-0',
    },
  ],
]);
