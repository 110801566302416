import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-shazam', 'IconBrandShazam', [
  [
    'path',
    {
      d: 'M10 12l2 -2a2.828 2.828 0 0 1 4 0a2.828 2.828 0 0 1 0 4l-3 3',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M14 12l-2 2a2.828 2.828 0 1 1 -4 -4l3 -3', key: 'svg-1' }],
  ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-2' }],
]);
