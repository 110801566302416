import createVueComponent from '../createVueComponent';
export default createVueComponent('headset', 'IconHeadset', [
  ['path', { d: 'M4 14v-3a8 8 0 1 1 16 0v3', key: 'svg-0' }],
  ['path', { d: 'M18 19c0 1.657 -2.686 3 -6 3', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M4 14a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-1a2 2 0 0 1 -2 -2v-3z',
      key: 'svg-2',
    },
  ],
  [
    'path',
    {
      d: 'M15 14a2 2 0 0 1 2 -2h1a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-1a2 2 0 0 1 -2 -2v-3z',
      key: 'svg-3',
    },
  ],
]);
