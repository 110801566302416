import createVueComponent from '../createVueComponent';
export default createVueComponent('bookmarks-off', 'IconBookmarksOff', [
  [
    'path',
    {
      d: 'M11 7h2a2 2 0 0 1 2 2v2m0 4v6l-5 -3l-5 3v-12a2 2 0 0 1 2 -2',
      key: 'svg-0',
    },
  ],
  [
    'path',
    { d: 'M9.265 4a2 2 0 0 1 1.735 -1h6a2 2 0 0 1 2 2v10', key: 'svg-1' },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
