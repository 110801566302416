import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'arrow-big-left-lines',
  'IconArrowBigLeftLines',
  [
    [
      'path',
      {
        d: 'M12 15v3.586a1 1 0 0 1 -1.707 .707l-6.586 -6.586a1 1 0 0 1 0 -1.414l6.586 -6.586a1 1 0 0 1 1.707 .707v3.586h3v6h-3z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M21 15v-6', key: 'svg-1' }],
    ['path', { d: 'M18 15v-6', key: 'svg-2' }],
  ],
);
