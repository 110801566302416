import createVueComponent from '../createVueComponent';
export default createVueComponent('wash-tumble-off', 'IconWashTumbleOff', [
  [
    'path',
    {
      d: 'M20.116 20.127a2.99 2.99 0 0 1 -2.116 .873h-12a3 3 0 0 1 -3 -3v-12c0 -.827 .335 -1.576 .877 -2.12m3.123 -.88h11a3 3 0 0 1 3 3v11',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M17.744 13.74a6 6 0 0 0 -7.486 -7.482m-2.499 1.497a6 6 0 1 0 8.48 8.49',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
