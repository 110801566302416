import createVueComponent from '../createVueComponent';
export default createVueComponent('shield-question', 'IconShieldQuestion', [
  [
    'path',
    {
      d: 'M15.065 19.732c-.95 .557 -1.98 .986 -3.065 1.268a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3a12 12 0 0 0 8.5 3c.51 1.738 .617 3.55 .333 5.303',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 22v.01', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
      key: 'svg-2',
    },
  ],
]);
