import createVueComponent from '../createVueComponent';
export default createVueComponent('database-x', 'IconDatabaseX', [
  [
    'path',
    {
      d: 'M4 6c0 1.657 3.582 3 8 3s8 -1.343 8 -3s-3.582 -3 -8 -3s-8 1.343 -8 3',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M4 6v6c0 1.657 3.582 3 8 3c.537 0 1.062 -.02 1.57 -.058',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M20 13.5v-7.5', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M4 12v6c0 1.657 3.582 3 8 3c.384 0 .762 -.01 1.132 -.03',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M22 22l-5 -5', key: 'svg-4' }],
  ['path', { d: 'M17 22l5 -5', key: 'svg-5' }],
]);
