import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'gender-hermaphrodite',
  'IconGenderHermaphrodite',
  [
    ['path', { d: 'M12 14v7', key: 'svg-0' }],
    ['path', { d: 'M9 18h6', key: 'svg-1' }],
    ['path', { d: 'M12 6a4 4 0 1 1 0 8a4 4 0 0 1 0 -8z', key: 'svg-2' }],
    ['path', { d: 'M15 3a3 3 0 1 1 -6 0', key: 'svg-3' }],
  ],
);
