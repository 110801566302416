import createVueComponent from '../createVueComponent';
export default createVueComponent('gradienter', 'IconGradienter', [
  [
    'path',
    {
      d: 'M3.227 14c.917 4 4.497 7 8.773 7c4.277 0 7.858 -3 8.773 -7',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M20.78 10a9 9 0 0 0 -8.78 -7a8.985 8.985 0 0 0 -8.782 7',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
]);
