import createVueComponent from '../createVueComponent';
export default createVueComponent('cloud-question', 'IconCloudQuestion', [
  [
    'path',
    {
      d: 'M14.5 18.004h-7.843c-2.572 -.004 -4.657 -2.011 -4.657 -4.487c0 -2.475 2.085 -4.482 4.657 -4.482c.393 -1.762 1.794 -3.2 3.675 -3.773c1.88 -.572 3.956 -.193 5.444 1c1.488 1.19 2.162 3.007 1.77 4.769h.99',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 22v.01', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
      key: 'svg-2',
    },
  ],
]);
