import createVueComponent from '../createVueComponent';
export default createVueComponent('thumb-down', 'IconThumbDown', [
  [
    'path',
    {
      d: 'M7 13v-8a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v7a1 1 0 0 0 1 1h3a4 4 0 0 1 4 4v1a2 2 0 0 0 4 0v-5h3a2 2 0 0 0 2 -2l-1 -5a2 3 0 0 0 -2 -2h-7a3 3 0 0 0 -3 3',
      key: 'svg-0',
    },
  ],
]);
