import createVueComponent from '../createVueComponent';
export default createVueComponent('hanger', 'IconHanger', [
  [
    'path',
    {
      d: 'M14 6a2 2 0 1 0 -4 0c0 1.667 .67 3 2 4h-.008l7.971 4.428a2 2 0 0 1 1.029 1.749v.823a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-.823a2 2 0 0 1 1.029 -1.749l7.971 -4.428',
      key: 'svg-0',
    },
  ],
]);
