import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'brand-google-analytics',
  'IconBrandGoogleAnalytics',
  [
    [
      'path',
      {
        d: 'M10 9m0 1.105a1.105 1.105 0 0 1 1.105 -1.105h1.79a1.105 1.105 0 0 1 1.105 1.105v9.79a1.105 1.105 0 0 1 -1.105 1.105h-1.79a1.105 1.105 0 0 1 -1.105 -1.105z',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M17 3m0 1.105a1.105 1.105 0 0 1 1.105 -1.105h1.79a1.105 1.105 0 0 1 1.105 1.105v15.79a1.105 1.105 0 0 1 -1.105 1.105h-1.79a1.105 1.105 0 0 1 -1.105 -1.105z',
        key: 'svg-1',
      },
    ],
    ['path', { d: 'M5 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
  ],
);
