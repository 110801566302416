import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-evernote', 'IconBrandEvernote', [
  ['path', { d: 'M4 8h5v-5', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M17.9 19c.6 -2.5 1.1 -5.471 1.1 -9c0 -4.5 -2 -5 -3 -5c-1.906 0 -3 -.5 -3.5 -1c-.354 -.354 -.5 -1 -1.5 -1h-2l-5 5c0 6 2.5 8 5 8c1 0 1.5 -.5 2 -1.5s1.414 -.326 2.5 0c1.044 .313 2.01 .255 2.5 .5c1 .5 2 1.5 2 3c0 .5 0 3 -3 3s-3 -3 -1 -3',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M15 10h1', key: 'svg-2' }],
]);
