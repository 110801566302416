import createVueComponent from '../createVueComponent';
export default createVueComponent('alarm-off', 'IconAlarmOff', [
  [
    'path',
    {
      d: 'M7.587 7.566a7 7 0 1 0 9.833 9.864m1.35 -2.645a7 7 0 0 0 -8.536 -8.56',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 12v1h1', key: 'svg-1' }],
  ['path', { d: 'M5.261 5.265l-1.011 .735', key: 'svg-2' }],
  ['path', { d: 'M17 4l2.75 2', key: 'svg-3' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
