import createVueComponent from '../createVueComponent';
export default createVueComponent('mood-check', 'IconMoodCheck', [
  [
    'path',
    {
      d: 'M20.925 13.163a8.998 8.998 0 0 0 -8.925 -10.163a9 9 0 0 0 0 18',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 10h.01', key: 'svg-1' }],
  ['path', { d: 'M15 10h.01', key: 'svg-2' }],
  [
    'path',
    { d: 'M9.5 15c.658 .64 1.56 1 2.5 1s1.842 -.36 2.5 -1', key: 'svg-3' },
  ],
  ['path', { d: 'M15 19l2 2l4 -4', key: 'svg-4' }],
]);
