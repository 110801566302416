import createVueComponent from '../createVueComponent';
export default createVueComponent('lasso-polygon', 'IconLassoPolygon', [
  [
    'path',
    {
      d: 'M4.028 13.252l-1.028 -3.252l2 -7l7 5l8 -3l1 9l-9 3l-5.144 -1.255',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M5 15m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M5 17c0 1.42 .316 2.805 1 4', key: 'svg-2' }],
]);
