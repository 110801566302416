import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'relation-one-to-one',
  'IconRelationOneToOne',
  [
    [
      'path',
      {
        d: 'M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M8 10h1v4', key: 'svg-1' }],
    ['path', { d: 'M15 10h1v4', key: 'svg-2' }],
    ['path', { d: 'M12 10.5l0 .01', key: 'svg-3' }],
    ['path', { d: 'M12 13.5l0 .01', key: 'svg-4' }],
  ],
);
