import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'square-rounded-letter-x',
  'IconSquareRoundedLetterX',
  [
    ['path', { d: 'M10 8l4 8', key: 'svg-0' }],
    ['path', { d: 'M10 16l4 -8', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z',
        key: 'svg-2',
      },
    ],
  ],
);
