import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'temperature-celsius',
  'IconTemperatureCelsius',
  [
    ['path', { d: 'M6 8m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
    [
      'path',
      {
        d: 'M20 9a3 3 0 0 0 -3 -3h-1a3 3 0 0 0 -3 3v6a3 3 0 0 0 3 3h1a3 3 0 0 0 3 -3',
        key: 'svg-1',
      },
    ],
  ],
);
