import createVueComponent from '../createVueComponent';
export default createVueComponent('currency-bahraini', 'IconCurrencyBahraini', [
  ['path', { d: 'M3 10v1a4 4 0 0 0 4 4h2a2 2 0 0 0 2 -2v-3', key: 'svg-0' }],
  ['path', { d: 'M7 19.01v-.01', key: 'svg-1' }],
  ['path', { d: 'M14 15.01v-.01', key: 'svg-2' }],
  [
    'path',
    { d: 'M17 15h2a2 2 0 0 0 1.649 -3.131l-2.653 -3.869', key: 'svg-3' },
  ],
]);
