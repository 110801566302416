import createVueComponent from '../createVueComponent';
export default createVueComponent('analyze-off', 'IconAnalyzeOff', [
  [
    'path',
    {
      d: 'M20 11a8.1 8.1 0 0 0 -6.986 -6.918a8.086 8.086 0 0 0 -4.31 .62m-2.383 1.608a8.089 8.089 0 0 0 -1.326 1.69',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 13a8.1 8.1 0 0 0 13.687 4.676', key: 'svg-1' }],
  ['path', { d: 'M20 16a1 1 0 0 0 -1 -1', key: 'svg-2' }],
  ['path', { d: 'M5 8m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M9.888 9.87a3 3 0 1 0 4.233 4.252m.595 -3.397a3.012 3.012 0 0 0 -1.426 -1.435',
      key: 'svg-4',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
