import createVueComponent from '../createVueComponent';
export default createVueComponent('affiliate', 'IconAffiliate', [
  [
    'path',
    { d: 'M5.931 6.936l1.275 4.249m5.607 5.609l4.251 1.275', key: 'svg-0' },
  ],
  ['path', { d: 'M11.683 12.317l5.759 -5.759', key: 'svg-1' }],
  [
    'path',
    { d: 'M5.5 5.5m-1.5 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0 -3 0', key: 'svg-2' },
  ],
  [
    'path',
    {
      d: 'M18.5 5.5m-1.5 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0 -3 0',
      key: 'svg-3',
    },
  ],
  [
    'path',
    {
      d: 'M18.5 18.5m-1.5 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0 -3 0',
      key: 'svg-4',
    },
  ],
  [
    'path',
    {
      d: 'M8.5 15.5m-4.5 0a4.5 4.5 0 1 0 9 0a4.5 4.5 0 1 0 -9 0',
      key: 'svg-5',
    },
  ],
]);
