import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-headlessui', 'IconBrandHeadlessui', [
  [
    'path',
    {
      d: 'M6.744 4.325l7.82 -1.267a4.456 4.456 0 0 1 5.111 3.686l1.267 7.82a4.456 4.456 0 0 1 -3.686 5.111l-7.82 1.267a4.456 4.456 0 0 1 -5.111 -3.686l-1.267 -7.82a4.456 4.456 0 0 1 3.686 -5.111z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M7.252 7.704l7.897 -1.28a1 1 0 0 1 1.147 .828l.36 2.223l-9.562 3.51l-.67 -4.134a1 1 0 0 1 .828 -1.147z',
      key: 'svg-1',
    },
  ],
]);
