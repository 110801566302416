import createVueComponent from '../createVueComponent';
export default createVueComponent('shredder', 'IconShredder', [
  [
    'path',
    {
      d: 'M4 10m0 1a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1v3a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M17 10v-4a2 2 0 0 0 -2 -2h-6a2 2 0 0 0 -2 2v4m5 5v5m4 -5v2m-8 -2v3',
      key: 'svg-1',
    },
  ],
]);
