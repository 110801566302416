import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'brand-visual-studio',
  'IconBrandVisualStudio',
  [
    [
      'path',
      { d: 'M4 8l2 -1l10 13l4 -2v-12l-4 -2l-10 13l-2 -1z', key: 'svg-0' },
    ],
  ],
);
