import createVueComponent from '../createVueComponent';
export default createVueComponent('circle-letter-o', 'IconCircleLetterO', [
  ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M12 8a2 2 0 0 1 2 2v4a2 2 0 1 1 -4 0v-4a2 2 0 0 1 2 -2z',
      key: 'svg-1',
    },
  ],
]);
