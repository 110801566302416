import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'text-direction-rtl',
  'IconTextDirectionRtl',
  [
    ['path', { d: 'M16 4h-6.5a3.5 3.5 0 0 0 0 7h.5', key: 'svg-0' }],
    ['path', { d: 'M14 15v-11', key: 'svg-1' }],
    ['path', { d: 'M10 15v-11', key: 'svg-2' }],
    ['path', { d: 'M5 19h14', key: 'svg-3' }],
    ['path', { d: 'M7 21l-2 -2l2 -2', key: 'svg-4' }],
  ],
);
