import createVueComponent from '../createVueComponent';
export default createVueComponent('balloon', 'IconBalloon', [
  ['path', { d: 'M14 8a2 2 0 0 0 -2 -2', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M6 8a6 6 0 1 1 12 0c0 4.97 -2.686 9 -6 9s-6 -4.03 -6 -9',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 17v1a2 2 0 0 1 -2 2h-3a2 2 0 0 0 -2 2', key: 'svg-2' }],
]);
