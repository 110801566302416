import createVueComponent from '../createVueComponent';
export default createVueComponent('shield-cancel', 'IconShieldCancel', [
  [
    'path',
    {
      d: 'M12.277 20.925c-.092 .026 -.184 .051 -.277 .075a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3a12 12 0 0 0 8.5 3a12 12 0 0 1 .145 6.232',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 19m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
  ['path', { d: 'M17 21l4 -4', key: 'svg-2' }],
]);
