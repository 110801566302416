import createVueComponent from '../createVueComponent';
export default createVueComponent('cooker', 'IconCooker', [
  ['path', { d: 'M12 7h.01', key: 'svg-0' }],
  ['path', { d: 'M15 7h.01', key: 'svg-1' }],
  ['path', { d: 'M9 7h.01', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M5 3m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M9 15h6', key: 'svg-4' }],
  ['path', { d: 'M5 11h14', key: 'svg-5' }],
]);
