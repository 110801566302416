import createVueComponent from '../createVueComponent';
export default createVueComponent('milk-off', 'IconMilkOff', [
  ['path', { d: 'M10 6h6v-2a1 1 0 0 0 -1 -1h-6a1 1 0 0 0 -1 1', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M16 6l1.094 1.759a6 6 0 0 1 .906 3.17v3.071m0 4v1a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8.071a6 6 0 0 1 .906 -3.17l.327 -.525',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 16m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
