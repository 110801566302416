import createVueComponent from '../createVueComponent';
export default createVueComponent('currency-pound', 'IconCurrencyPound', [
  [
    'path',
    {
      d: 'M17 18.5a6 6 0 0 1 -5 0a6 6 0 0 0 -5 .5a3 3 0 0 0 2 -2.5v-7.5a4 4 0 0 1 7.45 -2m-2.55 6h-7',
      key: 'svg-0',
    },
  ],
]);
