import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-zapier', 'IconBrandZapier', [
  ['path', { d: 'M3 12h6', key: 'svg-0' }],
  ['path', { d: 'M21 12h-6', key: 'svg-1' }],
  ['path', { d: 'M12 3v6', key: 'svg-2' }],
  ['path', { d: 'M12 15v6', key: 'svg-3' }],
  ['path', { d: 'M5.636 5.636l4.243 4.243', key: 'svg-4' }],
  ['path', { d: 'M18.364 18.364l-4.243 -4.243', key: 'svg-5' }],
  ['path', { d: 'M18.364 5.636l-4.243 4.243', key: 'svg-6' }],
  ['path', { d: 'M9.879 14.121l-4.243 4.243', key: 'svg-7' }],
]);
