import createVueComponent from '../createVueComponent';
export default createVueComponent('chair-director', 'IconChairDirector', [
  ['path', { d: 'M6 21l12 -9', key: 'svg-0' }],
  ['path', { d: 'M6 12l12 9', key: 'svg-1' }],
  ['path', { d: 'M5 12h14', key: 'svg-2' }],
  ['path', { d: 'M6 3v9', key: 'svg-3' }],
  ['path', { d: 'M18 3v9', key: 'svg-4' }],
  ['path', { d: 'M6 8h12', key: 'svg-5' }],
  ['path', { d: 'M6 5h12', key: 'svg-6' }],
]);
