import createVueComponent from '../createVueComponent';
export default createVueComponent('exposure-off', 'IconExposureOff', [
  ['path', { d: 'M4.6 19.4l7.4 -7.4m2 -2l5.4 -5.4', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M8 4h10a2 2 0 0 1 2 2v10m-.586 3.414a2 2 0 0 1 -1.414 .586h-12a2 2 0 0 1 -2 -2v-12c0 -.547 .22 -1.043 .576 -1.405',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M7 9h2v2', key: 'svg-2' }],
  ['path', { d: 'M13 16h3', key: 'svg-3' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
