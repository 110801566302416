import createVueComponent from '../createVueComponent';
export default createVueComponent('panorama-vertical', 'IconPanoramaVertical', [
  [
    'path',
    {
      d: 'M18.463 4.338c-1.932 5.106 -1.932 10.211 0 15.317a1 1 0 0 1 -.934 1.345h-11c-.692 0 -1.208 -.692 -.962 -1.34c1.932 -5.107 1.932 -10.214 0 -15.321c-.246 -.648 .243 -1.339 .935 -1.339h11.028c.693 0 1.18 .691 .935 1.338z',
      key: 'svg-0',
    },
  ],
]);
