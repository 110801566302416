import createVueComponent from '../createVueComponent';
export default createVueComponent('receipt', 'IconReceipt', [
  [
    'path',
    {
      d: 'M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2m4 -14h6m-6 4h6m-2 4h2',
      key: 'svg-0',
    },
  ],
]);
