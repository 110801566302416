import createVueComponent from '../createVueComponent';
export default createVueComponent('gift-card', 'IconGiftCard', [
  [
    'path',
    {
      d: 'M3 5m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7 16l3 -3l3 3', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M8 13c-.789 0 -2 -.672 -2 -1.5s.711 -1.5 1.5 -1.5c1.128 -.02 2.077 1.17 2.5 3c.423 -1.83 1.372 -3.02 2.5 -3c.789 0 1.5 .672 1.5 1.5s-1.211 1.5 -2 1.5h-4z',
      key: 'svg-2',
    },
  ],
]);
