import createVueComponent from '../createVueComponent';
export default createVueComponent('device-watch-x', 'IconDeviceWatchX', [
  [
    'path',
    {
      d: 'M13 18h-4a3 3 0 0 1 -3 -3v-6a3 3 0 0 1 3 -3h6a3 3 0 0 1 3 3v4',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 18v3h4', key: 'svg-1' }],
  ['path', { d: 'M9 6v-3h6v3', key: 'svg-2' }],
  ['path', { d: 'M22 22l-5 -5', key: 'svg-3' }],
  ['path', { d: 'M17 22l5 -5', key: 'svg-4' }],
]);
