import createVueComponent from '../createVueComponent';
export default createVueComponent('message-off', 'IconMessageOff', [
  ['path', { d: 'M8 9h1m4 0h3', key: 'svg-0' }],
  ['path', { d: 'M8 13h5', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M8 4h10a3 3 0 0 1 3 3v8c0 .577 -.163 1.116 -.445 1.573m-2.555 1.427h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8c0 -1.085 .576 -2.036 1.439 -2.562',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
