import createVueComponent from '../createVueComponent';
export default createVueComponent('drone', 'IconDrone', [
  ['path', { d: 'M10 10h4v4h-4z', key: 'svg-0' }],
  ['path', { d: 'M10 10l-3.5 -3.5', key: 'svg-1' }],
  ['path', { d: 'M9.96 6a3.5 3.5 0 1 0 -3.96 3.96', key: 'svg-2' }],
  ['path', { d: 'M14 10l3.5 -3.5', key: 'svg-3' }],
  ['path', { d: 'M18 9.96a3.5 3.5 0 1 0 -3.96 -3.96', key: 'svg-4' }],
  ['path', { d: 'M14 14l3.5 3.5', key: 'svg-5' }],
  ['path', { d: 'M14.04 18a3.5 3.5 0 1 0 3.96 -3.96', key: 'svg-6' }],
  ['path', { d: 'M10 14l-3.5 3.5', key: 'svg-7' }],
  ['path', { d: 'M6 14.04a3.5 3.5 0 1 0 3.96 3.96', key: 'svg-8' }],
]);
