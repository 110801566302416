import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'device-mobile-minus',
  'IconDeviceMobileMinus',
  [
    [
      'path',
      {
        d: 'M12.5 21h-4.5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v10',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M11 4h2', key: 'svg-1' }],
    ['path', { d: 'M12 17v.01', key: 'svg-2' }],
    ['path', { d: 'M16 19h6', key: 'svg-3' }],
  ],
);
