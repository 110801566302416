import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-pinterest', 'IconBrandPinterest', [
  ['path', { d: 'M8 20l4 -9', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M10.7 14c.437 1.263 1.43 2 2.55 2c2.071 0 3.75 -1.554 3.75 -4a5 5 0 1 0 -9.7 1.7',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-2' }],
]);
