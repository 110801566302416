import createVueComponent from '../createVueComponent';
export default createVueComponent('helmet-off', 'IconHelmetOff', [
  [
    'path',
    {
      d: 'M8.633 4.654a9 9 0 0 1 11.718 11.7m-1.503 2.486a9.008 9.008 0 0 1 -1.192 1.16h-11.312a9 9 0 0 1 -.185 -13.847',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M20 9h-7m-2.768 1.246c.507 2 1.596 3.418 3.268 4.254c.524 .262 1.07 .49 1.64 .683',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
