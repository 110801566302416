import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-waze', 'IconBrandWaze', [
  [
    'path',
    {
      d: 'M6.66 17.52a7 7 0 0 1 -3.66 -4.52c2 0 3 -1 3 -2.51c0 -3.92 2.25 -7.49 7.38 -7.49c4.62 0 7.62 3.51 7.62 8a8.08 8.08 0 0 1 -3.39 6.62',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 18.69a17.29 17.29 0 0 0 3.33 .3h.54', key: 'svg-1' }],
  ['path', { d: 'M16 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
  ['path', { d: 'M8 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-3' }],
  ['path', { d: 'M16 9h.01', key: 'svg-4' }],
  ['path', { d: 'M11 9h.01', key: 'svg-5' }],
]);
