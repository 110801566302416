import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-nytimes', 'IconBrandNytimes', [
  ['path', { d: 'M11.036 5.058a8 8 0 1 0 8.706 9.965', key: 'svg-0' }],
  ['path', { d: 'M12 21v-11l-7.5 4', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M17.5 3a2.5 2.5 0 1 1 0 5l-11 -5a2.5 2.5 0 0 0 -.67 4.91',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M9 12v8', key: 'svg-3' }],
  ['path', { d: 'M16 13h-.01', key: 'svg-4' }],
]);
