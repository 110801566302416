import createVueComponent from '../createVueComponent';
export default createVueComponent('cpu', 'IconCpu', [
  [
    'path',
    {
      d: 'M5 5m0 1a1 1 0 0 1 1 -1h12a1 1 0 0 1 1 1v12a1 1 0 0 1 -1 1h-12a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 9h6v6h-6z', key: 'svg-1' }],
  ['path', { d: 'M3 10h2', key: 'svg-2' }],
  ['path', { d: 'M3 14h2', key: 'svg-3' }],
  ['path', { d: 'M10 3v2', key: 'svg-4' }],
  ['path', { d: 'M14 3v2', key: 'svg-5' }],
  ['path', { d: 'M21 10h-2', key: 'svg-6' }],
  ['path', { d: 'M21 14h-2', key: 'svg-7' }],
  ['path', { d: 'M14 21v-2', key: 'svg-8' }],
  ['path', { d: 'M10 21v-2', key: 'svg-9' }],
]);
