import createVueComponent from '../createVueComponent';
export default createVueComponent('rewind-forward-50', 'IconRewindForward50', [
  ['path', { d: 'M5.007 16.478a6 6 0 0 1 3.993 -10.478h11', key: 'svg-0' }],
  [
    'path',
    { d: 'M15 15.5v3a1.5 1.5 0 0 0 3 0v-3a1.5 1.5 0 0 0 -3 0z', key: 'svg-1' },
  ],
  ['path', { d: 'M17 9l3 -3l-3 -3', key: 'svg-2' }],
  ['path', { d: 'M9 20h1.5a1.5 1.5 0 0 0 0 -3h-1.5v-3h3', key: 'svg-3' }],
]);
