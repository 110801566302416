import createVueComponent from '../createVueComponent';
export default createVueComponent('hotel-service', 'IconHotelService', [
  [
    'path',
    {
      d: 'M8.5 10a1.5 1.5 0 0 1 -1.5 -1.5a5.5 5.5 0 0 1 11 0v10.5a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-2c0 -1.38 .71 -2.444 1.88 -3.175l4.424 -2.765c1.055 -.66 1.696 -1.316 1.696 -2.56a2.5 2.5 0 1 0 -5 0a1.5 1.5 0 0 1 -1.5 1.5z',
      key: 'svg-0',
    },
  ],
]);
