import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'brand-react-native',
  'IconBrandReactNative',
  [
    [
      'path',
      {
        d: 'M6.357 9c-2.637 .68 -4.357 1.845 -4.357 3.175c0 2.107 4.405 3.825 9.85 3.825c.74 0 1.26 -.039 1.95 -.097',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M9.837 15.9c-.413 -.596 -.806 -1.133 -1.18 -1.8c-2.751 -4.9 -3.488 -9.77 -1.63 -10.873c1.15 -.697 3.047 .253 4.974 2.254',
        key: 'svg-1',
      },
    ],
    [
      'path',
      {
        d: 'M6.429 15.387c-.702 2.688 -.56 4.716 .56 5.395c1.783 1.08 5.387 -1.958 8.043 -6.804c.36 -.67 .683 -1.329 .968 -1.978',
        key: 'svg-2',
      },
    ],
    [
      'path',
      {
        d: 'M12 18.52c1.928 2 3.817 2.95 4.978 2.253c1.85 -1.102 1.121 -5.972 -1.633 -10.873c-.384 -.677 -.777 -1.204 -1.18 -1.8',
        key: 'svg-3',
      },
    ],
    [
      'path',
      {
        d: 'M17.66 15c2.612 -.687 4.34 -1.85 4.34 -3.176c0 -2.11 -4.408 -3.824 -9.845 -3.824c-.747 0 -1.266 .029 -1.955 .087',
        key: 'svg-4',
      },
    ],
    [
      'path',
      {
        d: 'M8 12c.285 -.66 .607 -1.308 .968 -1.978c2.647 -4.844 6.253 -7.89 8.046 -6.801c1.11 .679 1.262 2.706 .56 5.393',
        key: 'svg-5',
      },
    ],
    [
      'path',
      {
        d: 'M12.26 12.015h-.01c-.01 .13 -.12 .24 -.26 .24a.263 .263 0 0 1 -.25 -.26c0 -.14 .11 -.25 .24 -.25h-.01c.13 -.01 .25 .11 .25 .24',
        key: 'svg-6',
      },
    ],
  ],
);
