import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'building-warehouse',
  'IconBuildingWarehouse',
  [
    ['path', { d: 'M3 21v-13l9 -4l9 4v13', key: 'svg-0' }],
    ['path', { d: 'M13 13h4v8h-10v-6h6', key: 'svg-1' }],
    [
      'path',
      { d: 'M13 21v-9a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v3', key: 'svg-2' },
    ],
  ],
);
