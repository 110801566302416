import createVueComponent from '../createVueComponent';
export default createVueComponent('virus-off', 'IconVirusOff', [
  ['path', { d: 'M3 3l18 18', key: 'svg-0' }],
  ['path', { d: 'M8.469 8.46a5 5 0 0 0 7.058 7.084', key: 'svg-1' }],
  ['path', { d: 'M16.913 12.936a5 5 0 0 0 -5.826 -5.853', key: 'svg-2' }],
  ['path', { d: 'M12 7v-4', key: 'svg-3' }],
  ['path', { d: 'M11 3h2', key: 'svg-4' }],
  ['path', { d: 'M15.536 8.464l2.828 -2.828', key: 'svg-5' }],
  ['path', { d: 'M17.657 4.929l1.414 1.414', key: 'svg-6' }],
  ['path', { d: 'M17 12h4', key: 'svg-7' }],
  ['path', { d: 'M21 11v2', key: 'svg-8' }],
  ['path', { d: 'M18.364 18.363l-.707 .707', key: 'svg-9' }],
  ['path', { d: 'M12 17v4', key: 'svg-10' }],
  ['path', { d: 'M13 21h-2', key: 'svg-11' }],
  ['path', { d: 'M8.465 15.536l-2.829 2.828', key: 'svg-12' }],
  ['path', { d: 'M6.343 19.071l-1.413 -1.414', key: 'svg-13' }],
  ['path', { d: 'M7 12h-4', key: 'svg-14' }],
  ['path', { d: 'M3 13v-2', key: 'svg-15' }],
  ['path', { d: 'M5.636 5.637l-.707 .707', key: 'svg-16' }],
]);
