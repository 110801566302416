import createVueComponent from '../createVueComponent';
export default createVueComponent('language-hiragana', 'IconLanguageHiragana', [
  ['path', { d: 'M4 5h7', key: 'svg-0' }],
  ['path', { d: 'M7 4c0 4.846 0 7 .5 8', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M10 8.5c0 2.286 -2 4.5 -3.5 4.5s-2.5 -1.135 -2.5 -2c0 -2 1 -3 3 -3s5 .57 5 2.857c0 1.524 -.667 2.571 -2 3.143',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M12 20l4 -9l4 9', key: 'svg-3' }],
  ['path', { d: 'M19.1 18h-6.2', key: 'svg-4' }],
]);
