import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'shopping-cart-discount',
  'IconShoppingCartDiscount',
  [
    ['path', { d: 'M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
    ['path', { d: 'M17 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
    ['path', { d: 'M17 17h-11v-14h-2', key: 'svg-2' }],
    ['path', { d: 'M20 6l-1 7h-13', key: 'svg-3' }],
    ['path', { d: 'M10 10l6 -6', key: 'svg-4' }],
    [
      'path',
      { d: 'M10.5 4.5m-.5 0a.5 .5 0 1 0 1 0a.5 .5 0 1 0 -1 0', key: 'svg-5' },
    ],
    [
      'path',
      { d: 'M15.5 9.5m-.5 0a.5 .5 0 1 0 1 0a.5 .5 0 1 0 -1 0', key: 'svg-6' },
    ],
  ],
);
