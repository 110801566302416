import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-denodo', 'IconBrandDenodo', [
  ['path', { d: 'M11 11h2v2h-2z', key: 'svg-0' }],
  ['path', { d: 'M3.634 15.634l1.732 -1l1 1.732l-1.732 1z', key: 'svg-1' }],
  ['path', { d: 'M11 19h2v2h-2z', key: 'svg-2' }],
  ['path', { d: 'M18.634 14.634l1.732 1l-1 1.732l-1.732 -1z', key: 'svg-3' }],
  ['path', { d: 'M17.634 7.634l1.732 -1l1 1.732l-1.732 1z', key: 'svg-4' }],
  ['path', { d: 'M11 3h2v2h-2z', key: 'svg-5' }],
  ['path', { d: 'M3.634 8.366l1 -1.732l1.732 1l-1 1.732z', key: 'svg-6' }],
]);
