import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'device-tablet-heart',
  'IconDeviceTabletHeart',
  [
    [
      'path',
      {
        d: 'M11.5 21h-5.5a1 1 0 0 1 -1 -1v-16a1 1 0 0 1 1 -1h12a1 1 0 0 1 1 1v7',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M18 22l3.35 -3.284a2.143 2.143 0 0 0 .005 -3.071a2.242 2.242 0 0 0 -3.129 -.006l-.224 .22l-.223 -.22a2.242 2.242 0 0 0 -3.128 -.006a2.143 2.143 0 0 0 -.006 3.071l3.355 3.296z',
        key: 'svg-1',
      },
    ],
  ],
);
