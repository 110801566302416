import createVueComponent from '../createVueComponent';
export default createVueComponent('grave-2', 'IconGrave2', [
  [
    'path',
    { d: 'M7 16.17v-9.17a3 3 0 0 1 3 -3h4a3 3 0 0 1 3 3v9.171', key: 'svg-0' },
  ],
  ['path', { d: 'M12 7v5', key: 'svg-1' }],
  ['path', { d: 'M10 9h4', key: 'svg-2' }],
  [
    'path',
    { d: 'M5 21v-2a3 3 0 0 1 3 -3h8a3 3 0 0 1 3 3v2h-14z', key: 'svg-3' },
  ],
]);
