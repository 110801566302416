import createVueComponent from '../createVueComponent';
export default createVueComponent('ironing', 'IconIroning', [
  [
    'path',
    {
      d: 'M9 6h7.459a3 3 0 0 1 2.959 2.507l.577 3.464l.81 4.865a1 1 0 0 1 -.985 1.164h-16.82a7 7 0 0 1 7 -7h9.8',
      key: 'svg-0',
    },
  ],
]);
