import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-blogger', 'IconBrandBlogger', [
  [
    'path',
    {
      d: 'M8 21h8a5 5 0 0 0 5 -5v-3a3 3 0 0 0 -3 -3h-1v-2a5 5 0 0 0 -5 -5h-4a5 5 0 0 0 -5 5v8a5 5 0 0 0 5 5z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M7 7m0 1.5a1.5 1.5 0 0 1 1.5 -1.5h3a1.5 1.5 0 0 1 1.5 1.5v0a1.5 1.5 0 0 1 -1.5 1.5h-3a1.5 1.5 0 0 1 -1.5 -1.5z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M7 14m0 1.5a1.5 1.5 0 0 1 1.5 -1.5h7a1.5 1.5 0 0 1 1.5 1.5v0a1.5 1.5 0 0 1 -1.5 1.5h-7a1.5 1.5 0 0 1 -1.5 -1.5z',
      key: 'svg-2',
    },
  ],
]);
