import createVueComponent from '../createVueComponent';
export default createVueComponent('edit', 'IconEdit', [
  [
    'path',
    {
      d: 'M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M16 5l3 3', key: 'svg-2' }],
]);
