import createVueComponent from '../createVueComponent';
export default createVueComponent('select-all', 'IconSelectAll', [
  [
    'path',
    {
      d: 'M8 8m0 1a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 20v.01', key: 'svg-1' }],
  ['path', { d: 'M16 20v.01', key: 'svg-2' }],
  ['path', { d: 'M8 20v.01', key: 'svg-3' }],
  ['path', { d: 'M4 20v.01', key: 'svg-4' }],
  ['path', { d: 'M4 16v.01', key: 'svg-5' }],
  ['path', { d: 'M4 12v.01', key: 'svg-6' }],
  ['path', { d: 'M4 8v.01', key: 'svg-7' }],
  ['path', { d: 'M4 4v.01', key: 'svg-8' }],
  ['path', { d: 'M8 4v.01', key: 'svg-9' }],
  ['path', { d: 'M12 4v.01', key: 'svg-10' }],
  ['path', { d: 'M16 4v.01', key: 'svg-11' }],
  ['path', { d: 'M20 4v.01', key: 'svg-12' }],
  ['path', { d: 'M20 8v.01', key: 'svg-13' }],
  ['path', { d: 'M20 12v.01', key: 'svg-14' }],
  ['path', { d: 'M20 16v.01', key: 'svg-15' }],
  ['path', { d: 'M20 20v.01', key: 'svg-16' }],
]);
