import createVueComponent from '../createVueComponent';
export default createVueComponent('vaccine-off', 'IconVaccineOff', [
  ['path', { d: 'M17 3l4 4', key: 'svg-0' }],
  ['path', { d: 'M19 5l-4.5 4.5', key: 'svg-1' }],
  ['path', { d: 'M11.5 6.5l6 6', key: 'svg-2' }],
  [
    'path',
    { d: 'M16.5 11.5l-.5 .5m-2 2l-4 4h-4v-4l4 -4m2 -2l.5 -.5', key: 'svg-3' },
  ],
  ['path', { d: 'M7.5 12.5l1.5 1.5', key: 'svg-4' }],
  ['path', { d: 'M3 21l3 -3', key: 'svg-5' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
]);
