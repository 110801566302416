import createVueComponent from '../createVueComponent';
export default createVueComponent('usb', 'IconUsb', [
  ['path', { d: 'M12 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M12 17v-11.5', key: 'svg-1' }],
  ['path', { d: 'M7 10v3l5 3', key: 'svg-2' }],
  ['path', { d: 'M12 14.5l5 -2v-2.5', key: 'svg-3' }],
  ['path', { d: 'M16 10h2v-2h-2z', key: 'svg-4' }],
  ['path', { d: 'M7 9m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-5' }],
  ['path', { d: 'M10 5.5h4l-2 -2.5z', key: 'svg-6' }],
]);
