import createVueComponent from '../createVueComponent';
export default createVueComponent('code-plus', 'IconCodePlus', [
  ['path', { d: 'M9 12h6', key: 'svg-0' }],
  ['path', { d: 'M12 9v6', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M6 19a2 2 0 0 1 -2 -2v-4l-1 -1l1 -1v-4a2 2 0 0 1 2 -2',
      key: 'svg-2',
    },
  ],
  [
    'path',
    {
      d: 'M18 19a2 2 0 0 0 2 -2v-4l1 -1l-1 -1v-4a2 2 0 0 0 -2 -2',
      key: 'svg-3',
    },
  ],
]);
