import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'battery-charging-2',
  'IconBatteryCharging2',
  [
    [
      'path',
      {
        d: 'M4 9a2 2 0 0 1 2 -2h11a2 2 0 0 1 2 2v.5a.5 .5 0 0 0 .5 .5a.5 .5 0 0 1 .5 .5v3a.5 .5 0 0 1 -.5 .5a.5 .5 0 0 0 -.5 .5v.5a2 2 0 0 1 -2 2h-4.5',
        key: 'svg-0',
      },
    ],
    [
      'path',
      { d: 'M3 15h6v2a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2v-2z', key: 'svg-1' },
    ],
    ['path', { d: 'M6 22v-3', key: 'svg-2' }],
    ['path', { d: 'M4 15v-2.5', key: 'svg-3' }],
    ['path', { d: 'M8 15v-2.5', key: 'svg-4' }],
  ],
);
