import createVueComponent from '../createVueComponent';
export default createVueComponent('clock-shield', 'IconClockShield', [
  ['path', { d: 'M21 12a9 9 0 1 0 -8.98 9', key: 'svg-0' }],
  ['path', { d: 'M12 7v5l1 1', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M22 16c0 4 -2.5 6 -3.5 6s-3.5 -2 -3.5 -6c1 0 2.5 -.5 3.5 -1.5c1 1 2.5 1.5 3.5 1.5z',
      key: 'svg-2',
    },
  ],
]);
