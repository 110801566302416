import createVueComponent from '../createVueComponent';
export default createVueComponent('message-chatbot', 'IconMessageChatbot', [
  [
    'path',
    {
      d: 'M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9.5 9h.01', key: 'svg-1' }],
  ['path', { d: 'M14.5 9h.01', key: 'svg-2' }],
  ['path', { d: 'M9.5 13a3.5 3.5 0 0 0 5 0', key: 'svg-3' }],
]);
