import createVueComponent from '../createVueComponent';
export default createVueComponent('cone-2', 'IconCone2', [
  [
    'path',
    {
      d: 'M21 5.002v.5l-8.13 14.99a1 1 0 0 1 -1.74 0l-8.13 -14.989v-.5c0 -1.659 4.03 -3.003 9 -3.003s9 1.344 9 3.002',
      key: 'svg-0',
    },
  ],
]);
