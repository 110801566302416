import createVueComponent from '../createVueComponent';
export default createVueComponent('bike-off', 'IconBikeOff', [
  ['path', { d: 'M5 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M16.437 16.44a3 3 0 0 0 4.123 4.123m1.44 -2.563a3 3 0 0 0 -3 -3',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M12 19v-4l-3 -3l1.665 -1.332m2.215 -1.772l1.12 -.896l2 3h3',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M17 5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-3' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
