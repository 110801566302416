import createVueComponent from '../createVueComponent';
export default createVueComponent('sofa', 'IconSofa', [
  [
    'path',
    {
      d: 'M4 11a2 2 0 0 1 2 2v1h12v-1a2 2 0 1 1 4 0v5a1 1 0 0 1 -1 1h-18a1 1 0 0 1 -1 -1v-5a2 2 0 0 1 2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 11v-3a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v3', key: 'svg-1' }],
  ['path', { d: 'M12 5v9', key: 'svg-2' }],
]);
