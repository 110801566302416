import createVueComponent from '../createVueComponent';
export default createVueComponent('moped', 'IconMoped', [
  ['path', { d: 'M18 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M5 16v1a2 2 0 0 0 4 0v-5h-3a3 3 0 0 0 -3 3v1h10a6 6 0 0 1 5 -4v-5a2 2 0 0 0 -2 -2h-1',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M6 9l3 0', key: 'svg-2' }],
]);
