import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'message-circle-code',
  'IconMessageCircleCode',
  [
    [
      'path',
      {
        d: 'M11.036 19.933a9.798 9.798 0 0 1 -3.336 -.933l-4.7 1l1.3 -3.9c-2.324 -3.437 -1.426 -7.872 2.1 -10.374c3.526 -2.501 8.59 -2.296 11.845 .48c2.128 1.815 3.053 4.361 2.694 6.81',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M20 21l2 -2l-2 -2', key: 'svg-1' }],
    ['path', { d: 'M17 17l-2 2l2 2', key: 'svg-2' }],
  ],
);
