import createVueComponent from '../createVueComponent';
export default createVueComponent('chart-bubble', 'IconChartBubble', [
  ['path', { d: 'M6 16m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-0' }],
  ['path', { d: 'M16 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M14.5 7.5m-4.5 0a4.5 4.5 0 1 0 9 0a4.5 4.5 0 1 0 -9 0',
      key: 'svg-2',
    },
  ],
]);
