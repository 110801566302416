import createVueComponent from '../createVueComponent';
export default createVueComponent('message-down', 'IconMessageDown', [
  ['path', { d: 'M8 9h8', key: 'svg-0' }],
  ['path', { d: 'M8 13h6', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M11.998 18.601l-3.998 2.399v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v5.5',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M19 16v6', key: 'svg-3' }],
  ['path', { d: 'M22 19l-3 3l-3 -3', key: 'svg-4' }],
]);
