import createVueComponent from '../createVueComponent';
export default createVueComponent('dna-2', 'IconDna2', [
  [
    'path',
    {
      d: 'M17 3v1c-.01 3.352 -1.68 6.023 -5.008 8.014c-3.328 1.99 3.336 -2 .008 -.014c-3.328 1.99 -5 4.662 -5.008 8.014v1',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M17 21.014v-1c-.01 -3.352 -1.68 -6.023 -5.008 -8.014c-3.328 -1.99 3.336 2 .008 .014c-3.328 -1.991 -5 -4.662 -5.008 -8.014v-1',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M7 4h10', key: 'svg-2' }],
  ['path', { d: 'M7 20h10', key: 'svg-3' }],
  ['path', { d: 'M8 8h8', key: 'svg-4' }],
  ['path', { d: 'M8 16h8', key: 'svg-5' }],
]);
