import createVueComponent from '../createVueComponent';
export default createVueComponent('nurse', 'IconNurse', [
  [
    'path',
    {
      d: 'M12 6c2.941 0 5.685 .847 8 2.31l-2 9.69h-12l-2 -9.691a14.93 14.93 0 0 1 8 -2.309z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 12h4', key: 'svg-1' }],
  ['path', { d: 'M12 10v4', key: 'svg-2' }],
]);
