import createVueComponent from '../createVueComponent';
export default createVueComponent('shield-search', 'IconShieldSearch', [
  [
    'path',
    {
      d: 'M12 21a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3a12 12 0 0 0 8.5 3c.539 1.832 .627 3.747 .283 5.588',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
  ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-2' }],
]);
