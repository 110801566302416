import createVueComponent from '../createVueComponent';
export default createVueComponent('dog-bowl', 'IconDogBowl', [
  [
    'path',
    {
      d: 'M10 15l5.586 -5.585a2 2 0 1 1 3.414 -1.415a2 2 0 1 1 -1.413 3.414l-3.587 3.586',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M12 13l-3.586 -3.585a2 2 0 1 0 -3.414 -1.415a2 2 0 1 0 1.413 3.414l3.587 3.586',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M3 20h18c-.175 -1.671 -.046 -3.345 -2 -5h-14c-1.333 1 -2 2.667 -2 5z',
      key: 'svg-2',
    },
  ],
]);
