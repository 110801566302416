import createVueComponent from '../createVueComponent';
export default createVueComponent('abacus-off', 'IconAbacusOff', [
  ['path', { d: 'M5 5v16', key: 'svg-0' }],
  ['path', { d: 'M19 21v-2m0 -4v-12', key: 'svg-1' }],
  ['path', { d: 'M5 7h2m4 0h8', key: 'svg-2' }],
  ['path', { d: 'M5 15h10', key: 'svg-3' }],
  ['path', { d: 'M8 13v4', key: 'svg-4' }],
  ['path', { d: 'M11 13v4', key: 'svg-5' }],
  ['path', { d: 'M16 16v1', key: 'svg-6' }],
  ['path', { d: 'M14 5v4', key: 'svg-7' }],
  ['path', { d: 'M11 5v2', key: 'svg-8' }],
  ['path', { d: 'M8 8v1', key: 'svg-9' }],
  ['path', { d: 'M3 21h18', key: 'svg-10' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-11' }],
]);
