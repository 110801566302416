import createVueComponent from '../createVueComponent';
export default createVueComponent('mail', 'IconMail', [
  [
    'path',
    {
      d: 'M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 7l9 6l9 -6', key: 'svg-1' }],
]);
