import createVueComponent from '../createVueComponent';
export default createVueComponent('message', 'IconMessage', [
  ['path', { d: 'M8 9h8', key: 'svg-0' }],
  ['path', { d: 'M8 13h6', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z',
      key: 'svg-2',
    },
  ],
]);
