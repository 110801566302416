import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-upwork', 'IconBrandUpwork', [
  [
    'path',
    {
      d: 'M3 7v5a3 3 0 0 0 6 0v-5h1l4 6c.824 1.319 1.945 2 3.5 2a3.5 3.5 0 0 0 0 -7c-2.027 0 -3.137 1 -3.5 3c-.242 1.33 -.908 4 -2 8',
      key: 'svg-0',
    },
  ],
]);
