import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'vector-bezier-circle',
  'IconVectorBezierCircle',
  [
    [
      'path',
      {
        d: 'M3 10m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M17 10m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
        key: 'svg-1',
      },
    ],
    [
      'path',
      {
        d: 'M10 3m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
        key: 'svg-2',
      },
    ],
    [
      'path',
      {
        d: 'M10 17m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
        key: 'svg-3',
      },
    ],
    ['path', { d: 'M19 10a5 5 0 0 0 -5 -5', key: 'svg-4' }],
    ['path', { d: 'M19 14a5 5 0 0 1 -5 5', key: 'svg-5' }],
    ['path', { d: 'M5 14a5 5 0 0 0 5 5', key: 'svg-6' }],
    ['path', { d: 'M5 10a5 5 0 0 1 5 -5', key: 'svg-7' }],
  ],
);
