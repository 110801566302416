import createVueComponent from '../createVueComponent';
export default createVueComponent('variable-off', 'IconVariableOff', [
  [
    'path',
    { d: 'M4.675 4.68c-2.17 4.776 -2.062 9.592 .325 15.32', key: 'svg-0' },
  ],
  [
    'path',
    {
      d: 'M19 4c1.959 3.917 2.383 7.834 1.272 12.232m-.983 3.051c-.093 .238 -.189 .477 -.289 .717',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M11.696 11.696c.095 .257 .2 .533 .32 .831c.984 2.473 .984 3.473 1.984 3.473h1',
      key: 'svg-2',
    },
  ],
  [
    'path',
    {
      d: 'M8 16c1.5 0 3 -2 4 -3.5m2.022 -2.514c.629 -.582 1.304 -.986 1.978 -.986',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
