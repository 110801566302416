import createVueComponent from '../createVueComponent';
export default createVueComponent('heading-off', 'IconHeadingOff', [
  ['path', { d: 'M7 12h5m4 0h1', key: 'svg-0' }],
  ['path', { d: 'M7 7v12', key: 'svg-1' }],
  ['path', { d: 'M17 5v8m0 4v2', key: 'svg-2' }],
  ['path', { d: 'M15 19h4', key: 'svg-3' }],
  ['path', { d: 'M15 5h4', key: 'svg-4' }],
  ['path', { d: 'M5 19h4', key: 'svg-5' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
]);
