import createVueComponent from '../createVueComponent';
export default createVueComponent('arrow-bounce', 'IconArrowBounce', [
  ['path', { d: 'M10 18h4', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M3 8a9 9 0 0 1 9 9v1l1.428 -4.285a12 12 0 0 1 6.018 -6.938l.554 -.277',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M15 6h5v5', key: 'svg-2' }],
]);
