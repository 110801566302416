import createVueComponent from '../createVueComponent';
export default createVueComponent('device-laptop', 'IconDeviceLaptop', [
  ['path', { d: 'M3 19l18 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M5 6m0 1a1 1 0 0 1 1 -1h12a1 1 0 0 1 1 1v8a1 1 0 0 1 -1 1h-12a1 1 0 0 1 -1 -1z',
      key: 'svg-1',
    },
  ],
]);
