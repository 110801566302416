import createVueComponent from '../createVueComponent';
export default createVueComponent('video-plus', 'IconVideoPlus', [
  [
    'path',
    {
      d: 'M15 10l4.553 -2.276a1 1 0 0 1 1.447 .894v6.764a1 1 0 0 1 -1.447 .894l-4.553 -2.276v-4z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M3 6m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M7 12l4 0', key: 'svg-2' }],
  ['path', { d: 'M9 10l0 4', key: 'svg-3' }],
]);
