import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'box-align-right-filled',
  'IconBoxAlignRightFilled',
  [
    [
      'path',
      {
        d: 'M18.998 3.003h-5a1 1 0 0 0 -1 1v16a1 1 0 0 0 1 1h5a2 2 0 0 0 2 -2v-14a2 2 0 0 0 -2 -2z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M9.008 19.003a1 1 0 0 1 .117 1.993l-.127 .007a1 1 0 0 1 -.117 -1.993l.127 -.007z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-1',
      },
    ],
    [
      'path',
      {
        d: 'M4.008 19.003a1 1 0 0 1 .117 1.993l-.128 .007a1 1 0 0 1 -.117 -1.993l.128 -.007z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-2',
      },
    ],
    [
      'path',
      {
        d: 'M4.008 14.002a1 1 0 0 1 .117 1.993l-.128 .007a1 1 0 0 1 -.117 -1.993l.128 -.007z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-3',
      },
    ],
    [
      'path',
      {
        d: 'M4.008 8.002a1 1 0 0 1 .117 1.993l-.128 .007a1 1 0 0 1 -.117 -1.993l.128 -.007z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-4',
      },
    ],
    [
      'path',
      {
        d: 'M4.008 3.002a1 1 0 0 1 .117 1.993l-.128 .007a1 1 0 0 1 -.117 -1.993l.128 -.007z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-5',
      },
    ],
    [
      'path',
      {
        d: 'M9.008 3.002a1 1 0 0 1 .117 1.993l-.127 .007a1 1 0 0 1 -.117 -1.993l.127 -.007z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-6',
      },
    ],
  ],
);
