import createVueComponent from '../createVueComponent';
export default createVueComponent('calendar-dollar', 'IconCalendarDollar', [
  [
    'path',
    {
      d: 'M13 21h-7a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v3',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 3v4', key: 'svg-1' }],
  ['path', { d: 'M8 3v4', key: 'svg-2' }],
  ['path', { d: 'M4 11h12.5', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
      key: 'svg-4',
    },
  ],
  ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-5' }],
]);
