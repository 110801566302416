import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-cucumber', 'IconBrandCucumber', [
  [
    'path',
    {
      d: 'M20 10.99c-.01 5.52 -4.48 10 -10 10.01v-2.26l-.01 -.01c-4.28 -1.11 -6.86 -5.47 -5.76 -9.75a8 8 0 0 1 9.74 -5.76c3.53 .91 6.03 4.13 6.03 7.78v-.01z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10.5 8l-.5 -1', key: 'svg-1' }],
  ['path', { d: 'M13.5 14l.5 1', key: 'svg-2' }],
  ['path', { d: 'M9 12.5l-1 .5', key: 'svg-3' }],
  ['path', { d: 'M11 14l-.5 1', key: 'svg-4' }],
  ['path', { d: 'M13 8l.5 -1', key: 'svg-5' }],
  ['path', { d: 'M16 12.5l-1 -.5', key: 'svg-6' }],
  ['path', { d: 'M9 10l-1 -.5', key: 'svg-7' }],
]);
