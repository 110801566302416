import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'gender-intergender',
  'IconGenderIntergender',
  [
    ['path', { d: 'M13.5 11.5l6.5 6.5v-4', key: 'svg-0' }],
    ['path', { d: 'M11.5 13.5l6.5 6.5', key: 'svg-1' }],
    ['path', { d: 'M9 4a5 5 0 1 1 0 10a5 5 0 0 1 0 -10z', key: 'svg-2' }],
    ['path', { d: 'M14 20l2 -2', key: 'svg-3' }],
  ],
);
