import createVueComponent from '../createVueComponent';
export default createVueComponent('device-watch-star', 'IconDeviceWatchStar', [
  [
    'path',
    {
      d: 'M10 18h-1a3 3 0 0 1 -3 -3v-6a3 3 0 0 1 3 -3h6a3 3 0 0 1 3 3v1',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 18v3h2', key: 'svg-1' }],
  ['path', { d: 'M9 6v-3h6v3', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M17.8 20.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z',
      key: 'svg-3',
    },
  ],
]);
