import createVueComponent from '../createVueComponent';
export default createVueComponent('campfire', 'IconCampfire', [
  ['path', { d: 'M4 21l16 -4', key: 'svg-0' }],
  ['path', { d: 'M20 21l-16 -4', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M12 15a4 4 0 0 0 4 -4c0 -3 -2 -3 -2 -8c-4 2 -6 5 -6 8a4 4 0 0 0 4 4z',
      key: 'svg-2',
    },
  ],
]);
