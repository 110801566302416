import createVueComponent from '../createVueComponent';
export default createVueComponent('play-volleyball', 'IconPlayVolleyball', [
  ['path', { d: 'M13 4a1 1 0 1 0 2 0a1 1 0 0 0 -2 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M20.5 10a.5 .5 0 1 0 0 -1a.5 .5 0 0 0 0 1z',
      fill: 'currentColor',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M2 16l5 1l.5 -2.5', key: 'svg-2' }],
  ['path', { d: 'M11.5 21l2.5 -5.5l-5.5 -3.5l3.5 -4l3 4l4 2', key: 'svg-3' }],
]);
