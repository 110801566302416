import createVueComponent from '../createVueComponent';
export default createVueComponent('file-settings', 'IconFileSettings', [
  ['path', { d: 'M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M12 10.5v1.5', key: 'svg-1' }],
  ['path', { d: 'M12 16v1.5', key: 'svg-2' }],
  ['path', { d: 'M15.031 12.25l-1.299 .75', key: 'svg-3' }],
  ['path', { d: 'M10.268 15l-1.3 .75', key: 'svg-4' }],
  ['path', { d: 'M15 15.803l-1.285 -.773', key: 'svg-5' }],
  ['path', { d: 'M10.285 12.97l-1.285 -.773', key: 'svg-6' }],
  ['path', { d: 'M14 3v4a1 1 0 0 0 1 1h4', key: 'svg-7' }],
  [
    'path',
    {
      d: 'M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z',
      key: 'svg-8',
    },
  ],
]);
