import createVueComponent from '../createVueComponent';
export default createVueComponent('polaroid', 'IconPolaroid', [
  [
    'path',
    {
      d: 'M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 16l16 0', key: 'svg-1' }],
  ['path', { d: 'M4 12l3 -3c.928 -.893 2.072 -.893 3 0l4 4', key: 'svg-2' }],
  ['path', { d: 'M13 12l2 -2c.928 -.893 2.072 -.893 3 0l2 2', key: 'svg-3' }],
  ['path', { d: 'M14 7l.01 0', key: 'svg-4' }],
]);
