import createVueComponent from '../createVueComponent';
export default createVueComponent('eggs', 'IconEggs', [
  [
    'path',
    {
      d: 'M13 22c-3 0 -4.868 -2.118 -5 -5c0 -3 2 -5 5 -5c4 0 8.01 2.5 8 5c0 2.5 -4 5 -8 5z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M8 18c-3.03 -.196 -5 -2.309 -5 -5.38c0 -4.307 2.75 -8.625 5.5 -8.62c2.614 0 5.248 3.915 5.5 8',
      key: 'svg-1',
    },
  ],
]);
