import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-figma', 'IconBrandFigma', [
  ['path', { d: 'M15 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M6 3m0 3a3 3 0 0 1 3 -3h6a3 3 0 0 1 3 3v0a3 3 0 0 1 -3 3h-6a3 3 0 0 1 -3 -3z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M9 9a3 3 0 0 0 0 6h3m-3 0a3 3 0 1 0 3 3v-15', key: 'svg-2' }],
]);
