import createVueComponent from '../createVueComponent';
export default createVueComponent('brush-off', 'IconBrushOff', [
  ['path', { d: 'M3 17a4 4 0 1 1 4 4h-4v-4z', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M21 3a16 16 0 0 0 -9.309 4.704m-1.795 2.212a15.993 15.993 0 0 0 -1.696 3.284',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M21 3a16 16 0 0 1 -4.697 9.302m-2.195 1.786a15.993 15.993 0 0 1 -3.308 1.712',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
