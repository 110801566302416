import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'spacing-horizontal',
  'IconSpacingHorizontal',
  [
    [
      'path',
      { d: 'M20 20h-2a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h2', key: 'svg-0' },
    ],
    [
      'path',
      { d: 'M4 20h2a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2', key: 'svg-1' },
    ],
    ['path', { d: 'M12 8v8', key: 'svg-2' }],
  ],
);
