import createVueComponent from '../createVueComponent';
export default createVueComponent('settings-dollar', 'IconSettingsDollar', [
  [
    'path',
    {
      d: 'M13.038 20.666c-.902 .665 -2.393 .337 -2.713 -.983a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 .402 2.248',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M15 12a3 3 0 1 0 -1.724 2.716', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-3' }],
]);
