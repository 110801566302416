import createVueComponent from '../createVueComponent';
export default createVueComponent('poker-chip', 'IconPokerChip', [
  ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
  ['path', { d: 'M12 12m-5 0a5 5 0 1 0 10 0a5 5 0 1 0 -10 0', key: 'svg-1' }],
  ['path', { d: 'M12 3v4', key: 'svg-2' }],
  ['path', { d: 'M12 17v4', key: 'svg-3' }],
  ['path', { d: 'M3 12h4', key: 'svg-4' }],
  ['path', { d: 'M17 12h4', key: 'svg-5' }],
  ['path', { d: 'M18.364 5.636l-2.828 2.828', key: 'svg-6' }],
  ['path', { d: 'M8.464 15.536l-2.828 2.828', key: 'svg-7' }],
  ['path', { d: 'M5.636 5.636l2.828 2.828', key: 'svg-8' }],
  ['path', { d: 'M15.536 15.536l2.828 2.828', key: 'svg-9' }],
]);
