import createVueComponent from '../createVueComponent';
export default createVueComponent('tilde', 'IconTilde', [
  [
    'path',
    {
      d: 'M4 12c0 -1.657 1.592 -3 3.556 -3c1.963 0 3.11 1.5 4.444 3c1.333 1.5 2.48 3 4.444 3s3.556 -1.343 3.556 -3',
      key: 'svg-0',
    },
  ],
]);
