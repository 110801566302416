import createVueComponent from '../createVueComponent';
export default createVueComponent('dna-off', 'IconDnaOff', [
  [
    'path',
    {
      d: 'M16 12a3.898 3.898 0 0 0 -1.172 -2.828a4.027 4.027 0 0 0 -2.828 -1.172m-2.828 1.172a4 4 0 1 0 5.656 5.656',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9.172 20.485a4 4 0 1 0 -5.657 -5.657', key: 'svg-1' }],
  ['path', { d: 'M14.828 3.515a4 4 0 1 0 5.657 5.657', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
