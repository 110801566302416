import createVueComponent from '../createVueComponent';
export default createVueComponent('arrow-wave-left-up', 'IconArrowWaveLeftUp', [
  ['path', { d: 'M7 10h-4v4', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M21 12c-.887 -1.285 -2.48 -2.033 -4 -2c-1.52 -.033 -3.113 .715 -4 2c-.887 1.284 -2.48 2.033 -4 2c-1.52 .033 -3 -1 -4 -2l-2 -2',
      key: 'svg-1',
    },
  ],
]);
