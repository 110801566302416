import createVueComponent from '../createVueComponent';
export default createVueComponent('book-upload', 'IconBookUpload', [
  [
    'path',
    { d: 'M14 20h-8a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12v5', key: 'svg-0' },
  ],
  ['path', { d: 'M11 16h-5a2 2 0 0 0 -2 2', key: 'svg-1' }],
  ['path', { d: 'M15 16l3 -3l3 3', key: 'svg-2' }],
  ['path', { d: 'M18 13v9', key: 'svg-3' }],
]);
