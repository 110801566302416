import createVueComponent from '../createVueComponent';
export default createVueComponent('basket-off', 'IconBasketOff', [
  ['path', { d: 'M7 10l1.359 -1.63', key: 'svg-0' }],
  ['path', { d: 'M10.176 6.188l1.824 -2.188l5 6', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M18.77 18.757c-.358 .768 -1.027 1.262 -1.77 1.243h-10c-.966 .024 -1.807 -.817 -2 -2l-2 -8h7',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M14 10h7l-1.397 5.587', key: 'svg-3' }],
  ['path', { d: 'M12 15m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-4' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
