import createVueComponent from '../createVueComponent';
export default createVueComponent('eyeglass-2', 'IconEyeglass2', [
  ['path', { d: 'M8 4h-2l-3 10v2.5', key: 'svg-0' }],
  ['path', { d: 'M16 4h2l3 10v2.5', key: 'svg-1' }],
  ['path', { d: 'M10 16l4 0', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M17.5 16.5m-3.5 0a3.5 3.5 0 1 0 7 0a3.5 3.5 0 1 0 -7 0',
      key: 'svg-3',
    },
  ],
  [
    'path',
    {
      d: 'M6.5 16.5m-3.5 0a3.5 3.5 0 1 0 7 0a3.5 3.5 0 1 0 -7 0',
      key: 'svg-4',
    },
  ],
]);
