import createVueComponent from '../createVueComponent';
export default createVueComponent('camera-cancel', 'IconCameraCancel', [
  [
    'path',
    {
      d: 'M12 20h-7a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v3.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M14.984 13.307a3 3 0 1 0 -2.32 2.62', key: 'svg-1' }],
  ['path', { d: 'M19 19m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-2' }],
  ['path', { d: 'M17 21l4 -4', key: 'svg-3' }],
]);
