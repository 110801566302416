import createVueComponent from '../createVueComponent';
export default createVueComponent('calendar-event', 'IconCalendarEvent', [
  [
    'path',
    {
      d: 'M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 3l0 4', key: 'svg-1' }],
  ['path', { d: 'M8 3l0 4', key: 'svg-2' }],
  ['path', { d: 'M4 11l16 0', key: 'svg-3' }],
  ['path', { d: 'M8 15h2v2h-2z', key: 'svg-4' }],
]);
