import createVueComponent from '../createVueComponent';
export default createVueComponent('notebook-off', 'IconNotebookOff', [
  [
    'path',
    {
      d: 'M8 4h9a2 2 0 0 1 2 2v9m-.179 3.828a2 2 0 0 1 -1.821 1.172h-11a1 1 0 0 1 -1 -1v-14m4 -1v1m0 4v13',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M13 8h2', key: 'svg-1' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
