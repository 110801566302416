import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'align-box-top-center',
  'IconAlignBoxTopCenter',
  [
    [
      'path',
      {
        d: 'M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9 9v-2', key: 'svg-1' }],
    ['path', { d: 'M12 13v-6', key: 'svg-2' }],
    ['path', { d: 'M15 11v-4', key: 'svg-3' }],
  ],
);
