import createVueComponent from '../createVueComponent';
export default createVueComponent('folders', 'IconFolders', [
  [
    'path',
    {
      d: 'M9 4h3l2 2h5a2 2 0 0 1 2 2v7a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M17 17v2a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h2',
      key: 'svg-1',
    },
  ],
]);
