import createVueComponent from '../createVueComponent';
export default createVueComponent('vaccine-bottle', 'IconVaccineBottle', [
  [
    'path',
    {
      d: 'M9 3m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M10 6v.98c0 .877 -.634 1.626 -1.5 1.77c-.866 .144 -1.5 .893 -1.5 1.77v8.48a2 2 0 0 0 2 2h6a2 2 0 0 0 2 -2v-8.48c0 -.877 -.634 -1.626 -1.5 -1.77a1.795 1.795 0 0 1 -1.5 -1.77v-.98',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M7 12h10', key: 'svg-2' }],
  ['path', { d: 'M7 18h10', key: 'svg-3' }],
  ['path', { d: 'M11 15h2', key: 'svg-4' }],
]);
