import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'arrows-move-horizontal',
  'IconArrowsMoveHorizontal',
  [
    ['path', { d: 'M18 9l3 3l-3 3', key: 'svg-0' }],
    ['path', { d: 'M15 12h6', key: 'svg-1' }],
    ['path', { d: 'M6 9l-3 3l3 3', key: 'svg-2' }],
    ['path', { d: 'M3 12h6', key: 'svg-3' }],
  ],
);
