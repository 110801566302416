import createVueComponent from '../createVueComponent';
export default createVueComponent('cookie-off', 'IconCookieOff', [
  ['path', { d: 'M8 13v.01', key: 'svg-0' }],
  ['path', { d: 'M12 17v.01', key: 'svg-1' }],
  ['path', { d: 'M12 12v.01', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M18.192 18.187a3 3 0 0 1 -.976 .652c-1.048 .263 -1.787 .483 -2.216 .661c-.475 .197 -1.092 .538 -1.852 1.024a3 3 0 0 1 -2.296 0c-.802 -.503 -1.419 -.844 -1.852 -1.024c-.471 -.195 -1.21 -.415 -2.216 -.66a3 3 0 0 1 -1.623 -1.624c-.265 -1.052 -.485 -1.79 -.661 -2.216c-.198 -.479 -.54 -1.096 -1.024 -1.852a3 3 0 0 1 0 -2.296c.48 -.744 .82 -1.361 1.024 -1.852c.171 -.413 .391 -1.152 .66 -2.216a3 3 0 0 1 .649 -.971m2.821 -1.174c.14 -.049 .263 -.095 .37 -.139c.458 -.19 1.075 -.531 1.852 -1.024a3 3 0 0 1 2.296 0l2.667 1.104a4 4 0 0 0 4.656 6.14l.053 .132a3 3 0 0 1 0 2.296c-.497 .786 -.838 1.404 -1.024 1.852a6.579 6.579 0 0 0 -.135 .36',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
