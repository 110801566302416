import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-zulip', 'IconBrandZulip', [
  [
    'path',
    {
      d: 'M6.5 3h11c1.325 0 2.5 1 2.5 2.5c0 2 -1.705 3.264 -2 3.5l-4.5 4l2 -5h-9a2.5 2.5 0 0 1 0 -5z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M17.5 21h-11c-1.325 0 -2.5 -1 -2.5 -2.5c0 -2 1.705 -3.264 2 -3.5l4.5 -4l-2 5h9a2.5 2.5 0 1 1 0 5z',
      key: 'svg-1',
    },
  ],
]);
