import createVueComponent from '../createVueComponent';
export default createVueComponent('aspect-ratio', 'IconAspectRatio', [
  [
    'path',
    {
      d: 'M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7 12v-3h3', key: 'svg-1' }],
  ['path', { d: 'M17 12v3h-3', key: 'svg-2' }],
]);
