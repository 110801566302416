import createVueComponent from '../createVueComponent';
export default createVueComponent('cloud-snow', 'IconCloudSnow', [
  [
    'path',
    {
      d: 'M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M11 15v.01m0 3v.01m0 3v.01m4 -4v.01m0 3v.01', key: 'svg-1' }],
]);
