import createVueComponent from '../createVueComponent';
export default createVueComponent('point-filled', 'IconPointFilled', [
  [
    'path',
    {
      d: 'M12 7a5 5 0 1 1 -4.995 5.217l-.005 -.217l.005 -.217a5 5 0 0 1 4.995 -4.783z',
      'stroke-width': '0',
      fill: 'currentColor',
      key: 'svg-0',
    },
  ],
]);
