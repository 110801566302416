import createVueComponent from '../createVueComponent';
export default createVueComponent('bell-search', 'IconBellSearch', [
  [
    'path',
    {
      d: 'M11 17h-7a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6a2 2 0 1 1 4 0a7 7 0 0 1 4 6',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 17v1a3 3 0 0 0 2.685 2.984', key: 'svg-1' }],
  ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-2' }],
  ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-3' }],
]);
