import createVueComponent from '../createVueComponent';
export default createVueComponent('building', 'IconBuilding', [
  ['path', { d: 'M3 21l18 0', key: 'svg-0' }],
  ['path', { d: 'M9 8l1 0', key: 'svg-1' }],
  ['path', { d: 'M9 12l1 0', key: 'svg-2' }],
  ['path', { d: 'M9 16l1 0', key: 'svg-3' }],
  ['path', { d: 'M14 8l1 0', key: 'svg-4' }],
  ['path', { d: 'M14 12l1 0', key: 'svg-5' }],
  ['path', { d: 'M14 16l1 0', key: 'svg-6' }],
  ['path', { d: 'M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16', key: 'svg-7' }],
]);
