import createVueComponent from '../createVueComponent';
export default createVueComponent('currency-riyal', 'IconCurrencyRiyal', [
  [
    'path',
    {
      d: 'M15 9v2a2 2 0 1 1 -4 0v-1v1a2 2 0 1 1 -4 0v-1v4a2 2 0 1 1 -4 0v-2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M18 12.01v-.01', key: 'svg-1' }],
  ['path', { d: 'M22 10v1a5 5 0 0 1 -5 5', key: 'svg-2' }],
]);
