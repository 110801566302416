import createVueComponent from '../createVueComponent';
export default createVueComponent('file-spreadsheet', 'IconFileSpreadsheet', [
  ['path', { d: 'M14 3v4a1 1 0 0 0 1 1h4', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M8 11h8v7h-8z', key: 'svg-2' }],
  ['path', { d: 'M8 15h8', key: 'svg-3' }],
  ['path', { d: 'M11 11v7', key: 'svg-4' }],
]);
