import createVueComponent from '../createVueComponent';
export default createVueComponent('mood-up', 'IconMoodUp', [
  ['path', { d: 'M20.984 12.536a9 9 0 1 0 -8.463 8.449', key: 'svg-0' }],
  ['path', { d: 'M19 22v-6', key: 'svg-1' }],
  ['path', { d: 'M22 19l-3 -3l-3 3', key: 'svg-2' }],
  ['path', { d: 'M9 10h.01', key: 'svg-3' }],
  ['path', { d: 'M15 10h.01', key: 'svg-4' }],
  [
    'path',
    { d: 'M9.5 15c.658 .64 1.56 1 2.5 1s1.842 -.36 2.5 -1', key: 'svg-5' },
  ],
]);
