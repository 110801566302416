import createVueComponent from '../createVueComponent';
export default createVueComponent('layout-kanban', 'IconLayoutKanban', [
  ['path', { d: 'M4 4l6 0', key: 'svg-0' }],
  ['path', { d: 'M14 4l6 0', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M4 8m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z',
      key: 'svg-2',
    },
  ],
  [
    'path',
    {
      d: 'M14 8m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v2a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z',
      key: 'svg-3',
    },
  ],
]);
