import createVueComponent from '../createVueComponent';
export default createVueComponent('message-minus', 'IconMessageMinus', [
  ['path', { d: 'M8 9h8', key: 'svg-0' }],
  ['path', { d: 'M8 13h6', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M11.976 18.614l-3.976 2.386v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M16 19h6', key: 'svg-3' }],
]);
