import createVueComponent from '../createVueComponent';
export default createVueComponent('fish-hook-off', 'IconFishHookOff', [
  [
    'path',
    { d: 'M16 9v3m-.085 3.924a5 5 0 0 1 -9.915 -.924v-4l3 3', key: 'svg-0' },
  ],
  ['path', { d: 'M16 7m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M16 5v-2', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
