import createVueComponent from '../createVueComponent';
export default createVueComponent('road-sign', 'IconRoadSign', [
  [
    'path',
    {
      d: 'M13.446 2.6l7.955 7.954a2.045 2.045 0 0 1 0 2.892l-7.955 7.955a2.045 2.045 0 0 1 -2.892 0l-7.955 -7.955a2.045 2.045 0 0 1 0 -2.892l7.955 -7.955a2.045 2.045 0 0 1 2.892 0z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 14v-2c0 -.59 .414 -1 1 -1h5', key: 'svg-1' }],
  ['path', { d: 'M13 9l2 2l-2 2', key: 'svg-2' }],
]);
