import createVueComponent from '../createVueComponent';
export default createVueComponent('alpha', 'IconAlpha', [
  [
    'path',
    {
      d: 'M18.1 6c-1.1 2.913 -1.9 4.913 -2.4 6c-1.879 4.088 -3.713 6 -6 6c-2.4 0 -4.8 -2.4 -4.8 -6s2.4 -6 4.8 -6c2.267 0 4.135 1.986 6 6c.512 1.102 1.312 3.102 2.4 6',
      key: 'svg-0',
    },
  ],
]);
