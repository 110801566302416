import createVueComponent from '../createVueComponent';
export default createVueComponent('scale-off', 'IconScaleOff', [
  ['path', { d: 'M7 20h10', key: 'svg-0' }],
  ['path', { d: 'M9.452 5.425l2.548 -.425l6 1', key: 'svg-1' }],
  ['path', { d: 'M12 3v5m0 4v8', key: 'svg-2' }],
  ['path', { d: 'M9 12l-3 -6l-3 6a3 3 0 0 0 6 0', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M18.873 14.871a3 3 0 0 0 2.127 -2.871l-3 -6l-2.677 5.355',
      key: 'svg-4',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
