import createVueComponent from '../createVueComponent';
export default createVueComponent('hammer-off', 'IconHammerOff', [
  [
    'path',
    {
      d: 'M10.698 10.72l-6.668 6.698a2.091 2.091 0 0 0 0 2.967a2.11 2.11 0 0 0 2.976 0l6.696 -6.676',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M18.713 14.702l2 -2a1 1 0 0 0 0 -1.414l-7.586 -7.586a1 1 0 0 0 -1.414 0l-2 2',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
