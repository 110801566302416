import createVueComponent from '../createVueComponent';
export default createVueComponent('tournament', 'IconTournament', [
  ['path', { d: 'M4 4m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M20 10m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M4 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
  ['path', { d: 'M4 20m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-3' }],
  ['path', { d: 'M6 12h3a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-3', key: 'svg-4' }],
  ['path', { d: 'M6 4h7a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-2', key: 'svg-5' }],
  ['path', { d: 'M14 10h4', key: 'svg-6' }],
]);
