import createVueComponent from '../createVueComponent';
export default createVueComponent('database-minus', 'IconDatabaseMinus', [
  [
    'path',
    {
      d: 'M4 6c0 1.657 3.582 3 8 3s8 -1.343 8 -3s-3.582 -3 -8 -3s-8 1.343 -8 3',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 6v6c0 1.657 3.582 3 8 3s8 -1.343 8 -3v-6', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M4 12v6c0 1.657 3.582 3 8 3c.164 0 .328 -.002 .49 -.006',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M20 15v-3', key: 'svg-3' }],
  ['path', { d: 'M16 19h6', key: 'svg-4' }],
]);
