import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'layout-align-center',
  'IconLayoutAlignCenter',
  [
    ['path', { d: 'M12 4l0 5', key: 'svg-0' }],
    ['path', { d: 'M12 15l0 5', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M6 9m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v2a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z',
        key: 'svg-2',
      },
    ],
  ],
);
