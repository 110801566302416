import createVueComponent from '../createVueComponent';
export default createVueComponent('number-2', 'IconNumber2', [
  [
    'path',
    {
      d: 'M8 8a4 4 0 1 1 8 0c0 1.098 -.564 2.025 -1.159 2.815l-6.841 9.185h8',
      key: 'svg-0',
    },
  ],
]);
