import createVueComponent from '../createVueComponent';
export default createVueComponent('device-nintendo', 'IconDeviceNintendo', [
  [
    'path',
    { d: 'M10 20v-16h-3a4 4 0 0 0 -4 4v8a4 4 0 0 0 4 4h3z', key: 'svg-0' },
  ],
  [
    'path',
    { d: 'M14 20v-16h3a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-3z', key: 'svg-1' },
  ],
  [
    'circle',
    { cx: '17.5', cy: '15.5', r: '1', fill: 'currentColor', key: 'svg-2' },
  ],
  [
    'circle',
    { cx: '6.5', cy: '8.5', r: '1', fill: 'currentColor', key: 'svg-3' },
  ],
]);
