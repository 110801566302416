import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'building-wind-turbine',
  'IconBuildingWindTurbine',
  [
    ['path', { d: 'M12 11m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
    [
      'path',
      {
        d: 'M10 11v-2.573c0 -.18 .013 -.358 .04 -.536l.716 -4.828c.064 -.597 .597 -1.063 1.244 -1.063s1.18 .466 1.244 1.063l.716 4.828c.027 .178 .04 .357 .04 .536v2.573',
        key: 'svg-1',
      },
    ],
    [
      'path',
      {
        d: 'M13.01 9.28l2.235 1.276c.156 .09 .305 .19 .446 .3l3.836 2.911c.487 .352 .624 1.04 .3 1.596c-.325 .556 -1 .782 -1.548 .541l-4.555 -1.68a3.624 3.624 0 0 1 -.486 -.231l-2.235 -1.277',
        key: 'svg-2',
      },
    ],
    [
      'path',
      {
        d: 'M13 12.716l-2.236 1.277a3.624 3.624 0 0 1 -.485 .23l-4.555 1.681c-.551 .241 -1.223 .015 -1.548 -.54c-.324 -.557 -.187 -1.245 .3 -1.597l3.836 -2.91a3.41 3.41 0 0 1 .446 -.3l2.235 -1.277',
        key: 'svg-3',
      },
    ],
    ['path', { d: 'M7 21h10', key: 'svg-4' }],
    ['path', { d: 'M10 21l1 -7', key: 'svg-5' }],
    ['path', { d: 'M13 14l1 7', key: 'svg-6' }],
  ],
);
