import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'circle-chevrons-right',
  'IconCircleChevronsRight',
  [
    ['path', { d: 'M9 9l3 3l-3 3', key: 'svg-0' }],
    ['path', { d: 'M13 9l3 3l-3 3', key: 'svg-1' }],
    ['path', { d: 'M3 12a9 9 0 1 0 0 -.265l0 .265z', key: 'svg-2' }],
  ],
);
