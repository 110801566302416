import createVueComponent from '../createVueComponent';
export default createVueComponent('gavel', 'IconGavel', [
  [
    'path',
    {
      d: 'M13 10l7.383 7.418c.823 .82 .823 2.148 0 2.967a2.11 2.11 0 0 1 -2.976 0l-7.407 -7.385',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M6 9l4 4', key: 'svg-1' }],
  ['path', { d: 'M13 10l-4 -4', key: 'svg-2' }],
  ['path', { d: 'M3 21h7', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M6.793 15.793l-3.586 -3.586a1 1 0 0 1 0 -1.414l2.293 -2.293l.5 .5l3 -3l-.5 -.5l2.293 -2.293a1 1 0 0 1 1.414 0l3.586 3.586a1 1 0 0 1 0 1.414l-2.293 2.293l-.5 -.5l-3 3l.5 .5l-2.293 2.293a1 1 0 0 1 -1.414 0z',
      key: 'svg-4',
    },
  ],
]);
