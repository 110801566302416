import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'device-desktop-down',
  'IconDeviceDesktopDown',
  [
    [
      'path',
      {
        d: 'M13.5 16h-9.5a1 1 0 0 1 -1 -1v-10a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v7.5',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M7 20h5', key: 'svg-1' }],
    ['path', { d: 'M9 16v4', key: 'svg-2' }],
    ['path', { d: 'M19 16v6', key: 'svg-3' }],
    ['path', { d: 'M22 19l-3 3l-3 -3', key: 'svg-4' }],
  ],
);
