import createVueComponent from '../createVueComponent';
export default createVueComponent('math-1-divide-2', 'IconMath1Divide2', [
  ['path', { d: 'M5 12h14', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M10 15h3a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-2a1 1 0 0 0 -1 1v1a1 1 0 0 0 1 1h3',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M10 5l2 -2v6', key: 'svg-2' }],
]);
