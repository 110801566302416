import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-baidu', 'IconBrandBaidu', [
  ['path', { d: 'M5 9.5m-1 0a1 1.5 0 1 0 2 0a1 1.5 0 1 0 -2 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M14.463 11.596c1.282 1.774 3.476 3.416 3.476 3.416s1.921 1.574 .593 3.636c-1.328 2.063 -4.892 1.152 -4.892 1.152s-1.416 -.44 -3.06 -.088c-1.644 .356 -3.06 .22 -3.06 .22s-2.055 -.22 -2.47 -2.304c-.416 -2.084 1.918 -3.638 2.102 -3.858c.182 -.222 1.409 -.966 2.284 -2.394c.875 -1.428 3.337 -2.287 5.027 .221z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M9 4.5m-1 0a1 1.5 0 1 0 2 0a1 1.5 0 1 0 -2 0', key: 'svg-2' }],
  [
    'path',
    { d: 'M15 4.5m-1 0a1 1.5 0 1 0 2 0a1 1.5 0 1 0 -2 0', key: 'svg-3' },
  ],
  [
    'path',
    { d: 'M19 9.5m-1 0a1 1.5 0 1 0 2 0a1 1.5 0 1 0 -2 0', key: 'svg-4' },
  ],
]);
