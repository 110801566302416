import createVueComponent from '../createVueComponent';
export default createVueComponent('plane-tilt', 'IconPlaneTilt', [
  [
    'path',
    {
      d: 'M14.5 6.5l3 -2.9a2.05 2.05 0 0 1 2.9 2.9l-2.9 3l2.5 7.5l-2.5 2.55l-3.5 -6.55l-3 3v3l-2 2l-1.5 -4.5l-4.5 -1.5l2 -2h3l3 -3l-6.5 -3.5l2.5 -2.5l7.5 2.5z',
      key: 'svg-0',
    },
  ],
]);
