import createVueComponent from '../createVueComponent';
export default createVueComponent('album-off', 'IconAlbumOff', [
  [
    'path',
    {
      d: 'M8 4h10a2 2 0 0 1 2 2v10m-.581 3.41c-.362 .364 -.864 .59 -1.419 .59h-12a2 2 0 0 1 -2 -2v-12c0 -.552 .224 -1.052 .585 -1.413',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 4v4m1.503 1.497l.497 -.497l2 2v-7', key: 'svg-1' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
