import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'device-ipad-horizontal-heart',
  'IconDeviceIpadHorizontalHeart',
  [
    [
      'path',
      {
        d: 'M10.5 20h-5.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v5',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9 17h1', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M18 22l3.35 -3.284a2.143 2.143 0 0 0 .005 -3.071a2.242 2.242 0 0 0 -3.129 -.006l-.224 .22l-.223 -.22a2.242 2.242 0 0 0 -3.128 -.006a2.143 2.143 0 0 0 -.006 3.071l3.355 3.296z',
        key: 'svg-2',
      },
    ],
  ],
);
