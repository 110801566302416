import createVueComponent from '../createVueComponent';
export default createVueComponent('arrow-big-down', 'IconArrowBigDown', [
  [
    'path',
    {
      d: 'M15 4v8h3.586a1 1 0 0 1 .707 1.707l-6.586 6.586a1 1 0 0 1 -1.414 0l-6.586 -6.586a1 1 0 0 1 .707 -1.707h3.586v-8a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1z',
      key: 'svg-0',
    },
  ],
]);
