import createVueComponent from '../createVueComponent';
export default createVueComponent('chess', 'IconChess', [
  [
    'path',
    {
      d: 'M12 3a3 3 0 0 1 3 3c0 1.113 -.6 2.482 -1.5 3l1.5 7h-6l1.5 -7c-.9 -.518 -1.5 -1.887 -1.5 -3a3 3 0 0 1 3 -3z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8 9h8', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M6.684 16.772a1 1 0 0 0 -.684 .949v1.279a1 1 0 0 0 1 1h10a1 1 0 0 0 1 -1v-1.28a1 1 0 0 0 -.684 -.948l-2.316 -.772h-6l-2.316 .772z',
      key: 'svg-2',
    },
  ],
]);
