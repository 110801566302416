import createVueComponent from '../createVueComponent';
export default createVueComponent('wash-dry-flat', 'IconWashDryFlat', [
  [
    'path',
    {
      d: 'M3 6a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3v-12z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7 12h10', key: 'svg-1' }],
]);
