import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'box-align-top-right-filled',
  'IconBoxAlignTopRightFilled',
  [
    [
      'path',
      {
        d: 'M19 3.01h-5a2 2 0 0 0 -2 2v5a2 2 0 0 0 2 2h5a2 2 0 0 0 2 -2v-5a2 2 0 0 0 -2 -2z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M20 14a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-1',
      },
    ],
    [
      'path',
      {
        d: 'M20 19a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-2',
      },
    ],
    [
      'path',
      {
        d: 'M15 19a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-3',
      },
    ],
    [
      'path',
      {
        d: 'M9 19a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-4',
      },
    ],
    [
      'path',
      {
        d: 'M9 3a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-5',
      },
    ],
    [
      'path',
      {
        d: 'M4 19a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-6',
      },
    ],
    [
      'path',
      {
        d: 'M4 14a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-7',
      },
    ],
    [
      'path',
      {
        d: 'M4 8a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-8',
      },
    ],
    [
      'path',
      {
        d: 'M4 3a1 1 0 0 1 .993 .883l.007 .127a1 1 0 0 1 -1.993 .117l-.007 -.127a1 1 0 0 1 1 -1z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-9',
      },
    ],
  ],
);
