import createVueComponent from '../createVueComponent';
export default createVueComponent('devices-pc-off', 'IconDevicesPcOff', [
  ['path', { d: 'M9 9v10h-6v-14h2', key: 'svg-0' }],
  ['path', { d: 'M13 9h9v7h-2m-4 0h-4v-4', key: 'svg-1' }],
  ['path', { d: 'M14 19h5', key: 'svg-2' }],
  ['path', { d: 'M17 17v2', key: 'svg-3' }],
  ['path', { d: 'M6 13v.01', key: 'svg-4' }],
  ['path', { d: 'M6 16v.01', key: 'svg-5' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
]);
