import createVueComponent from '../createVueComponent';
export default createVueComponent('message-circle-up', 'IconMessageCircleUp', [
  [
    'path',
    {
      d: 'M12.004 19.98a9.869 9.869 0 0 1 -4.304 -.98l-4.7 1l1.3 -3.9c-2.324 -3.437 -1.426 -7.872 2.1 -10.374c3.526 -2.501 8.59 -2.296 11.845 .48c1.994 1.701 2.932 4.045 2.746 6.349',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 22v-6', key: 'svg-1' }],
  ['path', { d: 'M22 19l-3 -3l-3 3', key: 'svg-2' }],
]);
