import createVueComponent from '../createVueComponent';
export default createVueComponent('stereo-glasses', 'IconStereoGlasses', [
  ['path', { d: 'M8 3h-2l-3 9', key: 'svg-0' }],
  ['path', { d: 'M16 3h2l3 9', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M3 12v7a1 1 0 0 0 1 1h4.586a1 1 0 0 0 .707 -.293l2 -2a1 1 0 0 1 1.414 0l2 2a1 1 0 0 0 .707 .293h4.586a1 1 0 0 0 1 -1v-7h-18z',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M7 16h1', key: 'svg-3' }],
  ['path', { d: 'M16 16h1', key: 'svg-4' }],
]);
