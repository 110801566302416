import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'circuit-switch-open',
  'IconCircuitSwitchOpen',
  [
    ['path', { d: 'M2 12h2', key: 'svg-0' }],
    ['path', { d: 'M20 12h2', key: 'svg-1' }],
    ['path', { d: 'M6 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
    ['path', { d: 'M18 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-3' }],
    ['path', { d: 'M7.5 10.5l7.5 -5.5', key: 'svg-4' }],
  ],
);
