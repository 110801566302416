import createVueComponent from '../createVueComponent';
export default createVueComponent('xbox-a', 'IconXboxA', [
  [
    'path',
    {
      d: 'M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M15 16l-3 -8l-3 8', key: 'svg-1' }],
  ['path', { d: 'M14 14h-4', key: 'svg-2' }],
]);
