import createVueComponent from '../createVueComponent';
export default createVueComponent('hdr', 'IconHdr', [
  ['path', { d: 'M3 16v-8', key: 'svg-0' }],
  ['path', { d: 'M7 8v8', key: 'svg-1' }],
  ['path', { d: 'M3 12h4', key: 'svg-2' }],
  [
    'path',
    { d: 'M10 8v8h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-2z', key: 'svg-3' },
  ],
  ['path', { d: 'M17 12h2a2 2 0 1 0 0 -4h-2v8m4 0l-3 -4', key: 'svg-4' }],
]);
