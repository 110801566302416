import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'inner-shadow-bottom-right-filled',
  'IconInnerShadowBottomRightFilled',
  [
    [
      'path',
      {
        d: 'M12 2c5.523 0 10 4.477 10 10s-4.477 10 -10 10s-10 -4.477 -10 -10s4.477 -10 10 -10zm6 9a1 1 0 0 0 -1 1a5 5 0 0 1 -5 5a1 1 0 0 0 0 2a7 7 0 0 0 7 -7a1 1 0 0 0 -1 -1z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-0',
      },
    ],
  ],
);
