import createVueComponent from '../createVueComponent';
export default createVueComponent('devices-pin', 'IconDevicesPin', [
  ['path', { d: 'M13 14v-5a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v2', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M18 8v-3a1 1 0 0 0 -1 -1h-13a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h8',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M21.121 20.121a3 3 0 1 0 -4.242 0c.418 .419 1.125 1.045 2.121 1.879c1.051 -.89 1.759 -1.516 2.121 -1.879z',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M19 18v.01', key: 'svg-3' }],
  ['path', { d: 'M16 9h2', key: 'svg-4' }],
]);
