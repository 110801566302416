import createVueComponent from '../createVueComponent';
export default createVueComponent('pointer-x', 'IconPointerX', [
  [
    'path',
    {
      d: 'M15.768 13.212l-.99 -.99l3.113 -2.09a1.2 1.2 0 0 0 -.309 -2.228l-13.582 -3.904l3.904 13.563a1.2 1.2 0 0 0 2.228 .308l2.09 -3.093l.908 .908',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M22 22l-5 -5', key: 'svg-1' }],
  ['path', { d: 'M17 22l5 -5', key: 'svg-2' }],
]);
