import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'calendar-exclamation',
  'IconCalendarExclamation',
  [
    [
      'path',
      {
        d: 'M15 21h-9a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v5',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M16 3v4', key: 'svg-1' }],
    ['path', { d: 'M8 3v4', key: 'svg-2' }],
    ['path', { d: 'M4 11h16', key: 'svg-3' }],
    ['path', { d: 'M11 15h1', key: 'svg-4' }],
    ['path', { d: 'M12 15v3', key: 'svg-5' }],
    ['path', { d: 'M19 16v3', key: 'svg-6' }],
    ['path', { d: 'M19 22v.01', key: 'svg-7' }],
  ],
);
