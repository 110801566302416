import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-qq', 'IconBrandQq', [
  [
    'path',
    {
      d: 'M6 9.748a14.716 14.716 0 0 0 11.995 -.052c.275 -9.236 -11.104 -11.256 -11.995 .052z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M18 10c.984 2.762 1.949 4.765 2 7.153c.014 .688 -.664 1.346 -1.184 .303c-.346 -.696 -.952 -1.181 -1.816 -1.456',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M17 16c.031 1.831 .147 3.102 -1 4', key: 'svg-2' }],
  ['path', { d: 'M8 20c-1.099 -.87 -.914 -2.24 -1 -4', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M6 10c-.783 2.338 -1.742 4.12 -1.968 6.43c-.217 2.227 .716 1.644 1.16 .917c.296 -.487 .898 -.934 1.808 -1.347',
      key: 'svg-4',
    },
  ],
  ['path', { d: 'M15.898 13l-.476 -2', key: 'svg-5' }],
  [
    'path',
    {
      d: 'M8 20l-1.5 1c-.5 .5 -.5 1 .5 1h10c1 0 1 -.5 .5 -1l-1.5 -1',
      key: 'svg-6',
    },
  ],
  ['path', { d: 'M13.75 7m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-7' }],
  ['path', { d: 'M10.25 7m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-8' }],
]);
