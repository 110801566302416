import createVueComponent from '../createVueComponent';
export default createVueComponent('microscope-off', 'IconMicroscopeOff', [
  ['path', { d: 'M5 21h14', key: 'svg-0' }],
  ['path', { d: 'M6 18h2', key: 'svg-1' }],
  ['path', { d: 'M7 18v3', key: 'svg-2' }],
  ['path', { d: 'M10 10l-1 1l3 3l1 -1m2 -2l3 -3l-3 -3l-3 3', key: 'svg-3' }],
  ['path', { d: 'M10.5 12.5l-1.5 1.5', key: 'svg-4' }],
  ['path', { d: 'M17 3l3 3', key: 'svg-5' }],
  [
    'path',
    {
      d: 'M12 21a6 6 0 0 0 5.457 -3.505m.441 -3.599a6 6 0 0 0 -2.183 -3.608',
      key: 'svg-6',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-7' }],
]);
