import createVueComponent from '../createVueComponent';
export default createVueComponent('mouse-2', 'IconMouse2', [
  [
    'path',
    {
      d: 'M6 3m0 4a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v10a4 4 0 0 1 -4 4h-4a4 4 0 0 1 -4 -4z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 3v7', key: 'svg-1' }],
  ['path', { d: 'M6 10h12', key: 'svg-2' }],
]);
