import createVueComponent from '../createVueComponent';
export default createVueComponent('air-balloon', 'IconAirBalloon', [
  [
    'path',
    {
      d: 'M10 19m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M12 16c3.314 0 6 -4.686 6 -8a6 6 0 1 0 -12 0c0 3.314 2.686 8 6 8z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 9m-2 0a2 7 0 1 0 4 0a2 7 0 1 0 -4 0', key: 'svg-2' }],
]);
