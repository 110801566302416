import createVueComponent from '../createVueComponent';
export default createVueComponent('route', 'IconRoute', [
  ['path', { d: 'M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M18 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M12 19h4.5a3.5 3.5 0 0 0 0 -7h-8a3.5 3.5 0 0 1 0 -7h3.5',
      key: 'svg-2',
    },
  ],
]);
