import createVueComponent from '../createVueComponent';
export default createVueComponent('squares-filled', 'IconSquaresFilled', [
  [
    'path',
    {
      d: 'M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8 14.5l6.492 -6.492', key: 'svg-1' }],
  ['path', { d: 'M13.496 20l6.504 -6.504l-6.504 6.504z', key: 'svg-2' }],
  ['path', { d: 'M8.586 19.414l10.827 -10.827', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2',
      key: 'svg-4',
    },
  ],
]);
