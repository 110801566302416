import createVueComponent from '../createVueComponent';
export default createVueComponent('wash', 'IconWash', [
  [
    'path',
    {
      d: 'M3.486 8.965c.168 .02 .34 .033 .514 .035c.79 .009 1.539 -.178 2 -.5c.461 -.32 1.21 -.507 2 -.5c.79 -.007 1.539 .18 2 .5c.461 .322 1.21 .509 2 .5c.79 .009 1.539 -.178 2 -.5c.461 -.32 1.21 -.507 2 -.5c.79 -.007 1.539 .18 2 .5c.461 .322 1.21 .509 2 .5c.17 0 .339 -.014 .503 -.034',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M3 6l1.721 10.329a2 2 0 0 0 1.973 1.671h10.612a2 2 0 0 0 1.973 -1.671l1.721 -10.329',
      key: 'svg-1',
    },
  ],
]);
