import createVueComponent from '../createVueComponent';
export default createVueComponent('coin-euro', 'IconCoinEuro', [
  ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M14.401 8c-.669 -.628 -1.5 -1 -2.401 -1c-2.21 0 -4 2.239 -4 5s1.79 5 4 5c.9 0 1.731 -.372 2.4 -1',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M7 10.5h4', key: 'svg-2' }],
  ['path', { d: 'M7 13.5h4', key: 'svg-3' }],
]);
