import createVueComponent from '../createVueComponent';
export default createVueComponent('bookmark-minus', 'IconBookmarkMinus', [
  [
    'path',
    {
      d: 'M12.427 17.256l-.427 -.256l-5 3v-14a2 2 0 0 1 2 -2h6a2 2 0 0 1 2 2v9',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 19h6', key: 'svg-1' }],
]);
