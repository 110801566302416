import createVueComponent from '../createVueComponent';
export default createVueComponent('mail-ai', 'IconMailAi', [
  [
    'path',
    {
      d: 'M10 19h-5a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v4',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 7l8 5.345m4 -1.345l6 -4', key: 'svg-1' }],
  ['path', { d: 'M14 21v-4a2 2 0 1 1 4 0v4', key: 'svg-2' }],
  ['path', { d: 'M14 19h4', key: 'svg-3' }],
  ['path', { d: 'M21 15v6', key: 'svg-4' }],
]);
