import createVueComponent from '../createVueComponent';
export default createVueComponent('puzzle-2', 'IconPuzzle2', [
  [
    'path',
    {
      d: 'M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M12 4v2.5a.5 .5 0 0 1 -.5 .5a1.5 1.5 0 0 0 0 3a.5 .5 0 0 1 .5 .5v1.5',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M12 12v1.5a.5 .5 0 0 0 .5 .5a1.5 1.5 0 0 1 0 3a.5 .5 0 0 0 -.5 .5v2.5',
      key: 'svg-2',
    },
  ],
  [
    'path',
    {
      d: 'M20 12h-2.5a.5 .5 0 0 1 -.5 -.5a1.5 1.5 0 0 0 -3 0a.5 .5 0 0 1 -.5 .5h-1.5',
      key: 'svg-3',
    },
  ],
  [
    'path',
    {
      d: 'M12 12h-1.5a.5 .5 0 0 0 -.5 .5a1.5 1.5 0 0 1 -3 0a.5 .5 0 0 0 -.5 -.5h-2.5',
      key: 'svg-4',
    },
  ],
]);
