import createVueComponent from '../createVueComponent';
export default createVueComponent('fountain-off', 'IconFountainOff', [
  ['path', { d: 'M9 16v-5a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M15 16v-1m0 -4a2 2 0 1 1 4 0', key: 'svg-1' }],
  ['path', { d: 'M12 16v-4m0 -4v-2a3 3 0 0 1 6 0', key: 'svg-2' }],
  ['path', { d: 'M7.451 3.43a3 3 0 0 1 4.549 2.57', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M20 16h1v1m-.871 3.114a2.99 2.99 0 0 1 -2.129 .886h-12a3 3 0 0 1 -3 -3v-2h13',
      key: 'svg-4',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
