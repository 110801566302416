import createVueComponent from '../createVueComponent';
export default createVueComponent('device-tv', 'IconDeviceTv', [
  [
    'path',
    {
      d: 'M3 7m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 3l-4 4l-4 -4', key: 'svg-1' }],
]);
