import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-behance', 'IconBrandBehance', [
  [
    'path',
    { d: 'M3 18v-12h4.5a3 3 0 0 1 0 6a3 3 0 0 1 0 6h-4.5', key: 'svg-0' },
  ],
  ['path', { d: 'M3 12l4.5 0', key: 'svg-1' }],
  [
    'path',
    { d: 'M14 13h7a3.5 3.5 0 0 0 -7 0v2a3.5 3.5 0 0 0 6.64 1', key: 'svg-2' },
  ],
  ['path', { d: 'M16 6l3 0', key: 'svg-3' }],
]);
