import createVueComponent from '../createVueComponent';
export default createVueComponent('table-minus', 'IconTableMinus', [
  [
    'path',
    {
      d: 'M12.5 21h-7.5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 10h18', key: 'svg-1' }],
  ['path', { d: 'M10 3v18', key: 'svg-2' }],
  ['path', { d: 'M16 19h6', key: 'svg-3' }],
]);
