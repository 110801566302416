import createVueComponent from '../createVueComponent';
export default createVueComponent('drag-drop', 'IconDragDrop', [
  [
    'path',
    {
      d: 'M19 11v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M13 13l9 3l-4 2l-2 4l-3 -9', key: 'svg-1' }],
  ['path', { d: 'M3 3l0 .01', key: 'svg-2' }],
  ['path', { d: 'M7 3l0 .01', key: 'svg-3' }],
  ['path', { d: 'M11 3l0 .01', key: 'svg-4' }],
  ['path', { d: 'M15 3l0 .01', key: 'svg-5' }],
  ['path', { d: 'M3 7l0 .01', key: 'svg-6' }],
  ['path', { d: 'M3 11l0 .01', key: 'svg-7' }],
  ['path', { d: 'M3 15l0 .01', key: 'svg-8' }],
]);
