import createVueComponent from '../createVueComponent';
export default createVueComponent('http-delete', 'IconHttpDelete', [
  [
    'path',
    { d: 'M3 8v8h2a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-2z', key: 'svg-0' },
  ],
  ['path', { d: 'M14 8h-4v8h4', key: 'svg-1' }],
  ['path', { d: 'M10 12h2.5', key: 'svg-2' }],
  ['path', { d: 'M17 8v8h4', key: 'svg-3' }],
]);
