import createVueComponent from '../createVueComponent';
export default createVueComponent('message-dots', 'IconMessageDots', [
  [
    'path',
    {
      d: 'M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 11l0 .01', key: 'svg-1' }],
  ['path', { d: 'M8 11l0 .01', key: 'svg-2' }],
  ['path', { d: 'M16 11l0 .01', key: 'svg-3' }],
]);
