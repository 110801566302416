import createVueComponent from '../createVueComponent';
export default createVueComponent('help-triangle', 'IconHelpTriangle', [
  [
    'path',
    {
      d: 'M10.24 3.957l-8.422 14.06a1.989 1.989 0 0 0 1.7 2.983h16.845a1.989 1.989 0 0 0 1.7 -2.983l-8.423 -14.06a1.989 1.989 0 0 0 -3.4 0z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 17v.01', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M12 14a2 2 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
      key: 'svg-2',
    },
  ],
]);
