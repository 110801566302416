import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-codesandbox', 'IconBrandCodesandbox', [
  [
    'path',
    {
      d: 'M20 7.5v9l-4 2.25l-4 2.25l-4 -2.25l-4 -2.25v-9l4 -2.25l4 -2.25l4 2.25z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 12l4 -2.25l4 -2.25', key: 'svg-1' }],
  ['path', { d: 'M12 12l0 9', key: 'svg-2' }],
  ['path', { d: 'M12 12l-4 -2.25l-4 -2.25', key: 'svg-3' }],
  ['path', { d: 'M20 12l-4 2v4.75', key: 'svg-4' }],
  ['path', { d: 'M4 12l4 2l0 4.75', key: 'svg-5' }],
  ['path', { d: 'M8 5.25l4 2.25l4 -2.25', key: 'svg-6' }],
]);
