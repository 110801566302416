import createVueComponent from '../createVueComponent';
export default createVueComponent('photo-cog', 'IconPhotoCog', [
  ['path', { d: 'M15 8h.01', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M12 21h-6a3 3 0 0 1 -3 -3v-12a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v6',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 16l5 -5c.928 -.893 2.072 -.893 3 0l3 3', key: 'svg-2' }],
  ['path', { d: 'M14 14l1 -1c.48 -.461 1.016 -.684 1.551 -.67', key: 'svg-3' }],
  ['path', { d: 'M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-4' }],
  ['path', { d: 'M19.001 15.5v1.5', key: 'svg-5' }],
  ['path', { d: 'M19.001 21v1.5', key: 'svg-6' }],
  ['path', { d: 'M22.032 17.25l-1.299 .75', key: 'svg-7' }],
  ['path', { d: 'M17.27 20l-1.3 .75', key: 'svg-8' }],
  ['path', { d: 'M15.97 17.25l1.3 .75', key: 'svg-9' }],
  ['path', { d: 'M20.733 20l1.3 .75', key: 'svg-10' }],
]);
