import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-mantine', 'IconBrandMantine', [
  ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
  [
    'path',
    { d: 'M11 16c1.22 -.912 2 -2.36 2 -4a5.01 5.01 0 0 0 -2 -4', key: 'svg-1' },
  ],
  ['path', { d: 'M14 9h-2', key: 'svg-2' }],
  ['path', { d: 'M14 15h-2', key: 'svg-3' }],
  ['path', { d: 'M10 12h.01', key: 'svg-4' }],
]);
