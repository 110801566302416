import createVueComponent from '../createVueComponent';
export default createVueComponent('emergency-bed', 'IconEmergencyBed', [
  ['path', { d: 'M16 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M8 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M4 8l2.1 2.8a3 3 0 0 0 2.4 1.2h11.5', key: 'svg-2' }],
  ['path', { d: 'M10 6h4', key: 'svg-3' }],
  ['path', { d: 'M12 4v4', key: 'svg-4' }],
  ['path', { d: 'M12 12v2l-2.5 2.5', key: 'svg-5' }],
  ['path', { d: 'M14.5 16.5l-2.5 -2.5', key: 'svg-6' }],
]);
