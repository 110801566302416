import createVueComponent from '../createVueComponent';
export default createVueComponent('home-dollar', 'IconHomeDollar', [
  ['path', { d: 'M19 10l-7 -7l-9 9h2v7a2 2 0 0 0 2 2h6', key: 'svg-0' }],
  [
    'path',
    { d: 'M9 21v-6a2 2 0 0 1 2 -2h2c.387 0 .748 .11 1.054 .3', key: 'svg-1' },
  ],
  [
    'path',
    {
      d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-3' }],
]);
