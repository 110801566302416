import createVueComponent from '../createVueComponent';
export default createVueComponent('world-search', 'IconWorldSearch', [
  ['path', { d: 'M21 12a9 9 0 1 0 -9 9', key: 'svg-0' }],
  ['path', { d: 'M3.6 9h16.8', key: 'svg-1' }],
  ['path', { d: 'M3.6 15h7.9', key: 'svg-2' }],
  ['path', { d: 'M11.5 3a17 17 0 0 0 0 18', key: 'svg-3' }],
  ['path', { d: 'M12.5 3a16.984 16.984 0 0 1 2.574 8.62', key: 'svg-4' }],
  ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-5' }],
  ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-6' }],
]);
