import createVueComponent from '../createVueComponent';
export default createVueComponent('dna-2-off', 'IconDna2Off', [
  [
    'path',
    {
      d: 'M17 3v1c-.007 2.46 -.91 4.554 -2.705 6.281m-2.295 1.719c-3.328 1.99 -5 4.662 -5.008 8.014v1',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M17 21.014v-1c0 -1.44 -.315 -2.755 -.932 -3.944m-4.068 -4.07c-1.903 -1.138 -3.263 -2.485 -4.082 -4.068',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M8 4h9', key: 'svg-2' }],
  ['path', { d: 'M7 20h10', key: 'svg-3' }],
  ['path', { d: 'M12 8h4', key: 'svg-4' }],
  ['path', { d: 'M8 16h8', key: 'svg-5' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
]);
