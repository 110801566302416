import createVueComponent from '../createVueComponent';
export default createVueComponent('mouse-off', 'IconMouseOff', [
  [
    'path',
    {
      d: 'M7.733 3.704a3.982 3.982 0 0 1 2.267 -.704h4a4 4 0 0 1 4 4v7m-.1 3.895a4 4 0 0 1 -3.9 3.105h-4a4 4 0 0 1 -4 -4v-10c0 -.3 .033 -.593 .096 -.874',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 7v1', key: 'svg-1' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
