import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'player-play-filled',
  'IconPlayerPlayFilled',
  [
    [
      'path',
      {
        d: 'M6 4v16a1 1 0 0 0 1.524 .852l13 -8a1 1 0 0 0 0 -1.704l-13 -8a1 1 0 0 0 -1.524 .852z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-0',
      },
    ],
  ],
);
