import createVueComponent from '../createVueComponent';
export default createVueComponent('baby-bottle', 'IconBabyBottle', [
  ['path', { d: 'M5 10h14', key: 'svg-0' }],
  ['path', { d: 'M12 2v2', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M12 4a5 5 0 0 1 5 5v11a2 2 0 0 1 -2 2h-6a2 2 0 0 1 -2 -2v-11a5 5 0 0 1 5 -5z',
      key: 'svg-2',
    },
  ],
]);
