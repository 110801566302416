import createVueComponent from '../createVueComponent';
export default createVueComponent('christmas-tree', 'IconChristmasTree', [
  [
    'path',
    {
      d: 'M12 3l4 4l-2 1l4 4l-3 1l4 4h-14l4 -4l-3 -1l4 -4l-2 -1z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    { d: 'M14 17v3a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-3', key: 'svg-1' },
  ],
]);
