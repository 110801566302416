import createVueComponent from '../createVueComponent';
export default createVueComponent('message-2-off', 'IconMessage2Off', [
  ['path', { d: 'M8 9h1m4 0h3', key: 'svg-0' }],
  ['path', { d: 'M8 13h5', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M8 4h10a3 3 0 0 1 3 3v8c0 .57 -.16 1.104 -.436 1.558m-2.564 1.442h-3l-3 3l-3 -3h-3a3 3 0 0 1 -3 -3v-8c0 -1.084 .575 -2.034 1.437 -2.561',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
