import createVueComponent from '../createVueComponent';
export default createVueComponent('dna', 'IconDna', [
  [
    'path',
    {
      d: 'M14.828 14.828a4 4 0 1 0 -5.656 -5.656a4 4 0 0 0 5.656 5.656z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9.172 20.485a4 4 0 1 0 -5.657 -5.657', key: 'svg-1' }],
  ['path', { d: 'M14.828 3.515a4 4 0 0 0 5.657 5.657', key: 'svg-2' }],
]);
