import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-prisma', 'IconBrandPrisma', [
  [
    'path',
    {
      d: 'M4.186 16.202l3.615 5.313c.265 .39 .754 .57 1.215 .447l10.166 -2.718a1.086 1.086 0 0 0 .713 -1.511l-7.505 -15.483a.448 .448 0 0 0 -.787 -.033l-7.453 12.838a1.07 1.07 0 0 0 .037 1.147z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8.5 22l3.5 -20', key: 'svg-1' }],
]);
