import createVueComponent from '../createVueComponent';
export default createVueComponent('logout-2', 'IconLogout2', [
  [
    'path',
    {
      d: 'M10 8v-2a2 2 0 0 1 2 -2h7a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M15 12h-12l3 -3', key: 'svg-1' }],
  ['path', { d: 'M6 15l-3 -3', key: 'svg-2' }],
]);
