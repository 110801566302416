import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-windy', 'IconBrandWindy', [
  ['path', { d: 'M9 4c0 5.5 -.33 16 4 16s7.546 -11.27 8 -13', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M3 4c.253 5.44 1.449 16 5.894 16c4.444 0 8.42 -10.036 9.106 -14',
      key: 'svg-1',
    },
  ],
]);
