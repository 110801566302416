import createVueComponent from '../createVueComponent';
export default createVueComponent('typography-off', 'IconTypographyOff', [
  ['path', { d: 'M4 20h3', key: 'svg-0' }],
  ['path', { d: 'M14 20h6', key: 'svg-1' }],
  ['path', { d: 'M6.9 15h6.9', key: 'svg-2' }],
  ['path', { d: 'M13 13l3 7', key: 'svg-3' }],
  ['path', { d: 'M5 20l4.09 -10.906', key: 'svg-4' }],
  ['path', { d: 'M10.181 6.183l.819 -2.183h2l3.904 8.924', key: 'svg-5' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
]);
