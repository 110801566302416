import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'device-desktop-share',
  'IconDeviceDesktopShare',
  [
    [
      'path',
      {
        d: 'M12.5 16h-8.5a1 1 0 0 1 -1 -1v-10a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v8',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M7 20h5.5', key: 'svg-1' }],
    ['path', { d: 'M9 16v4', key: 'svg-2' }],
    ['path', { d: 'M16 22l5 -5', key: 'svg-3' }],
    ['path', { d: 'M21 21.5v-4.5h-4.5', key: 'svg-4' }],
  ],
);
