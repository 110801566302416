import createVueComponent from '../createVueComponent';
export default createVueComponent('shape-off', 'IconShapeOff', [
  ['path', { d: 'M3.575 3.597a2 2 0 0 0 2.849 2.808', key: 'svg-0' }],
  ['path', { d: 'M19 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M5 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
  ['path', { d: 'M17.574 17.598a2 2 0 0 0 2.826 2.83', key: 'svg-3' }],
  ['path', { d: 'M5 7v10', key: 'svg-4' }],
  ['path', { d: 'M9 5h8', key: 'svg-5' }],
  ['path', { d: 'M7 19h10', key: 'svg-6' }],
  ['path', { d: 'M19 7v8', key: 'svg-7' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-8' }],
]);
