import createVueComponent from '../createVueComponent';
export default createVueComponent('user-circle', 'IconUserCircle', [
  ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
  ['path', { d: 'M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855',
      key: 'svg-2',
    },
  ],
]);
