import createVueComponent from '../createVueComponent';
export default createVueComponent('code-dots', 'IconCodeDots', [
  ['path', { d: 'M15 12h.01', key: 'svg-0' }],
  ['path', { d: 'M12 12h.01', key: 'svg-1' }],
  ['path', { d: 'M9 12h.01', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M6 19a2 2 0 0 1 -2 -2v-4l-1 -1l1 -1v-4a2 2 0 0 1 2 -2',
      key: 'svg-3',
    },
  ],
  [
    'path',
    {
      d: 'M18 19a2 2 0 0 0 2 -2v-4l1 -1l-1 -1v-4a2 2 0 0 0 -2 -2',
      key: 'svg-4',
    },
  ],
]);
