import createVueComponent from '../createVueComponent';
export default createVueComponent('brightness-half', 'IconBrightnessHalf', [
  ['path', { d: 'M12 9a3 3 0 0 0 0 6v-6z', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M6 6h3.5l2.5 -2.5l2.5 2.5h3.5v3.5l2.5 2.5l-2.5 2.5v3.5h-3.5l-2.5 2.5l-2.5 -2.5h-3.5v-3.5l-2.5 -2.5l2.5 -2.5z',
      key: 'svg-1',
    },
  ],
]);
