import createVueComponent from '../createVueComponent';
export default createVueComponent('bell-share', 'IconBellShare', [
  [
    'path',
    {
      d: 'M12.5 17h-8.5a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6a2 2 0 1 1 4 0a7 7 0 0 1 4 6v2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 17v1a3 3 0 0 0 3 3', key: 'svg-1' }],
  ['path', { d: 'M16 22l5 -5', key: 'svg-2' }],
  ['path', { d: 'M21 21.5v-4.5h-4.5', key: 'svg-3' }],
]);
