import createVueComponent from '../createVueComponent';
export default createVueComponent('parking-off', 'IconParkingOff', [
  [
    'path',
    {
      d: 'M8 4h10a2 2 0 0 1 2 2v10m-.582 3.41c-.362 .365 -.864 .59 -1.418 .59h-12a2 2 0 0 1 -2 -2v-12c0 -.554 .225 -1.056 .59 -1.418',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M9 16v-7m3 -1h1a2 2 0 0 1 1.817 2.836m-2.817 1.164h-3',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
