import createVueComponent from '../createVueComponent';
export default createVueComponent('arrow-big-right', 'IconArrowBigRight', [
  [
    'path',
    {
      d: 'M4 9h8v-3.586a1 1 0 0 1 1.707 -.707l6.586 6.586a1 1 0 0 1 0 1.414l-6.586 6.586a1 1 0 0 1 -1.707 -.707v-3.586h-8a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1z',
      key: 'svg-0',
    },
  ],
]);
