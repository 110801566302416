import createVueComponent from '../createVueComponent';
export default createVueComponent('pizza', 'IconPizza', [
  [
    'path',
    {
      d: 'M12 21.5c-3.04 0 -5.952 -.714 -8.5 -1.983l8.5 -16.517l8.5 16.517a19.09 19.09 0 0 1 -8.5 1.983z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M5.38 15.866a14.94 14.94 0 0 0 6.815 1.634a14.944 14.944 0 0 0 6.502 -1.479',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M13 11.01v-.01', key: 'svg-2' }],
  ['path', { d: 'M11 14v-.01', key: 'svg-3' }],
]);
