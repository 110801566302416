import createVueComponent from '../createVueComponent';
export default createVueComponent('folder-symlink', 'IconFolderSymlink', [
  ['path', { d: 'M3 21v-4a3 3 0 0 1 3 -3h5', key: 'svg-0' }],
  ['path', { d: 'M8 17l3 -3l-3 -3', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M3 11v-5a2 2 0 0 1 2 -2h4l3 3h7a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8',
      key: 'svg-2',
    },
  ],
]);
