import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'device-imac-search',
  'IconDeviceImacSearch',
  [
    [
      'path',
      {
        d: 'M11 17h-7a1 1 0 0 1 -1 -1v-12a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v8',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M3 13h10', key: 'svg-1' }],
    ['path', { d: 'M8 21h4', key: 'svg-2' }],
    ['path', { d: 'M10 17l-.5 4', key: 'svg-3' }],
    ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-4' }],
    ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-5' }],
  ],
);
