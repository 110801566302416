import createVueComponent from '../createVueComponent';
export default createVueComponent('radio', 'IconRadio', [
  [
    'path',
    {
      d: 'M14 3l-9.371 3.749a1 1 0 0 0 -.629 .928v11.323a1 1 0 0 0 1 1h14a1 1 0 0 0 1 -1v-11a1 1 0 0 0 -1 -1h-14.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 12h16', key: 'svg-1' }],
  ['path', { d: 'M7 12v-2', key: 'svg-2' }],
  ['path', { d: 'M17 16v.01', key: 'svg-3' }],
  ['path', { d: 'M13 16v.01', key: 'svg-4' }],
]);
