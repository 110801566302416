import createVueComponent from '../createVueComponent';
export default createVueComponent('ball-baseball', 'IconBallBaseball', [
  [
    'path',
    {
      d: 'M5.636 18.364a9 9 0 1 0 12.728 -12.728a9 9 0 0 0 -12.728 12.728z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12.495 3.02a9 9 0 0 1 -9.475 9.475', key: 'svg-1' }],
  ['path', { d: 'M20.98 11.505a9 9 0 0 0 -9.475 9.475', key: 'svg-2' }],
  ['path', { d: 'M9 9l2 2', key: 'svg-3' }],
  ['path', { d: 'M13 13l2 2', key: 'svg-4' }],
  ['path', { d: 'M11 7l2 1', key: 'svg-5' }],
  ['path', { d: 'M7 11l1 2', key: 'svg-6' }],
  ['path', { d: 'M16 11l1 2', key: 'svg-7' }],
  ['path', { d: 'M11 16l2 1', key: 'svg-8' }],
]);
