import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'mood-neutral-filled',
  'IconMoodNeutralFilled',
  [
    [
      'path',
      {
        d: 'M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-7.99 5.66l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007zm6 0l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-0',
      },
    ],
  ],
);
