import createVueComponent from '../createVueComponent';
export default createVueComponent('http-post', 'IconHttpPost', [
  ['path', { d: 'M3 12h2a2 2 0 1 0 0 -4h-2v8', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M12 8a2 2 0 0 1 2 2v4a2 2 0 1 1 -4 0v-4a2 2 0 0 1 2 -2z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M17 15a1 1 0 0 0 1 1h2a1 1 0 0 0 1 -1v-2a1 1 0 0 0 -1 -1h-2a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1',
      key: 'svg-2',
    },
  ],
]);
