import createVueComponent from '../createVueComponent';
export default createVueComponent('salt', 'IconSalt', [
  ['path', { d: 'M12 13v.01', key: 'svg-0' }],
  ['path', { d: 'M10 16v.01', key: 'svg-1' }],
  ['path', { d: 'M14 16v.01', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M7.5 8h9l-.281 -2.248a2 2 0 0 0 -1.985 -1.752h-4.468a2 2 0 0 0 -1.986 1.752l-.28 2.248z',
      key: 'svg-3',
    },
  ],
  [
    'path',
    {
      d: 'M7.5 8l-1.612 9.671a2 2 0 0 0 1.973 2.329h8.278a2 2 0 0 0 1.973 -2.329l-1.612 -9.671',
      key: 'svg-4',
    },
  ],
]);
