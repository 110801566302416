import createVueComponent from '../createVueComponent';
export default createVueComponent('discount-2-off', 'IconDiscount2Off', [
  ['path', { d: 'M9 15l3 -3m2 -2l1 -1', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M9.148 9.145a.498 .498 0 0 0 .352 .855a.5 .5 0 0 0 .35 -.142',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M14.148 14.145a.498 .498 0 0 0 .352 .855a.5 .5 0 0 0 .35 -.142',
      key: 'svg-2',
    },
  ],
  [
    'path',
    {
      d: 'M8.887 4.89a2.2 2.2 0 0 0 .863 -.53l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7c.412 .41 .97 .64 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1c0 .58 .23 1.138 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.528 .858m-.757 3.248a2.193 2.193 0 0 1 -1.555 .644h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1c0 -.604 .244 -1.152 .638 -1.55',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
