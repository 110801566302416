import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'circle-arrow-up-left',
  'IconCircleArrowUpLeft',
  [
    ['path', { d: 'M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0', key: 'svg-0' }],
    ['path', { d: 'M9 9l6 6', key: 'svg-1' }],
    ['path', { d: 'M15 9h-6v6', key: 'svg-2' }],
  ],
);
