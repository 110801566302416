import createVueComponent from '../createVueComponent';
export default createVueComponent('plug-off', 'IconPlugOff', [
  [
    'path',
    {
      d: 'M16.123 16.092l-.177 .177a5.81 5.81 0 1 1 -8.215 -8.215l.159 -.159',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 20l3.5 -3.5', key: 'svg-1' }],
  ['path', { d: 'M15 4l-3.5 3.5', key: 'svg-2' }],
  ['path', { d: 'M20 9l-3.5 3.5', key: 'svg-3' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
