import createVueComponent from '../createVueComponent';
export default createVueComponent('file-3d', 'IconFile3d', [
  ['path', { d: 'M14 3v4a1 1 0 0 0 1 1h4', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 13.5l4 -1.5', key: 'svg-2' }],
  [
    'path',
    { d: 'M8 11.846l4 1.654v4.5l4 -1.846v-4.308l-4 -1.846z', key: 'svg-3' },
  ],
  ['path', { d: 'M8 12v4.2l4 1.8', key: 'svg-4' }],
]);
