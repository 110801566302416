import createVueComponent from '../createVueComponent';
export default createVueComponent('perspective', 'IconPerspective', [
  [
    'path',
    {
      d: 'M6.141 4.163l12 1.714a1 1 0 0 1 .859 .99v10.266a1 1 0 0 1 -.859 .99l-12 1.714a1 1 0 0 1 -1.141 -.99v-13.694a1 1 0 0 1 1.141 -.99z',
      key: 'svg-0',
    },
  ],
]);
