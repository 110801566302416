import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'picture-in-picture',
  'IconPictureInPicture',
  [
    [
      'path',
      {
        d: 'M11 19h-6a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v4',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M14 14m0 1a1 1 0 0 1 1 -1h5a1 1 0 0 1 1 1v3a1 1 0 0 1 -1 1h-5a1 1 0 0 1 -1 -1z',
        key: 'svg-1',
      },
    ],
  ],
);
