import createVueComponent from '../createVueComponent';
export default createVueComponent('layout-board', 'IconLayoutBoard', [
  [
    'path',
    {
      d: 'M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 9h8', key: 'svg-1' }],
  ['path', { d: 'M12 15h8', key: 'svg-2' }],
  ['path', { d: 'M12 4v16', key: 'svg-3' }],
]);
