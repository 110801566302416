import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'box-align-bottom-left',
  'IconBoxAlignBottomLeft',
  [
    [
      'path',
      {
        d: 'M5 13h5a1 1 0 0 1 1 1v5a1 1 0 0 1 -1 1h-5a1 1 0 0 1 -1 -1v-5a1 1 0 0 1 1 -1z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M4 9v.01', key: 'svg-1' }],
    ['path', { d: 'M4 4v.01', key: 'svg-2' }],
    ['path', { d: 'M9 4v.01', key: 'svg-3' }],
    ['path', { d: 'M15 4v.01', key: 'svg-4' }],
    ['path', { d: 'M15 20v.01', key: 'svg-5' }],
    ['path', { d: 'M20 4v.01', key: 'svg-6' }],
    ['path', { d: 'M20 9v.01', key: 'svg-7' }],
    ['path', { d: 'M20 15v.01', key: 'svg-8' }],
    ['path', { d: 'M20 20v.01', key: 'svg-9' }],
  ],
);
