import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'exclamation-circle',
  'IconExclamationCircle',
  [
    ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
    ['path', { d: 'M12 9v4', key: 'svg-1' }],
    ['path', { d: 'M12 16v.01', key: 'svg-2' }],
  ],
);
