import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'rectangle-vertical',
  'IconRectangleVertical',
  [
    [
      'path',
      {
        d: 'M5 3m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z',
        key: 'svg-0',
      },
    ],
  ],
);
