import createVueComponent from '../createVueComponent';
export default createVueComponent('device-watch-pin', 'IconDeviceWatchPin', [
  [
    'path',
    {
      d: 'M12 18h-3a3 3 0 0 1 -3 -3v-6a3 3 0 0 1 3 -3h6a3 3 0 0 1 3 3v2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 18v3h3.5', key: 'svg-1' }],
  ['path', { d: 'M9 6v-3h6v3', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M21.121 20.121a3 3 0 1 0 -4.242 0c.418 .419 1.125 1.045 2.121 1.879c1.051 -.89 1.759 -1.516 2.121 -1.879z',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M19 18v.01', key: 'svg-4' }],
]);
