import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'git-pull-request-closed',
  'IconGitPullRequestClosed',
  [
    ['path', { d: 'M6 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
    ['path', { d: 'M6 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
    ['path', { d: 'M18 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
    ['path', { d: 'M6 8v8', key: 'svg-3' }],
    ['path', { d: 'M18 11v5', key: 'svg-4' }],
    ['path', { d: 'M16 4l4 4m0 -4l-4 4', key: 'svg-5' }],
  ],
);
