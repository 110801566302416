import createVueComponent from '../createVueComponent';
export default createVueComponent('beer-off', 'IconBeerOff', [
  [
    'path',
    {
      d: 'M7 7v1.111c0 1.242 .29 2.467 .845 3.578l.31 .622a8 8 0 0 1 .845 3.578v4.111h6v-4.111a8 8 0 0 1 .045 -.85m.953 -3.035l.157 -.315a8 8 0 0 0 .845 -3.578v-4.111h-9',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7 8h1m4 0h5', key: 'svg-1' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
