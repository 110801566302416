import createVueComponent from '../createVueComponent';
export default createVueComponent('moneybag', 'IconMoneybag', [
  [
    'path',
    {
      d: 'M9.5 3h5a1.5 1.5 0 0 1 1.5 1.5a3.5 3.5 0 0 1 -3.5 3.5h-1a3.5 3.5 0 0 1 -3.5 -3.5a1.5 1.5 0 0 1 1.5 -1.5z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M4 17v-1a8 8 0 1 1 16 0v1a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z',
      key: 'svg-1',
    },
  ],
]);
