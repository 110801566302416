import createVueComponent from '../createVueComponent';
export default createVueComponent('poo', 'IconPoo', [
  ['path', { d: 'M10 12h.01', key: 'svg-0' }],
  ['path', { d: 'M14 12h.01', key: 'svg-1' }],
  ['path', { d: 'M10 16a3.5 3.5 0 0 0 4 0', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M11 4c2 0 3.5 1.5 3.5 4l.164 0a2.5 2.5 0 0 1 2.196 3.32a3 3 0 0 1 1.615 3.063a3 3 0 0 1 -1.299 5.607l-.176 0h-10a3 3 0 0 1 -1.474 -5.613a3 3 0 0 1 1.615 -3.062a2.5 2.5 0 0 1 2.195 -3.32l.164 0c1.5 0 2.5 -2 1.5 -4z',
      key: 'svg-3',
    },
  ],
]);
