import createVueComponent from '../createVueComponent';
export default createVueComponent('script-minus', 'IconScriptMinus', [
  ['path', { d: 'M17 19h4', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M14 20h-8a3 3 0 0 1 0 -6h11a3 3 0 0 0 -3 3m7 -2v-9a2 2 0 0 0 -2 -2h-10a2 2 0 0 0 -2 2v8',
      key: 'svg-1',
    },
  ],
]);
