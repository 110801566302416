import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-cinema-4d', 'IconBrandCinema4d', [
  ['path', { d: 'M9.65 6.956a5.39 5.39 0 0 0 7.494 7.495', key: 'svg-0' }],
  ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-1' }],
  ['path', { d: 'M17.7 12.137a5.738 5.738 0 1 1 -5.737 -5.737', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M17.7 12.338v-1.175c0 -.47 .171 -.92 .476 -1.253a1.56 1.56 0 0 1 1.149 -.52c.827 0 1.523 .676 1.62 1.573c.037 .344 .055 .69 .055 1.037',
      key: 'svg-3',
    },
  ],
  [
    'path',
    {
      d: 'M11.662 6.4h1.175c.47 0 .92 -.176 1.253 -.49c.333 -.314 .52 -.74 .52 -1.184c0 -.852 -.676 -1.57 -1.573 -1.67a9.496 9.496 0 0 0 -1.037 -.056',
      key: 'svg-4',
    },
  ],
]);
