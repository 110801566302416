import createVueComponent from '../createVueComponent';
export default createVueComponent('building-circus', 'IconBuildingCircus', [
  ['path', { d: 'M4 11h16', key: 'svg-0' }],
  ['path', { d: 'M12 6.5c0 1 -5 4.5 -8 4.5', key: 'svg-1' }],
  ['path', { d: 'M12 6.5c0 1 5 4.5 8 4.5', key: 'svg-2' }],
  [
    'path',
    { d: 'M6 11c-.333 5.333 -1 8.667 -2 10h4c1 0 4 -4 4 -9v-1', key: 'svg-3' },
  ],
  [
    'path',
    {
      d: 'M18 11c.333 5.333 1 8.667 2 10h-4c-1 0 -4 -4 -4 -9v-1',
      key: 'svg-4',
    },
  ],
  ['path', { d: 'M12 7v-4l2 1h-2', key: 'svg-5' }],
]);
