import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-radix-ui', 'IconBrandRadixUi', [
  [
    'path',
    {
      d: 'M16.5 5.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M6 3h5v5h-5z', key: 'svg-1' }],
  ['path', { d: 'M11 11v10a5 5 0 0 1 -.217 -9.995l.217 -.005z', key: 'svg-2' }],
]);
