import createVueComponent from '../createVueComponent';
export default createVueComponent('outlet', 'IconOutlet', [
  [
    'path',
    {
      d: 'M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  [
    'circle',
    { cx: '9', cy: '12', r: '.5', fill: 'currentColor', key: 'svg-1' },
  ],
  [
    'circle',
    { cx: '15', cy: '12', r: '.5', fill: 'currentColor', key: 'svg-2' },
  ],
]);
