import createVueComponent from '../createVueComponent';
export default createVueComponent('logic-xnor', 'IconLogicXnor', [
  ['path', { d: 'M22 12h-2', key: 'svg-0' }],
  ['path', { d: 'M2 9h4', key: 'svg-1' }],
  ['path', { d: 'M2 15h4', key: 'svg-2' }],
  ['path', { d: 'M5 19c1.778 -4.667 1.778 -9.333 0 -14', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M8 5c10.667 2.1 10.667 12.6 0 14c1.806 -4.667 1.806 -9.333 0 -14z',
      key: 'svg-4',
    },
  ],
  ['path', { d: 'M18 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-5' }],
]);
