import createVueComponent from '../createVueComponent';
export default createVueComponent('firetruck', 'IconFiretruck', [
  ['path', { d: 'M5 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M17 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  [
    'path',
    { d: 'M7 18h8m4 0h2v-6a5 5 0 0 0 -5 -5h-1l1.5 5h4.5', key: 'svg-2' },
  ],
  ['path', { d: 'M12 18v-11h3', key: 'svg-3' }],
  ['path', { d: 'M3 17l0 -5l9 0', key: 'svg-4' }],
  ['path', { d: 'M3 9l18 -6', key: 'svg-5' }],
  ['path', { d: 'M6 12l0 -4', key: 'svg-6' }],
]);
