import createVueComponent from '../createVueComponent';
export default createVueComponent('sock', 'IconSock', [
  [
    'path',
    {
      d: 'M13 3v6l4.798 5.142a4 4 0 0 1 -5.441 5.86l-6.736 -6.41a2 2 0 0 1 -.621 -1.451v-9.141h8z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M7.895 15.768c.708 -.721 1.105 -1.677 1.105 -2.768a4 4 0 0 0 -4 -4',
      key: 'svg-1',
    },
  ],
]);
