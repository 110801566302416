import createVueComponent from '../createVueComponent';
export default createVueComponent('phone-outgoing', 'IconPhoneOutgoing', [
  [
    'path',
    {
      d: 'M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M15 9l5 -5', key: 'svg-1' }],
  ['path', { d: 'M16 4l4 0l0 4', key: 'svg-2' }],
]);
