import createVueComponent from '../createVueComponent';
export default createVueComponent('folders-off', 'IconFoldersOff', [
  [
    'path',
    {
      d: 'M17 17h-8a2 2 0 0 1 -2 -2v-8m1.177 -2.823c.251 -.114 .53 -.177 .823 -.177h3l2 2h5a2 2 0 0 1 2 2v7c0 .55 -.223 1.05 -.583 1.411',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M17 17v2a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h2',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
