import createVueComponent from '../createVueComponent';
export default createVueComponent('devices-x', 'IconDevicesX', [
  [
    'path',
    {
      d: 'M14 20a1 1 0 0 1 -1 -1v-10a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v4',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M18 8v-3a1 1 0 0 0 -1 -1h-13a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h9',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M16 9h2', key: 'svg-2' }],
  ['path', { d: 'M22 22l-5 -5', key: 'svg-3' }],
  ['path', { d: 'M17 22l5 -5', key: 'svg-4' }],
]);
