import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'square-rotated-forbid',
  'IconSquareRotatedForbid',
  [
    [
      'path',
      {
        d: 'M13.446 2.6l7.955 7.954a2.045 2.045 0 0 1 0 2.892l-7.955 7.955a2.045 2.045 0 0 1 -2.892 0l-7.955 -7.955a2.045 2.045 0 0 1 0 -2.892l7.955 -7.955a2.045 2.045 0 0 1 2.892 0z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9.5 14.5l5 -5', key: 'svg-1' }],
  ],
);
