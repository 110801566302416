import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-medium', 'IconBrandMedium', [
  [
    'path',
    {
      d: 'M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8 9h1l3 3l3 -3h1', key: 'svg-1' }],
  ['path', { d: 'M8 15l2 0', key: 'svg-2' }],
  ['path', { d: 'M14 15l2 0', key: 'svg-3' }],
  ['path', { d: 'M9 9l0 6', key: 'svg-4' }],
  ['path', { d: 'M15 9l0 6', key: 'svg-5' }],
]);
