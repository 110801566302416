import createVueComponent from '../createVueComponent';
export default createVueComponent('mail-share', 'IconMailShare', [
  [
    'path',
    {
      d: 'M13 19h-8a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v6',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 7l9 6l9 -6', key: 'svg-1' }],
  ['path', { d: 'M16 22l5 -5', key: 'svg-2' }],
  ['path', { d: 'M21 21.5v-4.5h-4.5', key: 'svg-3' }],
]);
