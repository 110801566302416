import createVueComponent from '../createVueComponent';
export default createVueComponent('barrel-off', 'IconBarrelOff', [
  [
    'path',
    {
      d: 'M8 4h8.722a2 2 0 0 1 1.841 1.22c.958 2.26 1.437 4.52 1.437 6.78a16.35 16.35 0 0 1 -.407 3.609m-.964 3.013l-.066 .158a2 2 0 0 1 -1.841 1.22h-9.444a2 2 0 0 1 -1.841 -1.22c-.958 -2.26 -1.437 -4.52 -1.437 -6.78c0 -2.21 .458 -4.42 1.374 -6.63',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M14 4c.585 2.337 .913 4.674 .985 7.01m-.114 3.86a33.415 33.415 0 0 1 -.871 5.13',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M10 4a34.42 34.42 0 0 0 -.366 1.632m-.506 3.501a32.126 32.126 0 0 0 -.128 2.867c0 2.667 .333 5.333 1 8',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M4.5 16h11.5', key: 'svg-3' }],
  ['path', { d: 'M19.5 8h-7.5m-4 0h-3.5', key: 'svg-4' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
