import createVueComponent from '../createVueComponent';
export default createVueComponent('device-ipad-pause', 'IconDeviceIpadPause', [
  [
    'path',
    {
      d: 'M13 21h-7a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v8',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 18h4', key: 'svg-1' }],
  ['path', { d: 'M17 17v5', key: 'svg-2' }],
  ['path', { d: 'M21 17v5', key: 'svg-3' }],
]);
