import createVueComponent from '../createVueComponent';
export default createVueComponent('bandage', 'IconBandage', [
  ['path', { d: 'M14 12l0 .01', key: 'svg-0' }],
  ['path', { d: 'M10 12l0 .01', key: 'svg-1' }],
  ['path', { d: 'M12 10l0 .01', key: 'svg-2' }],
  ['path', { d: 'M12 14l0 .01', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M4.5 12.5l8 -8a4.94 4.94 0 0 1 7 7l-8 8a4.94 4.94 0 0 1 -7 -7',
      key: 'svg-4',
    },
  ],
]);
