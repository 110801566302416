import createVueComponent from '../createVueComponent';
export default createVueComponent('video-off', 'IconVideoOff', [
  ['path', { d: 'M3 3l18 18', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M15 11v-1l4.553 -2.276a1 1 0 0 1 1.447 .894v6.764a1 1 0 0 1 -.675 .946',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M10 6h3a2 2 0 0 1 2 2v3m0 4v1a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h1',
      key: 'svg-2',
    },
  ],
]);
