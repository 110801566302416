import createVueComponent from '../createVueComponent';
export default createVueComponent('square-f9', 'IconSquareF9', [
  [
    'path',
    {
      d: 'M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M13 14.25c0 .414 .336 .75 .75 .75h1.5a.75 .75 0 0 0 .75 -.75v-4.5a.75 .75 0 0 0 -.75 -.75h-1.5a.75 .75 0 0 0 -.75 .75v1.5c0 .414 .336 .75 .75 .75h2.25',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M8 12h2', key: 'svg-2' }],
  ['path', { d: 'M10 9h-2v6', key: 'svg-3' }],
]);
