import createVueComponent from '../createVueComponent';
export default createVueComponent('phone-off', 'IconPhoneOff', [
  ['path', { d: 'M3 21l18 -18', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M5.831 14.161a15.946 15.946 0 0 1 -2.831 -8.161a2 2 0 0 1 2 -2h4l2 5l-2.5 1.5c.108 .22 .223 .435 .345 .645m1.751 2.277c.843 .84 1.822 1.544 2.904 2.078l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a15.963 15.963 0 0 1 -10.344 -4.657',
      key: 'svg-1',
    },
  ],
]);
