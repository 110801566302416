import createVueComponent from '../createVueComponent';
export default createVueComponent('chef-hat-off', 'IconChefHatOff', [
  [
    'path',
    {
      d: 'M8.72 4.712a4 4 0 0 1 7.19 1.439a4 4 0 0 1 2.09 7.723v.126m0 4v3h-12v-7.126a4 4 0 0 1 .081 -7.796',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M6.161 17.009l10.839 -.009', key: 'svg-1' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
