import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-linqpad', 'IconBrandLinqpad', [
  [
    'path',
    {
      d: 'M5 21h3.5l2.5 -6l2.5 -1l2.5 7h4l1 -4.5l-2 -1l-7 -12l-6 -.5l1.5 4l2.5 .5l1 2.5l-7 8z',
      key: 'svg-0',
    },
  ],
]);
