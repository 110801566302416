import createVueComponent from '../createVueComponent';
export default createVueComponent('loader', 'IconLoader', [
  ['path', { d: 'M12 6l0 -3', key: 'svg-0' }],
  ['path', { d: 'M16.25 7.75l2.15 -2.15', key: 'svg-1' }],
  ['path', { d: 'M18 12l3 0', key: 'svg-2' }],
  ['path', { d: 'M16.25 16.25l2.15 2.15', key: 'svg-3' }],
  ['path', { d: 'M12 18l0 3', key: 'svg-4' }],
  ['path', { d: 'M7.75 16.25l-2.15 2.15', key: 'svg-5' }],
  ['path', { d: 'M6 12l-3 0', key: 'svg-6' }],
  ['path', { d: 'M7.75 7.75l-2.15 -2.15', key: 'svg-7' }],
]);
