import createVueComponent from '../createVueComponent';
export default createVueComponent('xbox-x', 'IconXboxX', [
  [
    'path',
    {
      d: 'M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 8l6 8', key: 'svg-1' }],
  ['path', { d: 'M15 8l-6 8', key: 'svg-2' }],
]);
