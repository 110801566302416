import createVueComponent from '../createVueComponent';
export default createVueComponent('explicit', 'IconExplicit', [
  [
    'path',
    {
      d: 'M4 4m0 1a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M14 8h-4v8h4', key: 'svg-1' }],
  ['path', { d: 'M14 12h-4', key: 'svg-2' }],
]);
