import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'arrow-big-right-line',
  'IconArrowBigRightLine',
  [
    [
      'path',
      {
        d: 'M12 9v-3.586a1 1 0 0 1 1.707 -.707l6.586 6.586a1 1 0 0 1 0 1.414l-6.586 6.586a1 1 0 0 1 -1.707 -.707v-3.586h-6v-6h6z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M3 9v6', key: 'svg-1' }],
  ],
);
