import createVueComponent from '../createVueComponent';
export default createVueComponent('flask-2-off', 'IconFlask2Off', [
  ['path', { d: 'M6.1 15h8.9', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M17.742 17.741a6 6 0 0 1 -2.424 3.259h-6.635a6 6 0 0 1 1.317 -10.66v-.326m0 -4.014v-3h4v7m.613 .598a6 6 0 0 1 2.801 2.817',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M9 3h6', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
