import createVueComponent from '../createVueComponent';
export default createVueComponent('folder-bolt', 'IconFolderBolt', [
  [
    'path',
    {
      d: 'M13 19h-8a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2h4l3 3h7a2 2 0 0 1 2 2v3.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 16l-2 3h4l-2 3', key: 'svg-1' }],
]);
