import createVueComponent from '../createVueComponent';
export default createVueComponent('device-tv-off', 'IconDeviceTvOff', [
  [
    'path',
    {
      d: 'M11 7h8a2 2 0 0 1 2 2v8m-1.178 2.824c-.25 .113 -.529 .176 -.822 .176h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 3l-4 4l-4 -4', key: 'svg-1' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
