import createVueComponent from '../createVueComponent';
export default createVueComponent('sword-off', 'IconSwordOff', [
  [
    'path',
    {
      d: 'M11.938 7.937l3.062 -3.937h5v5l-3.928 3.055m-2.259 1.757l-2.813 2.188l-4 4l-3 -3l4 -4l2.19 -2.815',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M6.5 11.5l6 6', key: 'svg-1' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
