import createVueComponent from '../createVueComponent';
export default createVueComponent('arrows-maximize', 'IconArrowsMaximize', [
  ['path', { d: 'M16 4l4 0l0 4', key: 'svg-0' }],
  ['path', { d: 'M14 10l6 -6', key: 'svg-1' }],
  ['path', { d: 'M8 20l-4 0l0 -4', key: 'svg-2' }],
  ['path', { d: 'M4 20l6 -6', key: 'svg-3' }],
  ['path', { d: 'M16 20l4 0l0 -4', key: 'svg-4' }],
  ['path', { d: 'M14 14l6 6', key: 'svg-5' }],
  ['path', { d: 'M8 4l-4 0l0 4', key: 'svg-6' }],
  ['path', { d: 'M4 4l6 6', key: 'svg-7' }],
]);
