import createVueComponent from '../createVueComponent';
export default createVueComponent('rewind-forward-10', 'IconRewindForward10', [
  ['path', { d: 'M17 9l3 -3l-3 -3', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M8 17.918a5.997 5.997 0 0 1 -5 -5.918a6 6 0 0 1 6 -6h11',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 14v6', key: 'svg-2' }],
  [
    'path',
    { d: 'M15 15.5v3a1.5 1.5 0 0 0 3 0v-3a1.5 1.5 0 0 0 -3 0z', key: 'svg-3' },
  ],
]);
