import createVueComponent from '../createVueComponent';
export default createVueComponent('zoom-money', 'IconZoomMoney', [
  ['path', { d: 'M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0', key: 'svg-0' }],
  ['path', { d: 'M21 21l-6 -6', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M12 7h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M10 13v1m0 -8v1', key: 'svg-3' }],
]);
