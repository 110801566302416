import createVueComponent from '../createVueComponent';
export default createVueComponent('box-model', 'IconBoxModel', [
  ['path', { d: 'M8 8h8v8h-8z', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M16 16l3.3 3.3', key: 'svg-2' }],
  ['path', { d: 'M16 8l3.3 -3.3', key: 'svg-3' }],
  ['path', { d: 'M8 8l-3.3 -3.3', key: 'svg-4' }],
  ['path', { d: 'M8 16l-3.3 3.3', key: 'svg-5' }],
]);
