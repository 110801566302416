import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-google-play', 'IconBrandGooglePlay', [
  [
    'path',
    {
      d: 'M4 3.71v16.58a.7 .7 0 0 0 1.05 .606l14.622 -8.42a.55 .55 0 0 0 0 -.953l-14.622 -8.419a.7 .7 0 0 0 -1.05 .607z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M15 9l-10.5 11.5', key: 'svg-1' }],
  ['path', { d: 'M4.5 3.5l10.5 11.5', key: 'svg-2' }],
]);
