import createVueComponent from '../createVueComponent';
export default createVueComponent('dice-2', 'IconDice2', [
  [
    'path',
    {
      d: 'M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  [
    'circle',
    { cx: '9.5', cy: '9.5', r: '.5', fill: 'currentColor', key: 'svg-1' },
  ],
  [
    'circle',
    { cx: '14.5', cy: '14.5', r: '.5', fill: 'currentColor', key: 'svg-2' },
  ],
]);
