import createVueComponent from '../createVueComponent';
export default createVueComponent('download-off', 'IconDownloadOff', [
  [
    'path',
    { d: 'M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 1.83 -1.19', key: 'svg-0' },
  ],
  ['path', { d: 'M7 11l5 5l2 -2m2 -2l1 -1', key: 'svg-1' }],
  ['path', { d: 'M12 4v4m0 4v4', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
