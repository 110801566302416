import createVueComponent from '../createVueComponent';
export default createVueComponent('question-mark', 'IconQuestionMark', [
  [
    'path',
    {
      d: 'M8 8a3.5 3 0 0 1 3.5 -3h1a3.5 3 0 0 1 3.5 3a3 3 0 0 1 -2 3a3 4 0 0 0 -2 4',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 19l0 .01', key: 'svg-1' }],
]);
