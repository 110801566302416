import createVueComponent from '../createVueComponent';
export default createVueComponent('shape', 'IconShape', [
  ['path', { d: 'M5 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M19 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M5 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
  ['path', { d: 'M19 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-3' }],
  ['path', { d: 'M5 7l0 10', key: 'svg-4' }],
  ['path', { d: 'M7 5l10 0', key: 'svg-5' }],
  ['path', { d: 'M7 19l10 0', key: 'svg-6' }],
  ['path', { d: 'M19 7l0 10', key: 'svg-7' }],
]);
