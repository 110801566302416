import createVueComponent from '../createVueComponent';
export default createVueComponent('sunset-2', 'IconSunset2', [
  ['path', { d: 'M3 13h1', key: 'svg-0' }],
  ['path', { d: 'M20 13h1', key: 'svg-1' }],
  ['path', { d: 'M5.6 6.6l.7 .7', key: 'svg-2' }],
  ['path', { d: 'M18.4 6.6l-.7 .7', key: 'svg-3' }],
  ['path', { d: 'M8 13a4 4 0 1 1 8 0', key: 'svg-4' }],
  ['path', { d: 'M3 17h18', key: 'svg-5' }],
  ['path', { d: 'M7 20h5', key: 'svg-6' }],
  ['path', { d: 'M16 20h1', key: 'svg-7' }],
  ['path', { d: 'M12 5v-1', key: 'svg-8' }],
]);
