import createVueComponent from '../createVueComponent';
export default createVueComponent('building-estate', 'IconBuildingEstate', [
  ['path', { d: 'M3 21h18', key: 'svg-0' }],
  ['path', { d: 'M19 21v-4', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M19 17a2 2 0 0 0 2 -2v-2a2 2 0 1 0 -4 0v2a2 2 0 0 0 2 2z',
      key: 'svg-2',
    },
  ],
  [
    'path',
    { d: 'M14 21v-14a3 3 0 0 0 -3 -3h-4a3 3 0 0 0 -3 3v14', key: 'svg-3' },
  ],
  ['path', { d: 'M9 17v4', key: 'svg-4' }],
  ['path', { d: 'M8 13h2', key: 'svg-5' }],
  ['path', { d: 'M8 9h2', key: 'svg-6' }],
]);
