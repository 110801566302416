import createVueComponent from '../createVueComponent';
export default createVueComponent('certificate-2', 'IconCertificate2', [
  ['path', { d: 'M12 15m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-0' }],
  ['path', { d: 'M10 7h4', key: 'svg-1' }],
  ['path', { d: 'M10 18v4l2 -1l2 1v-4', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M10 19h-2a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-2',
      key: 'svg-3',
    },
  ],
]);
