import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'devices-exclamation',
  'IconDevicesExclamation',
  [
    [
      'path',
      {
        d: 'M15 20h-1a1 1 0 0 1 -1 -1v-10a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v3.5',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M18 8v-3a1 1 0 0 0 -1 -1h-13a1 1 0 0 0 -1 1v12a1 1 0 0 0 1 1h9',
        key: 'svg-1',
      },
    ],
    ['path', { d: 'M16 9h2', key: 'svg-2' }],
    ['path', { d: 'M19 16v3', key: 'svg-3' }],
    ['path', { d: 'M19 22v.01', key: 'svg-4' }],
  ],
);
