import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'arrows-move-vertical',
  'IconArrowsMoveVertical',
  [
    ['path', { d: 'M9 18l3 3l3 -3', key: 'svg-0' }],
    ['path', { d: 'M12 15v6', key: 'svg-1' }],
    ['path', { d: 'M15 6l-3 -3l-3 3', key: 'svg-2' }],
    ['path', { d: 'M12 3v6', key: 'svg-3' }],
  ],
);
