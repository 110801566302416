import createVueComponent from '../createVueComponent';
export default createVueComponent('award', 'IconAward', [
  ['path', { d: 'M12 9m-6 0a6 6 0 1 0 12 0a6 6 0 1 0 -12 0', key: 'svg-0' }],
  [
    'path',
    { d: 'M12 15l3.4 5.89l1.598 -3.233l3.598 .232l-3.4 -5.889', key: 'svg-1' },
  ],
  [
    'path',
    {
      d: 'M6.802 12l-3.4 5.89l3.598 -.233l1.598 3.232l3.4 -5.889',
      key: 'svg-2',
    },
  ],
]);
