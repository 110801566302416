import createVueComponent from '../createVueComponent';
export default createVueComponent('home-share', 'IconHomeShare', [
  [
    'path',
    { d: 'M9 21v-6a2 2 0 0 1 2 -2h2c.247 0 .484 .045 .702 .127', key: 'svg-0' },
  ],
  ['path', { d: 'M19 12h2l-9 -9l-9 9h2v7a2 2 0 0 0 2 2h5', key: 'svg-1' }],
  ['path', { d: 'M16 22l5 -5', key: 'svg-2' }],
  ['path', { d: 'M21 21.5v-4.5h-4.5', key: 'svg-3' }],
]);
