import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'device-computer-camera',
  'IconDeviceComputerCamera',
  [
    ['path', { d: 'M12 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0', key: 'svg-0' }],
    ['path', { d: 'M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M8 16l-2.091 3.486a1 1 0 0 0 .857 1.514h10.468a1 1 0 0 0 .857 -1.514l-2.091 -3.486',
        key: 'svg-2',
      },
    ],
  ],
);
