import createVueComponent from '../createVueComponent';
export default createVueComponent('fingerprint-off', 'IconFingerprintOff', [
  ['path', { d: 'M18.9 7a8 8 0 0 1 1.1 5v1a6 6 0 0 0 .8 3', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M8 11c0 -.848 .264 -1.634 .713 -2.28m2.4 -1.621a4 4 0 0 1 4.887 3.901l0 1',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 12v1a14 14 0 0 0 2.5 8', key: 'svg-2' }],
  ['path', { d: 'M8 15a18 18 0 0 0 1.8 6', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M4.9 19a22 22 0 0 1 -.9 -7v-1a8 8 0 0 1 1.854 -5.143m2.176 -1.825a8 8 0 0 1 7.97 .018',
      key: 'svg-4',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
