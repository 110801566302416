import createVueComponent from '../createVueComponent';
export default createVueComponent('uv-index', 'IconUvIndex', [
  [
    'path',
    {
      d: 'M3 12h1m16 0h1m-15.4 -6.4l.7 .7m12.1 -.7l-.7 .7m-9.7 5.7a4 4 0 1 1 8 0',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 4v-1', key: 'svg-1' }],
  ['path', { d: 'M13 16l2 5h1l2 -5', key: 'svg-2' }],
  ['path', { d: 'M6 16v3a2 2 0 1 0 4 0v-3', key: 'svg-3' }],
]);
