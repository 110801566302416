import createVueComponent from '../createVueComponent';
export default createVueComponent('chart-candle', 'IconChartCandle', [
  [
    'path',
    {
      d: 'M4 6m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v3a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M6 4l0 2', key: 'svg-1' }],
  ['path', { d: 'M6 11l0 9', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M10 14m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v3a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M12 4l0 10', key: 'svg-4' }],
  ['path', { d: 'M12 19l0 1', key: 'svg-5' }],
  [
    'path',
    {
      d: 'M16 5m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
      key: 'svg-6',
    },
  ],
  ['path', { d: 'M18 4l0 1', key: 'svg-7' }],
  ['path', { d: 'M18 11l0 9', key: 'svg-8' }],
]);
