import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-abstract', 'IconBrandAbstract', [
  [
    'path',
    {
      d: 'M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M10.5 13.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M8 8h8v8', key: 'svg-2' }],
]);
