import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'device-ipad-horizontal-code',
  'IconDeviceIpadHorizontalCode',
  [
    [
      'path',
      {
        d: 'M11 20h-6a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9 17h2.5', key: 'svg-1' }],
    ['path', { d: 'M20 21l2 -2l-2 -2', key: 'svg-2' }],
    ['path', { d: 'M17 17l-2 2l2 2', key: 'svg-3' }],
  ],
);
