import createVueComponent from '../createVueComponent';
export default createVueComponent('pointer-cancel', 'IconPointerCancel', [
  [
    'path',
    {
      d: 'M15.526 12.97l-.748 -.748l3.113 -2.09a1.2 1.2 0 0 0 -.309 -2.228l-13.582 -3.904l3.904 13.563a1.2 1.2 0 0 0 2.228 .308l2.09 -3.093l.714 .714',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 19m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
  ['path', { d: 'M17 21l4 -4', key: 'svg-2' }],
]);
