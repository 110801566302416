import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'square-rounded-letter-k',
  'IconSquareRoundedLetterK',
  [
    ['path', { d: 'M10 8v8', key: 'svg-0' }],
    ['path', { d: 'M14 8l-2.5 4l2.5 4', key: 'svg-1' }],
    ['path', { d: 'M10 12h1.5', key: 'svg-2' }],
    [
      'path',
      {
        d: 'M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z',
        key: 'svg-3',
      },
    ],
  ],
);
