import createVueComponent from '../createVueComponent';
export default createVueComponent('barcode-off', 'IconBarcodeOff', [
  ['path', { d: 'M4 7v-1c0 -.552 .224 -1.052 .586 -1.414', key: 'svg-0' }],
  ['path', { d: 'M4 17v1a2 2 0 0 0 2 2h2', key: 'svg-1' }],
  ['path', { d: 'M16 4h2a2 2 0 0 1 2 2v1', key: 'svg-2' }],
  ['path', { d: 'M16 20h2c.551 0 1.05 -.223 1.412 -.584', key: 'svg-3' }],
  ['path', { d: 'M5 11h1v2h-1z', key: 'svg-4' }],
  ['path', { d: 'M10 11v2', key: 'svg-5' }],
  ['path', { d: 'M15 11v.01', key: 'svg-6' }],
  ['path', { d: 'M19 11v2', key: 'svg-7' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-8' }],
]);
