import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'zodiac-sagittarius',
  'IconZodiacSagittarius',
  [
    ['path', { d: 'M4 20l16 -16', key: 'svg-0' }],
    ['path', { d: 'M13 4h7v7', key: 'svg-1' }],
    ['path', { d: 'M6.5 12.5l5 5', key: 'svg-2' }],
  ],
);
