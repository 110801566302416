import createVueComponent from '../createVueComponent';
export default createVueComponent('mood-silence', 'IconMoodSilence', [
  ['path', { d: 'M12 21a9 9 0 1 1 0 -18a9 9 0 0 1 0 18z', key: 'svg-0' }],
  ['path', { d: 'M9 10h-.01', key: 'svg-1' }],
  ['path', { d: 'M15 10h-.01', key: 'svg-2' }],
  ['path', { d: 'M8 15h8', key: 'svg-3' }],
  ['path', { d: 'M9 14v2', key: 'svg-4' }],
  ['path', { d: 'M12 14v2', key: 'svg-5' }],
  ['path', { d: 'M15 14v2', key: 'svg-6' }],
]);
