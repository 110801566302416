import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'relation-one-to-many',
  'IconRelationOneToMany',
  [
    [
      'path',
      {
        d: 'M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M7 10h1v4', key: 'svg-1' }],
    ['path', { d: 'M14 14v-4l3 4v-4', key: 'svg-2' }],
    ['path', { d: 'M11 10.5l0 .01', key: 'svg-3' }],
    ['path', { d: 'M11 13.5l0 .01', key: 'svg-4' }],
  ],
);
