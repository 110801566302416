import createVueComponent from '../createVueComponent';
export default createVueComponent('math-function', 'IconMathFunction', [
  [
    'path',
    {
      d: 'M3 19a2 2 0 0 0 2 2c2 0 2 -4 3 -9s1 -9 3 -9a2 2 0 0 1 2 2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M5 12h6', key: 'svg-1' }],
  ['path', { d: 'M15 12l6 6', key: 'svg-2' }],
  ['path', { d: 'M15 18l6 -6', key: 'svg-3' }],
]);
