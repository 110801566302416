import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'player-track-prev-filled',
  'IconPlayerTrackPrevFilled',
  [
    [
      'path',
      {
        d: 'M20.341 4.247l-8 7a1 1 0 0 0 0 1.506l8 7c.647 .565 1.659 .106 1.659 -.753v-14c0 -.86 -1.012 -1.318 -1.659 -.753z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M9.341 4.247l-8 7a1 1 0 0 0 0 1.506l8 7c.647 .565 1.659 .106 1.659 -.753v-14c0 -.86 -1.012 -1.318 -1.659 -.753z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-1',
      },
    ],
  ],
);
