import createVueComponent from '../createVueComponent';
export default createVueComponent('blade', 'IconBlade', [
  [
    'path',
    {
      d: 'M17.707 3.707l2.586 2.586a1 1 0 0 1 0 1.414l-.586 .586a1 1 0 0 0 0 1.414l.586 .586a1 1 0 0 1 0 1.414l-8.586 8.586a1 1 0 0 1 -1.414 0l-.586 -.586a1 1 0 0 0 -1.414 0l-.586 .586a1 1 0 0 1 -1.414 0l-2.586 -2.586a1 1 0 0 1 0 -1.414l.586 -.586a1 1 0 0 0 0 -1.414l-.586 -.586a1 1 0 0 1 0 -1.414l8.586 -8.586a1 1 0 0 1 1.414 0l.586 .586a1 1 0 0 0 1.414 0l.586 -.586a1 1 0 0 1 1.414 0z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8 16l3.2 -3.2', key: 'svg-1' }],
  ['path', { d: 'M12.8 11.2l3.2 -3.2', key: 'svg-2' }],
  ['path', { d: 'M14 8l2 2', key: 'svg-3' }],
  ['path', { d: 'M8 14l2 2', key: 'svg-4' }],
  ['path', { d: 'M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-5' }],
]);
