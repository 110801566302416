import createVueComponent from '../createVueComponent';
export default createVueComponent('beta', 'IconBeta', [
  [
    'path',
    {
      d: 'M8 22v-14a4 4 0 0 1 4 -4h.5a3.5 3.5 0 0 1 0 7h-.5h.5a4.5 4.5 0 1 1 -4.5 4.5v-.5',
      key: 'svg-0',
    },
  ],
]);
