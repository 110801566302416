import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'currency-dollar-australian',
  'IconCurrencyDollarAustralian',
  [
    [
      'path',
      {
        d: 'M3 18l3.279 -11.476a.75 .75 0 0 1 1.442 0l3.279 11.476',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M21 6h-4a3 3 0 0 0 0 6h1a3 3 0 0 1 0 6h-4', key: 'svg-1' }],
    ['path', { d: 'M17 20v-2', key: 'svg-2' }],
    ['path', { d: 'M18 6v-2', key: 'svg-3' }],
    ['path', { d: 'M4.5 14h5', key: 'svg-4' }],
  ],
);
