import createVueComponent from '../createVueComponent';
export default createVueComponent('device-mobile-off', 'IconDeviceMobileOff', [
  [
    'path',
    {
      d: 'M7.159 3.185c.256 -.119 .54 -.185 .841 -.185h8a2 2 0 0 1 2 2v9m0 4v1a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-13',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M11 4h2', key: 'svg-1' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
  ['path', { d: 'M12 17v.01', key: 'svg-3' }],
]);
