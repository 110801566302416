import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'brand-stackoverflow',
  'IconBrandStackoverflow',
  [
    ['path', { d: 'M4 17v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-1', key: 'svg-0' }],
    ['path', { d: 'M8 16h8', key: 'svg-1' }],
    ['path', { d: 'M8.322 12.582l7.956 .836', key: 'svg-2' }],
    ['path', { d: 'M8.787 9.168l7.826 1.664', key: 'svg-3' }],
    ['path', { d: 'M10.096 5.764l7.608 2.472', key: 'svg-4' }],
  ],
);
