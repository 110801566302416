import createVueComponent from '../createVueComponent';
export default createVueComponent('ear-off', 'IconEarOff', [
  [
    'path',
    {
      d: 'M6 10c0 -1.146 .277 -2.245 .78 -3.219m1.792 -2.208a7 7 0 0 1 10.428 9.027a10 10 0 0 1 -.633 .762m-2.045 1.96a8 8 0 0 0 -1.322 2.278a4.5 4.5 0 0 1 -6.8 1.4',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M11.42 7.414a3 3 0 0 1 4.131 4.13', key: 'svg-1' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
