import createVueComponent from '../createVueComponent';
export default createVueComponent('flask', 'IconFlask', [
  ['path', { d: 'M9 3l6 0', key: 'svg-0' }],
  ['path', { d: 'M10 9l4 0', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M10 3v6l-4 11a.7 .7 0 0 0 .5 1h11a.7 .7 0 0 0 .5 -1l-4 -11v-6',
      key: 'svg-2',
    },
  ],
]);
