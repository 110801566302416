import createVueComponent from '../createVueComponent';
export default createVueComponent('luggage-off', 'IconLuggageOff', [
  [
    'path',
    {
      d: 'M10 6h6a2 2 0 0 1 2 2v6m0 4a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-10c0 -.546 .218 -1.04 .573 -1.4',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 5a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v1', key: 'svg-1' }],
  ['path', { d: 'M6 10h4m4 0h4', key: 'svg-2' }],
  ['path', { d: 'M6 16h10', key: 'svg-3' }],
  ['path', { d: 'M9 20v1', key: 'svg-4' }],
  ['path', { d: 'M15 20v1', key: 'svg-5' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
]);
