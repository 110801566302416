import createVueComponent from '../createVueComponent';
export default createVueComponent('clubs', 'IconClubs', [
  [
    'path',
    {
      d: 'M12 3a4 4 0 0 1 3.164 6.447a4 4 0 1 1 -1.164 6.198v1.355l1 4h-6l1 -4l0 -1.355a4 4 0 1 1 -1.164 -6.199a4 4 0 0 1 3.163 -6.446z',
      key: 'svg-0',
    },
  ],
]);
