import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'square-rounded-number-6',
  'IconSquareRoundedNumber6',
  [
    [
      'path',
      {
        d: 'M14 9a1 1 0 0 0 -1 -1h-2a1 1 0 0 0 -1 1v6a1 1 0 0 0 1 1h2a1 1 0 0 0 1 -1v-2a1 1 0 0 0 -1 -1h-3',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z',
        key: 'svg-1',
      },
    ],
  ],
);
