import createVueComponent from '../createVueComponent';
export default createVueComponent('bleach-chlorine', 'IconBleachChlorine', [
  [
    'path',
    {
      d: 'M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M11 12h-1a2 2 0 1 0 0 4h1', key: 'svg-1' }],
  ['path', { d: 'M14 12v4h2', key: 'svg-2' }],
]);
