import createVueComponent from '../createVueComponent';
export default createVueComponent('building-bridge', 'IconBuildingBridge', [
  ['path', { d: 'M6 5l0 14', key: 'svg-0' }],
  ['path', { d: 'M18 5l0 14', key: 'svg-1' }],
  ['path', { d: 'M2 15l20 0', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M3 8a7.5 7.5 0 0 0 3 -2a6.5 6.5 0 0 0 12 0a7.5 7.5 0 0 0 3 2',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M12 10l0 5', key: 'svg-4' }],
]);
