import createVueComponent from '../createVueComponent';
export default createVueComponent('fridge-off', 'IconFridgeOff', [
  [
    'path',
    {
      d: 'M7 3h10a2 2 0 0 1 2 2v10m0 4a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-14',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M5 10h5m4 0h5', key: 'svg-1' }],
  ['path', { d: 'M9 13v3', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
