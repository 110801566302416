import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'vector-triangle-off',
  'IconVectorTriangleOff',
  [
    [
      'path',
      {
        d: 'M10 6v-1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-1',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M3 17m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
        key: 'svg-1',
      },
    ],
    [
      'path',
      {
        d: 'M20.705 20.709a1 1 0 0 1 -.705 .291h-2a1 1 0 0 1 -1 -1v-2c0 -.28 .115 -.532 .3 -.714',
        key: 'svg-2',
      },
    ],
    ['path', { d: 'M6.5 17.1l3.749 -6.823', key: 'svg-3' }],
    ['path', { d: 'M13.158 9.197l-.658 -1.197', key: 'svg-4' }],
    ['path', { d: 'M7 19h10', key: 'svg-5' }],
    ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
  ],
);
