import createVueComponent from '../createVueComponent';
export default createVueComponent('messages', 'IconMessages', [
  [
    'path',
    {
      d: 'M21 14l-3 -3h-7a1 1 0 0 1 -1 -1v-6a1 1 0 0 1 1 -1h9a1 1 0 0 1 1 1v10',
      key: 'svg-0',
    },
  ],
  [
    'path',
    { d: 'M14 15v2a1 1 0 0 1 -1 1h-7l-3 3v-10a1 1 0 0 1 1 -1h2', key: 'svg-1' },
  ],
]);
