import createVueComponent from '../createVueComponent';
export default createVueComponent('chart-bar', 'IconChartBar', [
  [
    'path',
    {
      d: 'M3 12m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M9 8m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M15 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M4 20l14 0', key: 'svg-3' }],
]);
