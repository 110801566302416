import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'square-arrow-right',
  'IconSquareArrowRight',
  [
    ['path', { d: 'M12 16l4 -4l-4 -4', key: 'svg-0' }],
    ['path', { d: 'M8 12h8', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
        key: 'svg-2',
      },
    ],
  ],
);
