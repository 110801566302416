import createVueComponent from '../createVueComponent';
export default createVueComponent('credit-card', 'IconCreditCard', [
  [
    'path',
    {
      d: 'M3 5m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 10l18 0', key: 'svg-1' }],
  ['path', { d: 'M7 15l.01 0', key: 'svg-2' }],
  ['path', { d: 'M11 15l2 0', key: 'svg-3' }],
]);
