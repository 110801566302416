import createVueComponent from '../createVueComponent';
export default createVueComponent('copyright-filled', 'IconCopyrightFilled', [
  [
    'path',
    {
      d: 'M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-2.34 5.659a4.016 4.016 0 0 0 -5.543 .23a3.993 3.993 0 0 0 0 5.542a4.016 4.016 0 0 0 5.543 .23a1 1 0 0 0 -1.32 -1.502c-.81 .711 -2.035 .66 -2.783 -.116a1.993 1.993 0 0 1 0 -2.766a2.016 2.016 0 0 1 2.783 -.116a1 1 0 0 0 1.32 -1.501z',
      'stroke-width': '0',
      fill: 'currentColor',
      key: 'svg-0',
    },
  ],
]);
