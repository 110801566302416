import createVueComponent from '../createVueComponent';
export default createVueComponent('swipe', 'IconSwipe', [
  [
    'path',
    {
      d: 'M15 16.572v2.42a2.01 2.01 0 0 1 -2.009 2.008h-7.981a2.01 2.01 0 0 1 -2.01 -2.009v-7.981a2.01 2.01 0 0 1 2.009 -2.01h2.954',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M9.167 4.511a2.04 2.04 0 0 1 2.496 -1.441l7.826 2.097a2.04 2.04 0 0 1 1.441 2.496l-2.097 7.826a2.04 2.04 0 0 1 -2.496 1.441l-7.827 -2.097a2.04 2.04 0 0 1 -1.441 -2.496l2.098 -7.827z',
      key: 'svg-1',
    },
  ],
]);
