import createVueComponent from '../createVueComponent';
export default createVueComponent('user-minus', 'IconUserMinus', [
  ['path', { d: 'M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M6 21v-2a4 4 0 0 1 4 -4h4c.348 0 .686 .045 1.009 .128',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M16 19h6', key: 'svg-2' }],
]);
