import createVueComponent from '../createVueComponent';
export default createVueComponent('device-imac-pause', 'IconDeviceImacPause', [
  [
    'path',
    {
      d: 'M13 17h-9a1 1 0 0 1 -1 -1v-12a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v9',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 13h18', key: 'svg-1' }],
  ['path', { d: 'M8 21h5', key: 'svg-2' }],
  ['path', { d: 'M10 17l-.5 4', key: 'svg-3' }],
  ['path', { d: 'M17 17v5', key: 'svg-4' }],
  ['path', { d: 'M21 17v5', key: 'svg-5' }],
]);
