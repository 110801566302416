import createVueComponent from '../createVueComponent';
export default createVueComponent('droplet', 'IconDroplet', [
  [
    'path',
    {
      d: 'M7.502 19.423c2.602 2.105 6.395 2.105 8.996 0c2.602 -2.105 3.262 -5.708 1.566 -8.546l-4.89 -7.26c-.42 -.625 -1.287 -.803 -1.936 -.397a1.376 1.376 0 0 0 -.41 .397l-4.893 7.26c-1.695 2.838 -1.035 6.441 1.567 8.546z',
      key: 'svg-0',
    },
  ],
]);
