import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'device-watch-dollar',
  'IconDeviceWatchDollar',
  [
    [
      'path',
      {
        d: 'M13 18h-4a3 3 0 0 1 -3 -3v-6a3 3 0 0 1 3 -3h6a3 3 0 0 1 3 3v1',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
        key: 'svg-1',
      },
    ],
    ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-2' }],
    ['path', { d: 'M9 18v3h4', key: 'svg-3' }],
    ['path', { d: 'M9 6v-3h6v3', key: 'svg-4' }],
  ],
);
