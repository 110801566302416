import createVueComponent from '../createVueComponent';
export default createVueComponent('ad-circle', 'IconAdCircle', [
  [
    'path',
    { d: 'M12 12m-10 0a10 10 0 1 0 20 0a10 10 0 1 0 -20 0', key: 'svg-0' },
  ],
  ['path', { d: 'M7 15v-4.5a1.5 1.5 0 0 1 3 0v4.5', key: 'svg-1' }],
  ['path', { d: 'M7 13h3', key: 'svg-2' }],
  [
    'path',
    { d: 'M14 9v6h1a2 2 0 0 0 2 -2v-2a2 2 0 0 0 -2 -2h-1z', key: 'svg-3' },
  ],
]);
