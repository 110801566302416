import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'arrow-badge-up-filled',
  'IconArrowBadgeUpFilled',
  [
    [
      'path',
      {
        d: 'M11.375 6.22l-5 4a1 1 0 0 0 -.375 .78v6l.006 .112a1 1 0 0 0 1.619 .669l4.375 -3.501l4.375 3.5a1 1 0 0 0 1.625 -.78v-6a1 1 0 0 0 -.375 -.78l-5 -4a1 1 0 0 0 -1.25 0z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-0',
      },
    ],
  ],
);
