import createVueComponent from '../createVueComponent';
export default createVueComponent('rating-21-plus', 'IconRating21Plus', [
  ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
  ['path', { d: 'M13 15v-6', key: 'svg-1' }],
  ['path', { d: 'M15.5 12h3', key: 'svg-2' }],
  ['path', { d: 'M17 10.5v3', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M7 10.5a1.5 1.5 0 0 1 3 0c0 .443 -.313 .989 -.612 1.393l-2.388 3.107h3',
      key: 'svg-4',
    },
  ],
]);
