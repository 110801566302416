import createVueComponent from '../createVueComponent';
export default createVueComponent('square-rounded-x', 'IconSquareRoundedX', [
  ['path', { d: 'M10 10l4 4m0 -4l-4 4', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z',
      key: 'svg-1',
    },
  ],
]);
