import createVueComponent from '../createVueComponent';
export default createVueComponent('bong', 'IconBong', [
  [
    'path',
    {
      d: 'M13 3v8.416c.134 .059 .265 .123 .393 .193l3.607 -3.609l2 2l-3.608 3.608a5 5 0 1 1 -6.392 -2.192v-8.416h4z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8 3h6', key: 'svg-1' }],
  ['path', { d: 'M6.1 17h9.8', key: 'svg-2' }],
]);
