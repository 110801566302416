import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'message-circle-bolt',
  'IconMessageCircleBolt',
  [
    [
      'path',
      {
        d: 'M13.038 19.927a9.933 9.933 0 0 1 -5.338 -.927l-4.7 1l1.3 -3.9c-2.324 -3.437 -1.426 -7.872 2.1 -10.374c3.526 -2.501 8.59 -2.296 11.845 .48c1.993 1.7 2.93 4.043 2.746 6.346',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M19 16l-2 3h4l-2 3', key: 'svg-1' }],
  ],
);
