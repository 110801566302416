import createVueComponent from '../createVueComponent';
export default createVueComponent('template-off', 'IconTemplateOff', [
  [
    'path',
    {
      d: 'M8 4h11a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-7m-4 0h-3a1 1 0 0 1 -1 -1v-2c0 -.271 .108 -.517 .283 -.697',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M4 12m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M16 12h4', key: 'svg-2' }],
  ['path', { d: 'M14 16h2', key: 'svg-3' }],
  ['path', { d: 'M14 20h6', key: 'svg-4' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
