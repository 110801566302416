import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'currency-dollar-guyanese',
  'IconCurrencyDollarGuyanese',
  [
    ['path', { d: 'M21 6h-4a3 3 0 0 0 0 6h1a3 3 0 0 1 0 6h-4', key: 'svg-0' }],
    [
      'path',
      { d: 'M10 6h-3a4 4 0 0 0 -4 4v4a4 4 0 0 0 4 4h3v-6h-2', key: 'svg-1' },
    ],
    ['path', { d: 'M17 20v-2', key: 'svg-2' }],
    ['path', { d: 'M18 6v-2', key: 'svg-3' }],
  ],
);
