import createVueComponent from '../createVueComponent';
export default createVueComponent('cactus-off', 'IconCactusOff', [
  ['path', { d: 'M6 9v1a3 3 0 0 0 3 3h1', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M18 8v5a3 3 0 0 1 -.129 .872m-2.014 2a3 3 0 0 1 -.857 .124h-1',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M10 21v-11m0 -4v-1a2 2 0 1 1 4 0v5m0 4v7', key: 'svg-2' }],
  ['path', { d: 'M7 21h10', key: 'svg-3' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
