import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'arrow-wave-right-up',
  'IconArrowWaveRightUp',
  [
    ['path', { d: 'M17 10h4v4', key: 'svg-0' }],
    [
      'path',
      {
        d: 'M3 12c.887 -1.284 2.48 -2.033 4 -2c1.52 -.033 3.113 .716 4 2s2.48 2.033 4 2c1.52 .033 3 -1 4 -2l2 -2',
        key: 'svg-1',
      },
    ],
  ],
);
