import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'brand-flightradar24',
  'IconBrandFlightradar24',
  [
    ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
    ['path', { d: 'M12 12m-5 0a5 5 0 1 0 10 0a5 5 0 1 0 -10 0', key: 'svg-1' }],
    ['path', { d: 'M8.5 20l3.5 -8l-6.5 6', key: 'svg-2' }],
    ['path', { d: 'M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-3' }],
  ],
);
