import createVueComponent from '../createVueComponent';
export default createVueComponent('plant-off', 'IconPlantOff', [
  [
    'path',
    { d: 'M17 17v2a2 2 0 0 1 -2 2h-6a2 2 0 0 1 -2 -2v-4h8', key: 'svg-0' },
  ],
  [
    'path',
    {
      d: 'M11.9 7.908a6 6 0 0 0 -4.79 -4.806m-4.11 -.102v2a6 6 0 0 0 6 6h2',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M12.531 8.528a6 6 0 0 1 5.469 -3.528h3v1a6 6 0 0 1 -5.037 5.923',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M12 15v-3', key: 'svg-3' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
