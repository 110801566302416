import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'square-rounded-number-0',
  'IconSquareRoundedNumber0',
  [
    ['path', { d: 'M10 10v4a2 2 0 1 0 4 0v-4a2 2 0 1 0 -4 0z', key: 'svg-0' }],
    [
      'path',
      {
        d: 'M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z',
        key: 'svg-1',
      },
    ],
  ],
);
