import createVueComponent from '../createVueComponent';
export default createVueComponent('empathize', 'IconEmpathize', [
  [
    'path',
    { d: 'M12 5.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0', key: 'svg-0' },
  ],
  [
    'path',
    {
      d: 'M12 21.368l5.095 -5.096a3.088 3.088 0 1 0 -4.367 -4.367l-.728 .727l-.728 -.727a3.088 3.088 0 1 0 -4.367 4.367l5.095 5.096z',
      key: 'svg-1',
    },
  ],
]);
