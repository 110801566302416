import createVueComponent from '../createVueComponent';
export default createVueComponent('home-x', 'IconHomeX', [
  [
    'path',
    { d: 'M19 13.4v-1.4h2l-9 -9l-9 9h2v7a2 2 0 0 0 2 2h5.5', key: 'svg-0' },
  ],
  [
    'path',
    {
      d: 'M9 21v-6a2 2 0 0 1 2 -2h2c.402 0 .777 .119 1.091 .323',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M21.5 21.5l-5 -5', key: 'svg-2' }],
  ['path', { d: 'M16.5 21.5l5 -5', key: 'svg-3' }],
]);
