import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'help-square-rounded',
  'IconHelpSquareRounded',
  [
    [
      'path',
      {
        d: 'M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M12 16v.01', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M12 13a2 2 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
        key: 'svg-2',
      },
    ],
  ],
);
