import createVueComponent from '../createVueComponent';
export default createVueComponent('clock-dollar', 'IconClockDollar', [
  ['path', { d: 'M20.866 10.45a9 9 0 1 0 -7.815 10.488', key: 'svg-0' }],
  ['path', { d: 'M12 7v5l1.5 1.5', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-3' }],
]);
