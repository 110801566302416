import createVueComponent from '../createVueComponent';
export default createVueComponent('bookmark-off', 'IconBookmarkOff', [
  ['path', { d: 'M3 3l18 18', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M17 17v3l-5 -3l-5 3v-13m1.178 -2.818c.252 -.113 .53 -.176 .822 -.176h6a2 2 0 0 1 2 2v7',
      key: 'svg-1',
    },
  ],
]);
