import createVueComponent from '../createVueComponent';
export default createVueComponent('hand-sanitizer', 'IconHandSanitizer', [
  [
    'path',
    { d: 'M7 21h10v-10a3 3 0 0 0 -3 -3h-4a3 3 0 0 0 -3 3v10z', key: 'svg-0' },
  ],
  ['path', { d: 'M15 3h-6a2 2 0 0 0 -2 2', key: 'svg-1' }],
  ['path', { d: 'M12 3v5', key: 'svg-2' }],
  ['path', { d: 'M12 11v4', key: 'svg-3' }],
  ['path', { d: 'M10 13h4', key: 'svg-4' }],
]);
