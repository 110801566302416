import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-douban', 'IconBrandDouban', [
  ['path', { d: 'M4 20h16', key: 'svg-0' }],
  ['path', { d: 'M5 4h14', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M8 8h8a2 2 0 0 1 2 2v2a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-2a2 2 0 0 1 2 -2z',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M16 14l-2 6', key: 'svg-3' }],
  ['path', { d: 'M8 17l1 3', key: 'svg-4' }],
]);
