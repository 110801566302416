import createVueComponent from '../createVueComponent';
export default createVueComponent('volcano', 'IconVolcano', [
  ['path', { d: 'M9 8v-1a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M15 8v-1a2 2 0 1 1 4 0', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M4 20l3.472 -7.812a2 2 0 0 1 1.828 -1.188h5.4a2 2 0 0 1 1.828 1.188l3.472 7.812',
      key: 'svg-2',
    },
  ],
  [
    'path',
    {
      d: 'M6.192 15.064a2.14 2.14 0 0 1 .475 -.064c.527 -.009 1.026 .178 1.333 .5c.307 .32 .806 .507 1.333 .5c.527 .007 1.026 -.18 1.334 -.5c.307 -.322 .806 -.509 1.333 -.5c.527 -.009 1.026 .178 1.333 .5c.308 .32 .807 .507 1.334 .5c.527 .007 1.026 -.18 1.333 -.5c.307 -.322 .806 -.509 1.333 -.5c.161 .003 .32 .025 .472 .064',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M12 8v-4', key: 'svg-4' }],
]);
