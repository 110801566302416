import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-monday', 'IconBrandMonday', [
  [
    'path',
    {
      d: 'M19.5 15.5m-1.5 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0 -3 0',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M9.5 7a1.5 1.5 0 0 1 1.339 2.177l-4.034 7.074c-.264 .447 -.75 .749 -1.305 .749a1.5 1.5 0 0 1 -1.271 -2.297l3.906 -6.827a1.5 1.5 0 0 1 1.365 -.876z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M16.5 7a1.5 1.5 0 0 1 1.339 2.177l-4.034 7.074c-.264 .447 -.75 .749 -1.305 .749a1.5 1.5 0 0 1 -1.271 -2.297l3.906 -6.827a1.5 1.5 0 0 1 1.365 -.876z',
      key: 'svg-2',
    },
  ],
]);
