import createVueComponent from '../createVueComponent';
export default createVueComponent('trekking', 'IconTrekking', [
  ['path', { d: 'M12 4m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-0' }],
  ['path', { d: 'M7 21l2 -4', key: 'svg-1' }],
  ['path', { d: 'M13 21v-4l-3 -3l1 -6l3 4l3 2', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M10 14l-1.827 -1.218a2 2 0 0 1 -.831 -2.15l.28 -1.117a2 2 0 0 1 1.939 -1.515h1.439l4 1l3 -2',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M17 12v9', key: 'svg-4' }],
  ['path', { d: 'M16 20h2', key: 'svg-5' }],
]);
