import createVueComponent from '../createVueComponent';
export default createVueComponent('circuit-inductor', 'IconCircuitInductor', [
  [
    'path',
    {
      d: 'M2 14h3v-2a2 2 0 1 1 4 0v2v-1.5a2.5 2.5 0 1 1 5 0v1.5v-1.5a2.5 2.5 0 1 1 5 0v1.5h3',
      key: 'svg-0',
    },
  ],
]);
