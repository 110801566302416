import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'arrow-rotary-straight',
  'IconArrowRotaryStraight',
  [
    ['path', { d: 'M13 13m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-0' }],
    ['path', { d: 'M13 16v5', key: 'svg-1' }],
    ['path', { d: 'M13 3v7', key: 'svg-2' }],
    ['path', { d: 'M9 7l4 -4l4 4', key: 'svg-3' }],
  ],
);
