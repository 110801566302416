import createVueComponent from '../createVueComponent';
export default createVueComponent('file-report', 'IconFileReport', [
  ['path', { d: 'M17 17m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0', key: 'svg-0' }],
  ['path', { d: 'M17 13v4h4', key: 'svg-1' }],
  ['path', { d: 'M12 3v4a1 1 0 0 0 1 1h4', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M11.5 21h-6.5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v2m0 3v4',
      key: 'svg-3',
    },
  ],
]);
