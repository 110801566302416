import createVueComponent from '../createVueComponent';
export default createVueComponent('a-b-off', 'IconABOff', [
  ['path', { d: 'M3 16v-5.5a2.5 2.5 0 0 1 5 0v5.5m0 -4h-5', key: 'svg-0' }],
  ['path', { d: 'M12 12v6', key: 'svg-1' }],
  ['path', { d: 'M12 6v2', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M16 8h3a2 2 0 1 1 0 4h-3m3 0a2 2 0 0 1 .83 3.82m-3.83 -3.82v-4',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
