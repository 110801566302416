import createVueComponent from '../createVueComponent';
export default createVueComponent('devices-2', 'IconDevices2', [
  [
    'path',
    { d: 'M10 15h-6a1 1 0 0 1 -1 -1v-8a1 1 0 0 1 1 -1h6', key: 'svg-0' },
  ],
  [
    'path',
    {
      d: 'M13 4m0 1a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M7 19l3 0', key: 'svg-2' }],
  ['path', { d: 'M17 8l0 .01', key: 'svg-3' }],
  ['path', { d: 'M17 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-4' }],
  ['path', { d: 'M9 15l0 4', key: 'svg-5' }],
]);
