import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-toyota', 'IconBrandToyota', [
  [
    'path',
    { d: 'M12 12m-10 0a10 7 0 1 0 20 0a10 7 0 1 0 -20 0', key: 'svg-0' },
  ],
  [
    'path',
    {
      d: 'M9 12c0 3.866 1.343 7 3 7s3 -3.134 3 -7s-1.343 -7 -3 -7s-3 3.134 -3 7z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M6.415 6.191c-.888 .503 -1.415 1.13 -1.415 1.809c0 1.657 3.134 3 7 3s7 -1.343 7 -3c0 -.678 -.525 -1.304 -1.41 -1.806',
      key: 'svg-2',
    },
  ],
]);
