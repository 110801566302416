import createVueComponent from '../createVueComponent';
export default createVueComponent('heading', 'IconHeading', [
  ['path', { d: 'M7 12h10', key: 'svg-0' }],
  ['path', { d: 'M7 5v14', key: 'svg-1' }],
  ['path', { d: 'M17 5v14', key: 'svg-2' }],
  ['path', { d: 'M15 19h4', key: 'svg-3' }],
  ['path', { d: 'M15 5h4', key: 'svg-4' }],
  ['path', { d: 'M5 19h4', key: 'svg-5' }],
  ['path', { d: 'M5 5h4', key: 'svg-6' }],
]);
