import createVueComponent from '../createVueComponent';
export default createVueComponent('script', 'IconScript', [
  [
    'path',
    {
      d: 'M17 20h-11a3 3 0 0 1 0 -6h11a3 3 0 0 0 0 6h1a3 3 0 0 0 3 -3v-11a2 2 0 0 0 -2 -2h-10a2 2 0 0 0 -2 2v8',
      key: 'svg-0',
    },
  ],
]);
