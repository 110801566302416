import createVueComponent from '../createVueComponent';
export default createVueComponent('message-cancel', 'IconMessageCancel', [
  ['path', { d: 'M8 9h8', key: 'svg-0' }],
  ['path', { d: 'M8 13h6', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M11.995 18.603l-3.995 2.397v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v5.5',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M19 19m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-3' }],
  ['path', { d: 'M17 21l4 -4', key: 'svg-4' }],
]);
