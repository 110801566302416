import createVueComponent from '../createVueComponent';
export default createVueComponent('live-photo-off', 'IconLivePhotoOff', [
  ['path', { d: 'M11.296 11.29a1 1 0 1 0 1.414 1.415', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M8.473 8.456a5 5 0 1 0 7.076 7.066m1.365 -2.591a5 5 0 0 0 -5.807 -5.851',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M15.9 20.11v.01', key: 'svg-2' }],
  ['path', { d: 'M19.04 17.61v.01', key: 'svg-3' }],
  ['path', { d: 'M20.77 14v.01', key: 'svg-4' }],
  ['path', { d: 'M20.77 10v.01', key: 'svg-5' }],
  ['path', { d: 'M19.04 6.39v.01', key: 'svg-6' }],
  ['path', { d: 'M15.9 3.89v.01', key: 'svg-7' }],
  ['path', { d: 'M12 3v.01', key: 'svg-8' }],
  ['path', { d: 'M8.1 3.89v.01', key: 'svg-9' }],
  ['path', { d: 'M4.96 6.39v.01', key: 'svg-10' }],
  ['path', { d: 'M3.23 10v.01', key: 'svg-11' }],
  ['path', { d: 'M3.23 14v.01', key: 'svg-12' }],
  ['path', { d: 'M4.96 17.61v.01', key: 'svg-13' }],
  ['path', { d: 'M8.1 20.11v.01', key: 'svg-14' }],
  ['path', { d: 'M12 21v.01', key: 'svg-15' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-16' }],
]);
