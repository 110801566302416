import createVueComponent from '../createVueComponent';
export default createVueComponent('highlight', 'IconHighlight', [
  [
    'path',
    {
      d: 'M3 19h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12.5 5.5l4 4', key: 'svg-1' }],
  ['path', { d: 'M4.5 13.5l4 4', key: 'svg-2' }],
  ['path', { d: 'M21 15v4h-8l4 -4z', key: 'svg-3' }],
]);
