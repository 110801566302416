import createVueComponent from '../createVueComponent';
export default createVueComponent('device-ipad-up', 'IconDeviceIpadUp', [
  ['path', { d: 'M9 18h3', key: 'svg-0' }],
  ['path', { d: 'M19 22v-6', key: 'svg-1' }],
  ['path', { d: 'M22 19l-3 -3l-3 3', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M13.5 21h-6.5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v7',
      key: 'svg-3',
    },
  ],
]);
