import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'brackets-contain-end',
  'IconBracketsContainEnd',
  [
    ['path', { d: 'M14 4h4v16h-4', key: 'svg-0' }],
    ['path', { d: 'M5 16h.01', key: 'svg-1' }],
    ['path', { d: 'M9 16h.01', key: 'svg-2' }],
    ['path', { d: 'M13 16h.01', key: 'svg-3' }],
  ],
);
