import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'layers-intersect-2',
  'IconLayersIntersect2',
  [
    [
      'path',
      {
        d: 'M8 4m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M4 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z',
        key: 'svg-1',
      },
    ],
    ['path', { d: 'M9 15l6 -6', key: 'svg-2' }],
  ],
);
