import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'steering-wheel-off',
  'IconSteeringWheelOff',
  [
    [
      'path',
      {
        d: 'M20.04 16.048a9 9 0 0 0 -12.083 -12.09m-2.32 1.678a9 9 0 1 0 12.737 12.719',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M10.595 10.576a2 2 0 1 0 2.827 2.83', key: 'svg-1' }],
    ['path', { d: 'M12 14v7', key: 'svg-2' }],
    ['path', { d: 'M10 12l-6.75 -2', key: 'svg-3' }],
    ['path', { d: 'M15.542 11.543l5.208 -1.543', key: 'svg-4' }],
    ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
  ],
);
