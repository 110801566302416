import createVueComponent from '../createVueComponent';
export default createVueComponent('gif', 'IconGif', [
  [
    'path',
    { d: 'M8 8h-3a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h3v-4h-1', key: 'svg-0' },
  ],
  ['path', { d: 'M12 8v8', key: 'svg-1' }],
  ['path', { d: 'M16 16v-8h5', key: 'svg-2' }],
  ['path', { d: 'M20 12h-4', key: 'svg-3' }],
]);
