import createVueComponent from '../createVueComponent';
export default createVueComponent('camera-pause', 'IconCameraPause', [
  [
    'path',
    {
      d: 'M13 20h-8a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v4',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M14.958 13.506a3 3 0 1 0 -1.735 2.235', key: 'svg-1' }],
  ['path', { d: 'M17 17v5', key: 'svg-2' }],
  ['path', { d: 'M21 17v5', key: 'svg-3' }],
]);
