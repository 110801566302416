import createVueComponent from '../createVueComponent';
export default createVueComponent('subtask', 'IconSubtask', [
  ['path', { d: 'M6 9l6 0', key: 'svg-0' }],
  ['path', { d: 'M4 5l4 0', key: 'svg-1' }],
  ['path', { d: 'M6 5v11a1 1 0 0 0 1 1h5', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M12 7m0 1a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1z',
      key: 'svg-3',
    },
  ],
  [
    'path',
    {
      d: 'M12 15m0 1a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1z',
      key: 'svg-4',
    },
  ],
]);
