import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'panorama-horizontal',
  'IconPanoramaHorizontal',
  [
    [
      'path',
      {
        d: 'M4.338 5.53c5.106 1.932 10.211 1.932 15.317 0a1 1 0 0 1 1.345 .934v11c0 .692 -.692 1.2 -1.34 .962c-5.107 -1.932 -10.214 -1.932 -15.321 0c-.648 .246 -1.339 -.242 -1.339 -.935v-11.027a1 1 0 0 1 1.338 -.935z',
        key: 'svg-0',
      },
    ],
  ],
);
