import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'device-computer-camera-off',
  'IconDeviceComputerCameraOff',
  [
    [
      'path',
      {
        d: 'M6.15 6.153a7 7 0 0 0 9.696 9.696m2 -2a7 7 0 0 0 -9.699 -9.695',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M9.13 9.122a3 3 0 0 0 3.743 3.749m2 -2a3 3 0 0 0 -3.737 -3.736',
        key: 'svg-1',
      },
    ],
    [
      'path',
      {
        d: 'M8 16l-2.091 3.486a1 1 0 0 0 .857 1.514h10.468a1 1 0 0 0 .857 -1.514l-2.091 -3.486',
        key: 'svg-2',
      },
    ],
    ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
  ],
);
