import createVueComponent from '../createVueComponent';
export default createVueComponent('certificate-2-off', 'IconCertificate2Off', [
  ['path', { d: 'M12 12a3 3 0 1 0 3 3', key: 'svg-0' }],
  ['path', { d: 'M11 7h3', key: 'svg-1' }],
  ['path', { d: 'M10 18v4l2 -1l2 1v-4', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M10 19h-2a2 2 0 0 1 -2 -2v-11m1.18 -2.825c.25 -.112 .529 -.175 .82 -.175h8a2 2 0 0 1 2 2v9m-.175 3.82a2 2 0 0 1 -1.825 1.18h-2',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
