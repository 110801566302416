import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-tinder', 'IconBrandTinder', [
  [
    'path',
    {
      d: 'M18.918 8.174c2.56 4.982 .501 11.656 -5.38 12.626c-7.702 1.687 -12.84 -7.716 -7.054 -13.229c.309 -.305 1.161 -1.095 1.516 -1.349c0 .528 .27 3.475 1 3.167c3 0 4 -4.222 3.587 -7.389c2.7 1.411 4.987 3.376 6.331 6.174z',
      key: 'svg-0',
    },
  ],
]);
