import createVueComponent from '../createVueComponent';
export default createVueComponent('api', 'IconApi', [
  ['path', { d: 'M4 13h5', key: 'svg-0' }],
  [
    'path',
    { d: 'M12 16v-8h3a2 2 0 0 1 2 2v1a2 2 0 0 1 -2 2h-3', key: 'svg-1' },
  ],
  ['path', { d: 'M20 8v8', key: 'svg-2' }],
  ['path', { d: 'M9 16v-5.5a2.5 2.5 0 0 0 -5 0v5.5', key: 'svg-3' }],
]);
