import createVueComponent from '../createVueComponent';
export default createVueComponent('point-off', 'IconPointOff', [
  [
    'path',
    {
      d: 'M9.15 9.194a4 4 0 0 0 5.697 5.617m1.153 -2.811a4 4 0 0 0 -4 -4',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-1' }],
]);
