import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'currency-krone-czech',
  'IconCurrencyKroneCzech',
  [
    ['path', { d: 'M5 6v12', key: 'svg-0' }],
    ['path', { d: 'M5 12c3.5 0 6 -3 6 -6', key: 'svg-1' }],
    ['path', { d: 'M5 12c3.5 0 6 3 6 6', key: 'svg-2' }],
    ['path', { d: 'M19 6l-2 2l-2 -2', key: 'svg-3' }],
    ['path', { d: 'M19 12h-2a3 3 0 0 0 0 6h2', key: 'svg-4' }],
  ],
);
