import createVueComponent from '../createVueComponent';
export default createVueComponent('microphone', 'IconMicrophone', [
  [
    'path',
    {
      d: 'M9 2m0 3a3 3 0 0 1 3 -3h0a3 3 0 0 1 3 3v5a3 3 0 0 1 -3 3h0a3 3 0 0 1 -3 -3z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M5 10a7 7 0 0 0 14 0', key: 'svg-1' }],
  ['path', { d: 'M8 21l8 0', key: 'svg-2' }],
  ['path', { d: 'M12 17l0 4', key: 'svg-3' }],
]);
