import createVueComponent from '../createVueComponent';
export default createVueComponent('variable-minus', 'IconVariableMinus', [
  ['path', { d: 'M8 16c1.5 0 3 -2 4 -3.5s2.5 -3.5 4 -3.5', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M5 4c-2.5 5 -2.5 10 0 16m14 -16c1.775 3.55 2.29 7.102 1.544 11.01m-11.544 -6.01h1c1 0 1 1 2.016 3.527c.782 1.966 .943 3 1.478 3.343',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M8 16c1.5 0 3 -2 4 -3.5s2.5 -3.5 4 -3.5', key: 'svg-2' }],
  ['path', { d: 'M16 19h6', key: 'svg-3' }],
]);
