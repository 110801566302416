import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-netbeans', 'IconBrandNetbeans', [
  [
    'path',
    {
      d: 'M19.875 6.27a2.225 2.225 0 0 1 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M15.5 9.43a1 1 0 0 1 .5 .874v3.268a1 1 0 0 1 -.515 .874l-3 1.917a1 1 0 0 1 -.97 0l-3 -1.917a1 1 0 0 1 -.515 -.873v-3.269a1 1 0 0 1 .514 -.874l3 -1.786c.311 -.173 .69 -.173 1 0l3 1.787h-.014z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 21v-9l-7.5 -4.5', key: 'svg-2' }],
  ['path', { d: 'M12 12l7.5 -4.5', key: 'svg-3' }],
  ['path', { d: 'M12 3v4.5', key: 'svg-4' }],
  ['path', { d: 'M19.5 16l-3.5 -2', key: 'svg-5' }],
  ['path', { d: 'M8 14l-3.5 2', key: 'svg-6' }],
]);
