import createVueComponent from '../createVueComponent';
export default createVueComponent('backhoe', 'IconBackhoe', [
  ['path', { d: 'M4 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M13 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M13 19l-9 0', key: 'svg-2' }],
  ['path', { d: 'M4 15l9 0', key: 'svg-3' }],
  ['path', { d: 'M8 12v-5h2a3 3 0 0 1 3 3v5', key: 'svg-4' }],
  ['path', { d: 'M5 15v-2a1 1 0 0 1 1 -1h7', key: 'svg-5' }],
  ['path', { d: 'M21.12 9.88l-3.12 -4.88l-5 5', key: 'svg-6' }],
  [
    'path',
    {
      d: 'M21.12 9.88a3 3 0 0 1 -2.12 5.12a3 3 0 0 1 -2.12 -.88l4.24 -4.24z',
      key: 'svg-7',
    },
  ],
]);
