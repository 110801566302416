import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'arrow-sharp-turn-right',
  'IconArrowSharpTurnRight',
  [
    [
      'path',
      { d: 'M7 18v-11.31a.7 .7 0 0 1 1.195 -.495l9.805 9.805', key: 'svg-0' },
    ],
    ['path', { d: 'M13 16h5v-5', key: 'svg-1' }],
  ],
);
