import createVueComponent from '../createVueComponent';
export default createVueComponent('crane-off', 'IconCraneOff', [
  ['path', { d: 'M6 21h6', key: 'svg-0' }],
  ['path', { d: 'M9 21v-12', key: 'svg-1' }],
  ['path', { d: 'M9 5v-2l-1 1', key: 'svg-2' }],
  ['path', { d: 'M6 6l-3 3h6', key: 'svg-3' }],
  ['path', { d: 'M13 9h8', key: 'svg-4' }],
  ['path', { d: 'M9 3l10 6', key: 'svg-5' }],
  ['path', { d: 'M17 9v4a2 2 0 0 1 2 2m-2 2a2 2 0 0 1 -2 -2', key: 'svg-6' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-7' }],
]);
