import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-storj', 'IconBrandStorj', [
  ['path', { d: 'M4 17m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-0' }],
  ['path', { d: 'M4 7m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-1' }],
  ['path', { d: 'M20 17m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-2' }],
  ['path', { d: 'M20 7m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-3' }],
  ['path', { d: 'M12 3m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-4' }],
  ['path', { d: 'M12 21m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-5' }],
  ['path', { d: 'M12 21l-8 -4v-10l8 -4l8 4v10z', key: 'svg-6' }],
  [
    'path',
    {
      d: 'M9.1 15a2.1 2.1 0 0 1 -.648 -4.098c.282 -1.648 1.319 -2.902 3.048 -2.902c1.694 0 2.906 1.203 3.23 2.8h.17a2.1 2.1 0 0 1 .202 4.19l-.202 .01h-5.8z',
      key: 'svg-7',
    },
  ],
  ['path', { d: 'M4 7l4.323 2.702', key: 'svg-8' }],
  ['path', { d: 'M16.413 14.758l3.587 2.242', key: 'svg-9' }],
  ['path', { d: 'M4 17l3.529 -2.206', key: 'svg-10' }],
  ['path', { d: 'M14.609 10.37l5.391 -3.37', key: 'svg-11' }],
  ['path', { d: 'M12 3v5', key: 'svg-12' }],
  ['path', { d: 'M12 15v6', key: 'svg-13' }],
]);
