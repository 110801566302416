import createVueComponent from '../createVueComponent';
export default createVueComponent('box-multiple-7', 'IconBoxMultiple7', [
  [
    'path',
    {
      d: 'M7 3m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 6h4l-2 8', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M17 17v2a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2',
      key: 'svg-2',
    },
  ],
]);
