import createVueComponent from '../createVueComponent';
export default createVueComponent('masks-theater', 'IconMasksTheater', [
  [
    'path',
    {
      d: 'M13.192 9h6.616a2 2 0 0 1 1.992 2.183l-.567 6.182a4 4 0 0 1 -3.983 3.635h-1.5a4 4 0 0 1 -3.983 -3.635l-.567 -6.182a2 2 0 0 1 1.992 -2.183z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M15 13h.01', key: 'svg-1' }],
  ['path', { d: 'M18 13h.01', key: 'svg-2' }],
  ['path', { d: 'M15 16.5c1 .667 2 .667 3 0', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M8.632 15.982a4.037 4.037 0 0 1 -.382 .018h-1.5a4 4 0 0 1 -3.983 -3.635l-.567 -6.182a2 2 0 0 1 1.992 -2.183h6.616a2 2 0 0 1 2 2',
      key: 'svg-4',
    },
  ],
  ['path', { d: 'M6 8h.01', key: 'svg-5' }],
  ['path', { d: 'M9 8h.01', key: 'svg-6' }],
  ['path', { d: 'M6 12c.764 -.51 1.528 -.63 2.291 -.36', key: 'svg-7' }],
]);
