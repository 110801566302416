import createVueComponent from '../createVueComponent';
export default createVueComponent('chess-knight', 'IconChessKnight', [
  [
    'path',
    {
      d: 'M8 16l-1.447 .724a1 1 0 0 0 -.553 .894v2.382h12v-2.382a1 1 0 0 0 -.553 -.894l-1.447 -.724h-8z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M9 3l1 3l-3.491 2.148a1 1 0 0 0 .524 1.852h2.967l-2.073 6h7.961l.112 -5c0 -3 -1.09 -5.983 -4 -7c-1.94 -.678 -2.94 -1.011 -3 -1z',
      key: 'svg-1',
    },
  ],
]);
