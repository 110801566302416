import createVueComponent from '../createVueComponent';
export default createVueComponent('tag-off', 'IconTagOff', [
  ['path', { d: 'M7.792 7.793a1 1 0 0 0 1.414 1.414', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M4.88 4.877a2.99 2.99 0 0 0 -.88 2.123v3.859c0 .537 .213 1.052 .593 1.432l8.116 8.116a2.025 2.025 0 0 0 2.864 0l2.416 -2.416m2 -2l.416 -.416a2.025 2.025 0 0 0 0 -2.864l-8.117 -8.116a2.025 2.025 0 0 0 -1.431 -.593h-2.859',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
