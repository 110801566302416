import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-npm', 'IconBrandNpm', [
  ['path', { d: 'M1 8h22v7h-12v2h-4v-2h-6z', key: 'svg-0' }],
  ['path', { d: 'M7 8v7', key: 'svg-1' }],
  ['path', { d: 'M14 8v7', key: 'svg-2' }],
  ['path', { d: 'M17 11v4', key: 'svg-3' }],
  ['path', { d: 'M4 11v4', key: 'svg-4' }],
  ['path', { d: 'M11 11v1', key: 'svg-5' }],
  ['path', { d: 'M20 11v4', key: 'svg-6' }],
]);
