import createVueComponent from '../createVueComponent';
export default createVueComponent('repeat-off', 'IconRepeatOff', [
  [
    'path',
    {
      d: 'M4 12v-3c0 -1.336 .873 -2.468 2.08 -2.856m3.92 -.144h10m-3 -3l3 3l-3 3',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M20 12v3a3 3 0 0 1 -.133 .886m-1.99 1.984a3 3 0 0 1 -.877 .13h-13m3 3l-3 -3l3 -3',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
