import createVueComponent from '../createVueComponent';
export default createVueComponent('plug-connected', 'IconPlugConnected', [
  [
    'path',
    { d: 'M7 12l5 5l-1.5 1.5a3.536 3.536 0 1 1 -5 -5l1.5 -1.5z', key: 'svg-0' },
  ],
  [
    'path',
    {
      d: 'M17 12l-5 -5l1.5 -1.5a3.536 3.536 0 1 1 5 5l-1.5 1.5z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 21l2.5 -2.5', key: 'svg-2' }],
  ['path', { d: 'M18.5 5.5l2.5 -2.5', key: 'svg-3' }],
  ['path', { d: 'M10 11l-2 2', key: 'svg-4' }],
  ['path', { d: 'M13 14l-2 2', key: 'svg-5' }],
]);
