import createVueComponent from '../createVueComponent';
export default createVueComponent('rectangle-filled', 'IconRectangleFilled', [
  [
    'path',
    {
      d: 'M19 4h-14a3 3 0 0 0 -3 3v10a3 3 0 0 0 3 3h14a3 3 0 0 0 3 -3v-10a3 3 0 0 0 -3 -3z',
      'stroke-width': '0',
      fill: 'currentColor',
      key: 'svg-0',
    },
  ],
]);
