import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'device-tablet-code',
  'IconDeviceTabletCode',
  [
    [
      'path',
      {
        d: 'M11.5 21h-5.5a1 1 0 0 1 -1 -1v-16a1 1 0 0 1 1 -1h12a1 1 0 0 1 1 1v9',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M12.344 16.06a1 1 0 0 0 -1.07 1.627', key: 'svg-1' }],
    ['path', { d: 'M20 21l2 -2l-2 -2', key: 'svg-2' }],
    ['path', { d: 'M17 17l-2 2l2 2', key: 'svg-3' }],
  ],
);
