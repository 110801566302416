import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'message-circle-question',
  'IconMessageCircleQuestion',
  [
    [
      'path',
      {
        d: 'M15.02 19.52c-2.341 .736 -5 .606 -7.32 -.52l-4.7 1l1.3 -3.9c-2.324 -3.437 -1.426 -7.872 2.1 -10.374c3.526 -2.501 8.59 -2.296 11.845 .48c1.649 1.407 2.575 3.253 2.742 5.152',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M19 22v.01', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
        key: 'svg-2',
      },
    ],
  ],
);
