import createVueComponent from '../createVueComponent';
export default createVueComponent('radioactive', 'IconRadioactive', [
  [
    'path',
    {
      d: 'M13.5 14.6l3 5.19a9 9 0 0 0 4.5 -7.79h-6a3 3 0 0 1 -1.5 2.6',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M13.5 9.4l3 -5.19a9 9 0 0 0 -9 0l3 5.19a3 3 0 0 1 3 0',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M10.5 14.6l-3 5.19a9 9 0 0 1 -4.5 -7.79h6a3 3 0 0 0 1.5 2.6',
      key: 'svg-2',
    },
  ],
]);
