import createVueComponent from '../createVueComponent';
export default createVueComponent('beach', 'IconBeach', [
  ['path', { d: 'M17.553 16.75a7.5 7.5 0 0 0 -10.606 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M18 3.804a6 6 0 0 0 -8.196 2.196l10.392 6a6 6 0 0 0 -2.196 -8.196z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M16.732 10c1.658 -2.87 2.225 -5.644 1.268 -6.196c-.957 -.552 -3.075 1.326 -4.732 4.196',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M15 9l-3 5.196', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M3 19.25a2.4 2.4 0 0 1 1 -.25a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 1 .25',
      key: 'svg-4',
    },
  ],
]);
