import createVueComponent from '../createVueComponent';
export default createVueComponent('info-triangle', 'IconInfoTriangle', [
  ['path', { d: 'M12 10h.01', key: 'svg-0' }],
  ['path', { d: 'M11 13h1v4h1', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M10.24 3.957l-8.422 14.06a1.989 1.989 0 0 0 1.7 2.983h16.845a1.989 1.989 0 0 0 1.7 -2.983l-8.423 -14.06a1.989 1.989 0 0 0 -3.4 0z',
      key: 'svg-2',
    },
  ],
]);
