import createVueComponent from '../createVueComponent';
export default createVueComponent('hexagonal-pyramid', 'IconHexagonalPyramid', [
  [
    'path',
    {
      d: 'M11.162 2.457l-7.846 12.954a1.988 1.988 0 0 0 .267 2.483l2.527 2.523c.374 .373 .88 .583 1.408 .583h8.964c.528 0 1.034 -.21 1.408 -.583l2.527 -2.523a1.988 1.988 0 0 0 .267 -2.483l-7.846 -12.954a.996 .996 0 0 0 -1.676 0z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 2l-5 18.9', key: 'svg-1' }],
  ['path', { d: 'M12 2l5 18.9', key: 'svg-2' }],
]);
