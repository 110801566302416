import createVueComponent from '../createVueComponent';
export default createVueComponent('microphone-2-off', 'IconMicrophone2Off', [
  ['path', { d: 'M16.908 12.917a5 5 0 1 0 -5.827 -5.819', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M10.116 10.125l-6.529 7.46a2 2 0 1 0 2.827 2.83l7.461 -6.529',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
