import createVueComponent from '../createVueComponent';
export default createVueComponent('hexagon-filled', 'IconHexagonFilled', [
  [
    'path',
    {
      d: 'M10.425 1.414l-6.775 3.996a3.21 3.21 0 0 0 -1.65 2.807v7.285a3.226 3.226 0 0 0 1.678 2.826l6.695 4.237c1.034 .57 2.22 .57 3.2 .032l6.804 -4.302c.98 -.537 1.623 -1.618 1.623 -2.793v-7.284l-.005 -.204a3.223 3.223 0 0 0 -1.284 -2.39l-.107 -.075l-.007 -.007a1.074 1.074 0 0 0 -.181 -.133l-6.776 -3.995a3.33 3.33 0 0 0 -3.216 0z',
      'stroke-width': '0',
      fill: 'currentColor',
      key: 'svg-0',
    },
  ],
]);
