import createVueComponent from '../createVueComponent';
export default createVueComponent('discount-2', 'IconDiscount2', [
  ['path', { d: 'M9 15l6 -6', key: 'svg-0' }],
  [
    'circle',
    { cx: '9.5', cy: '9.5', r: '.5', fill: 'currentColor', key: 'svg-1' },
  ],
  [
    'circle',
    { cx: '14.5', cy: '14.5', r: '.5', fill: 'currentColor', key: 'svg-2' },
  ],
  [
    'path',
    {
      d: 'M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7a2.2 2.2 0 0 0 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1a2.2 2.2 0 0 0 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1',
      key: 'svg-3',
    },
  ],
]);
