import createVueComponent from '../createVueComponent';
export default createVueComponent('columns', 'IconColumns', [
  ['path', { d: 'M4 6l5.5 0', key: 'svg-0' }],
  ['path', { d: 'M4 10l5.5 0', key: 'svg-1' }],
  ['path', { d: 'M4 14l5.5 0', key: 'svg-2' }],
  ['path', { d: 'M4 18l5.5 0', key: 'svg-3' }],
  ['path', { d: 'M14.5 6l5.5 0', key: 'svg-4' }],
  ['path', { d: 'M14.5 10l5.5 0', key: 'svg-5' }],
  ['path', { d: 'M14.5 14l5.5 0', key: 'svg-6' }],
  ['path', { d: 'M14.5 18l5.5 0', key: 'svg-7' }],
]);
