import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'device-landline-phone',
  'IconDeviceLandlinePhone',
  [
    [
      'path',
      {
        d: 'M20 3h-2a2 2 0 0 0 -2 2v14a2 2 0 0 0 2 2h2a2 2 0 0 0 2 -2v-14a2 2 0 0 0 -2 -2z',
        key: 'svg-0',
      },
    ],
    [
      'path',
      { d: 'M16 4h-11a3 3 0 0 0 -3 3v10a3 3 0 0 0 3 3h11', key: 'svg-1' },
    ],
    ['path', { d: 'M12 8h-6v3h6z', key: 'svg-2' }],
    ['path', { d: 'M12 14v.01', key: 'svg-3' }],
    ['path', { d: 'M9 14v.01', key: 'svg-4' }],
    ['path', { d: 'M6 14v.01', key: 'svg-5' }],
    ['path', { d: 'M12 17v.01', key: 'svg-6' }],
    ['path', { d: 'M9 17v.01', key: 'svg-7' }],
    ['path', { d: 'M6 17v.01', key: 'svg-8' }],
  ],
);
