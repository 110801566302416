import createVueComponent from '../createVueComponent';
export default createVueComponent('border-style-2', 'IconBorderStyle2', [
  ['path', { d: 'M4 18v.01', key: 'svg-0' }],
  ['path', { d: 'M8 18v.01', key: 'svg-1' }],
  ['path', { d: 'M12 18v.01', key: 'svg-2' }],
  ['path', { d: 'M16 18v.01', key: 'svg-3' }],
  ['path', { d: 'M20 18v.01', key: 'svg-4' }],
  ['path', { d: 'M18 12h2', key: 'svg-5' }],
  ['path', { d: 'M11 12h2', key: 'svg-6' }],
  ['path', { d: 'M4 12h2', key: 'svg-7' }],
  ['path', { d: 'M4 6h16', key: 'svg-8' }],
]);
