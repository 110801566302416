import createVueComponent from '../createVueComponent';
export default createVueComponent('whirl', 'IconWhirl', [
  ['path', { d: 'M14 12a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-0' }],
  [
    'path',
    { d: 'M12 21c-3.314 0 -6 -2.462 -6 -5.5s2.686 -5.5 6 -5.5', key: 'svg-1' },
  ],
  [
    'path',
    { d: 'M21 12c0 3.314 -2.462 6 -5.5 6s-5.5 -2.686 -5.5 -6', key: 'svg-2' },
  ],
  [
    'path',
    { d: 'M12 14c3.314 0 6 -2.462 6 -5.5s-2.686 -5.5 -6 -5.5', key: 'svg-3' },
  ],
  [
    'path',
    { d: 'M14 12c0 -3.314 -2.462 -6 -5.5 -6s-5.5 2.686 -5.5 6', key: 'svg-4' },
  ],
]);
