import createVueComponent from '../createVueComponent';
export default createVueComponent('cherry', 'IconCherry', [
  [
    'path',
    {
      d: 'M7.5 16.5m-3.5 0a3.5 3.5 0 1 0 7 0a3.5 3.5 0 1 0 -7 0',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M17 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
  ['path', { d: 'M9 13c.366 -2 1.866 -3.873 4.5 -5.6', key: 'svg-2' }],
  ['path', { d: 'M17 15c-1.333 -2.333 -2.333 -5.333 -1 -9', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M5 6c3.667 -2.667 7.333 -2.667 11 0c-3.667 2.667 -7.333 2.667 -11 0',
      key: 'svg-4',
    },
  ],
]);
