import createVueComponent from '../createVueComponent';
export default createVueComponent('help-square', 'IconHelpSquare', [
  [
    'path',
    {
      d: 'M3 5a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-14z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 16v.01', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M12 13a2 2 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
      key: 'svg-2',
    },
  ],
]);
