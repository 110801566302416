import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-php', 'IconBrandPhp', [
  [
    'path',
    { d: 'M12 12m-10 0a10 9 0 1 0 20 0a10 9 0 1 0 -20 0', key: 'svg-0' },
  ],
  [
    'path',
    {
      d: 'M5.5 15l.395 -1.974l.605 -3.026h1.32a1 1 0 0 1 .986 1.164l-.167 1a1 1 0 0 1 -.986 .836h-1.653',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M15.5 15l.395 -1.974l.605 -3.026h1.32a1 1 0 0 1 .986 1.164l-.167 1a1 1 0 0 1 -.986 .836h-1.653',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M12 7.5l-1 5.5', key: 'svg-3' }],
  ['path', { d: 'M11.6 10h2.4l-.5 3', key: 'svg-4' }],
]);
