import createVueComponent from '../createVueComponent';
export default createVueComponent('photo-bolt', 'IconPhotoBolt', [
  ['path', { d: 'M15 8h.01', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M13.5 21h-7.5a3 3 0 0 1 -3 -3v-12a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v6.5',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 16l5 -5c.928 -.893 2.072 -.893 3 0l4 4', key: 'svg-2' }],
  ['path', { d: 'M14 14l1 -1c.669 -.643 1.45 -.823 2.18 -.54', key: 'svg-3' }],
  ['path', { d: 'M19 16l-2 3h4l-2 3', key: 'svg-4' }],
]);
