import createVueComponent from '../createVueComponent';
export default createVueComponent('toml', 'IconToml', [
  ['path', { d: 'M1.499 8h3', key: 'svg-0' }],
  ['path', { d: 'M2.999 8v8', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M8.5 8a1.5 1.5 0 0 1 1.5 1.5v5a1.5 1.5 0 0 1 -3 0v-5a1.5 1.5 0 0 1 1.5 -1.5z',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M13 16v-8l2 5l2 -5v8', key: 'svg-3' }],
  ['path', { d: 'M20 8v8h2.5', key: 'svg-4' }],
]);
