import createVueComponent from '../createVueComponent';
export default createVueComponent('ballpen-off', 'IconBallpenOff', [
  ['path', { d: 'M14 6l7 7l-2 2', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M10 10l-4.172 4.172a2.828 2.828 0 1 0 4 4l4.172 -4.172',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M16 12l4.414 -4.414a2 2 0 0 0 0 -2.829l-1.171 -1.171a2 2 0 0 0 -2.829 0l-4.414 4.414',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M4 20l1.768 -1.768', key: 'svg-3' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
