import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'message-circle-dollar',
  'IconMessageCircleDollar',
  [
    [
      'path',
      {
        d: 'M13.16 19.914a9.94 9.94 0 0 1 -5.46 -.914l-4.7 1l1.3 -3.9c-2.324 -3.437 -1.426 -7.872 2.1 -10.374c3.526 -2.501 8.59 -2.296 11.845 .48c1.384 1.181 2.26 2.672 2.603 4.243',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
        key: 'svg-1',
      },
    ],
    ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-2' }],
  ],
);
