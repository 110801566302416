import createVueComponent from '../createVueComponent';
export default createVueComponent('chart-arrows', 'IconChartArrows', [
  ['path', { d: 'M3 18l14 0', key: 'svg-0' }],
  ['path', { d: 'M9 9l3 3l-3 3', key: 'svg-1' }],
  ['path', { d: 'M14 15l3 3l-3 3', key: 'svg-2' }],
  ['path', { d: 'M3 3l0 18', key: 'svg-3' }],
  ['path', { d: 'M3 12l9 0', key: 'svg-4' }],
  ['path', { d: 'M18 3l3 3l-3 3', key: 'svg-5' }],
  ['path', { d: 'M3 6l18 0', key: 'svg-6' }],
]);
