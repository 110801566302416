import createVueComponent from '../createVueComponent';
export default createVueComponent('switch-2', 'IconSwitch2', [
  [
    'path',
    { d: 'M3 17h5l1.67 -2.386m3.66 -5.227l1.67 -2.387h6', key: 'svg-0' },
  ],
  ['path', { d: 'M18 4l3 3l-3 3', key: 'svg-1' }],
  ['path', { d: 'M3 7h5l7 10h6', key: 'svg-2' }],
  ['path', { d: 'M18 20l3 -3l-3 -3', key: 'svg-3' }],
]);
