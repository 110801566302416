import createVueComponent from '../createVueComponent';
export default createVueComponent('home-off', 'IconHomeOff', [
  [
    'path',
    { d: 'M5 12h-2l4.497 -4.497m2 -2l2.504 -2.504l9 9h-2', key: 'svg-0' },
  ],
  [
    'path',
    { d: 'M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2m0 -4v-3', key: 'svg-1' },
  ],
  ['path', { d: 'M9 21v-6a2 2 0 0 1 2 -2h2m2 2v6', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
