import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-slack', 'IconBrandSlack', [
  [
    'path',
    { d: 'M12 12v-6a2 2 0 0 1 4 0v6m0 -2a2 2 0 1 1 2 2h-6', key: 'svg-0' },
  ],
  [
    'path',
    { d: 'M12 12h6a2 2 0 0 1 0 4h-6m2 0a2 2 0 1 1 -2 2v-6', key: 'svg-1' },
  ],
  [
    'path',
    { d: 'M12 12v6a2 2 0 0 1 -4 0v-6m0 2a2 2 0 1 1 -2 -2h6', key: 'svg-2' },
  ],
  [
    'path',
    { d: 'M12 12h-6a2 2 0 0 1 0 -4h6m-2 0a2 2 0 1 1 2 -2v6', key: 'svg-3' },
  ],
]);
