import createVueComponent from '../createVueComponent';
export default createVueComponent('report-off', 'IconReportOff', [
  [
    'path',
    {
      d: 'M5.576 5.595a2 2 0 0 0 -.576 1.405v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2m0 -4v-8a2 2 0 0 0 -2 -2h-2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 5a2 2 0 0 1 2 -2h2a2 2 0 1 1 0 4h-2', key: 'svg-1' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
