import createVueComponent from '../createVueComponent';
export default createVueComponent('truck-loading', 'IconTruckLoading', [
  ['path', { d: 'M2 3h1a2 2 0 0 1 2 2v10a2 2 0 0 0 2 2h15', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M9 6m0 3a3 3 0 0 1 3 -3h4a3 3 0 0 1 3 3v2a3 3 0 0 1 -3 3h-4a3 3 0 0 1 -3 -3z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M9 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
  ['path', { d: 'M18 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-3' }],
]);
