import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-bing', 'IconBrandBing', [
  [
    'path',
    {
      d: 'M5 3l4 1.5v12l6 -2.5l-2 -1l-1 -4l7 2.5v4.5l-10 5l-4 -2z',
      key: 'svg-0',
    },
  ],
]);
