import createVueComponent from '../createVueComponent';
export default createVueComponent('ruler-2-off', 'IconRuler2Off', [
  [
    'path',
    { d: 'M12.03 7.97l4.97 -4.97l4 4l-5 5m-2 2l-7 7l-4 -4l7 -7', key: 'svg-0' },
  ],
  ['path', { d: 'M16 7l-1.5 -1.5', key: 'svg-1' }],
  ['path', { d: 'M10 13l-1.5 -1.5', key: 'svg-2' }],
  ['path', { d: 'M7 16l-1.5 -1.5', key: 'svg-3' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
