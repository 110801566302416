import createVueComponent from '../createVueComponent';
export default createVueComponent('topology-bus', 'IconTopologyBus', [
  ['path', { d: 'M14 10a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-0' }],
  ['path', { d: 'M6 10a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-1' }],
  ['path', { d: 'M22 10a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-2' }],
  ['path', { d: 'M2 16h20', key: 'svg-3' }],
  ['path', { d: 'M4 12v4', key: 'svg-4' }],
  ['path', { d: 'M12 12v4', key: 'svg-5' }],
  ['path', { d: 'M20 12v4', key: 'svg-6' }],
]);
