import createVueComponent from '../createVueComponent';
export default createVueComponent('hierarchy-3', 'IconHierarchy3', [
  ['path', { d: 'M12 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M8 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M12 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
  ['path', { d: 'M20 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-3' }],
  ['path', { d: 'M4 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-4' }],
  ['path', { d: 'M16 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-5' }],
  ['path', { d: 'M5 17l2 -3', key: 'svg-6' }],
  ['path', { d: 'M9 10l2 -3', key: 'svg-7' }],
  ['path', { d: 'M13 7l2 3', key: 'svg-8' }],
  ['path', { d: 'M17 14l2 3', key: 'svg-9' }],
  ['path', { d: 'M15 14l-2 3', key: 'svg-10' }],
  ['path', { d: 'M9 14l2 3', key: 'svg-11' }],
]);
