import createVueComponent from '../createVueComponent';
export default createVueComponent('reload', 'IconReload', [
  [
    'path',
    {
      d: 'M19.933 13.041a8 8 0 1 1 -9.925 -8.788c3.899 -1 7.935 1.007 9.425 4.747',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M20 4v5h-5', key: 'svg-1' }],
]);
