import createVueComponent from '../createVueComponent';
export default createVueComponent('fish-christianity', 'IconFishChristianity', [
  [
    'path',
    {
      d: 'M22 7s-5.646 10 -12.308 10c-3.226 .025 -6.194 -1.905 -7.692 -5c1.498 -3.095 4.466 -5.025 7.692 -5c6.662 0 12.308 10 12.308 10',
      key: 'svg-0',
    },
  ],
]);
