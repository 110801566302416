import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-nuxt', 'IconBrandNuxt', [
  [
    'path',
    {
      d: 'M12.146 8.583l-1.3 -2.09a1.046 1.046 0 0 0 -1.786 .017l-5.91 9.908a1.046 1.046 0 0 0 .897 1.582h3.913',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M20.043 18c.743 0 1.201 -.843 .82 -1.505l-4.044 -7.013a.936 .936 0 0 0 -1.638 0l-4.043 7.013c-.382 .662 .076 1.505 .819 1.505h8.086z',
      key: 'svg-1',
    },
  ],
]);
