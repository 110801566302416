import createVueComponent from '../createVueComponent';
export default createVueComponent('user-dollar', 'IconUserDollar', [
  ['path', { d: 'M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0', key: 'svg-0' }],
  ['path', { d: 'M6 21v-2a4 4 0 0 1 4 -4h3', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-3' }],
]);
