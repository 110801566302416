import createVueComponent from '../createVueComponent';
export default createVueComponent('xbox-b', 'IconXboxB', [
  [
    'path',
    {
      d: 'M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M13 12a2 2 0 1 1 0 4h-3v-4', key: 'svg-1' }],
  ['path', { d: 'M13 12h-3', key: 'svg-2' }],
  ['path', { d: 'M13 12a2 2 0 1 0 0 -4h-3v4', key: 'svg-3' }],
]);
