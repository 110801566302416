import createVueComponent from '../createVueComponent';
export default createVueComponent('brain', 'IconBrain', [
  [
    'path',
    {
      d: 'M15.5 13a3.5 3.5 0 0 0 -3.5 3.5v1a3.5 3.5 0 0 0 7 0v-1.8',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M8.5 13a3.5 3.5 0 0 1 3.5 3.5v1a3.5 3.5 0 0 1 -7 0v-1.8',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M17.5 16a3.5 3.5 0 0 0 0 -7h-.5', key: 'svg-2' }],
  ['path', { d: 'M19 9.3v-2.8a3.5 3.5 0 0 0 -7 0', key: 'svg-3' }],
  ['path', { d: 'M6.5 16a3.5 3.5 0 0 1 0 -7h.5', key: 'svg-4' }],
  ['path', { d: 'M5 9.3v-2.8a3.5 3.5 0 0 1 7 0v10', key: 'svg-5' }],
]);
