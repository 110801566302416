import createVueComponent from '../createVueComponent';
export default createVueComponent('marquee-off', 'IconMarqueeOff', [
  ['path', { d: 'M4 6c0 -.556 .227 -1.059 .593 -1.421', key: 'svg-0' }],
  ['path', { d: 'M9 4h1.5', key: 'svg-1' }],
  ['path', { d: 'M13.5 4h1.5', key: 'svg-2' }],
  ['path', { d: 'M18 4a2 2 0 0 1 2 2', key: 'svg-3' }],
  ['path', { d: 'M20 9v1.5', key: 'svg-4' }],
  ['path', { d: 'M20 13.5v1.5', key: 'svg-5' }],
  ['path', { d: 'M19.402 19.426a1.993 1.993 0 0 1 -1.402 .574', key: 'svg-6' }],
  ['path', { d: 'M15 20h-1.5', key: 'svg-7' }],
  ['path', { d: 'M10.5 20h-1.5', key: 'svg-8' }],
  ['path', { d: 'M6 20a2 2 0 0 1 -2 -2', key: 'svg-9' }],
  ['path', { d: 'M4 15v-1.5', key: 'svg-10' }],
  ['path', { d: 'M4 10.5v-1.5', key: 'svg-11' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-12' }],
]);
