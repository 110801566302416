import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'message-2-question',
  'IconMessage2Question',
  [
    ['path', { d: 'M8 9h8', key: 'svg-0' }],
    ['path', { d: 'M8 13h6', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M14.5 18.5l-2.5 2.5l-3 -3h-3a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v4.5',
        key: 'svg-2',
      },
    ],
    ['path', { d: 'M19 22v.01', key: 'svg-3' }],
    [
      'path',
      {
        d: 'M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
        key: 'svg-4',
      },
    ],
  ],
);
