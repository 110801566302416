import createVueComponent from '../createVueComponent';
export default createVueComponent('scuba-mask', 'IconScubaMask', [
  [
    'path',
    {
      d: 'M4 7h12a1 1 0 0 1 1 1v4.5a2.5 2.5 0 0 1 -2.5 2.5h-.5a2 2 0 0 1 -2 -2a2 2 0 1 0 -4 0a2 2 0 0 1 -2 2h-.5a2.5 2.5 0 0 1 -2.5 -2.5v-4.5a1 1 0 0 1 1 -1z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    { d: 'M10 17a2 2 0 0 0 2 2h3.5a5.5 5.5 0 0 0 5.5 -5.5v-9.5', key: 'svg-1' },
  ],
]);
