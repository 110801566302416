import createVueComponent from '../createVueComponent';
export default createVueComponent('barrier-block', 'IconBarrierBlock', [
  [
    'path',
    {
      d: 'M4 7m0 1a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1v7a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7 16v4', key: 'svg-1' }],
  ['path', { d: 'M7.5 16l9 -9', key: 'svg-2' }],
  ['path', { d: 'M13.5 16l6.5 -6.5', key: 'svg-3' }],
  ['path', { d: 'M4 13.5l6.5 -6.5', key: 'svg-4' }],
  ['path', { d: 'M17 16v4', key: 'svg-5' }],
  ['path', { d: 'M5 20h4', key: 'svg-6' }],
  ['path', { d: 'M15 20h4', key: 'svg-7' }],
  ['path', { d: 'M17 7v-2', key: 'svg-8' }],
  ['path', { d: 'M7 7v-2', key: 'svg-9' }],
]);
