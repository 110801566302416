import createVueComponent from '../createVueComponent';
export default createVueComponent('seo', 'IconSeo', [
  [
    'path',
    {
      d: 'M7 8h-3a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-3',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M14 16h-4v-8h4', key: 'svg-1' }],
  ['path', { d: 'M11 12h2', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M17 8m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
      key: 'svg-3',
    },
  ],
]);
