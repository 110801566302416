import createVueComponent from '../createVueComponent';
export default createVueComponent('trending-down-3', 'IconTrendingDown3', [
  [
    'path',
    {
      d: 'M3 6h2.397a5 5 0 0 1 4.096 2.133l4.014 5.734a5 5 0 0 0 4.096 2.133h3.397',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M18 19l3 -3l-3 -3', key: 'svg-1' }],
]);
