import createVueComponent from '../createVueComponent';
export default createVueComponent('mail-exclamation', 'IconMailExclamation', [
  [
    'path',
    {
      d: 'M15 19h-10a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v5.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 7l9 6l9 -6', key: 'svg-1' }],
  ['path', { d: 'M19 16v3', key: 'svg-2' }],
  ['path', { d: 'M19 22v.01', key: 'svg-3' }],
]);
