import createVueComponent from '../createVueComponent';
export default createVueComponent('antenna-off', 'IconAntennaOff', [
  ['path', { d: 'M20 4v8', key: 'svg-0' }],
  ['path', { d: 'M16 4.5v7', key: 'svg-1' }],
  ['path', { d: 'M12 5v3m0 4v9', key: 'svg-2' }],
  ['path', { d: 'M8 8v2.5', key: 'svg-3' }],
  ['path', { d: 'M4 6v4', key: 'svg-4' }],
  ['path', { d: 'M20 8h-8m-4 0h-4', key: 'svg-5' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
]);
