import createVueComponent from '../createVueComponent';
export default createVueComponent('lamp-off', 'IconLampOff', [
  ['path', { d: 'M9 20h6', key: 'svg-0' }],
  ['path', { d: 'M12 20v-8', key: 'svg-1' }],
  [
    'path',
    { d: 'M7.325 7.35l-2.325 4.65h7m4 0h3l-4 -8h-6l-.338 .676', key: 'svg-2' },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
