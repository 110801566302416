import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-paypay', 'IconBrandPaypay', [
  ['path', { d: 'M6.375 21l3.938 -13.838', key: 'svg-0' }],
  ['path', { d: 'M3 6c16.731 0 21.231 9.881 4.5 11', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M21 19v-14a2 2 0 0 0 -2 -2h-14a2 2 0 0 0 -2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2 -2z',
      key: 'svg-2',
    },
  ],
]);
