import createVueComponent from '../createVueComponent';
export default createVueComponent('device-laptop-off', 'IconDeviceLaptopOff', [
  ['path', { d: 'M3 19h16', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M10 6h8a1 1 0 0 1 1 1v8m-3 1h-10a1 1 0 0 1 -1 -1v-8a1 1 0 0 1 1 -1',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
