import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-mcdonalds', 'IconBrandMcdonalds', [
  [
    'path',
    {
      d: 'M20 20c0 -3.952 -.966 -16 -4.038 -16s-3.962 9.087 -3.962 14.756c0 -5.669 -.896 -14.756 -3.962 -14.756c-3.065 0 -4.038 12.048 -4.038 16',
      key: 'svg-0',
    },
  ],
]);
