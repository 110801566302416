import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'gender-genderfluid',
  'IconGenderGenderfluid',
  [
    [
      'path',
      { d: 'M10 15.464a4 4 0 1 0 4 -6.928a4 4 0 0 0 -4 6.928z', key: 'svg-0' },
    ],
    ['path', { d: 'M15.464 14l3 -5.196', key: 'svg-1' }],
    ['path', { d: 'M5.536 15.195l3 -5.196', key: 'svg-2' }],
    ['path', { d: 'M12 12h.01', key: 'svg-3' }],
    ['path', { d: 'M9 9l-6 -6', key: 'svg-4' }],
    ['path', { d: 'M5.5 8.5l3 -3', key: 'svg-5' }],
    ['path', { d: 'M21 21l-6 -6', key: 'svg-6' }],
    ['path', { d: 'M17 20l3 -3', key: 'svg-7' }],
    ['path', { d: 'M3 7v-4h4', key: 'svg-8' }],
  ],
);
