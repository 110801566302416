import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-discord', 'IconBrandDiscord', [
  ['path', { d: 'M8 12a1 1 0 1 0 2 0a1 1 0 0 0 -2 0', key: 'svg-0' }],
  ['path', { d: 'M14 12a1 1 0 1 0 2 0a1 1 0 0 0 -2 0', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M15.5 17c0 1 1.5 3 2 3c1.5 0 2.833 -1.667 3.5 -3c.667 -1.667 .5 -5.833 -1.5 -11.5c-1.457 -1.015 -3 -1.34 -4.5 -1.5l-.972 1.923a11.913 11.913 0 0 0 -4.053 0l-.975 -1.923c-1.5 .16 -3.043 .485 -4.5 1.5c-2 5.667 -2.167 9.833 -1.5 11.5c.667 1.333 2 3 3.5 3c.5 0 2 -2 2 -3',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M7 16.5c3.5 1 6.5 1 10 0', key: 'svg-3' }],
]);
