import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-leetcode', 'IconBrandLeetcode', [
  ['path', { d: 'M12 13h7.5', key: 'svg-0' }],
  ['path', { d: 'M9.424 7.268l4.999 -4.999', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M16.633 16.644l-2.402 2.415a3.189 3.189 0 0 1 -4.524 0l-3.77 -3.787a3.223 3.223 0 0 1 0 -4.544l3.77 -3.787a3.189 3.189 0 0 1 4.524 0l2.302 2.313',
      key: 'svg-2',
    },
  ],
]);
