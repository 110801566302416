import createVueComponent from '../createVueComponent';
export default createVueComponent('h-6', 'IconH6', [
  ['path', { d: 'M19 14a2 2 0 1 0 0 4a2 2 0 0 0 0 -4z', key: 'svg-0' }],
  ['path', { d: 'M21 12a2 2 0 1 0 -4 0v4', key: 'svg-1' }],
  ['path', { d: 'M4 6v12', key: 'svg-2' }],
  ['path', { d: 'M12 6v12', key: 'svg-3' }],
  ['path', { d: 'M11 18h2', key: 'svg-4' }],
  ['path', { d: 'M3 18h2', key: 'svg-5' }],
  ['path', { d: 'M4 12h8', key: 'svg-6' }],
  ['path', { d: 'M3 6h2', key: 'svg-7' }],
  ['path', { d: 'M11 6h2', key: 'svg-8' }],
]);
