import createVueComponent from '../createVueComponent';
export default createVueComponent('wheelchair-off', 'IconWheelchairOff', [
  ['path', { d: 'M8 16m-5 0a5 5 0 1 0 10 0a5 5 0 1 0 -10 0', key: 'svg-0' }],
  ['path', { d: 'M17.582 17.59a2 2 0 0 0 2.833 2.824', key: 'svg-1' }],
  ['path', { d: 'M14 14h-1.4', key: 'svg-2' }],
  ['path', { d: 'M6 6v5', key: 'svg-3' }],
  ['path', { d: 'M6 8h2m4 0h5', key: 'svg-4' }],
  ['path', { d: 'M15 8v3', key: 'svg-5' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
]);
