import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-uber', 'IconBrandUber', [
  ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M9 9m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 12h6', key: 'svg-2' }],
]);
