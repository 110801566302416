import createVueComponent from '../createVueComponent';
export default createVueComponent('zoom-replace', 'IconZoomReplace', [
  ['path', { d: 'M21 21l-6 -6', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M3.291 8a7 7 0 0 1 5.077 -4.806a7.021 7.021 0 0 1 8.242 4.403',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M17 4v4h-4', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M16.705 12a7 7 0 0 1 -5.074 4.798a7.021 7.021 0 0 1 -8.241 -4.403',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M3 16v-4h4', key: 'svg-4' }],
]);
