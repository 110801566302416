import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'arrow-big-down-line',
  'IconArrowBigDownLine',
  [
    [
      'path',
      {
        d: 'M15 12h3.586a1 1 0 0 1 .707 1.707l-6.586 6.586a1 1 0 0 1 -1.414 0l-6.586 -6.586a1 1 0 0 1 .707 -1.707h3.586v-6h6v6z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M15 3h-6', key: 'svg-1' }],
  ],
);
