import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-twitch', 'IconBrandTwitch', [
  [
    'path',
    {
      d: 'M4 5v11a1 1 0 0 0 1 1h2v4l4 -4h5.584c.266 0 .52 -.105 .707 -.293l2.415 -2.414c.187 -.188 .293 -.442 .293 -.708v-8.585a1 1 0 0 0 -1 -1h-14a1 1 0 0 0 -1 1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 8l0 4', key: 'svg-1' }],
  ['path', { d: 'M12 8l0 4', key: 'svg-2' }],
]);
