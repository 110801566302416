import createVueComponent from '../createVueComponent';
export default createVueComponent('spider', 'IconSpider', [
  ['path', { d: 'M5 4v2l5 5', key: 'svg-0' }],
  ['path', { d: 'M2.5 9.5l1.5 1.5h6', key: 'svg-1' }],
  ['path', { d: 'M4 19v-2l6 -6', key: 'svg-2' }],
  ['path', { d: 'M19 4v2l-5 5', key: 'svg-3' }],
  ['path', { d: 'M21.5 9.5l-1.5 1.5h-6', key: 'svg-4' }],
  ['path', { d: 'M20 19v-2l-6 -6', key: 'svg-5' }],
  ['path', { d: 'M12 15m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0', key: 'svg-6' }],
  ['path', { d: 'M12 9m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-7' }],
]);
