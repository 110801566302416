import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'device-mobile-charging',
  'IconDeviceMobileCharging',
  [
    [
      'path',
      {
        d: 'M6 3m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M11 4h2', key: 'svg-1' }],
    ['path', { d: 'M12 9.5l-1 2.5h2l-1 2.5', key: 'svg-2' }],
  ],
);
