import createVueComponent from '../createVueComponent';
export default createVueComponent('confetti', 'IconConfetti', [
  ['path', { d: 'M4 5h2', key: 'svg-0' }],
  ['path', { d: 'M5 4v2', key: 'svg-1' }],
  ['path', { d: 'M11.5 4l-.5 2', key: 'svg-2' }],
  ['path', { d: 'M18 5h2', key: 'svg-3' }],
  ['path', { d: 'M19 4v2', key: 'svg-4' }],
  ['path', { d: 'M15 9l-1 1', key: 'svg-5' }],
  ['path', { d: 'M18 13l2 -.5', key: 'svg-6' }],
  ['path', { d: 'M18 19h2', key: 'svg-7' }],
  ['path', { d: 'M19 18v2', key: 'svg-8' }],
  [
    'path',
    {
      d: 'M14 16.518l-6.518 -6.518l-4.39 9.58a1 1 0 0 0 1.329 1.329l9.579 -4.39z',
      key: 'svg-9',
    },
  ],
]);
