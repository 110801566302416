import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'device-ipad-horizontal-pin',
  'IconDeviceIpadHorizontalPin',
  [
    [
      'path',
      {
        d: 'M12 20h-7a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v5',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9 17h3', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M21.121 20.121a3 3 0 1 0 -4.242 0c.418 .419 1.125 1.045 2.121 1.879c1.051 -.89 1.759 -1.516 2.121 -1.879z',
        key: 'svg-2',
      },
    ],
    ['path', { d: 'M19 18v.01', key: 'svg-3' }],
  ],
);
