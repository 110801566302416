import createVueComponent from '../createVueComponent';
export default createVueComponent('texture', 'IconTexture', [
  ['path', { d: 'M6 3l-3 3', key: 'svg-0' }],
  ['path', { d: 'M21 18l-3 3', key: 'svg-1' }],
  ['path', { d: 'M11 3l-8 8', key: 'svg-2' }],
  ['path', { d: 'M16 3l-13 13', key: 'svg-3' }],
  ['path', { d: 'M21 3l-18 18', key: 'svg-4' }],
  ['path', { d: 'M21 8l-13 13', key: 'svg-5' }],
  ['path', { d: 'M21 13l-8 8', key: 'svg-6' }],
]);
