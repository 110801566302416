import createVueComponent from '../createVueComponent';
export default createVueComponent('row-insert-bottom', 'IconRowInsertBottom', [
  [
    'path',
    {
      d: 'M20 6v4a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 15l0 4', key: 'svg-1' }],
  ['path', { d: 'M14 17l-4 0', key: 'svg-2' }],
]);
