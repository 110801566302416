import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'message-circle-pause',
  'IconMessageCirclePause',
  [
    [
      'path',
      {
        d: 'M12.989 19.932a9.93 9.93 0 0 1 -5.289 -.932l-4.7 1l1.3 -3.9c-2.324 -3.437 -1.426 -7.872 2.1 -10.374c3.526 -2.501 8.59 -2.296 11.845 .48c2.131 1.818 3.056 4.37 2.692 6.824',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M17 17v5', key: 'svg-1' }],
    ['path', { d: 'M21 17v5', key: 'svg-2' }],
  ],
);
