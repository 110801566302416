import createVueComponent from '../createVueComponent';
export default createVueComponent('mood-share', 'IconMoodShare', [
  ['path', { d: 'M20.942 13.018a9 9 0 1 0 -8.942 7.982', key: 'svg-0' }],
  ['path', { d: 'M9 10h.01', key: 'svg-1' }],
  ['path', { d: 'M15 10h.01', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M9.5 15c.658 .672 1.56 1 2.5 1c.213 0 .424 -.017 .63 -.05',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M16 22l5 -5', key: 'svg-4' }],
  ['path', { d: 'M21 21.5v-4.5h-4.5', key: 'svg-5' }],
]);
