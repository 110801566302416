import createVueComponent from '../createVueComponent';
export default createVueComponent('dimensions', 'IconDimensions', [
  ['path', { d: 'M3 5h11', key: 'svg-0' }],
  ['path', { d: 'M12 7l2 -2l-2 -2', key: 'svg-1' }],
  ['path', { d: 'M5 3l-2 2l2 2', key: 'svg-2' }],
  ['path', { d: 'M19 10v11', key: 'svg-3' }],
  ['path', { d: 'M17 19l2 2l2 -2', key: 'svg-4' }],
  ['path', { d: 'M21 12l-2 -2l-2 2', key: 'svg-5' }],
  [
    'path',
    {
      d: 'M3 10m0 2a2 2 0 0 1 2 -2h7a2 2 0 0 1 2 2v7a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2z',
      key: 'svg-6',
    },
  ],
]);
