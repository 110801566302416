import createVueComponent from '../createVueComponent';
export default createVueComponent('badge-vr', 'IconBadgeVr', [
  [
    'path',
    {
      d: 'M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    { d: 'M14 12h1.5a1.5 1.5 0 0 0 0 -3h-1.5v6m3 0l-2 -3', key: 'svg-1' },
  ],
  ['path', { d: 'M7 9l2 6l2 -6', key: 'svg-2' }],
]);
