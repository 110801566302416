import createVueComponent from '../createVueComponent';
export default createVueComponent('home-exclamation', 'IconHomeExclamation', [
  ['path', { d: 'M21 12l-9 -9l-9 9h2v7a2 2 0 0 0 2 2h8', key: 'svg-0' }],
  [
    'path',
    { d: 'M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 1.857 1.257', key: 'svg-1' },
  ],
  ['path', { d: 'M19 16v3', key: 'svg-2' }],
  ['path', { d: 'M19 22v.01', key: 'svg-3' }],
]);
