import createVueComponent from '../createVueComponent';
export default createVueComponent('share-3', 'IconShare3', [
  [
    'path',
    {
      d: 'M13 4v4c-6.575 1.028 -9.02 6.788 -10 12c-.037 .206 5.384 -5.962 10 -6v4l8 -7l-8 -7z',
      key: 'svg-0',
    },
  ],
]);
