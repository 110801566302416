import createVueComponent from '../createVueComponent';
export default createVueComponent('letter-case', 'IconLetterCase', [
  [
    'path',
    {
      d: 'M17.5 15.5m-3.5 0a3.5 3.5 0 1 0 7 0a3.5 3.5 0 1 0 -7 0',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 19v-10.5a3.5 3.5 0 0 1 7 0v10.5', key: 'svg-1' }],
  ['path', { d: 'M3 13h7', key: 'svg-2' }],
  ['path', { d: 'M21 12v7', key: 'svg-3' }],
]);
