import createVueComponent from '../createVueComponent';
export default createVueComponent('cloud-cancel', 'IconCloudCancel', [
  [
    'path',
    {
      d: 'M12 18.004h-5.343c-2.572 -.004 -4.657 -2.011 -4.657 -4.487c0 -2.475 2.085 -4.482 4.657 -4.482c.393 -1.762 1.794 -3.2 3.675 -3.773c1.88 -.572 3.956 -.193 5.444 1c1.488 1.19 2.162 3.007 1.77 4.769h.99a3.45 3.45 0 0 1 2.756 1.373',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 19m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
  ['path', { d: 'M17 21l4 -4', key: 'svg-2' }],
]);
