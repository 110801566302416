import createVueComponent from '../createVueComponent';
export default createVueComponent('calendar-time', 'IconCalendarTime', [
  [
    'path',
    {
      d: 'M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0', key: 'svg-1' }],
  ['path', { d: 'M15 3v4', key: 'svg-2' }],
  ['path', { d: 'M7 3v4', key: 'svg-3' }],
  ['path', { d: 'M3 11h16', key: 'svg-4' }],
  ['path', { d: 'M18 16.496v1.504l1 1', key: 'svg-5' }],
]);
