import createVueComponent from '../createVueComponent';
export default createVueComponent('heart-off', 'IconHeartOff', [
  ['path', { d: 'M3 3l18 18', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M19.5 12.572l-1.5 1.428m-2 2l-4 4l-7.5 -7.428a5 5 0 0 1 -1.288 -5.068a4.976 4.976 0 0 1 1.788 -2.504m3 -1c1.56 0 3.05 .727 4 2a5 5 0 1 1 7.5 6.572',
      key: 'svg-1',
    },
  ],
]);
