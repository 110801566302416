import createVueComponent from '../createVueComponent';
export default createVueComponent('lamp-2', 'IconLamp2', [
  ['path', { d: 'M5 21h9', key: 'svg-0' }],
  ['path', { d: 'M10 21l-7 -8l8.5 -5.5', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M13 14c-2.148 -2.148 -2.148 -5.852 0 -8c2.088 -2.088 5.842 -1.972 8 0l-8 8z',
      key: 'svg-2',
    },
  ],
  [
    'path',
    {
      d: 'M11.742 7.574l-1.156 -1.156a2 2 0 0 1 2.828 -2.829l1.144 1.144',
      key: 'svg-3',
    },
  ],
  [
    'path',
    {
      d: 'M15.5 12l.208 .274a2.527 2.527 0 0 0 3.556 0c.939 -.933 .98 -2.42 .122 -3.4l-.366 -.369',
      key: 'svg-4',
    },
  ],
]);
