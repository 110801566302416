import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'device-watch-search',
  'IconDeviceWatchSearch',
  [
    [
      'path',
      {
        d: 'M11 18h-2a3 3 0 0 1 -3 -3v-6a3 3 0 0 1 3 -3h6a3 3 0 0 1 3 3v2',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
    ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-2' }],
    ['path', { d: 'M9 18v3h3', key: 'svg-3' }],
    ['path', { d: 'M9 6v-3h6v3', key: 'svg-4' }],
  ],
);
