import createVueComponent from '../createVueComponent';
export default createVueComponent('details-off', 'IconDetailsOff', [
  ['path', { d: 'M5 19h14', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M20.986 16.984a2 2 0 0 0 -.146 -.734l-7.1 -12.25a2 2 0 0 0 -3.5 0l-.821 1.417m-1.469 2.534l-4.81 8.299a2 2 0 0 0 1.75 2.75',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 3v5m0 4v7', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
