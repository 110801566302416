import createVueComponent from '../createVueComponent';
export default createVueComponent('hearts', 'IconHearts', [
  [
    'path',
    {
      d: 'M14.017 18l-2.017 2l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 0 1 8.153 5.784',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M15.99 20l4.197 -4.223a2.81 2.81 0 0 0 0 -3.948a2.747 2.747 0 0 0 -3.91 -.007l-.28 .282l-.279 -.283a2.747 2.747 0 0 0 -3.91 -.007a2.81 2.81 0 0 0 -.007 3.948l4.182 4.238z',
      key: 'svg-1',
    },
  ],
]);
