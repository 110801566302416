import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-planetscale', 'IconBrandPlanetscale', [
  [
    'path',
    { d: 'M20.993 11.63a9 9 0 0 1 -9.362 9.362l9.362 -9.362z', key: 'svg-0' },
  ],
  [
    'path',
    {
      d: 'M12 3a9.001 9.001 0 0 1 8.166 5.211l-11.955 11.955a9 9 0 0 1 3.789 -17.166z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 12l-6 6', key: 'svg-2' }],
]);
