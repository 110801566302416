import createVueComponent from '../createVueComponent';
export default createVueComponent('chart-bar-off', 'IconChartBarOff', [
  [
    'path',
    {
      d: 'M3 12m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v6a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M12 8h2a1 1 0 0 1 1 1v2m0 4v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1v-10',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M15 11v-6a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v12m-1 3h-4a1 1 0 0 1 -1 -1v-4',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M4 20h14', key: 'svg-3' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
