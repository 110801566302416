import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'sort-descending-numbers',
  'IconSortDescendingNumbers',
  [
    ['path', { d: 'M4 15l3 3l3 -3', key: 'svg-0' }],
    ['path', { d: 'M7 6v12', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M17 14a2 2 0 0 1 2 2v3a2 2 0 1 1 -4 0v-3a2 2 0 0 1 2 -2z',
        key: 'svg-2',
      },
    ],
    ['path', { d: 'M17 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-3' }],
    ['path', { d: 'M19 5v3a2 2 0 0 1 -2 2h-1.5', key: 'svg-4' }],
  ],
);
