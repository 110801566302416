import createVueComponent from '../createVueComponent';
export default createVueComponent('camera-search', 'IconCameraSearch', [
  [
    'path',
    {
      d: 'M11.5 20h-6.5a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v2.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M14.757 11.815a3 3 0 1 0 -3.431 4.109', key: 'svg-1' }],
  ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-2' }],
  ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-3' }],
]);
