import createVueComponent from '../createVueComponent';
export default createVueComponent('medal-2', 'IconMedal2', [
  ['path', { d: 'M9 3h6l3 7l-6 2l-6 -2z', key: 'svg-0' }],
  ['path', { d: 'M12 12l-3 -9', key: 'svg-1' }],
  ['path', { d: 'M15 11l-3 -8', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M12 19.5l-3 1.5l.5 -3.5l-2 -2l3 -.5l1.5 -3l1.5 3l3 .5l-2 2l.5 3.5z',
      key: 'svg-3',
    },
  ],
]);
