import createVueComponent from '../createVueComponent';
export default createVueComponent('user-pin', 'IconUserPin', [
  ['path', { d: 'M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0', key: 'svg-0' }],
  ['path', { d: 'M6 21v-2a4 4 0 0 1 4 -4h2.5', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M21.121 20.121a3 3 0 1 0 -4.242 0c.418 .419 1.125 1.045 2.121 1.879c1.051 -.89 1.759 -1.516 2.121 -1.879z',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M19 18v.01', key: 'svg-3' }],
]);
