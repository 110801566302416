import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'playstation-triangle',
  'IconPlaystationTriangle',
  [
    [
      'path',
      {
        d: 'M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M7.5 15h9l-4.5 -8z', key: 'svg-1' }],
  ],
);
