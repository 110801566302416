import createVueComponent from '../createVueComponent';
export default createVueComponent('satellite', 'IconSatellite', [
  [
    'path',
    {
      d: 'M3.707 6.293l2.586 -2.586a1 1 0 0 1 1.414 0l5.586 5.586a1 1 0 0 1 0 1.414l-2.586 2.586a1 1 0 0 1 -1.414 0l-5.586 -5.586a1 1 0 0 1 0 -1.414z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M6 10l-3 3l3 3l3 -3', key: 'svg-1' }],
  ['path', { d: 'M10 6l3 -3l3 3l-3 3', key: 'svg-2' }],
  ['path', { d: 'M12 12l1.5 1.5', key: 'svg-3' }],
  ['path', { d: 'M14.5 17a2.5 2.5 0 0 0 2.5 -2.5', key: 'svg-4' }],
  ['path', { d: 'M15 21a6 6 0 0 0 6 -6', key: 'svg-5' }],
]);
