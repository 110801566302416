import createVueComponent from '../createVueComponent';
export default createVueComponent('backpack-off', 'IconBackpackOff', [
  [
    'path',
    {
      d: 'M10 6h3a6 6 0 0 1 6 6v3m-.129 3.872a3 3 0 0 1 -2.871 2.128h-8a3 3 0 0 1 -3 -3v-6a5.99 5.99 0 0 1 2.285 -4.712',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 6v-1a2 2 0 1 1 4 0v1', key: 'svg-1' }],
  ['path', { d: 'M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
