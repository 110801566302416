import createVueComponent from '../createVueComponent';
export default createVueComponent('map-pins', 'IconMapPins', [
  [
    'path',
    {
      d: 'M10.828 9.828a4 4 0 1 0 -5.656 0l2.828 2.829l2.828 -2.829z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8 7l0 .01', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M18.828 17.828a4 4 0 1 0 -5.656 0l2.828 2.829l2.828 -2.829z',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M16 15l0 .01', key: 'svg-3' }],
]);
