import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'arrows-double-se-nw',
  'IconArrowsDoubleSeNw',
  [
    ['path', { d: 'M3 10l11 11', key: 'svg-0' }],
    ['path', { d: 'M14 17v4h-4', key: 'svg-1' }],
    ['path', { d: 'M14 3h-4v4', key: 'svg-2' }],
    ['path', { d: 'M21 14l-11 -11', key: 'svg-3' }],
  ],
);
