import createVueComponent from '../createVueComponent';
export default createVueComponent('list-details', 'IconListDetails', [
  ['path', { d: 'M13 5h8', key: 'svg-0' }],
  ['path', { d: 'M13 9h5', key: 'svg-1' }],
  ['path', { d: 'M13 15h8', key: 'svg-2' }],
  ['path', { d: 'M13 19h5', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M3 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z',
      key: 'svg-4',
    },
  ],
  [
    'path',
    {
      d: 'M3 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z',
      key: 'svg-5',
    },
  ],
]);
