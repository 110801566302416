import createVueComponent from '../createVueComponent';
export default createVueComponent('shopping-bag', 'IconShoppingBag', [
  [
    'path',
    {
      d: 'M6.331 8h11.339a2 2 0 0 1 1.977 2.304l-1.255 8.152a3 3 0 0 1 -2.966 2.544h-6.852a3 3 0 0 1 -2.965 -2.544l-1.255 -8.152a2 2 0 0 1 1.977 -2.304z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 11v-5a3 3 0 0 1 6 0v5', key: 'svg-1' }],
]);
