import createVueComponent from '../createVueComponent';
export default createVueComponent('toilet-paper-off', 'IconToiletPaperOff', [
  [
    'path',
    {
      d: 'M4.27 4.28c-.768 1.27 -1.27 3.359 -1.27 5.72c0 3.866 1.343 7 3 7s3 -3.134 3 -7c0 -.34 -.01 -.672 -.03 -1',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M21 10c0 -3.866 -1.343 -7 -3 -7', key: 'svg-1' }],
  ['path', { d: 'M7 3h11', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M21 10v7m-1.513 2.496l-1.487 -.496l-3 2l-3 -3l-3 2v-10',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M6 10h.01', key: 'svg-4' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
