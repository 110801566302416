import createVueComponent from '../createVueComponent';
export default createVueComponent('temperature-off', 'IconTemperatureOff', [
  [
    'path',
    {
      d: 'M10 10v3.5a4 4 0 1 0 5.836 2.33m-1.836 -5.83v-5a2 2 0 1 0 -4 0v1',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M13 9h1', key: 'svg-1' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
