import createVueComponent from '../createVueComponent';
export default createVueComponent('bread', 'IconBread', [
  [
    'path',
    {
      d: 'M17 5a3 3 0 0 1 2 5.235v6.765a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-6.764a3 3 0 0 1 1.824 -5.231l.176 0h10z',
      key: 'svg-0',
    },
  ],
]);
