import createVueComponent from '../createVueComponent';
export default createVueComponent('curly-loop', 'IconCurlyLoop', [
  [
    'path',
    {
      d: 'M21 8c-4 0 -7 2 -7 5a3 3 0 0 0 6 0c0 -3 -2.5 -5 -8 -5s-8 2 -8 5a3 3 0 0 0 6 0c0 -3 -3 -5 -7 -5',
      key: 'svg-0',
    },
  ],
]);
