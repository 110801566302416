import createVueComponent from '../createVueComponent';
export default createVueComponent('float-center', 'IconFloatCenter', [
  [
    'path',
    {
      d: 'M9 5m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 7l1 0', key: 'svg-1' }],
  ['path', { d: 'M4 11l1 0', key: 'svg-2' }],
  ['path', { d: 'M19 7l1 0', key: 'svg-3' }],
  ['path', { d: 'M19 11l1 0', key: 'svg-4' }],
  ['path', { d: 'M4 15l16 0', key: 'svg-5' }],
  ['path', { d: 'M4 19l16 0', key: 'svg-6' }],
]);
