import createVueComponent from '../createVueComponent';
export default createVueComponent('chart-pie-filled', 'IconChartPieFilled', [
  [
    'path',
    {
      d: 'M9.883 2.207a1.9 1.9 0 0 1 2.087 1.522l.025 .167l.005 .104v7a1 1 0 0 0 .883 .993l.117 .007h6.8a2 2 0 0 1 2 2a1 1 0 0 1 -.026 .226a10 10 0 1 1 -12.27 -11.933l.27 -.067l.11 -.02z',
      'stroke-width': '0',
      fill: 'currentColor',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M14 3.5v5.5a1 1 0 0 0 1 1h5.5a1 1 0 0 0 .943 -1.332a10 10 0 0 0 -6.11 -6.111a1 1 0 0 0 -1.333 .943z',
      'stroke-width': '0',
      fill: 'currentColor',
      key: 'svg-1',
    },
  ],
]);
