import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-ted', 'IconBrandTed', [
  ['path', { d: 'M2 8h4', key: 'svg-0' }],
  ['path', { d: 'M4 8v8', key: 'svg-1' }],
  ['path', { d: 'M13 8h-4v8h4', key: 'svg-2' }],
  ['path', { d: 'M9 12h2.5', key: 'svg-3' }],
  [
    'path',
    { d: 'M16 8v8h2a3 3 0 0 0 3 -3v-2a3 3 0 0 0 -3 -3h-2z', key: 'svg-4' },
  ],
]);
