import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-whatsapp', 'IconBrandWhatsapp', [
  ['path', { d: 'M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M9 10a.5 .5 0 0 0 1 0v-1a.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a.5 .5 0 0 0 0 -1h-1a.5 .5 0 0 0 0 1',
      key: 'svg-1',
    },
  ],
]);
