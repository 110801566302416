import createVueComponent from '../createVueComponent';
export default createVueComponent('nfc-off', 'IconNfcOff', [
  ['path', { d: 'M11 20a3 3 0 0 1 -3 -3v-9', key: 'svg-0' }],
  ['path', { d: 'M13 4a3 3 0 0 1 3 3v5m0 4v2l-5 -5', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M8 4h9a3 3 0 0 1 3 3v9m-.873 3.116a2.99 2.99 0 0 1 -2.127 .884h-10a3 3 0 0 1 -3 -3v-10c0 -.83 .337 -1.582 .882 -2.125',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
