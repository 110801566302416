import createVueComponent from '../createVueComponent';
export default createVueComponent('unlink', 'IconUnlink', [
  ['path', { d: 'M17 22v-2', key: 'svg-0' }],
  ['path', { d: 'M9 15l6 -6', key: 'svg-1' }],
  [
    'path',
    { d: 'M11 6l.463 -.536a5 5 0 0 1 7.071 7.072l-.534 .464', key: 'svg-2' },
  ],
  [
    'path',
    {
      d: 'M13 18l-.397 .534a5.068 5.068 0 0 1 -7.127 0a4.972 4.972 0 0 1 0 -7.071l.524 -.463',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M20 17h2', key: 'svg-4' }],
  ['path', { d: 'M2 7h2', key: 'svg-5' }],
  ['path', { d: 'M7 2v2', key: 'svg-6' }],
]);
