import createVueComponent from '../createVueComponent';
export default createVueComponent('arrows-join-2', 'IconArrowsJoin2', [
  [
    'path',
    {
      d: 'M3 7h1.948c1.913 0 3.705 .933 4.802 2.5a5.861 5.861 0 0 0 4.802 2.5h6.448',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M3 17h1.95a5.854 5.854 0 0 0 4.798 -2.5a5.854 5.854 0 0 1 4.798 -2.5h5.454',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M18 15l3 -3l-3 -3', key: 'svg-2' }],
]);
