import createVueComponent from '../createVueComponent';
export default createVueComponent('lock-open', 'IconLockOpen', [
  [
    'path',
    {
      d: 'M5 11m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-1' }],
  ['path', { d: 'M8 11v-5a4 4 0 0 1 8 0', key: 'svg-2' }],
]);
