import createVueComponent from '../createVueComponent';
export default createVueComponent('tic-tac', 'IconTicTac', [
  ['path', { d: 'M6 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M3 12h18', key: 'svg-1' }],
  ['path', { d: 'M12 3v18', key: 'svg-2' }],
  ['path', { d: 'M4 16l4 4', key: 'svg-3' }],
  ['path', { d: 'M4 20l4 -4', key: 'svg-4' }],
  ['path', { d: 'M16 4l4 4', key: 'svg-5' }],
  ['path', { d: 'M16 8l4 -4', key: 'svg-6' }],
  ['path', { d: 'M18 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-7' }],
]);
