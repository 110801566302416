import createVueComponent from '../createVueComponent';
export default createVueComponent('compass-off', 'IconCompassOff', [
  ['path', { d: 'M13 9l3 -1l-1 3m-1 3l-6 2l2 -6', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M20.042 16.045a9 9 0 0 0 -12.087 -12.087m-2.318 1.677a9 9 0 1 0 12.725 12.73',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 3v2', key: 'svg-2' }],
  ['path', { d: 'M12 19v2', key: 'svg-3' }],
  ['path', { d: 'M3 12h2', key: 'svg-4' }],
  ['path', { d: 'M19 12h2', key: 'svg-5' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
]);
