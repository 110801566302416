import createVueComponent from '../createVueComponent';
export default createVueComponent('camera-share', 'IconCameraShare', [
  [
    'path',
    {
      d: 'M12.5 20h-7.5a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v4',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M14.98 13.347a3 3 0 1 0 -2.39 2.595', key: 'svg-1' }],
  ['path', { d: 'M16 22l5 -5', key: 'svg-2' }],
  ['path', { d: 'M21 21.5v-4.5h-4.5', key: 'svg-3' }],
]);
