import createVueComponent from '../createVueComponent';
export default createVueComponent('replace', 'IconReplace', [
  [
    'path',
    {
      d: 'M3 3m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M15 15m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M21 11v-3a2 2 0 0 0 -2 -2h-6l3 3m0 -6l-3 3', key: 'svg-2' }],
  ['path', { d: 'M3 13v3a2 2 0 0 0 2 2h6l-3 -3m0 6l3 -3', key: 'svg-3' }],
]);
