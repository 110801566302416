import createVueComponent from '../createVueComponent';
export default createVueComponent('alien', 'IconAlien', [
  ['path', { d: 'M11 17a2.5 2.5 0 0 0 2 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M12 3c-4.664 0 -7.396 2.331 -7.862 5.595a11.816 11.816 0 0 0 2 8.592a10.777 10.777 0 0 0 3.199 3.064c1.666 1 3.664 1 5.33 0a10.777 10.777 0 0 0 3.199 -3.064a11.89 11.89 0 0 0 2 -8.592c-.466 -3.265 -3.198 -5.595 -7.862 -5.595z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M8 11l2 2', key: 'svg-2' }],
  ['path', { d: 'M16 11l-2 2', key: 'svg-3' }],
]);
