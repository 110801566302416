import createVueComponent from '../createVueComponent';
export default createVueComponent('tags', 'IconTags', [
  [
    'path',
    {
      d: 'M7.859 6h-2.834a2.025 2.025 0 0 0 -2.025 2.025v2.834c0 .537 .213 1.052 .593 1.432l6.116 6.116a2.025 2.025 0 0 0 2.864 0l2.834 -2.834a2.025 2.025 0 0 0 0 -2.864l-6.117 -6.116a2.025 2.025 0 0 0 -1.431 -.593z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M17.573 18.407l2.834 -2.834a2.025 2.025 0 0 0 0 -2.864l-7.117 -7.116',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M6 9h-.01', key: 'svg-2' }],
]);
