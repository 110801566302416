import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'brackets-contain-start',
  'IconBracketsContainStart',
  [
    ['path', { d: 'M9 4h-4v16h4', key: 'svg-0' }],
    ['path', { d: 'M18 16h-.01', key: 'svg-1' }],
    ['path', { d: 'M14 16h-.01', key: 'svg-2' }],
    ['path', { d: 'M10 16h-.01', key: 'svg-3' }],
  ],
);
