import createVueComponent from '../createVueComponent';
export default createVueComponent('arrows-random', 'IconArrowsRandom', [
  ['path', { d: 'M20 21h-4v-4', key: 'svg-0' }],
  ['path', { d: 'M16 21l5 -5', key: 'svg-1' }],
  ['path', { d: 'M6.5 9.504l-3.5 -2l2 -3.504', key: 'svg-2' }],
  ['path', { d: 'M3 7.504l6.83 -1.87', key: 'svg-3' }],
  ['path', { d: 'M4 16l4 -1l1 4', key: 'svg-4' }],
  ['path', { d: 'M8 15l-3.5 6', key: 'svg-5' }],
  ['path', { d: 'M21 5l-.5 4l-4 -.5', key: 'svg-6' }],
  ['path', { d: 'M20.5 9l-4.5 -5.5', key: 'svg-7' }],
]);
