import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-mixpanel', 'IconBrandMixpanel', [
  [
    'path',
    { d: 'M4.5 12m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0', key: 'svg-0' },
  ],
  [
    'path',
    { d: 'M20.5 12m-1.5 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0 -3 0', key: 'svg-1' },
  ],
  ['path', { d: 'M13 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
]);
