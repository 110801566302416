import createVueComponent from '../createVueComponent';
export default createVueComponent('calendar-question', 'IconCalendarQuestion', [
  [
    'path',
    {
      d: 'M15 21h-9a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 3v4', key: 'svg-1' }],
  ['path', { d: 'M8 3v4', key: 'svg-2' }],
  ['path', { d: 'M4 11h16', key: 'svg-3' }],
  ['path', { d: 'M19 22v.01', key: 'svg-4' }],
  [
    'path',
    {
      d: 'M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
      key: 'svg-5',
    },
  ],
]);
