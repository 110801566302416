import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'building-community',
  'IconBuildingCommunity',
  [
    [
      'path',
      {
        d: 'M8 9l5 5v7h-5v-4m0 4h-5v-7l5 -5m1 1v-6a1 1 0 0 1 1 -1h10a1 1 0 0 1 1 1v17h-8',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M13 7l0 .01', key: 'svg-1' }],
    ['path', { d: 'M17 7l0 .01', key: 'svg-2' }],
    ['path', { d: 'M17 11l0 .01', key: 'svg-3' }],
    ['path', { d: 'M17 15l0 .01', key: 'svg-4' }],
  ],
);
