import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'adjustments-search',
  'IconAdjustmentsSearch',
  [
    ['path', { d: 'M4 10a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
    ['path', { d: 'M6 4v4', key: 'svg-1' }],
    ['path', { d: 'M6 12v8', key: 'svg-2' }],
    ['path', { d: 'M12 14a2 2 0 0 0 -1.042 3.707', key: 'svg-3' }],
    ['path', { d: 'M12 4v10', key: 'svg-4' }],
    ['path', { d: 'M16 7a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-5' }],
    ['path', { d: 'M18 4v1', key: 'svg-6' }],
    ['path', { d: 'M18 9v2', key: 'svg-7' }],
    ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-8' }],
    ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-9' }],
  ],
);
