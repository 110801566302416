import createVueComponent from '../createVueComponent';
export default createVueComponent('window-minimize', 'IconWindowMinimize', [
  [
    'path',
    {
      d: 'M3 16m0 1a1 1 0 0 1 1 -1h3a1 1 0 0 1 1 1v3a1 1 0 0 1 -1 1h-3a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M4 12v-6a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-6',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M15 13h-4v-4', key: 'svg-2' }],
  ['path', { d: 'M11 13l5 -5', key: 'svg-3' }],
]);
