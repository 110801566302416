import createVueComponent from '../createVueComponent';
export default createVueComponent('hanger-2', 'IconHanger2', [
  [
    'path',
    {
      d: 'M12 9l-7.971 4.428a2 2 0 0 0 -1.029 1.749v.823a2 2 0 0 0 2 2h1',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M18 18h1a2 2 0 0 0 2 -2v-.823a2 2 0 0 0 -1.029 -1.749l-7.971 -4.428c-1.457 -.81 -1.993 -2.333 -2 -4a2 2 0 1 1 4 0',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M6 16m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v1a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z',
      key: 'svg-2',
    },
  ],
]);
