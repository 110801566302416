import createVueComponent from '../createVueComponent';
export default createVueComponent('file-orientation', 'IconFileOrientation', [
  ['path', { d: 'M14 3v4a1 1 0 0 0 1 1h4', key: 'svg-0' }],
  [
    'path',
    { d: 'M10 21h-3a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v2', key: 'svg-1' },
  ],
  ['path', { d: 'M13 20h5a2 2 0 0 0 2 -2v-5', key: 'svg-2' }],
  ['path', { d: 'M15 22l-2 -2l2 -2', key: 'svg-3' }],
  ['path', { d: 'M18 15l2 -2l2 2', key: 'svg-4' }],
]);
