import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'device-mobile-cancel',
  'IconDeviceMobileCancel',
  [
    [
      'path',
      {
        d: 'M12 21h-4a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v7',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M19 19m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
    ['path', { d: 'M17 21l4 -4', key: 'svg-2' }],
    ['path', { d: 'M11 4h2', key: 'svg-3' }],
    ['path', { d: 'M12 17v.01', key: 'svg-4' }],
  ],
);
