import createVueComponent from '../createVueComponent';
export default createVueComponent('accessible-off', 'IconAccessibleOff', [
  [
    'path',
    {
      d: 'M10 16.5l2 -3l2 3m-2 -3v-1.5m2.627 -1.376l.373 -.124m-6 0l2.231 .744',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M20.042 16.045a9 9 0 0 0 -12.087 -12.087m-2.318 1.677a9 9 0 1 0 12.725 12.73',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 8a.5 .5 0 1 0 -.5 -.5', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
