import createVueComponent from '../createVueComponent';
export default createVueComponent('mood-minus', 'IconMoodMinus', [
  ['path', { d: 'M20.48 15.014a9 9 0 1 0 -7.956 5.97', key: 'svg-0' }],
  ['path', { d: 'M9 10h.01', key: 'svg-1' }],
  ['path', { d: 'M15 10h.01', key: 'svg-2' }],
  ['path', { d: 'M16 19h6', key: 'svg-3' }],
  [
    'path',
    { d: 'M9.5 15c.658 .64 1.56 1 2.5 1s1.842 -.36 2.5 -1', key: 'svg-4' },
  ],
]);
