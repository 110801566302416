import createVueComponent from '../createVueComponent';
export default createVueComponent('volume-2', 'IconVolume2', [
  ['path', { d: 'M15 8a5 5 0 0 1 0 8', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M6 15h-2a1 1 0 0 1 -1 -1v-4a1 1 0 0 1 1 -1h2l3.5 -4.5a.8 .8 0 0 1 1.5 .5v14a.8 .8 0 0 1 -1.5 .5l-3.5 -4.5',
      key: 'svg-1',
    },
  ],
]);
