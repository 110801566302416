import createVueComponent from '../createVueComponent';
export default createVueComponent('device-imac-bolt', 'IconDeviceImacBolt', [
  [
    'path',
    {
      d: 'M13.5 17h-9.5a1 1 0 0 1 -1 -1v-12a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v8.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 13h13', key: 'svg-1' }],
  ['path', { d: 'M8 21h5.5', key: 'svg-2' }],
  ['path', { d: 'M10 17l-.5 4', key: 'svg-3' }],
  ['path', { d: 'M19 16l-2 3h4l-2 3', key: 'svg-4' }],
]);
