import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-citymapper', 'IconBrandCitymapper', [
  [
    'path',
    { d: 'M3 11a1 1 0 1 1 -1 1.013a1 1 0 0 1 1 -1v-.013z', key: 'svg-0' },
  ],
  [
    'path',
    { d: 'M21 11a1 1 0 1 1 -1 1.013a1 1 0 0 1 1 -1v-.013z', key: 'svg-1' },
  ],
  ['path', { d: 'M8 12h8', key: 'svg-2' }],
  ['path', { d: 'M13 9l3 3l-3 3', key: 'svg-3' }],
]);
