import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'exclamation-mark-off',
  'IconExclamationMarkOff',
  [
    ['path', { d: 'M12 19v.01', key: 'svg-0' }],
    ['path', { d: 'M12 15v-3m0 -4v-3', key: 'svg-1' }],
    ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
  ],
);
