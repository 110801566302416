import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'separator-horizontal',
  'IconSeparatorHorizontal',
  [
    ['path', { d: 'M4 12l16 0', key: 'svg-0' }],
    ['path', { d: 'M8 8l4 -4l4 4', key: 'svg-1' }],
    ['path', { d: 'M16 16l-4 4l-4 -4', key: 'svg-2' }],
  ],
);
