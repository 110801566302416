import createVueComponent from '../createVueComponent';
export default createVueComponent('curling', 'IconCurling', [
  [
    'path',
    {
      d: 'M4 9m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v2a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 14h16', key: 'svg-1' }],
  ['path', { d: 'M8 5h6a2 2 0 0 1 2 2v2', key: 'svg-2' }],
]);
