import createVueComponent from '../createVueComponent';
export default createVueComponent('cloud-storm', 'IconCloudStorm', [
  [
    'path',
    {
      d: 'M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M13 14l-2 4l3 0l-2 4', key: 'svg-1' }],
]);
