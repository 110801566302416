import createVueComponent from '../createVueComponent';
export default createVueComponent('artboard-filled', 'IconArtboardFilled', [
  [
    'path',
    {
      d: 'M15 7h-6a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h6a2 2 0 0 0 2 -2v-6a2 2 0 0 0 -2 -2z',
      'stroke-width': '0',
      fill: 'currentColor',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M4 7a1 1 0 0 1 .117 1.993l-.117 .007h-1a1 1 0 0 1 -.117 -1.993l.117 -.007h1z',
      'stroke-width': '0',
      fill: 'currentColor',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M4 15a1 1 0 0 1 .117 1.993l-.117 .007h-1a1 1 0 0 1 -.117 -1.993l.117 -.007h1z',
      'stroke-width': '0',
      fill: 'currentColor',
      key: 'svg-2',
    },
  ],
  [
    'path',
    {
      d: 'M8 2a1 1 0 0 1 .993 .883l.007 .117v1a1 1 0 0 1 -1.993 .117l-.007 -.117v-1a1 1 0 0 1 1 -1z',
      'stroke-width': '0',
      fill: 'currentColor',
      key: 'svg-3',
    },
  ],
  [
    'path',
    {
      d: 'M16 2a1 1 0 0 1 .993 .883l.007 .117v1a1 1 0 0 1 -1.993 .117l-.007 -.117v-1a1 1 0 0 1 1 -1z',
      'stroke-width': '0',
      fill: 'currentColor',
      key: 'svg-4',
    },
  ],
  [
    'path',
    {
      d: 'M21 7a1 1 0 0 1 .117 1.993l-.117 .007h-1a1 1 0 0 1 -.117 -1.993l.117 -.007h1z',
      'stroke-width': '0',
      fill: 'currentColor',
      key: 'svg-5',
    },
  ],
  [
    'path',
    {
      d: 'M21 15a1 1 0 0 1 .117 1.993l-.117 .007h-1a1 1 0 0 1 -.117 -1.993l.117 -.007h1z',
      'stroke-width': '0',
      fill: 'currentColor',
      key: 'svg-6',
    },
  ],
  [
    'path',
    {
      d: 'M8 19a1 1 0 0 1 .993 .883l.007 .117v1a1 1 0 0 1 -1.993 .117l-.007 -.117v-1a1 1 0 0 1 1 -1z',
      'stroke-width': '0',
      fill: 'currentColor',
      key: 'svg-7',
    },
  ],
  [
    'path',
    {
      d: 'M16 19a1 1 0 0 1 .993 .883l.007 .117v1a1 1 0 0 1 -1.993 .117l-.007 -.117v-1a1 1 0 0 1 1 -1z',
      'stroke-width': '0',
      fill: 'currentColor',
      key: 'svg-8',
    },
  ],
]);
