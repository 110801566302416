import createVueComponent from '../createVueComponent';
export default createVueComponent('vocabulary-off', 'IconVocabularyOff', [
  [
    'path',
    {
      d: 'M7 3h3a2 2 0 0 1 2 2a2 2 0 0 1 2 -2h6a1 1 0 0 1 1 1v13m-2 2h-5a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2h-6a1 1 0 0 1 -1 -1v-14c0 -.279 .114 -.53 .298 -.712',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 5v3m0 4v9', key: 'svg-1' }],
  ['path', { d: 'M7 11h1', key: 'svg-2' }],
  ['path', { d: 'M16 7h1', key: 'svg-3' }],
  ['path', { d: 'M16 11h1', key: 'svg-4' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-5' }],
]);
