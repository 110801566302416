import createVueComponent from '../createVueComponent';
export default createVueComponent('freeze-row', 'IconFreezeRow', [
  [
    'path',
    {
      d: 'M3 5a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-14z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M21 9h-18', key: 'svg-1' }],
  ['path', { d: 'M15 3l-6 6', key: 'svg-2' }],
  ['path', { d: 'M9.5 3l-6 6', key: 'svg-3' }],
  ['path', { d: 'M20 3.5l-5.5 5.5', key: 'svg-4' }],
]);
