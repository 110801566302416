import createVueComponent from '../createVueComponent';
export default createVueComponent('topology-star-3', 'IconTopologyStar3', [
  ['path', { d: 'M10 19a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-0' }],
  ['path', { d: 'M18 5a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-1' }],
  ['path', { d: 'M10 5a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-2' }],
  ['path', { d: 'M6 12a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-3' }],
  ['path', { d: 'M18 19a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-4' }],
  ['path', { d: 'M14 12a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-5' }],
  ['path', { d: 'M22 12a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-6' }],
  ['path', { d: 'M6 12h4', key: 'svg-7' }],
  ['path', { d: 'M14 12h4', key: 'svg-8' }],
  ['path', { d: 'M15 7l-2 3', key: 'svg-9' }],
  ['path', { d: 'M9 7l2 3', key: 'svg-10' }],
  ['path', { d: 'M11 14l-2 3', key: 'svg-11' }],
  ['path', { d: 'M13 14l2 3', key: 'svg-12' }],
]);
