import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'baseline-density-medium',
  'IconBaselineDensityMedium',
  [
    ['path', { d: 'M4 20h16', key: 'svg-0' }],
    ['path', { d: 'M4 12h16', key: 'svg-1' }],
    ['path', { d: 'M4 4h16', key: 'svg-2' }],
  ],
);
