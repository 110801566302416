import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'topology-full-hierarchy',
  'IconTopologyFullHierarchy',
  [
    ['path', { d: 'M20 18a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-0' }],
    ['path', { d: 'M8 18a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-1' }],
    ['path', { d: 'M8 6a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-2' }],
    ['path', { d: 'M20 6a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-3' }],
    ['path', { d: 'M14 12a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-4' }],
    ['path', { d: 'M6 8v8', key: 'svg-5' }],
    ['path', { d: 'M18 16v-8', key: 'svg-6' }],
    ['path', { d: 'M8 6h8', key: 'svg-7' }],
    ['path', { d: 'M16 18h-8', key: 'svg-8' }],
    ['path', { d: 'M7.5 7.5l3 3', key: 'svg-9' }],
    ['path', { d: 'M13.5 13.5l3 3', key: 'svg-10' }],
    ['path', { d: 'M16.5 7.5l-3 3', key: 'svg-11' }],
    ['path', { d: 'M10.5 13.5l-3 3', key: 'svg-12' }],
  ],
);
