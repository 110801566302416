import createVueComponent from '../createVueComponent';
export default createVueComponent('packages', 'IconPackages', [
  ['path', { d: 'M7 16.5l-5 -3l5 -3l5 3v5.5l-5 3z', key: 'svg-0' }],
  ['path', { d: 'M2 13.5v5.5l5 3', key: 'svg-1' }],
  ['path', { d: 'M7 16.545l5 -3.03', key: 'svg-2' }],
  ['path', { d: 'M17 16.5l-5 -3l5 -3l5 3v5.5l-5 3z', key: 'svg-3' }],
  ['path', { d: 'M12 19l5 3', key: 'svg-4' }],
  ['path', { d: 'M17 16.5l5 -3', key: 'svg-5' }],
  ['path', { d: 'M12 13.5v-5.5l-5 -3l5 -3l5 3v5.5', key: 'svg-6' }],
  ['path', { d: 'M7 5.03v5.455', key: 'svg-7' }],
  ['path', { d: 'M12 8l5 -3', key: 'svg-8' }],
]);
