import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-vk', 'IconBrandVk', [
  [
    'path',
    {
      d: 'M14 19h-4a8 8 0 0 1 -8 -8v-5h4v5a4 4 0 0 0 4 4h0v-9h4v4.5l.03 0a4.531 4.531 0 0 0 3.97 -4.496h4l-.342 1.711a6.858 6.858 0 0 1 -3.658 4.789h0a5.34 5.34 0 0 1 3.566 4.111l.434 2.389h0h-4a4.531 4.531 0 0 0 -3.97 -4.496v4.5z',
      key: 'svg-0',
    },
  ],
]);
