import createVueComponent from '../createVueComponent';
export default createVueComponent('arrow-curve-right', 'IconArrowCurveRight', [
  ['path', { d: 'M10 7l4 -4l4 4', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M14 3v4.394a6.737 6.737 0 0 1 -3 5.606a6.737 6.737 0 0 0 -3 5.606v2.394',
      key: 'svg-1',
    },
  ],
]);
