import createVueComponent from '../createVueComponent';
export default createVueComponent('rotate-360', 'IconRotate360', [
  ['path', { d: 'M12 16h4v4', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M19.458 11.042c.86 -2.366 .722 -4.58 -.6 -5.9c-2.272 -2.274 -7.185 -1.045 -10.973 2.743c-3.788 3.788 -5.017 8.701 -2.744 10.974c2.227 2.226 6.987 1.093 10.74 -2.515',
      key: 'svg-1',
    },
  ],
]);
