import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'device-desktop-code',
  'IconDeviceDesktopCode',
  [
    [
      'path',
      {
        d: 'M12.5 16h-8.5a1 1 0 0 1 -1 -1v-10a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v8',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M7 20h4', key: 'svg-1' }],
    ['path', { d: 'M9 16v4', key: 'svg-2' }],
    ['path', { d: 'M20 21l2 -2l-2 -2', key: 'svg-3' }],
    ['path', { d: 'M17 17l-2 2l2 2', key: 'svg-4' }],
  ],
);
