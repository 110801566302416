import createVueComponent from '../createVueComponent';
export default createVueComponent('droplet-up', 'IconDropletUp', [
  [
    'path',
    {
      d: 'M18.6 11.998a6.66 6.66 0 0 0 -.536 -1.12l-4.89 -7.26c-.42 -.626 -1.287 -.804 -1.936 -.398a1.376 1.376 0 0 0 -.41 .397l-4.893 7.26c-1.695 2.838 -1.035 6.441 1.567 8.546a7.16 7.16 0 0 0 5.002 1.562',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 22v-6', key: 'svg-1' }],
  ['path', { d: 'M22 19l-3 -3l-3 3', key: 'svg-2' }],
]);
