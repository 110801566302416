import createVueComponent from '../createVueComponent';
export default createVueComponent('checklist', 'IconChecklist', [
  [
    'path',
    {
      d: 'M9.615 20h-2.615a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M14 19l2 2l4 -4', key: 'svg-1' }],
  ['path', { d: 'M9 8h4', key: 'svg-2' }],
  ['path', { d: 'M9 12h2', key: 'svg-3' }],
]);
