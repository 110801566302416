import createVueComponent from '../createVueComponent';
export default createVueComponent('badge-3d', 'IconBadge3d', [
  [
    'path',
    {
      d: 'M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M7 9.5a.5 .5 0 0 1 .5 -.5h1a1.5 1.5 0 0 1 0 3h-.5h.5a1.5 1.5 0 0 1 0 3h-1a.5 .5 0 0 1 -.5 -.5',
      key: 'svg-1',
    },
  ],
  [
    'path',
    { d: 'M14 9v6h1a2 2 0 0 0 2 -2v-2a2 2 0 0 0 -2 -2h-1z', key: 'svg-2' },
  ],
]);
