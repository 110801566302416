import createVueComponent from '../createVueComponent';
export default createVueComponent('braces-off', 'IconBracesOff', [
  [
    'path',
    {
      d: 'M5.176 5.177c-.113 .251 -.176 .53 -.176 .823v3c0 1.657 -.895 3 -2 3c1.105 0 2 1.343 2 3v3a2 2 0 0 0 2 2',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M17 4a2 2 0 0 1 2 2v3c0 1.657 .895 3 2 3c-1.105 0 -2 1.343 -2 3m-.176 3.821a2 2 0 0 1 -1.824 1.179',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
