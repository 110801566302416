import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'ball-american-football',
  'IconBallAmericanFootball',
  [
    ['path', { d: 'M15 9l-6 6', key: 'svg-0' }],
    ['path', { d: 'M10 12l2 2', key: 'svg-1' }],
    ['path', { d: 'M12 10l2 2', key: 'svg-2' }],
    ['path', { d: 'M8 21a5 5 0 0 0 -5 -5', key: 'svg-3' }],
    [
      'path',
      {
        d: 'M16 3c-7.18 0 -13 5.82 -13 13a5 5 0 0 0 5 5c7.18 0 13 -5.82 13 -13a5 5 0 0 0 -5 -5',
        key: 'svg-4',
      },
    ],
    ['path', { d: 'M16 3a5 5 0 0 0 5 5', key: 'svg-5' }],
  ],
);
