import createVueComponent from '../createVueComponent';
export default createVueComponent('cloud-lock', 'IconCloudLock', [
  [
    'path',
    {
      d: 'M19 18a3.5 3.5 0 0 0 0 -7h-1c.397 -1.768 -.285 -3.593 -1.788 -4.787c-1.503 -1.193 -3.6 -1.575 -5.5 -1s-3.315 2.019 -3.712 3.787c-2.199 -.088 -4.155 1.326 -4.666 3.373c-.512 2.047 .564 4.154 2.566 5.027',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M8 15m0 1a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v3a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M10 15v-2a2 2 0 1 1 4 0v2', key: 'svg-2' }],
]);
