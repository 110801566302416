import createVueComponent from '../createVueComponent';
export default createVueComponent('topology-star-2', 'IconTopologyStar2', [
  ['path', { d: 'M14 20a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-0' }],
  ['path', { d: 'M14 4a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-1' }],
  ['path', { d: 'M6 12a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-2' }],
  ['path', { d: 'M22 12a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-3' }],
  ['path', { d: 'M14 12a2 2 0 1 0 -4 0a2 2 0 0 0 4 0z', key: 'svg-4' }],
  ['path', { d: 'M6 12h4', key: 'svg-5' }],
  ['path', { d: 'M14 12h4', key: 'svg-6' }],
  ['path', { d: 'M12 6v4', key: 'svg-7' }],
  ['path', { d: 'M12 14v4', key: 'svg-8' }],
]);
