import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'player-skip-back-filled',
  'IconPlayerSkipBackFilled',
  [
    [
      'path',
      {
        d: 'M19.496 4.136l-12 7a1 1 0 0 0 0 1.728l12 7a1 1 0 0 0 1.504 -.864v-14a1 1 0 0 0 -1.504 -.864z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M4 4a1 1 0 0 1 .993 .883l.007 .117v14a1 1 0 0 1 -1.993 .117l-.007 -.117v-14a1 1 0 0 1 1 -1z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-1',
      },
    ],
  ],
);
