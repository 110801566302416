import createVueComponent from '../createVueComponent';
export default createVueComponent('frustum-off', 'IconFrustumOff', [
  [
    'path',
    {
      d: 'M7.72 3.728l3.484 -1.558a1.95 1.95 0 0 1 1.59 0l4.496 2.01c.554 .246 .963 .736 1.112 1.328l2.538 10.158c.103 .412 .07 .832 -.075 1.206m-2.299 1.699l-5.725 2.738a1.945 1.945 0 0 1 -1.682 0l-7.035 -3.365a1.99 1.99 0 0 1 -1.064 -2.278l2.52 -10.08',
      key: 'svg-0',
    },
  ],
  [
    'path',
    { d: 'M18 4.82l-5.198 2.324a1.963 1.963 0 0 1 -1.602 0', key: 'svg-1' },
  ],
  ['path', { d: 'M12 7.32v.68m0 4v9.5', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
