import createVueComponent from '../createVueComponent';
export default createVueComponent('bath', 'IconBath', [
  [
    'path',
    {
      d: 'M4 12h16a1 1 0 0 1 1 1v3a4 4 0 0 1 -4 4h-10a4 4 0 0 1 -4 -4v-3a1 1 0 0 1 1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M6 12v-7a2 2 0 0 1 2 -2h3v2.25', key: 'svg-1' }],
  ['path', { d: 'M4 21l1 -1.5', key: 'svg-2' }],
  ['path', { d: 'M20 21l-1 -1.5', key: 'svg-3' }],
]);
