import createVueComponent from '../createVueComponent';
export default createVueComponent('wiper-wash', 'IconWiperWash', [
  ['path', { d: 'M12 20m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M3 11l5.5 5.5a5 5 0 0 1 7 0l5.5 -5.5a12 12 0 0 0 -18 0',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 20l0 -14', key: 'svg-2' }],
  ['path', { d: 'M4 6a4 4 0 0 1 .4 -1.8', key: 'svg-3' }],
  ['path', { d: 'M7 2.1a4 4 0 0 1 2 0', key: 'svg-4' }],
  ['path', { d: 'M12 6a4 4 0 0 0 -.4 -1.8', key: 'svg-5' }],
  ['path', { d: 'M12 6a4 4 0 0 1 .4 -1.8', key: 'svg-6' }],
  ['path', { d: 'M15 2.1a4 4 0 0 1 2 0', key: 'svg-7' }],
  ['path', { d: 'M20 6a4 4 0 0 0 -.4 -1.8', key: 'svg-8' }],
]);
