import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-couchdb', 'IconBrandCouchdb', [
  [
    'path',
    {
      d: 'M6 12h12v-2a2 2 0 0 1 2 -2a2 2 0 0 0 -2 -2h-12a2 2 0 0 0 -2 2a2 2 0 0 1 2 2v2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M6 15h12', key: 'svg-1' }],
  ['path', { d: 'M6 18h12', key: 'svg-2' }],
  ['path', { d: 'M21 11v7', key: 'svg-3' }],
  ['path', { d: 'M3 11v7', key: 'svg-4' }],
]);
