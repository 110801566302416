import createVueComponent from '../createVueComponent';
export default createVueComponent('hemisphere-plus', 'IconHemispherePlus', [
  ['path', { d: 'M12 9m-9 0a9 3 0 1 0 18 0a9 3 0 1 0 -18 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M3 9a9 9 0 0 0 9 9m8.396 -5.752a8.978 8.978 0 0 0 .604 -3.248',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M16 19h6', key: 'svg-2' }],
  ['path', { d: 'M19 16v6', key: 'svg-3' }],
]);
