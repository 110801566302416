import createVueComponent from '../createVueComponent';
export default createVueComponent('scan-eye', 'IconScanEye', [
  ['path', { d: 'M4 8v-2a2 2 0 0 1 2 -2h2', key: 'svg-0' }],
  ['path', { d: 'M4 16v2a2 2 0 0 0 2 2h2', key: 'svg-1' }],
  ['path', { d: 'M16 4h2a2 2 0 0 1 2 2v2', key: 'svg-2' }],
  ['path', { d: 'M16 20h2a2 2 0 0 0 2 -2v-2', key: 'svg-3' }],
  ['path', { d: 'M7 12c3.333 -4.667 6.667 -4.667 10 0', key: 'svg-4' }],
  ['path', { d: 'M7 12c3.333 4.667 6.667 4.667 10 0', key: 'svg-5' }],
  ['path', { d: 'M12 12h-.01', key: 'svg-6' }],
]);
