import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'device-ipad-horizontal-exclamation',
  'IconDeviceIpadHorizontalExclamation',
  [
    [
      'path',
      {
        d: 'M15 20h-10a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v6',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9 17h6', key: 'svg-1' }],
    ['path', { d: 'M19 16v3', key: 'svg-2' }],
    ['path', { d: 'M19 22v.01', key: 'svg-3' }],
  ],
);
