import createVueComponent from '../createVueComponent';
export default createVueComponent('float-left', 'IconFloatLeft', [
  [
    'path',
    {
      d: 'M4 5m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M14 7l6 0', key: 'svg-1' }],
  ['path', { d: 'M14 11l6 0', key: 'svg-2' }],
  ['path', { d: 'M4 15l16 0', key: 'svg-3' }],
  ['path', { d: 'M4 19l16 0', key: 'svg-4' }],
]);
