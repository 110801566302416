import createVueComponent from '../createVueComponent';
export default createVueComponent('numbers', 'IconNumbers', [
  ['path', { d: 'M8 10v-7l-2 2', key: 'svg-0' }],
  [
    'path',
    { d: 'M6 16a2 2 0 1 1 4 0c0 .591 -.601 1.46 -1 2l-3 3h4', key: 'svg-1' },
  ],
  ['path', { d: 'M15 14a2 2 0 1 0 2 -2a2 2 0 1 0 -2 -2', key: 'svg-2' }],
  ['path', { d: 'M6.5 10h3', key: 'svg-3' }],
]);
