import createVueComponent from '../createVueComponent';
export default createVueComponent('schema', 'IconSchema', [
  ['path', { d: 'M5 2h5v4h-5z', key: 'svg-0' }],
  ['path', { d: 'M15 10h5v4h-5z', key: 'svg-1' }],
  ['path', { d: 'M5 18h5v4h-5z', key: 'svg-2' }],
  ['path', { d: 'M5 10h5v4h-5z', key: 'svg-3' }],
  ['path', { d: 'M10 12h5', key: 'svg-4' }],
  ['path', { d: 'M7.5 6v4', key: 'svg-5' }],
  ['path', { d: 'M7.5 14v4', key: 'svg-6' }],
]);
