import createVueComponent from '../createVueComponent';
export default createVueComponent('exposure-minus-2', 'IconExposureMinus2', [
  [
    'path',
    {
      d: 'M12 9a4 4 0 1 1 8 0c0 1.098 -.564 2.025 -1.159 2.815l-6.841 7.185h8',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 12h6', key: 'svg-1' }],
]);
