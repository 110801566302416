import createVueComponent from '../createVueComponent';
export default createVueComponent('letter-g', 'IconLetterG', [
  [
    'path',
    {
      d: 'M18 9a5 5 0 0 0 -5 -5h-2a5 5 0 0 0 -5 5v6a5 5 0 0 0 5 5h2a5 5 0 0 0 5 -5v-2h-4',
      key: 'svg-0',
    },
  ],
]);
