import createVueComponent from '../createVueComponent';
export default createVueComponent('cloud-dollar', 'IconCloudDollar', [
  [
    'path',
    {
      d: 'M13.5 18.004h-6.843c-2.572 -.004 -4.657 -2.011 -4.657 -4.487c0 -2.475 2.085 -4.482 4.657 -4.482c.393 -1.762 1.794 -3.2 3.675 -3.773c1.88 -.572 3.956 -.193 5.444 1c1.28 1.023 1.957 2.51 1.873 4.027',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-2' }],
]);
