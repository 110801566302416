import createVueComponent from '../createVueComponent';
export default createVueComponent('message-2-search', 'IconMessage2Search', [
  ['path', { d: 'M8 9h8', key: 'svg-0' }],
  ['path', { d: 'M8 13h5', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M12 21l-.5 -.5l-2.5 -2.5h-3a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v4.5',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-3' }],
  ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-4' }],
]);
