import createVueComponent from '../createVueComponent';
export default createVueComponent('pill-off', 'IconPillOff', [
  [
    'path',
    {
      d: 'M10.495 6.505l2 -2a4.95 4.95 0 0 1 7 7l-2 2m-2 2l-4 4a4.95 4.95 0 0 1 -7 -7l4 -4',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8.5 8.5l7 7', key: 'svg-1' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
