import createVueComponent from '../createVueComponent';
export default createVueComponent('file-signal', 'IconFileSignal', [
  ['path', { d: 'M14 3v4a1 1 0 0 0 1 1h4', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 14v.01', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M9.525 11.525a3.5 3.5 0 0 0 0 4.95m4.95 0a3.5 3.5 0 0 0 0 -4.95',
      key: 'svg-3',
    },
  ],
]);
