import createVueComponent from '../createVueComponent';
export default createVueComponent('world-pause', 'IconWorldPause', [
  ['path', { d: 'M20.945 12.997a9 9 0 1 0 -7.928 7.945', key: 'svg-0' }],
  ['path', { d: 'M3.6 9h16.8', key: 'svg-1' }],
  ['path', { d: 'M3.6 15h9.9', key: 'svg-2' }],
  ['path', { d: 'M11.5 3a17 17 0 0 0 0 18', key: 'svg-3' }],
  ['path', { d: 'M12.5 3a16.992 16.992 0 0 1 2.51 10.526', key: 'svg-4' }],
  ['path', { d: 'M17 17v5', key: 'svg-5' }],
  ['path', { d: 'M21 17v5', key: 'svg-6' }],
]);
