import createVueComponent from '../createVueComponent';
export default createVueComponent('pepper-off', 'IconPepperOff', [
  [
    'path',
    {
      d: 'M12.59 12.59c-.77 1.418 -2.535 2.41 -4.59 2.41c-2.761 0 -5 -1.79 -5 -4a8 8 0 0 0 13.643 5.67m1.64 -2.357a7.97 7.97 0 0 0 .717 -3.313a3 3 0 0 0 -5.545 -1.59',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 8c0 -2 2 -4 4 -4', key: 'svg-1' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
]);
