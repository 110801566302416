import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'shield-half-filled',
  'IconShieldHalfFilled',
  [
    [
      'path',
      {
        d: 'M12 3a12 12 0 0 0 8.5 3a12 12 0 0 1 -8.5 15a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M12 3v18', key: 'svg-1' }],
    ['path', { d: 'M12 11h8.9', key: 'svg-2' }],
    ['path', { d: 'M12 8h8.9', key: 'svg-3' }],
    ['path', { d: 'M12 5h3.1', key: 'svg-4' }],
    ['path', { d: 'M12 17h6.2', key: 'svg-5' }],
    ['path', { d: 'M12 14h8', key: 'svg-6' }],
  ],
);
