import createVueComponent from '../createVueComponent';
export default createVueComponent('elevator-off', 'IconElevatorOff', [
  [
    'path',
    {
      d: 'M8 4h10a1 1 0 0 1 1 1v10m0 4a1 1 0 0 1 -1 1h-12a1 1 0 0 1 -1 -1v-14',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 8l2 2', key: 'svg-1' }],
  ['path', { d: 'M10 14l2 2l2 -2', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
