import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'arrow-badge-right-filled',
  'IconArrowBadgeRightFilled',
  [
    [
      'path',
      {
        d: 'M7 6l-.112 .006a1 1 0 0 0 -.669 1.619l3.501 4.375l-3.5 4.375a1 1 0 0 0 .78 1.625h6a1 1 0 0 0 .78 -.375l4 -5a1 1 0 0 0 0 -1.25l-4 -5a1 1 0 0 0 -.78 -.375h-6z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-0',
      },
    ],
  ],
);
