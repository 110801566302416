import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-crunchbase', 'IconBrandCrunchbase', [
  [
    'path',
    {
      d: 'M3 19v-14a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10.414 11.586a2 2 0 1 0 0 2.828', key: 'svg-1' }],
  ['path', { d: 'M15 13m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
  ['path', { d: 'M13 7v6', key: 'svg-3' }],
]);
