import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'column-insert-left',
  'IconColumnInsertLeft',
  [
    [
      'path',
      {
        d: 'M14 4h4a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M5 12l4 0', key: 'svg-1' }],
    ['path', { d: 'M7 10l0 4', key: 'svg-2' }],
  ],
);
