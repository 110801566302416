import createVueComponent from '../createVueComponent';
export default createVueComponent('photo-sensor-3', 'IconPhotoSensor3', [
  ['path', { d: 'M17 4h1a2 2 0 0 1 2 2v1', key: 'svg-0' }],
  ['path', { d: 'M20 17v1a2 2 0 0 1 -2 2h-1', key: 'svg-1' }],
  ['path', { d: 'M7 20h-1a2 2 0 0 1 -2 -2v-1', key: 'svg-2' }],
  ['path', { d: 'M4 7v-1a2 2 0 0 1 2 -2h1', key: 'svg-3' }],
  ['path', { d: 'M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-4' }],
  ['path', { d: 'M12 18v2', key: 'svg-5' }],
  ['path', { d: 'M4 12h2', key: 'svg-6' }],
  ['path', { d: 'M12 4v2', key: 'svg-7' }],
  ['path', { d: 'M20 12h-2', key: 'svg-8' }],
]);
