import createVueComponent from '../createVueComponent';
export default createVueComponent('mushroom', 'IconMushroom', [
  [
    'path',
    {
      d: 'M20 11.1c0 -4.474 -3.582 -8.1 -8 -8.1s-8 3.626 -8 8.1a.9 .9 0 0 0 .9 .9h14.2a.9 .9 0 0 0 .9 -.9z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 12v7a2 2 0 1 0 4 0v-7', key: 'svg-1' }],
]);
