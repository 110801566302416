import createVueComponent from '../createVueComponent';
export default createVueComponent('browser-check', 'IconBrowserCheck', [
  [
    'path',
    {
      d: 'M4 4m0 1a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 8h16', key: 'svg-1' }],
  ['path', { d: 'M8 4v4', key: 'svg-2' }],
  ['path', { d: 'M9.5 14.5l1.5 1.5l3 -3', key: 'svg-3' }],
]);
