import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'device-speaker-off',
  'IconDeviceSpeakerOff',
  [
    [
      'path',
      {
        d: 'M7 3h10a2 2 0 0 1 2 2v10m0 4a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-14',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M11.114 11.133a3 3 0 1 0 3.754 3.751', key: 'svg-1' }],
    ['path', { d: 'M12 7v.01', key: 'svg-2' }],
    ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
  ],
);
