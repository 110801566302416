import createVueComponent from '../createVueComponent';
export default createVueComponent('pointer', 'IconPointer', [
  [
    'path',
    {
      d: 'M7.904 17.563a1.2 1.2 0 0 0 2.228 .308l2.09 -3.093l4.907 4.907a1.067 1.067 0 0 0 1.509 0l1.047 -1.047a1.067 1.067 0 0 0 0 -1.509l-4.907 -4.907l3.113 -2.09a1.2 1.2 0 0 0 -.309 -2.228l-13.582 -3.904l3.904 13.563z',
      key: 'svg-0',
    },
  ],
]);
