import createVueComponent from '../createVueComponent';
export default createVueComponent('file-diff', 'IconFileDiff', [
  ['path', { d: 'M14 3v4a1 1 0 0 0 1 1h4', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 10l0 4', key: 'svg-2' }],
  ['path', { d: 'M10 12l4 0', key: 'svg-3' }],
  ['path', { d: 'M10 17l4 0', key: 'svg-4' }],
]);
