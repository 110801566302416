import createVueComponent from '../createVueComponent';
export default createVueComponent('player-stop', 'IconPlayerStop', [
  [
    'path',
    {
      d: 'M5 5m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
]);
