import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'carousel-horizontal',
  'IconCarouselHorizontal',
  [
    [
      'path',
      {
        d: 'M7 5m0 1a1 1 0 0 1 1 -1h8a1 1 0 0 1 1 1v12a1 1 0 0 1 -1 1h-8a1 1 0 0 1 -1 -1z',
        key: 'svg-0',
      },
    ],
    [
      'path',
      { d: 'M22 17h-1a1 1 0 0 1 -1 -1v-8a1 1 0 0 1 1 -1h1', key: 'svg-1' },
    ],
    [
      'path',
      { d: 'M2 17h1a1 1 0 0 0 1 -1v-8a1 1 0 0 0 -1 -1h-1', key: 'svg-2' },
    ],
  ],
);
