import createVueComponent from '../createVueComponent';
export default createVueComponent('square-letter-e', 'IconSquareLetterE', [
  [
    'path',
    {
      d: 'M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M14 8h-4v8h4', key: 'svg-1' }],
  ['path', { d: 'M10 12h2.5', key: 'svg-2' }],
]);
