import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-polymer', 'IconBrandPolymer', [
  [
    'path',
    {
      d: 'M6.706 6l-3.706 6l3.706 6h1.059l8.47 -12h1.06l3.705 6l-3.706 6',
      key: 'svg-0',
    },
  ],
]);
