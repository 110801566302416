import createVueComponent from '../createVueComponent';
export default createVueComponent('clipboard-check', 'IconClipboardCheck', [
  [
    'path',
    {
      d: 'M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M9 14l2 2l4 -4', key: 'svg-2' }],
]);
