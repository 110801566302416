import createVueComponent from '../createVueComponent';
export default createVueComponent('fidget-spinner', 'IconFidgetSpinner', [
  ['path', { d: 'M18 16v.01', key: 'svg-0' }],
  ['path', { d: 'M6 16v.01', key: 'svg-1' }],
  ['path', { d: 'M12 5v.01', key: 'svg-2' }],
  ['path', { d: 'M12 12v.01', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M12 1a4 4 0 0 1 2.001 7.464l.001 .072a3.998 3.998 0 0 1 1.987 3.758l.22 .128a3.978 3.978 0 0 1 1.591 -.417l.2 -.005a4 4 0 1 1 -3.994 3.77l-.28 -.16c-.522 .25 -1.108 .39 -1.726 .39c-.619 0 -1.205 -.14 -1.728 -.391l-.279 .16l.007 .231a4 4 0 1 1 -2.212 -3.579l.222 -.129a3.998 3.998 0 0 1 1.988 -3.756l.002 -.071a4 4 0 0 1 -1.995 -3.265l-.005 -.2a4 4 0 0 1 4 -4z',
      key: 'svg-4',
    },
  ],
]);
