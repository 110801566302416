import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-carbon', 'IconBrandCarbon', [
  [
    'path',
    {
      d: 'M14 10v-.2a1.8 1.8 0 0 0 -1.8 -1.8h-.4a1.8 1.8 0 0 0 -1.8 1.8v4.4a1.8 1.8 0 0 0 1.8 1.8h.4a1.8 1.8 0 0 0 1.8 -1.8v-.2',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M3 3m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z',
      key: 'svg-1',
    },
  ],
]);
