import createVueComponent from '../createVueComponent';
export default createVueComponent('plane', 'IconPlane', [
  [
    'path',
    {
      d: 'M16 10h4a2 2 0 0 1 0 4h-4l-4 7h-3l2 -7h-4l-2 2h-3l2 -4l-2 -4h3l2 2h4l-2 -7h3z',
      key: 'svg-0',
    },
  ],
]);
