import createVueComponent from '../createVueComponent';
export default createVueComponent('prison', 'IconPrison', [
  ['path', { d: 'M18 4v16', key: 'svg-0' }],
  ['path', { d: 'M14 4v16', key: 'svg-1' }],
  ['path', { d: 'M6 4v5', key: 'svg-2' }],
  ['path', { d: 'M6 15v5', key: 'svg-3' }],
  ['path', { d: 'M10 4v5', key: 'svg-4' }],
  ['path', { d: 'M11 9h-6v6h6z', key: 'svg-5' }],
  ['path', { d: 'M10 15v5', key: 'svg-6' }],
  ['path', { d: 'M8 12h-.01', key: 'svg-7' }],
]);
