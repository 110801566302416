import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-python', 'IconBrandPython', [
  ['path', { d: 'M12 9h-7a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h3', key: 'svg-0' }],
  [
    'path',
    { d: 'M12 15h7a2 2 0 0 0 2 -2v-4a2 2 0 0 0 -2 -2h-3', key: 'svg-1' },
  ],
  [
    'path',
    {
      d: 'M8 9v-4a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v5a2 2 0 0 1 -2 2h-4a2 2 0 0 0 -2 2v5a2 2 0 0 0 2 2h4a2 2 0 0 0 2 -2v-4',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M11 6l0 .01', key: 'svg-3' }],
  ['path', { d: 'M13 18l0 .01', key: 'svg-4' }],
]);
