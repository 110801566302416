import createVueComponent from '../createVueComponent';
export default createVueComponent('chess-rook', 'IconChessRook', [
  [
    'path',
    {
      d: 'M8 16l-1.447 .724a1 1 0 0 0 -.553 .894v2.382h12v-2.382a1 1 0 0 0 -.553 -.894l-1.447 -.724h-8z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8 16l1 -9h6l1 9', key: 'svg-1' }],
  ['path', { d: 'M6 4l.5 3h11l.5 -3', key: 'svg-2' }],
  ['path', { d: 'M10 4v3', key: 'svg-3' }],
  ['path', { d: 'M14 4v3', key: 'svg-4' }],
]);
