import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'device-ipad-horizontal-bolt',
  'IconDeviceIpadHorizontalBolt',
  [
    [
      'path',
      {
        d: 'M13 20h-8a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v6.5',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9 17h4.5', key: 'svg-1' }],
    ['path', { d: 'M19 16l-2 3h4l-2 3', key: 'svg-2' }],
  ],
);
