import createVueComponent from '../createVueComponent';
export default createVueComponent('svg', 'IconSvg', [
  [
    'path',
    { d: 'M21 8h-2a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2v-4h-1', key: 'svg-0' },
  ],
  [
    'path',
    {
      d: 'M7 8h-3a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-3',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M10 8l1.5 8h1l1.5 -8', key: 'svg-2' }],
]);
