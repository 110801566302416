import createVueComponent from '../createVueComponent';
export default createVueComponent('square-f6', 'IconSquareF6', [
  [
    'path',
    {
      d: 'M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M16 9.75a.75 .75 0 0 0 -.75 -.75h-1.25a1 1 0 0 0 -1 1v4a1 1 0 0 0 1 1h1a1 1 0 0 0 1 -1v-1a1 1 0 0 0 -1 -1h-2',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M8 12h2', key: 'svg-2' }],
  ['path', { d: 'M10 9h-2v6', key: 'svg-3' }],
]);
