import createVueComponent from '../createVueComponent';
export default createVueComponent('notes', 'IconNotes', [
  [
    'path',
    {
      d: 'M5 3m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 7l6 0', key: 'svg-1' }],
  ['path', { d: 'M9 11l6 0', key: 'svg-2' }],
  ['path', { d: 'M9 15l4 0', key: 'svg-3' }],
]);
