import createVueComponent from '../createVueComponent';
export default createVueComponent('world-cancel', 'IconWorldCancel', [
  ['path', { d: 'M21 12a9 9 0 1 0 -8.985 9', key: 'svg-0' }],
  ['path', { d: 'M3.6 9h16.8', key: 'svg-1' }],
  ['path', { d: 'M3.6 15h9.9', key: 'svg-2' }],
  ['path', { d: 'M11.5 3a17 17 0 0 0 0 18', key: 'svg-3' }],
  ['path', { d: 'M12.5 3a16.991 16.991 0 0 1 2.53 10.275', key: 'svg-4' }],
  ['path', { d: 'M19 19m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-5' }],
  ['path', { d: 'M17 21l4 -4', key: 'svg-6' }],
]);
