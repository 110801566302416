import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'device-tablet-dollar',
  'IconDeviceTabletDollar',
  [
    [
      'path',
      {
        d: 'M13 21h-7a1 1 0 0 1 -1 -1v-16a1 1 0 0 1 1 -1h12a1 1 0 0 1 1 1v6',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M11 17a1 1 0 1 0 2 0a1 1 0 0 0 -2 0', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5',
        key: 'svg-2',
      },
    ],
    ['path', { d: 'M19 21v1m0 -8v1', key: 'svg-3' }],
  ],
);
