import createVueComponent from '../createVueComponent';
export default createVueComponent('drag-drop-2', 'IconDragDrop2', [
  [
    'path',
    {
      d: 'M8 8m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 4l0 .01', key: 'svg-1' }],
  ['path', { d: 'M8 4l0 .01', key: 'svg-2' }],
  ['path', { d: 'M12 4l0 .01', key: 'svg-3' }],
  ['path', { d: 'M16 4l0 .01', key: 'svg-4' }],
  ['path', { d: 'M4 8l0 .01', key: 'svg-5' }],
  ['path', { d: 'M4 12l0 .01', key: 'svg-6' }],
  ['path', { d: 'M4 16l0 .01', key: 'svg-7' }],
]);
