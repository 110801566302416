import createVueComponent from '../createVueComponent';
export default createVueComponent('windmill', 'IconWindmill', [
  [
    'path',
    { d: 'M12 12c2.76 0 5 -2.01 5 -4.5s-2.24 -4.5 -5 -4.5v9z', key: 'svg-0' },
  ],
  [
    'path',
    { d: 'M12 12c0 2.76 2.01 5 4.5 5s4.5 -2.24 4.5 -5h-9z', key: 'svg-1' },
  ],
  [
    'path',
    { d: 'M12 12c-2.76 0 -5 2.01 -5 4.5s2.24 4.5 5 4.5v-9z', key: 'svg-2' },
  ],
  [
    'path',
    { d: 'M12 12c0 -2.76 -2.01 -5 -4.5 -5s-4.5 2.24 -4.5 5h9z', key: 'svg-3' },
  ],
]);
