import createVueComponent from '../createVueComponent';
export default createVueComponent('box-align-top-left', 'IconBoxAlignTopLeft', [
  [
    'path',
    {
      d: 'M11 5v5a1 1 0 0 1 -1 1h-5a1 1 0 0 1 -1 -1v-5a1 1 0 0 1 1 -1h5a1 1 0 0 1 1 1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M15 4h-.01', key: 'svg-1' }],
  ['path', { d: 'M20 4h-.01', key: 'svg-2' }],
  ['path', { d: 'M20 9h-.01', key: 'svg-3' }],
  ['path', { d: 'M20 15h-.01', key: 'svg-4' }],
  ['path', { d: 'M4 15h-.01', key: 'svg-5' }],
  ['path', { d: 'M20 20h-.01', key: 'svg-6' }],
  ['path', { d: 'M15 20h-.01', key: 'svg-7' }],
  ['path', { d: 'M9 20h-.01', key: 'svg-8' }],
  ['path', { d: 'M4 20h-.01', key: 'svg-9' }],
]);
