import createVueComponent from '../createVueComponent';
export default createVueComponent('battery-1', 'IconBattery1', [
  [
    'path',
    {
      d: 'M6 7h11a2 2 0 0 1 2 2v.5a.5 .5 0 0 0 .5 .5a.5 .5 0 0 1 .5 .5v3a.5 .5 0 0 1 -.5 .5a.5 .5 0 0 0 -.5 .5v.5a2 2 0 0 1 -2 2h-11a2 2 0 0 1 -2 -2v-6a2 2 0 0 1 2 -2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7 10l0 4', key: 'svg-1' }],
]);
