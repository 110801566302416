import createVueComponent from '../createVueComponent';
export default createVueComponent('currency-bitcoin', 'IconCurrencyBitcoin', [
  ['path', { d: 'M6 6h8a3 3 0 0 1 0 6a3 3 0 0 1 0 6h-8', key: 'svg-0' }],
  ['path', { d: 'M8 6l0 12', key: 'svg-1' }],
  ['path', { d: 'M8 12l6 0', key: 'svg-2' }],
  ['path', { d: 'M9 3l0 3', key: 'svg-3' }],
  ['path', { d: 'M13 3l0 3', key: 'svg-4' }],
  ['path', { d: 'M9 18l0 3', key: 'svg-5' }],
  ['path', { d: 'M13 18l0 3', key: 'svg-6' }],
]);
