import createVueComponent from '../createVueComponent';
export default createVueComponent('dog', 'IconDog', [
  ['path', { d: 'M11 5h2', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M19 12c-.667 5.333 -2.333 8 -5 8h-4c-2.667 0 -4.333 -2.667 -5 -8',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M11 16c0 .667 .333 1 1 1s1 -.333 1 -1h-2z', key: 'svg-2' }],
  ['path', { d: 'M12 18v2', key: 'svg-3' }],
  ['path', { d: 'M10 11v.01', key: 'svg-4' }],
  ['path', { d: 'M14 11v.01', key: 'svg-5' }],
  [
    'path',
    {
      d: 'M5 4l6 .97l-6.238 6.688a1.021 1.021 0 0 1 -1.41 .111a.953 .953 0 0 1 -.327 -.954l1.975 -6.815z',
      key: 'svg-6',
    },
  ],
  [
    'path',
    {
      d: 'M19 4l-6 .97l6.238 6.688c.358 .408 .989 .458 1.41 .111a.953 .953 0 0 0 .327 -.954l-1.975 -6.815z',
      key: 'svg-7',
    },
  ],
]);
