import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-peanut', 'IconBrandPeanut', [
  [
    'path',
    {
      d: 'M15 16.25l-.816 -.36l-.462 -.196c-1.444 -.592 -2 -.593 -3.447 0l-.462 .195l-.817 .359a4.5 4.5 0 1 1 0 -8.49v0l1.054 .462l.434 .178c1.292 .507 1.863 .48 3.237 -.082l.462 -.195l.817 -.359a4.5 4.5 0 1 1 0 8.49',
      key: 'svg-0',
    },
  ],
]);
