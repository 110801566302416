import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'device-ipad-cancel',
  'IconDeviceIpadCancel',
  [
    [
      'path',
      {
        d: 'M12.5 21h-6.5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v7',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M9 18h3', key: 'svg-1' }],
    ['path', { d: 'M19 19m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-2' }],
    ['path', { d: 'M17 21l4 -4', key: 'svg-3' }],
  ],
);
