import createVueComponent from '../createVueComponent';
export default createVueComponent('login', 'IconLogin', [
  [
    'path',
    {
      d: 'M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M20 12h-13l3 -3m0 6l-3 -3', key: 'svg-1' }],
]);
