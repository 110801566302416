import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-coreos', 'IconBrandCoreos', [
  ['path', { d: 'M21 12a9 9 0 1 1 -18 0a9 9 0 0 1 18 0z', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M12 3c-3.263 3.212 -3 7.654 -3 12c4.59 .244 8.814 -.282 12 -3',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M9.5 9a4.494 4.494 0 0 1 5.5 5.5', key: 'svg-2' }],
]);
