import createVueComponent from '../createVueComponent';
export default createVueComponent('browser', 'IconBrowser', [
  [
    'path',
    {
      d: 'M4 4m0 1a1 1 0 0 1 1 -1h14a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 8l16 0', key: 'svg-1' }],
  ['path', { d: 'M8 4l0 4', key: 'svg-2' }],
]);
