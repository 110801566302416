import createVueComponent from '../createVueComponent';
export default createVueComponent('jacket', 'IconJacket', [
  ['path', { d: 'M16 3l-4 5l-4 -5', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M12 19a2 2 0 0 1 -2 2h-4a2 2 0 0 1 -2 -2v-8.172a2 2 0 0 1 .586 -1.414l.828 -.828a2 2 0 0 0 .586 -1.414v-2.172a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v2.172a2 2 0 0 0 .586 1.414l.828 .828a2 2 0 0 1 .586 1.414v8.172a2 2 0 0 1 -2 2h-4a2 2 0 0 1 -2 -2z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M20 13h-3a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h3', key: 'svg-2' }],
  ['path', { d: 'M4 17h3a1 1 0 0 0 1 -1v-2a1 1 0 0 0 -1 -1h-3', key: 'svg-3' }],
  ['path', { d: 'M12 19v-11', key: 'svg-4' }],
]);
