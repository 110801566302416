import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'arrow-rotary-first-right',
  'IconArrowRotaryFirstRight',
  [
    ['path', { d: 'M8 7m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-0' }],
    ['path', { d: 'M8 10v10', key: 'svg-1' }],
    ['path', { d: 'M10.5 9.5l8.5 8.5', key: 'svg-2' }],
    ['path', { d: 'M14 18h5v-5', key: 'svg-3' }],
  ],
);
