import createVueComponent from '../createVueComponent';
export default createVueComponent('fire-hydrant', 'IconFireHydrant', [
  ['path', { d: 'M5 21h14', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M17 21v-5h1a1 1 0 0 0 1 -1v-2a1 1 0 0 0 -1 -1h-1v-4a5 5 0 0 0 -10 0v4h-1a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h1v5',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
  ['path', { d: 'M6 8h12', key: 'svg-3' }],
]);
