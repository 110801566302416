import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'message-circle-plus',
  'IconMessageCirclePlus',
  [
    [
      'path',
      {
        d: 'M12.007 19.98a9.869 9.869 0 0 1 -4.307 -.98l-4.7 1l1.3 -3.9c-2.324 -3.437 -1.426 -7.872 2.1 -10.374c3.526 -2.501 8.59 -2.296 11.845 .48c1.992 1.7 2.93 4.04 2.747 6.34',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M16 19h6', key: 'svg-1' }],
    ['path', { d: 'M19 16v6', key: 'svg-2' }],
  ],
);
