import createVueComponent from '../createVueComponent';
export default createVueComponent('help-off', 'IconHelpOff', [
  [
    'path',
    {
      d: 'M5.641 5.631a9 9 0 1 0 12.719 12.738m1.68 -2.318a9 9 0 0 0 -12.074 -12.098',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 17v.01', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M12 13.5a1.5 1.5 0 0 1 .394 -1.1m2.106 -1.9a2.6 2.6 0 0 0 -3.347 -3.361',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
