import createVueComponent from '../createVueComponent';
export default createVueComponent('robot-off', 'IconRobotOff', [
  [
    'path',
    {
      d: 'M11 7h6a2 2 0 0 1 2 2v1l1 1v3l-1 1m-.171 3.811a2 2 0 0 1 -1.829 1.189h-10a2 2 0 0 1 -2 -2v-3l-1 -1v-3l1 -1v-1a2 2 0 0 1 2 -2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M10 16h4', key: 'svg-1' }],
  ['path', { d: 'M8.5 11.5m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M15.854 11.853a.498 .498 0 0 0 -.354 -.853a.498 .498 0 0 0 -.356 .149',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M8.336 4.343l-.336 -1.343', key: 'svg-4' }],
  ['path', { d: 'M15 7l1 -4', key: 'svg-5' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-6' }],
]);
