import createVueComponent from '../createVueComponent';
export default createVueComponent('location', 'IconLocation', [
  [
    'path',
    {
      d: 'M21 3l-6.5 18a.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5',
      key: 'svg-0',
    },
  ],
]);
