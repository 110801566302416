import createVueComponent from '../createVueComponent';
export default createVueComponent('ruler', 'IconRuler', [
  [
    'path',
    {
      d: 'M5 4h14a1 1 0 0 1 1 1v5a1 1 0 0 1 -1 1h-7a1 1 0 0 0 -1 1v7a1 1 0 0 1 -1 1h-5a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 8l2 0', key: 'svg-1' }],
  ['path', { d: 'M4 12l3 0', key: 'svg-2' }],
  ['path', { d: 'M4 16l2 0', key: 'svg-3' }],
  ['path', { d: 'M8 4l0 2', key: 'svg-4' }],
  ['path', { d: 'M12 4l0 3', key: 'svg-5' }],
  ['path', { d: 'M16 4l0 2', key: 'svg-6' }],
]);
