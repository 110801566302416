import createVueComponent from '../createVueComponent';
export default createVueComponent('map-pin-pause', 'IconMapPinPause', [
  ['path', { d: 'M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M13.414 20.9a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 13.337 -3.413',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M17 17v5', key: 'svg-2' }],
  ['path', { d: 'M21 17v5', key: 'svg-3' }],
]);
