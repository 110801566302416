import createVueComponent from '../createVueComponent';
export default createVueComponent('map-pin-question', 'IconMapPinQuestion', [
  ['path', { d: 'M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M14.997 19.317l-1.583 1.583a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 13.657 -5.584',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M19 22v.01', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
      key: 'svg-3',
    },
  ],
]);
