import createVueComponent from '../createVueComponent';
export default createVueComponent('notification', 'IconNotification', [
  [
    'path',
    {
      d: 'M10 6h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M17 7m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
]);
