import createVueComponent from '../createVueComponent';
export default createVueComponent('123', 'Icon123', [
  ['path', { d: 'M3 10l2 -2v8', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M9 8h3a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h3',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M17 8h2.5a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1 -1.5 1.5h-1.5h1.5a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1 -1.5 1.5h-2.5',
      key: 'svg-2',
    },
  ],
]);
