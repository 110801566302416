import createVueComponent from '../createVueComponent';
export default createVueComponent('wash-dry', 'IconWashDry', [
  [
    'path',
    {
      d: 'M3 3m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v12a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z',
      key: 'svg-0',
    },
  ],
]);
