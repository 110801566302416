import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'device-mobile-message',
  'IconDeviceMobileMessage',
  [
    ['path', { d: 'M11 3h10v8h-3l-4 2v-2h-3z', key: 'svg-0' }],
    [
      'path',
      {
        d: 'M15 16v4a1 1 0 0 1 -1 1h-8a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1h2',
        key: 'svg-1',
      },
    ],
    ['path', { d: 'M10 18v.01', key: 'svg-2' }],
  ],
);
