import createVueComponent from '../createVueComponent';
export default createVueComponent('fish-bone', 'IconFishBone', [
  [
    'path',
    {
      d: 'M16.69 7.44a6.973 6.973 0 0 0 -1.69 4.56a6.97 6.97 0 0 0 1.699 4.571c1.914 -.684 3.691 -2.183 5.301 -4.565c-1.613 -2.384 -3.394 -3.883 -5.312 -4.565',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M2 9.504a40.73 40.73 0 0 0 2.422 2.504a39.679 39.679 0 0 0 -2.422 2.498',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M18 11v.01', key: 'svg-2' }],
  ['path', { d: 'M4.422 12h10.578', key: 'svg-3' }],
  ['path', { d: 'M7 10v4', key: 'svg-4' }],
  ['path', { d: 'M11 8v8', key: 'svg-5' }],
]);
