import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'creative-commons-off',
  'IconCreativeCommonsOff',
  [
    [
      'path',
      {
        d: 'M5.638 5.634a9 9 0 1 0 12.723 12.733m1.686 -2.332a9 9 0 0 0 -12.093 -12.077',
        key: 'svg-0',
      },
    ],
    [
      'path',
      {
        d: 'M10.5 10.5a2.187 2.187 0 0 0 -2.914 .116a1.928 1.928 0 0 0 0 2.768a2.188 2.188 0 0 0 2.914 .116',
        key: 'svg-1',
      },
    ],
    ['path', { d: 'M16.5 10.5a2.194 2.194 0 0 0 -2.309 -.302', key: 'svg-2' }],
    ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
  ],
);
