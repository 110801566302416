import createVueComponent from '../createVueComponent';
export default createVueComponent('home-bolt', 'IconHomeBolt', [
  ['path', { d: 'M19 10l-7 -7l-9 9h2v7a2 2 0 0 0 2 2h7.5', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M9 21v-6a2 2 0 0 1 2 -2h2c.661 0 1.248 .32 1.612 .815',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M19 14l-2 4h4l-2 4', key: 'svg-2' }],
]);
