import createVueComponent from '../createVueComponent';
export default createVueComponent('sun-low', 'IconSunLow', [
  ['path', { d: 'M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0', key: 'svg-0' }],
  ['path', { d: 'M4 12h.01', key: 'svg-1' }],
  ['path', { d: 'M12 4v.01', key: 'svg-2' }],
  ['path', { d: 'M20 12h.01', key: 'svg-3' }],
  ['path', { d: 'M12 20v.01', key: 'svg-4' }],
  ['path', { d: 'M6.31 6.31l-.01 -.01', key: 'svg-5' }],
  ['path', { d: 'M17.71 6.31l-.01 -.01', key: 'svg-6' }],
  ['path', { d: 'M17.7 17.7l.01 .01', key: 'svg-7' }],
  ['path', { d: 'M6.3 17.7l.01 .01', key: 'svg-8' }],
]);
