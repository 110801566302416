import createVueComponent from '../createVueComponent';
export default createVueComponent('heart-minus', 'IconHeartMinus', [
  [
    'path',
    {
      d: 'M13 19l-1 1l-7.5 -7.428a5 5 0 1 1 7.5 -6.566a5 5 0 0 1 8 6',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M14 16h6', key: 'svg-1' }],
]);
