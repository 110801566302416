import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'oval-vertical-filled',
  'IconOvalVerticalFilled',
  [
    [
      'path',
      {
        d: 'M12 5c-5.457 0 -10 3.028 -10 7s4.543 7 10 7s10 -3.028 10 -7s-4.543 -7 -10 -7z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-0',
      },
    ],
  ],
);
