import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'device-mobile-code',
  'IconDeviceMobileCode',
  [
    [
      'path',
      {
        d: 'M11.5 21h-3.5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M20 21l2 -2l-2 -2', key: 'svg-1' }],
    ['path', { d: 'M17 17l-2 2l2 2', key: 'svg-2' }],
    ['path', { d: 'M11 4h2', key: 'svg-3' }],
    ['path', { d: 'M12 17v.01', key: 'svg-4' }],
  ],
);
