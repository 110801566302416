import createVueComponent from '../createVueComponent';
export default createVueComponent('marquee', 'IconMarquee', [
  [
    'path',
    {
      d: 'M4 6a2 2 0 0 1 2 -2m3 0h1.5m3 0h1.5m3 0a2 2 0 0 1 2 2m0 3v1.5m0 3v1.5m0 3a2 2 0 0 1 -2 2m-3 0h-1.5m-3 0h-1.5m-3 0a2 2 0 0 1 -2 -2m0 -3v-1.5m0 -3v-1.5m0 -3',
      key: 'svg-0',
    },
  ],
]);
