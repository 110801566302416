import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'square-rounded-letter-z',
  'IconSquareRoundedLetterZ',
  [
    ['path', { d: 'M10 8h4l-4 8h4', key: 'svg-0' }],
    [
      'path',
      {
        d: 'M12 3c7.2 0 9 1.8 9 9s-1.8 9 -9 9s-9 -1.8 -9 -9s1.8 -9 9 -9z',
        key: 'svg-1',
      },
    ],
  ],
);
