import createVueComponent from '../createVueComponent';
export default createVueComponent('world-exclamation', 'IconWorldExclamation', [
  ['path', { d: 'M20.986 12.51a9 9 0 1 0 -5.71 7.873', key: 'svg-0' }],
  ['path', { d: 'M3.6 9h16.8', key: 'svg-1' }],
  ['path', { d: 'M3.6 15h10.9', key: 'svg-2' }],
  ['path', { d: 'M11.5 3a17 17 0 0 0 0 18', key: 'svg-3' }],
  ['path', { d: 'M12.5 3a17 17 0 0 1 0 18', key: 'svg-4' }],
  ['path', { d: 'M19 16v3', key: 'svg-5' }],
  ['path', { d: 'M19 22v.01', key: 'svg-6' }],
]);
