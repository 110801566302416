import createVueComponent from '../createVueComponent';
export default createVueComponent('home-check', 'IconHomeCheck', [
  ['path', { d: 'M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M19 13.488v-1.488h2l-9 -9l-9 9h2v7a2 2 0 0 0 2 2h4.525',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M15 19l2 2l4 -4', key: 'svg-2' }],
]);
