import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'building-skyscraper',
  'IconBuildingSkyscraper',
  [
    ['path', { d: 'M3 21l18 0', key: 'svg-0' }],
    ['path', { d: 'M5 21v-14l8 -4v18', key: 'svg-1' }],
    ['path', { d: 'M19 21v-10l-6 -4', key: 'svg-2' }],
    ['path', { d: 'M9 9l0 .01', key: 'svg-3' }],
    ['path', { d: 'M9 12l0 .01', key: 'svg-4' }],
    ['path', { d: 'M9 15l0 .01', key: 'svg-5' }],
    ['path', { d: 'M9 18l0 .01', key: 'svg-6' }],
  ],
);
