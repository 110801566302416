import createVueComponent from '../createVueComponent';
export default createVueComponent('wall-off', 'IconWallOff', [
  [
    'path',
    {
      d: 'M8 4h10a2 2 0 0 1 2 2v10m-.589 3.417c-.361 .36 -.86 .583 -1.411 .583h-12a2 2 0 0 1 -2 -2v-12c0 -.55 .222 -1.047 .58 -1.409',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 8h4m4 0h8', key: 'svg-1' }],
  ['path', { d: 'M20 12h-4m-4 0h-8', key: 'svg-2' }],
  ['path', { d: 'M4 16h12', key: 'svg-3' }],
  ['path', { d: 'M9 4v1', key: 'svg-4' }],
  ['path', { d: 'M14 8v2', key: 'svg-5' }],
  ['path', { d: 'M8 12v4', key: 'svg-6' }],
  ['path', { d: 'M11 16v4', key: 'svg-7' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-8' }],
]);
