import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'arrow-forward-up-double',
  'IconArrowForwardUpDouble',
  [
    ['path', { d: 'M11 14l4 -4l-4 -4', key: 'svg-0' }],
    ['path', { d: 'M16 14l4 -4l-4 -4', key: 'svg-1' }],
    ['path', { d: 'M15 10h-7a4 4 0 1 0 0 8h1', key: 'svg-2' }],
  ],
);
