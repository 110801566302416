import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'device-mobile-share',
  'IconDeviceMobileShare',
  [
    [
      'path',
      {
        d: 'M12 21h-4a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M11 4h2', key: 'svg-1' }],
    ['path', { d: 'M16 22l5 -5', key: 'svg-2' }],
    ['path', { d: 'M21 21.5v-4.5h-4.5', key: 'svg-3' }],
    ['path', { d: 'M12 17v.01', key: 'svg-4' }],
  ],
);
