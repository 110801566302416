import createVueComponent from '../createVueComponent';
export default createVueComponent('shield-code', 'IconShieldCode', [
  [
    'path',
    {
      d: 'M12 21a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3a12 12 0 0 0 8.5 3a12 12 0 0 1 -.078 7.024',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M20 21l2 -2l-2 -2', key: 'svg-1' }],
  ['path', { d: 'M17 17l-2 2l2 2', key: 'svg-2' }],
]);
