import createVueComponent from '../createVueComponent';
export default createVueComponent('box-align-bottom', 'IconBoxAlignBottom', [
  [
    'path',
    { d: 'M4 14h16v5a1 1 0 0 1 -1 1h-14a1 1 0 0 1 -1 -1v-5z', key: 'svg-0' },
  ],
  ['path', { d: 'M4 9v.01', key: 'svg-1' }],
  ['path', { d: 'M4 4v.01', key: 'svg-2' }],
  ['path', { d: 'M9 4v.01', key: 'svg-3' }],
  ['path', { d: 'M15 4v.01', key: 'svg-4' }],
  ['path', { d: 'M20 4v.01', key: 'svg-5' }],
  ['path', { d: 'M20 9v.01', key: 'svg-6' }],
]);
