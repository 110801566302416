import createVueComponent from '../createVueComponent';
export default createVueComponent('razor', 'IconRazor', [
  ['path', { d: 'M7 3h10v4h-10z', key: 'svg-0' }],
  ['path', { d: 'M12 7v4', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M12 11a2 2 0 0 1 2 2v6a2 2 0 1 1 -4 0v-6a2 2 0 0 1 2 -2z',
      key: 'svg-2',
    },
  ],
]);
