import createVueComponent from '../createVueComponent';
export default createVueComponent('recharging', 'IconRecharging', [
  ['path', { d: 'M7.038 4.5a9 9 0 0 0 -2.495 2.47', key: 'svg-0' }],
  ['path', { d: 'M3.186 10.209a9 9 0 0 0 0 3.508', key: 'svg-1' }],
  ['path', { d: 'M4.5 16.962a9 9 0 0 0 2.47 2.495', key: 'svg-2' }],
  ['path', { d: 'M10.209 20.814a9 9 0 0 0 3.5 0', key: 'svg-3' }],
  ['path', { d: 'M16.962 19.5a9 9 0 0 0 2.495 -2.47', key: 'svg-4' }],
  ['path', { d: 'M20.814 13.791a9 9 0 0 0 0 -3.508', key: 'svg-5' }],
  ['path', { d: 'M19.5 7.038a9 9 0 0 0 -2.47 -2.495', key: 'svg-6' }],
  ['path', { d: 'M13.791 3.186a9 9 0 0 0 -3.508 -.02', key: 'svg-7' }],
  ['path', { d: 'M12 8l-2 4h4l-2 4', key: 'svg-8' }],
  ['path', { d: 'M12 21a9 9 0 0 0 0 -18', key: 'svg-9' }],
]);
