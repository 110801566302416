import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'align-box-right-bottom',
  'IconAlignBoxRightBottom',
  [
    [
      'path',
      {
        d: 'M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M15 17h2', key: 'svg-1' }],
    ['path', { d: 'M11 14h6', key: 'svg-2' }],
    ['path', { d: 'M13 11h4', key: 'svg-3' }],
  ],
);
