import createVueComponent from '../createVueComponent';
export default createVueComponent('scribble', 'IconScribble', [
  [
    'path',
    {
      d: 'M3 15c2 3 4 4 7 4s7 -3 7 -7s-3 -7 -6 -7s-5 1.5 -5 4s2 5 6 5s8.408 -2.453 10 -5',
      key: 'svg-0',
    },
  ],
]);
