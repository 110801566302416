import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'circle-rectangle-off',
  'IconCircleRectangleOff',
  [
    ['path', { d: 'M14 10h3v3m-3 1h-7v-4h3', key: 'svg-0' }],
    [
      'path',
      {
        d: 'M20.042 16.045a9 9 0 0 0 -12.087 -12.087m-2.318 1.677a9 9 0 1 0 12.725 12.73',
        key: 'svg-1',
      },
    ],
    ['path', { d: 'M3 3l18 18', key: 'svg-2' }],
  ],
);
