import createVueComponent from '../createVueComponent';
export default createVueComponent('ampersand', 'IconAmpersand', [
  [
    'path',
    {
      d: 'M19 20l-10.403 -10.972a2.948 2.948 0 0 1 0 -4.165a2.94 2.94 0 0 1 4.161 0a2.948 2.948 0 0 1 0 4.165l-4.68 4.687a3.685 3.685 0 0 0 0 5.207a3.675 3.675 0 0 0 5.2 0l5.722 -5.922',
      key: 'svg-0',
    },
  ],
]);
