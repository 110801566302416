import createVueComponent from '../createVueComponent';
export default createVueComponent('hammer', 'IconHammer', [
  [
    'path',
    {
      d: 'M11.414 10l-7.383 7.418a2.091 2.091 0 0 0 0 2.967a2.11 2.11 0 0 0 2.976 0l7.407 -7.385',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M18.121 15.293l2.586 -2.586a1 1 0 0 0 0 -1.414l-7.586 -7.586a1 1 0 0 0 -1.414 0l-2.586 2.586a1 1 0 0 0 0 1.414l7.586 7.586a1 1 0 0 0 1.414 0z',
      key: 'svg-1',
    },
  ],
]);
