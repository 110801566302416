import createVueComponent from '../createVueComponent';
export default createVueComponent('device-gamepad-2', 'IconDeviceGamepad2', [
  [
    'path',
    {
      d: 'M12 5h3.5a5 5 0 0 1 0 10h-5.5l-4.015 4.227a2.3 2.3 0 0 1 -3.923 -2.035l1.634 -8.173a5 5 0 0 1 4.904 -4.019h3.4z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M14 15l4.07 4.284a2.3 2.3 0 0 0 3.925 -2.023l-1.6 -8.232',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M8 9v2', key: 'svg-2' }],
  ['path', { d: 'M7 10h2', key: 'svg-3' }],
  ['path', { d: 'M14 10h2', key: 'svg-4' }],
]);
