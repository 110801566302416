import createVueComponent from '../createVueComponent';
export default createVueComponent('device-analytics', 'IconDeviceAnalytics', [
  [
    'path',
    {
      d: 'M3 4m0 1a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v10a1 1 0 0 1 -1 1h-16a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7 20l10 0', key: 'svg-1' }],
  ['path', { d: 'M9 16l0 4', key: 'svg-2' }],
  ['path', { d: 'M15 16l0 4', key: 'svg-3' }],
  ['path', { d: 'M8 12l3 -3l2 2l3 -3', key: 'svg-4' }],
]);
