import createVueComponent from '../createVueComponent';
export default createVueComponent('device-watch-up', 'IconDeviceWatchUp', [
  [
    'path',
    {
      d: 'M12 18h-3a3 3 0 0 1 -3 -3v-6a3 3 0 0 1 3 -3h6a3 3 0 0 1 3 3v3',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 18v3h3.5', key: 'svg-1' }],
  ['path', { d: 'M9 6v-3h6v3', key: 'svg-2' }],
  ['path', { d: 'M19 22v-6', key: 'svg-3' }],
  ['path', { d: 'M22 19l-3 -3l-3 3', key: 'svg-4' }],
]);
