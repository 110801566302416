import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'arrow-autofit-left',
  'IconArrowAutofitLeft',
  [
    ['path', { d: 'M4 12v-6a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v8', key: 'svg-0' }],
    ['path', { d: 'M20 18h-17', key: 'svg-1' }],
    ['path', { d: 'M6 15l-3 3l3 3', key: 'svg-2' }],
  ],
);
