import createVueComponent from '../createVueComponent';
export default createVueComponent('route-off', 'IconRouteOff', [
  ['path', { d: 'M6 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M18 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M12 19h4.5c.71 0 1.372 -.212 1.924 -.576m1.545 -2.459a3.5 3.5 0 0 0 -3.469 -3.965h-.499m-4 0h-3.501a3.5 3.5 0 0 1 -2.477 -5.972m2.477 -1.028h3.5',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
