import createVueComponent from '../createVueComponent';
export default createVueComponent('bmp', 'IconBmp', [
  ['path', { d: 'M18 16v-8h2a2 2 0 1 1 0 4h-2', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M6 14a2 2 0 0 1 -2 2h-2v-8h2a2 2 0 1 1 0 4h-2h2a2 2 0 0 1 2 2z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M9 16v-8l3 6l3 -6v8', key: 'svg-2' }],
]);
