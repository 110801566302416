import createVueComponent from '../createVueComponent';
export default createVueComponent('calendar-up', 'IconCalendarUp', [
  [
    'path',
    {
      d: 'M12.5 21h-6.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 3v4', key: 'svg-1' }],
  ['path', { d: 'M8 3v4', key: 'svg-2' }],
  ['path', { d: 'M4 11h16', key: 'svg-3' }],
  ['path', { d: 'M19 22v-6', key: 'svg-4' }],
  ['path', { d: 'M22 19l-3 -3l-3 3', key: 'svg-5' }],
]);
