import createVueComponent from '../createVueComponent';
export default createVueComponent('currency-naira', 'IconCurrencyNaira', [
  [
    'path',
    {
      d: 'M7 18v-10.948a1.05 1.05 0 0 1 1.968 -.51l6.064 10.916a1.05 1.05 0 0 0 1.968 -.51v-10.948',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M5 10h14', key: 'svg-1' }],
  ['path', { d: 'M5 14h14', key: 'svg-2' }],
]);
