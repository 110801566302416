import createVueComponent from '../createVueComponent';
export default createVueComponent('command', 'IconCommand', [
  [
    'path',
    {
      d: 'M7 9a2 2 0 1 1 2 -2v10a2 2 0 1 1 -2 -2h10a2 2 0 1 1 -2 2v-10a2 2 0 1 1 2 2h-10',
      key: 'svg-0',
    },
  ],
]);
