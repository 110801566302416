import createVueComponent from '../createVueComponent';
export default createVueComponent('world-x', 'IconWorldX', [
  ['path', { d: 'M20.929 13.131a9 9 0 1 0 -8.931 7.869', key: 'svg-0' }],
  ['path', { d: 'M3.6 9h16.8', key: 'svg-1' }],
  ['path', { d: 'M3.6 15h9.9', key: 'svg-2' }],
  ['path', { d: 'M11.5 3a17 17 0 0 0 0 18', key: 'svg-3' }],
  ['path', { d: 'M12.5 3a16.992 16.992 0 0 1 2.505 10.573', key: 'svg-4' }],
  ['path', { d: 'M22 22l-5 -5', key: 'svg-5' }],
  ['path', { d: 'M17 22l5 -5', key: 'svg-6' }],
]);
