import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-golang', 'IconBrandGolang', [
  [
    'path',
    {
      d: 'M15.695 14.305c1.061 1.06 2.953 .888 4.226 -.384c1.272 -1.273 1.444 -3.165 .384 -4.226c-1.061 -1.06 -2.953 -.888 -4.226 .384c-1.272 1.273 -1.444 3.165 -.384 4.226z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M12.68 9.233c-1.084 -.497 -2.545 -.191 -3.591 .846c-1.284 1.273 -1.457 3.165 -.388 4.226c1.07 1.06 2.978 .888 4.261 -.384a3.669 3.669 0 0 0 1.038 -1.921h-2.427',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M5.5 15h-1.5', key: 'svg-2' }],
  ['path', { d: 'M6 9h-2', key: 'svg-3' }],
  ['path', { d: 'M5 12h-3', key: 'svg-4' }],
]);
