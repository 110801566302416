import createVueComponent from '../createVueComponent';
export default createVueComponent('world-www', 'IconWorldWww', [
  [
    'path',
    { d: 'M19.5 7a9 9 0 0 0 -7.5 -4a8.991 8.991 0 0 0 -7.484 4', key: 'svg-0' },
  ],
  ['path', { d: 'M11.5 3a16.989 16.989 0 0 0 -1.826 4', key: 'svg-1' }],
  ['path', { d: 'M12.5 3a16.989 16.989 0 0 1 1.828 4', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M19.5 17a9 9 0 0 1 -7.5 4a8.991 8.991 0 0 1 -7.484 -4',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M11.5 21a16.989 16.989 0 0 1 -1.826 -4', key: 'svg-4' }],
  ['path', { d: 'M12.5 21a16.989 16.989 0 0 0 1.828 -4', key: 'svg-5' }],
  ['path', { d: 'M2 10l1 4l1.5 -4l1.5 4l1 -4', key: 'svg-6' }],
  ['path', { d: 'M17 10l1 4l1.5 -4l1.5 4l1 -4', key: 'svg-7' }],
  ['path', { d: 'M9.5 10l1 4l1.5 -4l1.5 4l1 -4', key: 'svg-8' }],
]);
