import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'circuit-diode-zener',
  'IconCircuitDiodeZener',
  [
    ['path', { d: 'M22 12h-6', key: 'svg-0' }],
    ['path', { d: 'M2 12h6', key: 'svg-1' }],
    ['path', { d: 'M8 7l8 5l-8 5z', key: 'svg-2' }],
    ['path', { d: 'M14 7h2v10h2', key: 'svg-3' }],
  ],
);
