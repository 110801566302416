import createVueComponent from '../createVueComponent';
export default createVueComponent('camera-pin', 'IconCameraPin', [
  [
    'path',
    {
      d: 'M12.5 20h-7.5a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v2',
      key: 'svg-0',
    },
  ],
  [
    'path',
    { d: 'M14.933 12.366a3.001 3.001 0 1 0 -2.933 3.634', key: 'svg-1' },
  ],
  [
    'path',
    {
      d: 'M21.121 20.121a3 3 0 1 0 -4.242 0c.418 .419 1.125 1.045 2.121 1.879c1.051 -.89 1.759 -1.516 2.121 -1.879z',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M19 18v.01', key: 'svg-3' }],
]);
