import createVueComponent from '../createVueComponent';
export default createVueComponent('droplet-filled', 'IconDropletFilled', [
  [
    'path',
    {
      d: 'M6.801 11.003a6 6 0 1 0 10.396 -.003l-5.197 -8l-5.199 8.003z',
      stroke: '#010202',
      'stroke-width': '0',
      fill: 'currentColor',
      key: 'svg-0',
    },
  ],
  [
    'path',
    { d: 'M12 3v17', 'stroke-width': '0', fill: 'currentColor', key: 'svg-1' },
  ],
  [
    'path',
    {
      d: 'M12 12l3.544 -3.544',
      'stroke-width': '0',
      fill: 'currentColor',
      key: 'svg-2',
    },
  ],
  [
    'path',
    {
      d: 'M12 17.3l5.558 -5.558',
      'stroke-width': '0',
      fill: 'currentColor',
      key: 'svg-3',
    },
  ],
]);
