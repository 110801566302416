import createVueComponent from '../createVueComponent';
export default createVueComponent('device-tablet', 'IconDeviceTablet', [
  [
    'path',
    {
      d: 'M5 4a1 1 0 0 1 1 -1h12a1 1 0 0 1 1 1v16a1 1 0 0 1 -1 1h-12a1 1 0 0 1 -1 -1v-16z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M11 17a1 1 0 1 0 2 0a1 1 0 0 0 -2 0', key: 'svg-1' }],
]);
