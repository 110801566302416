import createVueComponent from '../createVueComponent';
export default createVueComponent('face-id-error', 'IconFaceIdError', [
  ['path', { d: 'M4 8v-2a2 2 0 0 1 2 -2h2', key: 'svg-0' }],
  ['path', { d: 'M4 16v2a2 2 0 0 0 2 2h2', key: 'svg-1' }],
  ['path', { d: 'M16 4h2a2 2 0 0 1 2 2v2', key: 'svg-2' }],
  ['path', { d: 'M16 20h2a2 2 0 0 0 2 -2v-2', key: 'svg-3' }],
  ['path', { d: 'M9 10h.01', key: 'svg-4' }],
  ['path', { d: 'M15 10h.01', key: 'svg-5' }],
  ['path', { d: 'M9.5 15.05a3.5 3.5 0 0 1 5 0', key: 'svg-6' }],
]);
