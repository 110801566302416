import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-tether', 'IconBrandTether', [
  [
    'path',
    {
      d: 'M14.08 20.188c-1.15 1.083 -3.02 1.083 -4.17 0l-6.93 -6.548c-.96 -.906 -1.27 -2.624 -.69 -3.831l2.4 -5.018c.47 -.991 1.72 -1.791 2.78 -1.791h9.06c1.06 0 2.31 .802 2.78 1.79l2.4 5.019c.58 1.207 .26 2.925 -.69 3.83c-3.453 3.293 -3.466 3.279 -6.94 6.549z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 15v-7', key: 'svg-1' }],
  ['path', { d: 'M8 8h8', key: 'svg-2' }],
]);
