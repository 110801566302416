import createVueComponent from '../createVueComponent';
export default createVueComponent('mail-fast', 'IconMailFast', [
  ['path', { d: 'M3 7h3', key: 'svg-0' }],
  ['path', { d: 'M3 11h2', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M9.02 8.801l-.6 6a2 2 0 0 0 1.99 2.199h7.98a2 2 0 0 0 1.99 -1.801l.6 -6a2 2 0 0 0 -1.99 -2.199h-7.98a2 2 0 0 0 -1.99 1.801z',
      key: 'svg-2',
    },
  ],
  [
    'path',
    { d: 'M9.8 7.5l2.982 3.28a3 3 0 0 0 4.238 .202l3.28 -2.982', key: 'svg-3' },
  ],
]);
