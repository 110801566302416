import createVueComponent from '../createVueComponent';
export default createVueComponent('shield-x', 'IconShieldX', [
  [
    'path',
    {
      d: 'M13.252 20.601c-.408 .155 -.826 .288 -1.252 .399a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3a12 12 0 0 0 8.5 3a12 12 0 0 1 -.19 7.357',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M22 22l-5 -5', key: 'svg-1' }],
  ['path', { d: 'M17 22l5 -5', key: 'svg-2' }],
]);
