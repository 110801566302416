import createVueComponent from '../createVueComponent';
export default createVueComponent('sitemap-off', 'IconSitemapOff', [
  [
    'path',
    {
      d: 'M3 15m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v2a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M19 15a2 2 0 0 1 2 2m-.591 3.42c-.362 .358 -.86 .58 -1.409 .58h-2a2 2 0 0 1 -2 -2v-2c0 -.549 .221 -1.046 .579 -1.407',
      key: 'svg-1',
    },
  ],
  [
    'path',
    { d: 'M9 5a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v2a2 2 0 0 1 -2 2', key: 'svg-2' },
  ],
  ['path', { d: 'M6 15v-1a2 2 0 0 1 2 -2h4m4 0a2 2 0 0 1 2 2', key: 'svg-3' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
