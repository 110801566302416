import createVueComponent from '../createVueComponent';
export default createVueComponent('live-photo', 'IconLivePhoto', [
  ['path', { d: 'M12 12m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-0' }],
  ['path', { d: 'M12 12m-5 0a5 5 0 1 0 10 0a5 5 0 1 0 -10 0', key: 'svg-1' }],
  ['path', { d: 'M15.9 20.11l0 .01', key: 'svg-2' }],
  ['path', { d: 'M19.04 17.61l0 .01', key: 'svg-3' }],
  ['path', { d: 'M20.77 14l0 .01', key: 'svg-4' }],
  ['path', { d: 'M20.77 10l0 .01', key: 'svg-5' }],
  ['path', { d: 'M19.04 6.39l0 .01', key: 'svg-6' }],
  ['path', { d: 'M15.9 3.89l0 .01', key: 'svg-7' }],
  ['path', { d: 'M12 3l0 .01', key: 'svg-8' }],
  ['path', { d: 'M8.1 3.89l0 .01', key: 'svg-9' }],
  ['path', { d: 'M4.96 6.39l0 .01', key: 'svg-10' }],
  ['path', { d: 'M3.23 10l0 .01', key: 'svg-11' }],
  ['path', { d: 'M3.23 14l0 .01', key: 'svg-12' }],
  ['path', { d: 'M4.96 17.61l0 .01', key: 'svg-13' }],
  ['path', { d: 'M8.1 20.11l0 .01', key: 'svg-14' }],
  ['path', { d: 'M12 21l0 .01', key: 'svg-15' }],
]);
