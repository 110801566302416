import createVueComponent from '../createVueComponent';
export default createVueComponent('photo-shield', 'IconPhotoShield', [
  ['path', { d: 'M15 8h.01', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M11.5 20h-4.5a3 3 0 0 1 -3 -3v-10a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v4',
      key: 'svg-1',
    },
  ],
  [
    'path',
    { d: 'M4 15l4 -4c.928 -.893 2.072 -.893 3 0l1.5 1.5', key: 'svg-2' },
  ],
  [
    'path',
    {
      d: 'M22 16c0 4 -2.5 6 -3.5 6s-3.5 -2 -3.5 -6c1 0 2.5 -.5 3.5 -1.5c1 1 2.5 1.5 3.5 1.5z',
      key: 'svg-3',
    },
  ],
]);
