import createVueComponent from '../createVueComponent';
export default createVueComponent('dialpad', 'IconDialpad', [
  [
    'path',
    {
      d: 'M4 3h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M18 3h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M11 3h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1z',
      key: 'svg-2',
    },
  ],
  [
    'path',
    {
      d: 'M4 10h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1z',
      key: 'svg-3',
    },
  ],
  [
    'path',
    {
      d: 'M18 10h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1z',
      key: 'svg-4',
    },
  ],
  [
    'path',
    {
      d: 'M11 10h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1z',
      key: 'svg-5',
    },
  ],
  [
    'path',
    {
      d: 'M11 17h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1z',
      key: 'svg-6',
    },
  ],
]);
