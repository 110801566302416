import createVueComponent from '../createVueComponent';
export default createVueComponent('device-audio-tape', 'IconDeviceAudioTape', [
  [
    'path',
    {
      d: 'M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 17l4 -3h10l4 3', key: 'svg-1' }],
  [
    'circle',
    { cx: '7.5', cy: '9.5', r: '.5', fill: 'currentColor', key: 'svg-2' },
  ],
  [
    'circle',
    { cx: '16.5', cy: '9.5', r: '.5', fill: 'currentColor', key: 'svg-3' },
  ],
]);
