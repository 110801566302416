import createVueComponent from '../createVueComponent';
export default createVueComponent('clothes-rack-off', 'IconClothesRackOff', [
  ['path', { d: 'M12 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M12 7v1m0 4v9', key: 'svg-1' }],
  ['path', { d: 'M9 21h6', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M7.757 9.243a6 6 0 0 0 3.129 1.653m3.578 -.424a6 6 0 0 0 1.779 -1.229',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-4' }],
]);
