import createVueComponent from '../createVueComponent';
export default createVueComponent('device-tablet-x', 'IconDeviceTabletX', [
  [
    'path',
    {
      d: 'M13 21h-7a1 1 0 0 1 -1 -1v-16a1 1 0 0 1 1 -1h12a1 1 0 0 1 1 1v9.5',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M22 22l-5 -5', key: 'svg-1' }],
  ['path', { d: 'M17 22l5 -5', key: 'svg-2' }],
  ['path', { d: 'M11 17a1 1 0 1 0 2 0a1 1 0 0 0 -2 0', key: 'svg-3' }],
]);
