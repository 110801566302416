import createVueComponent from '../createVueComponent';
export default createVueComponent('disc-golf', 'IconDiscGolf', [
  ['path', { d: 'M5 5h14', key: 'svg-0' }],
  ['path', { d: 'M6 5c.32 6.744 2.74 9.246 6 10', key: 'svg-1' }],
  ['path', { d: 'M18 5c-.32 6.744 -2.74 9.246 -6 10', key: 'svg-2' }],
  ['path', { d: 'M10 5c0 4.915 .552 7.082 2 10', key: 'svg-3' }],
  ['path', { d: 'M14 5c0 4.915 -.552 7.082 -2 10', key: 'svg-4' }],
  ['path', { d: 'M12 15v6', key: 'svg-5' }],
  ['path', { d: 'M12 3v2', key: 'svg-6' }],
  [
    'path',
    {
      d: 'M7 16c.64 .64 1.509 1 2.414 1h5.172c.905 0 1.774 -.36 2.414 -1',
      key: 'svg-7',
    },
  ],
  ['path', { d: 'M11 21h2', key: 'svg-8' }],
]);
