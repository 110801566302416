import createVueComponent from '../createVueComponent';
export default createVueComponent('pacman', 'IconPacman', [
  [
    'path',
    {
      d: 'M5.636 5.636a9 9 0 0 1 13.397 .747l-5.619 5.617l5.619 5.617a9 9 0 1 1 -13.397 -11.981z',
      key: 'svg-0',
    },
  ],
  [
    'circle',
    { cx: '11.5', cy: '7.5', r: '1', fill: 'currentColor', key: 'svg-1' },
  ],
]);
