import createVueComponent from '../createVueComponent';
export default createVueComponent('currency-baht', 'IconCurrencyBaht', [
  [
    'path',
    {
      d: 'M8 6h5a3 3 0 0 1 3 3v.143a2.857 2.857 0 0 1 -2.857 2.857h-5.143',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M8 12h5a3 3 0 0 1 3 3v.143a2.857 2.857 0 0 1 -2.857 2.857h-5.143',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M8 6v12', key: 'svg-2' }],
  ['path', { d: 'M11 4v2', key: 'svg-3' }],
  ['path', { d: 'M11 18v2', key: 'svg-4' }],
]);
