import createVueComponent from '../createVueComponent';
export default createVueComponent('mood-x', 'IconMoodX', [
  ['path', { d: 'M20.983 12.556a9 9 0 1 0 -8.433 8.427', key: 'svg-0' }],
  ['path', { d: 'M9 10h.01', key: 'svg-1' }],
  ['path', { d: 'M15 10h.01', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M9.5 15c.658 .64 1.56 1 2.5 1c.194 0 .386 -.015 .574 -.045',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M21.5 21.5l-5 -5', key: 'svg-4' }],
  ['path', { d: 'M16.5 21.5l5 -5', key: 'svg-5' }],
]);
