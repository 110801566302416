import createVueComponent from '../createVueComponent';
export default createVueComponent('adjustments-check', 'IconAdjustmentsCheck', [
  ['path', { d: 'M4 10a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M6 4v4', key: 'svg-1' }],
  ['path', { d: 'M6 12v8', key: 'svg-2' }],
  ['path', { d: 'M13.823 15.176a2 2 0 1 0 -2.638 2.651', key: 'svg-3' }],
  ['path', { d: 'M12 4v10', key: 'svg-4' }],
  ['path', { d: 'M16 7a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-5' }],
  ['path', { d: 'M18 4v1', key: 'svg-6' }],
  ['path', { d: 'M18 9v5', key: 'svg-7' }],
  ['path', { d: 'M15 19l2 2l4 -4', key: 'svg-8' }],
]);
