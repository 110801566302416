import createVueComponent from '../createVueComponent';
export default createVueComponent('pumpkin-scary', 'IconPumpkinScary', [
  ['path', { d: 'M9 15l1.5 1l1.5 -1l1.5 1l1.5 -1', key: 'svg-0' }],
  ['path', { d: 'M10 11h.01', key: 'svg-1' }],
  ['path', { d: 'M14 11h.01', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M17 6.082c2.609 .588 3.627 4.162 2.723 7.983c-.903 3.82 -2.75 6.44 -5.359 5.853a3.355 3.355 0 0 1 -.774 -.279a3.728 3.728 0 0 1 -1.59 .361c-.556 0 -1.09 -.127 -1.59 -.362a3.296 3.296 0 0 1 -.774 .28c-2.609 .588 -4.456 -2.033 -5.36 -5.853c-.903 -3.82 .115 -7.395 2.724 -7.983c1.085 -.244 1.575 .066 2.585 .787c.716 -.554 1.54 -.869 2.415 -.869c.876 0 1.699 .315 2.415 .87c1.01 -.722 1.5 -1.032 2.585 -.788z',
      key: 'svg-3',
    },
  ],
  [
    'path',
    { d: 'M12 6c0 -1.226 .693 -2.346 1.789 -2.894l.211 -.106', key: 'svg-4' },
  ],
]);
