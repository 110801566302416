import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-deliveroo', 'IconBrandDeliveroo', [
  [
    'path',
    {
      d: 'M15 11l1 -9l5 .5l-1 13.5l-3 6l-12.5 -2.5l-1.5 -6l7 -1.5l-1.5 -7.5l4.5 -1z',
      key: 'svg-0',
    },
  ],
  [
    'circle',
    { cx: '15.5', cy: '15.5', r: '1', fill: 'currentColor', key: 'svg-1' },
  ],
  [
    'circle',
    { cx: '11.5', cy: '14.5', r: '1', fill: 'currentColor', key: 'svg-2' },
  ],
]);
