import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-gumroad', 'IconBrandGumroad', [
  ['path', { d: 'M21 12a9 9 0 1 1 -18 0a9 9 0 0 1 18 0z', key: 'svg-0' }],
  ['path', { d: 'M13.5 13h2.5v3', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M15.024 9.382a4 4 0 1 0 -3.024 6.618c1.862 0 2.554 -1.278 3 -3',
      key: 'svg-2',
    },
  ],
]);
