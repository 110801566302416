import createVueComponent from '../createVueComponent';
export default createVueComponent('books', 'IconBooks', [
  [
    'path',
    {
      d: 'M5 4m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M9 4m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M5 8h4', key: 'svg-2' }],
  ['path', { d: 'M9 16h4', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M13.803 4.56l2.184 -.53c.562 -.135 1.133 .19 1.282 .732l3.695 13.418a1.02 1.02 0 0 1 -.634 1.219l-.133 .041l-2.184 .53c-.562 .135 -1.133 -.19 -1.282 -.732l-3.695 -13.418a1.02 1.02 0 0 1 .634 -1.219l.133 -.041z',
      key: 'svg-4',
    },
  ],
  ['path', { d: 'M14 9l4 -1', key: 'svg-5' }],
  ['path', { d: 'M16 16l3.923 -.98', key: 'svg-6' }],
]);
