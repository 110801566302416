import createVueComponent from '../createVueComponent';
export default createVueComponent('photo-pin', 'IconPhotoPin', [
  ['path', { d: 'M15 8h.01', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M12.5 21h-6.5a3 3 0 0 1 -3 -3v-12a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v5.5',
      key: 'svg-1',
    },
  ],
  [
    'path',
    { d: 'M3 16l5 -5c.928 -.893 2.072 -.893 3 0l2.5 2.5', key: 'svg-2' },
  ],
  [
    'path',
    {
      d: 'M21.121 20.121a3 3 0 1 0 -4.242 0c.418 .419 1.125 1.045 2.121 1.879c1.051 -.89 1.759 -1.516 2.121 -1.879z',
      key: 'svg-3',
    },
  ],
  ['path', { d: 'M19 18v.01', key: 'svg-4' }],
]);
