import createVueComponent from '../createVueComponent';
export default createVueComponent('album', 'IconAlbum', [
  [
    'path',
    {
      d: 'M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 4v7l2 -2l2 2v-7', key: 'svg-1' }],
]);
