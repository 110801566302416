import createVueComponent from '../createVueComponent';
export default createVueComponent('luggage', 'IconLuggage', [
  [
    'path',
    {
      d: 'M6 6m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 6v-1a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v1', key: 'svg-1' }],
  ['path', { d: 'M6 10h12', key: 'svg-2' }],
  ['path', { d: 'M6 16h12', key: 'svg-3' }],
  ['path', { d: 'M9 20v1', key: 'svg-4' }],
  ['path', { d: 'M15 20v1', key: 'svg-5' }],
]);
