import createVueComponent from '../createVueComponent';
export default createVueComponent('database-leak', 'IconDatabaseLeak', [
  [
    'path',
    {
      d: 'M4 6c0 1.657 3.582 3 8 3s8 -1.343 8 -3s-3.582 -3 -8 -3s-8 1.343 -8 3',
      key: 'svg-0',
    },
  ],
  [
    'path',
    { d: 'M4 6v12c0 1.657 3.582 3 8 3s8 -1.343 8 -3v-12', key: 'svg-1' },
  ],
  [
    'path',
    {
      d: 'M4 15a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1',
      key: 'svg-2',
    },
  ],
]);
