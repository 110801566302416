import createVueComponent from '../createVueComponent';
export default createVueComponent('mood-heart', 'IconMoodHeart', [
  ['path', { d: 'M21 12a9 9 0 1 0 -8.012 8.946', key: 'svg-0' }],
  ['path', { d: 'M9 10h.01', key: 'svg-1' }],
  ['path', { d: 'M15 10h.01', key: 'svg-2' }],
  ['path', { d: 'M9.5 15a3.59 3.59 0 0 0 2.774 .99', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M18.994 21.5l2.518 -2.58a1.74 1.74 0 0 0 .004 -2.413a1.627 1.627 0 0 0 -2.346 -.005l-.168 .172l-.168 -.172a1.627 1.627 0 0 0 -2.346 -.004a1.74 1.74 0 0 0 -.004 2.412l2.51 2.59z',
      key: 'svg-4',
    },
  ],
]);
