import createVueComponent from '../createVueComponent';
export default createVueComponent('signal-2g', 'IconSignal2g', [
  [
    'path',
    { d: 'M19 8h-3a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h3v-4h-1', key: 'svg-0' },
  ],
  [
    'path',
    {
      d: 'M5 8h4a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-3a1 1 0 0 0 -1 1v2a1 1 0 0 0 1 1h4',
      key: 'svg-1',
    },
  ],
]);
