import createVueComponent from '../createVueComponent';
export default createVueComponent('cash-off', 'IconCashOff', [
  [
    'path',
    {
      d: 'M13 9h6a2 2 0 0 1 2 2v6m-2 2h-10a2 2 0 0 1 -2 -2v-6a2 2 0 0 1 2 -2',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12.582 12.59a2 2 0 0 0 2.83 2.826', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M17 9v-2a2 2 0 0 0 -2 -2h-6m-4 0a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h2',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
