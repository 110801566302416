import createVueComponent from '../createVueComponent';
export default createVueComponent('device-desktop-x', 'IconDeviceDesktopX', [
  [
    'path',
    {
      d: 'M13 16h-9a1 1 0 0 1 -1 -1v-10a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v8',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7 20h6.5', key: 'svg-1' }],
  ['path', { d: 'M9 16v4', key: 'svg-2' }],
  ['path', { d: 'M22 22l-5 -5', key: 'svg-3' }],
  ['path', { d: 'M17 22l5 -5', key: 'svg-4' }],
]);
