import createVueComponent from '../createVueComponent';
export default createVueComponent('lemon-2', 'IconLemon2', [
  [
    'path',
    {
      d: 'M18 4a2 2 0 0 1 1.185 3.611c1.55 2.94 .873 6.917 -1.892 9.682c-2.765 2.765 -6.743 3.442 -9.682 1.892a2 2 0 1 1 -2.796 -2.796c-1.55 -2.94 -.873 -6.917 1.892 -9.682c2.765 -2.765 6.743 -3.442 9.682 -1.892a2 2 0 0 1 1.611 -.815z',
      key: 'svg-0',
    },
  ],
]);
