import createVueComponent from '../createVueComponent';
export default createVueComponent('tir', 'IconTir', [
  ['path', { d: 'M5 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M17 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  [
    'path',
    { d: 'M7 18h8m4 0h2v-6a5 7 0 0 0 -5 -7h-1l1.5 7h4.5', key: 'svg-2' },
  ],
  ['path', { d: 'M12 18v-13h3', key: 'svg-3' }],
  ['path', { d: 'M3 17l0 -5l9 0', key: 'svg-4' }],
]);
