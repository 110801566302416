import createVueComponent from '../createVueComponent';
export default createVueComponent('badge-vo', 'IconBadgeVo', [
  [
    'path',
    {
      d: 'M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7 9l2 6l2 -6', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M15.5 9a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1 -3 0v-3a1.5 1.5 0 0 1 1.5 -1.5z',
      key: 'svg-2',
    },
  ],
]);
