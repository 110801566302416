import createVueComponent from '../createVueComponent';
export default createVueComponent('inner-shadow-left', 'IconInnerShadowLeft', [
  [
    'path',
    {
      d: 'M5.636 5.636a9 9 0 1 1 12.728 12.728a9 9 0 0 1 -12.728 -12.728z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7.757 16.243a6 6 0 0 1 0 -8.486', key: 'svg-1' }],
]);
