import createVueComponent from '../createVueComponent';
export default createVueComponent('movie-off', 'IconMovieOff', [
  [
    'path',
    {
      d: 'M8 4h10a2 2 0 0 1 2 2v10m-.592 3.42c-.362 .359 -.859 .58 -1.408 .58h-12a2 2 0 0 1 -2 -2v-12c0 -.539 .213 -1.028 .56 -1.388',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8 8v12', key: 'svg-1' }],
  ['path', { d: 'M16 4v8m0 4v4', key: 'svg-2' }],
  ['path', { d: 'M4 8h4', key: 'svg-3' }],
  ['path', { d: 'M4 16h4', key: 'svg-4' }],
  ['path', { d: 'M4 12h8m4 0h4', key: 'svg-5' }],
  ['path', { d: 'M16 8h4', key: 'svg-6' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-7' }],
]);
