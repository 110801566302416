import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'arrow-down-right-circle',
  'IconArrowDownRightCircle',
  [
    ['path', { d: 'M8.464 8.464l9.536 9.536', key: 'svg-0' }],
    ['path', { d: 'M14 18h4v-4', key: 'svg-1' }],
    [
      'path',
      {
        d: 'M8.414 8.414a2 2 0 1 0 -2.828 -2.828a2 2 0 0 0 2.828 2.828',
        key: 'svg-2',
      },
    ],
  ],
);
