import createVueComponent from '../createVueComponent';
export default createVueComponent('pill', 'IconPill', [
  [
    'path',
    {
      d: 'M4.5 12.5l8 -8a4.94 4.94 0 0 1 7 7l-8 8a4.94 4.94 0 0 1 -7 -7',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M8.5 8.5l7 7', key: 'svg-1' }],
]);
