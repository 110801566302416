import createVueComponent from '../createVueComponent';
export default createVueComponent('brand-d3', 'IconBrandD3', [
  [
    'path',
    {
      d: 'M3 4h1.8c3.976 0 7.2 3.582 7.2 8s-3.224 8 -7.2 8h-1.8',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M12 4h5.472c1.948 0 3.528 1.79 3.528 4s-1.58 4 -3.528 4',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M17.472 12h-2.472', key: 'svg-2' }],
  ['path', { d: 'M17.472 12h-2.352', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M17.472 12c1.948 0 3.528 1.79 3.528 4s-1.58 4 -3.528 4h-5.472',
      key: 'svg-4',
    },
  ],
]);
