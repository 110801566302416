import createVueComponent from '../createVueComponent';
export default createVueComponent('train', 'IconTrain', [
  ['path', { d: 'M21 13c0 -3.87 -3.37 -7 -10 -7h-8', key: 'svg-0' }],
  ['path', { d: 'M3 15h16a2 2 0 0 0 2 -2', key: 'svg-1' }],
  ['path', { d: 'M3 6v5h17.5', key: 'svg-2' }],
  ['path', { d: 'M3 10l0 4', key: 'svg-3' }],
  ['path', { d: 'M8 11l0 -5', key: 'svg-4' }],
  ['path', { d: 'M13 11l0 -4.5', key: 'svg-5' }],
  ['path', { d: 'M3 19l18 0', key: 'svg-6' }],
]);
