import createVueComponent from '../createVueComponent';
export default createVueComponent('qrcode', 'IconQrcode', [
  [
    'path',
    {
      d: 'M4 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7 17l0 .01', key: 'svg-1' }],
  [
    'path',
    {
      d: 'M14 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M7 7l0 .01', key: 'svg-3' }],
  [
    'path',
    {
      d: 'M4 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z',
      key: 'svg-4',
    },
  ],
  ['path', { d: 'M17 7l0 .01', key: 'svg-5' }],
  ['path', { d: 'M14 14l3 0', key: 'svg-6' }],
  ['path', { d: 'M20 14l0 .01', key: 'svg-7' }],
  ['path', { d: 'M14 14l0 3', key: 'svg-8' }],
  ['path', { d: 'M14 20l3 0', key: 'svg-9' }],
  ['path', { d: 'M17 17l3 0', key: 'svg-10' }],
  ['path', { d: 'M20 17l0 3', key: 'svg-11' }],
]);
