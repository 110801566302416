import createVueComponent from '../createVueComponent';
export default createVueComponent('lock-open-off', 'IconLockOpenOff', [
  [
    'path',
    {
      d: 'M15 11h2a2 2 0 0 1 2 2v2m0 4a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-6a2 2 0 0 1 2 -2h4',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M12 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0', key: 'svg-1' }],
  ['path', { d: 'M8 11v-3m.347 -3.631a4 4 0 0 1 7.653 1.631', key: 'svg-2' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
