import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'git-pull-request-draft',
  'IconGitPullRequestDraft',
  [
    ['path', { d: 'M6 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
    ['path', { d: 'M6 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
    ['path', { d: 'M18 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
    ['path', { d: 'M6 8v8', key: 'svg-3' }],
    ['path', { d: 'M18 11h.01', key: 'svg-4' }],
    ['path', { d: 'M18 6h.01', key: 'svg-5' }],
  ],
);
