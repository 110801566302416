import createVueComponent from '../createVueComponent';
export default createVueComponent('book-download', 'IconBookDownload', [
  [
    'path',
    { d: 'M12 20h-6a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12v5', key: 'svg-0' },
  ],
  ['path', { d: 'M13 16h-7a2 2 0 0 0 -2 2', key: 'svg-1' }],
  ['path', { d: 'M15 19l3 3l3 -3', key: 'svg-2' }],
  ['path', { d: 'M18 22v-9', key: 'svg-3' }],
]);
