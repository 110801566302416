import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'square-rotated-filled',
  'IconSquareRotatedFilled',
  [
    [
      'path',
      {
        d: 'M9.793 2.893l-6.9 6.9c-1.172 1.171 -1.172 3.243 0 4.414l6.9 6.9c1.171 1.172 3.243 1.172 4.414 0l6.9 -6.9c1.172 -1.171 1.172 -3.243 0 -4.414l-6.9 -6.9c-1.171 -1.172 -3.243 -1.172 -4.414 0z',
        'stroke-width': '0',
        fill: 'currentColor',
        key: 'svg-0',
      },
    ],
  ],
);
