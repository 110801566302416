import createVueComponent from '../createVueComponent';
export default createVueComponent('file-broken', 'IconFileBroken', [
  ['path', { d: 'M14 3v4a1 1 0 0 0 1 1h4', key: 'svg-0' }],
  ['path', { d: 'M5 7v-2a2 2 0 0 1 2 -2h7l5 5v2', key: 'svg-1' }],
  ['path', { d: 'M19 19a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2', key: 'svg-2' }],
  ['path', { d: 'M5 16h.01', key: 'svg-3' }],
  ['path', { d: 'M5 13h.01', key: 'svg-4' }],
  ['path', { d: 'M5 10h.01', key: 'svg-5' }],
  ['path', { d: 'M19 13h.01', key: 'svg-6' }],
  ['path', { d: 'M19 16h.01', key: 'svg-7' }],
]);
