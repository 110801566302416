import createVueComponent from '../createVueComponent';
export default createVueComponent('square-f3', 'IconSquareF3', [
  [
    'path',
    {
      d: 'M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M13 9.5a.5 .5 0 0 1 .5 -.5h1a1.5 1.5 0 0 1 0 3h-.5h.5a1.5 1.5 0 0 1 0 3h-1a.5 .5 0 0 1 -.5 -.5',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M8 12h2', key: 'svg-2' }],
  ['path', { d: 'M10 9h-2v6', key: 'svg-3' }],
]);
