import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'device-tablet-search',
  'IconDeviceTabletSearch',
  [
    [
      'path',
      {
        d: 'M11.5 21h-5.5a1 1 0 0 1 -1 -1v-16a1 1 0 0 1 1 -1h12a1 1 0 0 1 1 1v7',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0', key: 'svg-1' }],
    ['path', { d: 'M20.2 20.2l1.8 1.8', key: 'svg-2' }],
  ],
);
