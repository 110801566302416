import createVueComponent from '../createVueComponent';
export default createVueComponent('device-imac-minus', 'IconDeviceImacMinus', [
  [
    'path',
    {
      d: 'M12.5 17h-8.5a1 1 0 0 1 -1 -1v-12a1 1 0 0 1 1 -1h16a1 1 0 0 1 1 1v11',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M3 13h18', key: 'svg-1' }],
  ['path', { d: 'M8 21h4.5', key: 'svg-2' }],
  ['path', { d: 'M10 17l-.5 4', key: 'svg-3' }],
  ['path', { d: 'M16 19h6', key: 'svg-4' }],
]);
