import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'adjustments-exclamation',
  'IconAdjustmentsExclamation',
  [
    ['path', { d: 'M4 10a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-0' }],
    ['path', { d: 'M6 4v4', key: 'svg-1' }],
    ['path', { d: 'M6 12v8', key: 'svg-2' }],
    ['path', { d: 'M10 16a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-3' }],
    ['path', { d: 'M12 4v10', key: 'svg-4' }],
    ['path', { d: 'M12 18v2', key: 'svg-5' }],
    ['path', { d: 'M16 7a2 2 0 1 0 4 0a2 2 0 0 0 -4 0', key: 'svg-6' }],
    ['path', { d: 'M18 4v1', key: 'svg-7' }],
    ['path', { d: 'M18 9v3', key: 'svg-8' }],
    ['path', { d: 'M19 16v3', key: 'svg-9' }],
    ['path', { d: 'M19 22v.01', key: 'svg-10' }],
  ],
);
