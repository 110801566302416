import createVueComponent from '../createVueComponent';
export default createVueComponent('leaf', 'IconLeaf', [
  ['path', { d: 'M5 21c.5 -4.5 2.5 -8 7 -10', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M9 18c6.218 0 10.5 -3.288 11 -12v-2h-4.014c-9 0 -11.986 4 -12 9c0 1 0 3 2 5h3z',
      key: 'svg-1',
    },
  ],
]);
