import createVueComponent from '../createVueComponent';
export default createVueComponent('jump-rope', 'IconJumpRope', [
  ['path', { d: 'M6 14v-6a3 3 0 1 1 6 0v8a3 3 0 0 0 6 0v-6', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M16 3m0 2a2 2 0 0 1 2 -2h0a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h0a2 2 0 0 1 -2 -2z',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M4 14m0 2a2 2 0 0 1 2 -2h0a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h0a2 2 0 0 1 -2 -2z',
      key: 'svg-2',
    },
  ],
]);
