import createVueComponent from '../createVueComponent';
export default createVueComponent('graph', 'IconGraph', [
  [
    'path',
    {
      d: 'M4 18v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M7 14l3 -3l2 2l3 -3l2 2', key: 'svg-1' }],
]);
