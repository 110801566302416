import createVueComponent from '../createVueComponent';
export default createVueComponent('sort-descending-2', 'IconSortDescending2', [
  [
    'path',
    {
      d: 'M5 5m0 .5a.5 .5 0 0 1 .5 -.5h4a.5 .5 0 0 1 .5 .5v4a.5 .5 0 0 1 -.5 .5h-4a.5 .5 0 0 1 -.5 -.5z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M5 14m0 .5a.5 .5 0 0 1 .5 -.5h4a.5 .5 0 0 1 .5 .5v4a.5 .5 0 0 1 -.5 .5h-4a.5 .5 0 0 1 -.5 -.5z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M14 15l3 3l3 -3', key: 'svg-2' }],
  ['path', { d: 'M17 18v-12', key: 'svg-3' }],
]);
