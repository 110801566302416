import createVueComponent from '../createVueComponent';
export default createVueComponent('calculator', 'IconCalculator', [
  [
    'path',
    {
      d: 'M4 3m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M8 7m0 1a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-6a1 1 0 0 1 -1 -1z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M8 14l0 .01', key: 'svg-2' }],
  ['path', { d: 'M12 14l0 .01', key: 'svg-3' }],
  ['path', { d: 'M16 14l0 .01', key: 'svg-4' }],
  ['path', { d: 'M8 17l0 .01', key: 'svg-5' }],
  ['path', { d: 'M12 17l0 .01', key: 'svg-6' }],
  ['path', { d: 'M16 17l0 .01', key: 'svg-7' }],
]);
