import createVueComponent from '../createVueComponent';
export default createVueComponent('id', 'IconId', [
  [
    'path',
    {
      d: 'M3 4m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v10a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M9 10m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M15 8l2 0', key: 'svg-2' }],
  ['path', { d: 'M15 12l2 0', key: 'svg-3' }],
  ['path', { d: 'M7 16l10 0', key: 'svg-4' }],
]);
