import createVueComponent from '../createVueComponent';
export default createVueComponent('box-multiple-1', 'IconBoxMultiple1', [
  [
    'path',
    {
      d: 'M7 3m0 2a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M17 17v2a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M14 14v-8l-2 2', key: 'svg-2' }],
]);
