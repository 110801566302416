import createVueComponent from '../createVueComponent';
export default createVueComponent('tool', 'IconTool', [
  [
    'path',
    {
      d: 'M7 10h3v-3l-3.5 -3.5a6 6 0 0 1 8 8l6 6a2 2 0 0 1 -3 3l-6 -6a6 6 0 0 1 -8 -8l3.5 3.5',
      key: 'svg-0',
    },
  ],
]);
