import createVueComponent from '../createVueComponent';
export default createVueComponent('info-square', 'IconInfoSquare', [
  ['path', { d: 'M12 9h.01', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M3 5a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-14z',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M11 12h1v4h1', key: 'svg-2' }],
]);
