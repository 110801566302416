import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'adjustments-horizontal',
  'IconAdjustmentsHorizontal',
  [
    ['path', { d: 'M14 6m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
    ['path', { d: 'M4 6l8 0', key: 'svg-1' }],
    ['path', { d: 'M16 6l4 0', key: 'svg-2' }],
    ['path', { d: 'M8 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-3' }],
    ['path', { d: 'M4 12l2 0', key: 'svg-4' }],
    ['path', { d: 'M10 12l10 0', key: 'svg-5' }],
    ['path', { d: 'M17 18m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-6' }],
    ['path', { d: 'M4 18l11 0', key: 'svg-7' }],
    ['path', { d: 'M19 18l1 0', key: 'svg-8' }],
  ],
);
