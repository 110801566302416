import createVueComponent from '../createVueComponent';
export default createVueComponent('h-2', 'IconH2', [
  [
    'path',
    {
      d: 'M17 12a2 2 0 1 1 4 0c0 .591 -.417 1.318 -.816 1.858l-3.184 4.143l4 0',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M4 6v12', key: 'svg-1' }],
  ['path', { d: 'M12 6v12', key: 'svg-2' }],
  ['path', { d: 'M11 18h2', key: 'svg-3' }],
  ['path', { d: 'M3 18h2', key: 'svg-4' }],
  ['path', { d: 'M4 12h8', key: 'svg-5' }],
  ['path', { d: 'M3 6h2', key: 'svg-6' }],
  ['path', { d: 'M11 6h2', key: 'svg-7' }],
]);
