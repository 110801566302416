import createVueComponent from '../createVueComponent';
export default createVueComponent('printer-off', 'IconPrinterOff', [
  [
    'path',
    {
      d: 'M20.412 16.416c.363 -.362 .588 -.863 .588 -1.416v-4a2 2 0 0 0 -2 -2h-6m-4 0h-4a2 2 0 0 0 -2 2v4a2 2 0 0 0 2 2h2',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M17 9v-4a2 2 0 0 0 -2 -2h-6c-.551 0 -1.05 .223 -1.412 .584m-.588 3.416v2',
      key: 'svg-1',
    },
  ],
  [
    'path',
    {
      d: 'M17 17v2a2 2 0 0 1 -2 2h-6a2 2 0 0 1 -2 -2v-4a2 2 0 0 1 2 -2h4',
      key: 'svg-2',
    },
  ],
  ['path', { d: 'M3 3l18 18', key: 'svg-3' }],
]);
