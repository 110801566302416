import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'arrow-big-right-lines',
  'IconArrowBigRightLines',
  [
    [
      'path',
      {
        d: 'M12 9v-3.586a1 1 0 0 1 1.707 -.707l6.586 6.586a1 1 0 0 1 0 1.414l-6.586 6.586a1 1 0 0 1 -1.707 -.707v-3.586h-3v-6h3z',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M3 9v6', key: 'svg-1' }],
    ['path', { d: 'M6 9v6', key: 'svg-2' }],
  ],
);
