import createVueComponent from '../createVueComponent';
export default createVueComponent('wrecking-ball', 'IconWreckingBall', [
  ['path', { d: 'M19 13m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M4 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M13 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
  ['path', { d: 'M13 19l-9 0', key: 'svg-3' }],
  ['path', { d: 'M4 15l9 0', key: 'svg-4' }],
  ['path', { d: 'M8 12v-5h2a3 3 0 0 1 3 3v5', key: 'svg-5' }],
  ['path', { d: 'M5 15v-2a1 1 0 0 1 1 -1h7', key: 'svg-6' }],
  ['path', { d: 'M19 11v-7l-6 7', key: 'svg-7' }],
]);
