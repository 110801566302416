import createVueComponent from '../createVueComponent';
export default createVueComponent('axe', 'IconAxe', [
  [
    'path',
    {
      d: 'M13 9l7.383 7.418c.823 .82 .823 2.148 0 2.967a2.11 2.11 0 0 1 -2.976 0l-7.407 -7.385',
      key: 'svg-0',
    },
  ],
  [
    'path',
    {
      d: 'M6.66 15.66l-3.32 -3.32a1.25 1.25 0 0 1 .42 -2.044l3.24 -1.296l6 -6l3 3l-6 6l-1.296 3.24a1.25 1.25 0 0 1 -2.044 .42z',
      key: 'svg-1',
    },
  ],
]);
