import createVueComponent from '../createVueComponent';
export default createVueComponent('friends', 'IconFriends', [
  ['path', { d: 'M7 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M5 22v-5l-1 -1v-4a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4l-1 1v5',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M17 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M15 22v-4h-2l2 -6a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1l2 6h-2v4',
      key: 'svg-3',
    },
  ],
]);
