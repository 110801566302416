import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'augmented-reality-2',
  'IconAugmentedReality2',
  [
    [
      'path',
      {
        d: 'M10 21h-2a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v3.5',
        key: 'svg-0',
      },
    ],
    ['path', { d: 'M17 17l-4 -2.5l4 -2.5l4 2.5v4.5l-4 2.5z', key: 'svg-1' }],
    ['path', { d: 'M13 14.5v4.5l4 2.5', key: 'svg-2' }],
    ['path', { d: 'M17 17l4 -2.5', key: 'svg-3' }],
    ['path', { d: 'M11 4h2', key: 'svg-4' }],
  ],
);
