import createVueComponent from '../createVueComponent';
export default createVueComponent('text-resize', 'IconTextResize', [
  ['path', { d: 'M5 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-0' }],
  ['path', { d: 'M19 5m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-1' }],
  ['path', { d: 'M5 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-2' }],
  ['path', { d: 'M19 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0', key: 'svg-3' }],
  ['path', { d: 'M5 7v10', key: 'svg-4' }],
  ['path', { d: 'M7 5h10', key: 'svg-5' }],
  ['path', { d: 'M7 19h10', key: 'svg-6' }],
  ['path', { d: 'M19 7v10', key: 'svg-7' }],
  ['path', { d: 'M10 10h4', key: 'svg-8' }],
  ['path', { d: 'M12 14v-4', key: 'svg-9' }],
]);
