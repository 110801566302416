import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'ease-in-out-control-points',
  'IconEaseInOutControlPoints',
  [
    ['path', { d: 'M17 20a2 2 0 1 0 4 0a2 2 0 0 0 -4 0z', key: 'svg-0' }],
    ['path', { d: 'M17 20h-2', key: 'svg-1' }],
    ['path', { d: 'M7 4a2 2 0 1 1 -4 0a2 2 0 0 1 4 0z', key: 'svg-2' }],
    ['path', { d: 'M7 4h2', key: 'svg-3' }],
    ['path', { d: 'M14 4h-2', key: 'svg-4' }],
    ['path', { d: 'M12 20h-2', key: 'svg-5' }],
    ['path', { d: 'M3 20c8 0 10 -16 18 -16', key: 'svg-6' }],
  ],
);
