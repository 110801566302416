import createVueComponent from '../createVueComponent';
export default createVueComponent('columns-off', 'IconColumnsOff', [
  ['path', { d: 'M4 6h2', key: 'svg-0' }],
  ['path', { d: 'M4 10h5.5', key: 'svg-1' }],
  ['path', { d: 'M4 14h5.5', key: 'svg-2' }],
  ['path', { d: 'M4 18h5.5', key: 'svg-3' }],
  ['path', { d: 'M14.5 6h5.5', key: 'svg-4' }],
  ['path', { d: 'M14.5 10h5.5', key: 'svg-5' }],
  ['path', { d: 'M18 14h2', key: 'svg-6' }],
  ['path', { d: 'M14.5 18h3.5', key: 'svg-7' }],
  ['path', { d: 'M3 3l18 18', key: 'svg-8' }],
]);
