import createVueComponent from '../createVueComponent';
export default createVueComponent('wash-dry-a', 'IconWashDryA', [
  ['path', { d: 'M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0', key: 'svg-0' }],
  [
    'path',
    {
      d: 'M9 16v-4.8c0 -1.657 1.343 -3.2 3 -3.2s3 1.543 3 3.2v4.8',
      key: 'svg-1',
    },
  ],
  ['path', { d: 'M15 13h-6', key: 'svg-2' }],
]);
