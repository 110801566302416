import createVueComponent from '../createVueComponent';
export default createVueComponent('shield-up', 'IconShieldUp', [
  [
    'path',
    {
      d: 'M12.442 20.876a13.12 13.12 0 0 1 -.442 .124a12 12 0 0 1 -8.5 -15a12 12 0 0 0 8.5 -3a12 12 0 0 0 8.5 3a12 12 0 0 1 .119 6.336',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M19 22v-6', key: 'svg-1' }],
  ['path', { d: 'M22 19l-3 -3l-3 3', key: 'svg-2' }],
]);
