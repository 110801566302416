import createVueComponent from '../createVueComponent';
export default createVueComponent('user-question', 'IconUserQuestion', [
  ['path', { d: 'M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0', key: 'svg-0' }],
  ['path', { d: 'M6 21v-2a4 4 0 0 1 4 -4h3.5', key: 'svg-1' }],
  ['path', { d: 'M19 22v.01', key: 'svg-2' }],
  [
    'path',
    {
      d: 'M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483',
      key: 'svg-3',
    },
  ],
]);
