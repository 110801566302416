import createVueComponent from '../createVueComponent';
export default createVueComponent('folder-share', 'IconFolderShare', [
  [
    'path',
    {
      d: 'M13 19h-8a2 2 0 0 1 -2 -2v-11a2 2 0 0 1 2 -2h4l3 3h7a2 2 0 0 1 2 2v4',
      key: 'svg-0',
    },
  ],
  ['path', { d: 'M16 22l5 -5', key: 'svg-1' }],
  ['path', { d: 'M21 21.5v-4.5h-4.5', key: 'svg-2' }],
]);
