import createVueComponent from '../createVueComponent';
export default createVueComponent(
  'circle-chevrons-up',
  'IconCircleChevronsUp',
  [
    ['path', { d: 'M9 15l3 -3l3 3', key: 'svg-0' }],
    ['path', { d: 'M9 11l3 -3l3 3', key: 'svg-1' }],
    ['path', { d: 'M12 21a9 9 0 1 0 -.265 0l.265 0z', key: 'svg-2' }],
  ],
);
